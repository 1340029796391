import { useState, FC, FormEvent } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import LoadingSpinner from "../utilities/LoadingSpinner";
import { PATHS } from "../utilities/Paths";
import { restablecerContrasena } from "../services/public-api-client";
import Header from "./Header";

const RecuperarContraseña: FC = () => {
  const [correo, setCorreo] = useState<string>("");
  const [contraseñaRestablecidaModalIsOpen, setContraseñaRestablecidaModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRecuperarContraseña = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    restablecerContrasena(correo)
      .then(() => setContraseñaRestablecidaModalIsOpen(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Header />
      <h1>Recuperar contraseña</h1>
      <p>Ingrese su correo electrónico para recuperar su contraseña.</p>
      <Form onSubmit={handleRecuperarContraseña}>
        <FormGroup floating>
          <Input
            type="email"
            id="correo"
            placeholder="Correo electrónico"
            value={correo}
            onChange={(e) => setCorreo(e.target.value)}
            required
          />
          <Label for="correo">Correo electrónico</Label>
          <FormText>Se enviará un correo electrónico con una contraseña temporal.</FormText>
        </FormGroup>
        <Button type="submit" color="primary">
          Recuperar contraseña
        </Button>
      </Form>
      <Modal isOpen={contraseñaRestablecidaModalIsOpen}>
        <ModalHeader>Contraseña restablecida</ModalHeader>
        <ModalBody>
          Se ha enviado un correo electrónico con una contraseña temporal. Ingrese con la contraseña
          temporal y cámbiela por una nueva.
        </ModalBody>
        <ModalFooter>
          <LinkContainer to={{ pathname: PATHS.inicio.to, hash: "login" }}>
            <Button color="primary">Ir a iniciar sesión</Button>
          </LinkContainer>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RecuperarContraseña;
