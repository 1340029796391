import { FC, useEffect } from "react";
import axios from "axios";
import { Link, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ContextProvider } from "./components/Context";
import { PATHS } from "./utilities/Paths";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Registro from "./components/Registro";
import ConfirmarCorreo from "./components/ConfirmarCorreo";
import CorreoDeConfirmacionEnviado from "./components/CorreoDeConfirmacionEnviado";
import SolicitudDeRegistro from "./components/SolicitudDeRegistro";
import RecuperarContraseña from "./components/RecuperarContrasena";
import Logout from "./components/Logout";
import AdminLogin from "./components/AdminLogin";
import LayoutAdmin from "./components/admin/LayoutAdmin";
import AdminHome from "./components/admin/AdminHome";
import Registros from "./components/admin/Registros";
import DocumentosLegales from "./components/admin/DocumentosLegales";
import NuevoDocumentoLegal from "./components/admin/DocumentosLegales/NuevoDocumentoLegal";
import GestionarAutoridades from "./components/admin/GestionarAutoridades";
import GestionarDocumentosSugeridos from "./components/admin/GestionarDocumentosSugeridos";
import LayoutUsuario from "./components/usuario/Layout";
import UsuarioHome from "./components/usuario/UsuarioHome";
import Perfil from "./components/usuario/Perfil";
import AdministracionDeUsuarios from "./components/usuario/AdministracionDeUsuarios";
import FormacionYTomaDeConciencia from "./components/usuario/FormacionYTomaDeConciencia";
import Cursos from "./components/usuario/FormacionYTomaDeConciencia/Cursos";
import CursoView from "./components/usuario/FormacionYTomaDeConciencia/CursoView";
import NuevoCurso from "./components/usuario/FormacionYTomaDeConciencia/NuevoCurso";
import EditarCurso from "./components/usuario/FormacionYTomaDeConciencia/EditarCurso";
import RequisitosLegales from "./components/usuario/RequisitosLegales";
import TablaDeAnalisisDeRequisitoLegal from "./components/usuario/RequisitosLegales/TablaAnalisisDeRequisitoLegal";
import GestionDocumental from "./components/usuario/GestionDocumental";
import InvestigacionDeIncidentes from "./components/usuario/InvestigacionDeIncidentes";
import PlanesDeAccion from "./components/usuario/PlanesDeAccion";
import PlanDeAccion from "./components/usuario/PlanesDeAccion/PlanDeAccionView";
import Accion from "./components/usuario/PlanesDeAccion/AccionView";
import IdentificacionDePeligrosYEvaluacionDeRiesgos from "./components/usuario/IdentificacionDePeligrosYEvaluacionDeRiesgos";
import IndicadoresDeGestion from "./components/usuario/IndicadoresDeGestion";
import Contexto from "./components/usuario/Contexto";
import NuevaParteInteresada from "./components/usuario/Contexto/NuevaParteInteresada";
import AgregarEmpresa from "./components/usuario/AgregarEmpresa";
import EditarEmpresa from "./components/usuario/EditarEmpresa";
import ConfirmarAutorizacion from "./components/usuario/ConfirmarAutorizacion";
import GestionDocumentalInterna from "./components/usuario/GestionDocumental/Interna";
import AgregarDocumentoInterno from "./components/usuario/GestionDocumental/Interna/AgregarDocumentoInterno";
import DocumentoView from "./components/usuario/GestionDocumental/Interna/DocumentoView";
import AdministracionDeContratos from "./components/usuario/GestionDocumental/AdministracionDeContratos";
import ConfiguracionDeContrato from "./components/usuario/GestionDocumental/AdministracionDeContratos/ConfiguracionDeContrato";
import DocumentoDelContratoView from "./components/usuario/GestionDocumental/AdministracionDeContratos/DocumentoDelContratoView";
import NuevoRequisitoLegal from "./components/usuario/RequisitosLegales/NuevoRequisitoLegal";
import RequisitoLegalView from "./components/usuario/RequisitosLegales/RequisitoLegalView";
import AgregarAnalisisDeRequisitoLegal from "./components/usuario/RequisitosLegales/AgregarAnalisisDeRequisitoLegal";
import NuevaActividad from "./components/usuario/IdentificacionDePeligrosYEvaluacionDeRiesgos/Actividad/NuevaActividad";
import EditarActividad from "./components/usuario/IdentificacionDePeligrosYEvaluacionDeRiesgos/Actividad/EditarActividad";
import NuevoRiesgo from "./components/usuario/IdentificacionDePeligrosYEvaluacionDeRiesgos/Riesgo/NuevoRiesgo";
import ActividadesEvaluadas from "./components/usuario/IdentificacionDePeligrosYEvaluacionDeRiesgos/Resultados/ActividadesEvaluadas";
import EditarRiesgo from "./components/usuario/IdentificacionDePeligrosYEvaluacionDeRiesgos/Riesgo/EditarRiesgo";
import AgregarIncidente from "./components/usuario/InvestigacionDeIncidentes/AgregarIncidente";
import EditarIncidente from "./components/usuario/InvestigacionDeIncidentes/EditarIncidente";
import IncidenteView from "./components/usuario/InvestigacionDeIncidentes/IncidenteView";
import WidgetsLayout from "./components/widgets/Layout";
import WidgetDocumentosLegales from "./components/widgets/DocumentosLegales";

const router = createBrowserRouter([
  {
    path: PATHS.inicio.to,
    element: <Layout />,
    children: [
      {
        path: PATHS.inicio.to,
        element: <Home />,
      },
      {
        path: PATHS.registro.to,
        element: <Registro />,
      },
      {
        path: PATHS.logout.to,
        element: <Logout />,
      },
      {
        path: PATHS.confirmarCorreo.to,
        element: <ConfirmarCorreo />,
      },
      {
        path: PATHS.correoEnviado.to,
        element: <CorreoDeConfirmacionEnviado />,
      },
      {
        path: PATHS.solicitudDeRegistro.to,
        element: <SolicitudDeRegistro />,
      },
      {
        path: PATHS.recuperarContrasena.to,
        element: <RecuperarContraseña />,
      },
      {
        path: PATHS.adminLogin.to,
        element: <AdminLogin />,
      },
      {
        path: "*",
        element: <NoMatch />,
      },
    ],
  },
  {
    path: PATHS.usuarioHome.to,
    element: <LayoutUsuario />,
    children: [
      {
        path: PATHS.usuarioHome.to,
        element: <UsuarioHome />,
      },
      {
        path: PATHS.perfil.to,
        element: <Perfil />,
      },
      {
        path: PATHS.administracionDeUsuarios.to,
        element: <AdministracionDeUsuarios />,
      },
      {
        path: PATHS.formacion.to,
        element: <FormacionYTomaDeConciencia />,
      },
      {
        path: PATHS.cursos.to,
        element: <Cursos />,
      },
      {
        path: PATHS.curso.to,
        element: <CursoView />,
      },
      {
        path: PATHS.nuevoCurso.to,
        element: <NuevoCurso />,
      },
      {
        path: PATHS.editarCurso.to,
        element: <EditarCurso />,
      },
      {
        path: PATHS.requisitosLegales.to,
        element: <RequisitosLegales />,
      },
      {
        path: PATHS.nuevoRequisitoLegal.to,
        element: <NuevoRequisitoLegal />,
      },
      {
        path: PATHS.requisitoLegal.to,
        element: <RequisitoLegalView />,
      },
      {
        path: PATHS.analisisDeRequisitoLegal.to,
        element: <TablaDeAnalisisDeRequisitoLegal />,
      },
      {
        path: PATHS.nuevoAnalisisDeRequisitoLegal.to,
        element: <AgregarAnalisisDeRequisitoLegal />,
      },
      {
        path: PATHS.gestionDocumental.to,
        element: <GestionDocumental />,
      },
      {
        path: PATHS.gestionDocumentalInterna.to,
        element: <GestionDocumentalInterna />,
      },
      {
        path: PATHS.nuevoDocumentoInterno.to,
        element: <AgregarDocumentoInterno />,
      },
      {
        path: PATHS.documentoInterno.to,
        element: <DocumentoView />,
      },
      {
        path: PATHS.gestionDocumentalContratos.to,
        element: <AdministracionDeContratos />,
      },
      {
        path: PATHS.configuracionDeContrato.to,
        element: <ConfiguracionDeContrato />,
      },
      {
        path: PATHS.documentoDelContrato.to,
        element: <DocumentoDelContratoView />,
      },
      {
        path: PATHS.investigacionDeIncidentes.to,
        element: <InvestigacionDeIncidentes />,
      },
      {
        path: PATHS.nuevoIncidente.to,
        element: <AgregarIncidente />,
      },
      {
        path: PATHS.incidente.to,
        element: <IncidenteView />,
      },
      {
        path: PATHS.editarIncidente.to,
        element: <EditarIncidente />,
      },
      {
        path: PATHS.planesDeAccion.to,
        element: <PlanesDeAccion />,
      },
      {
        path: PATHS.planDeAccion.to,
        element: <PlanDeAccion />,
      },
      {
        path: PATHS.accion.to,
        element: <Accion />,
      },
      {
        path: PATHS.miper.to,
        element: <IdentificacionDePeligrosYEvaluacionDeRiesgos />,
      },
      {
        path: PATHS.nuevaActividad.to,
        element: <NuevaActividad />,
      },
      {
        path: PATHS.editarActividad.to,
        element: <EditarActividad />,
      },
      {
        path: PATHS.asignarRiesgos.to,
        element: <NuevoRiesgo />,
      },
      {
        path: PATHS.editarEvaluacion.to,
        element: <EditarRiesgo />,
      },
      {
        path: PATHS.resultadosDeRiesgos.to,
        element: <ActividadesEvaluadas />,
      },
      {
        path: PATHS.contexto.to,
        element: <Contexto />,
      },
      {
        path: PATHS.nuevaParteInteresada.to,
        element: <NuevaParteInteresada />,
      },
      {
        path: PATHS.indicadores.to,
        element: <IndicadoresDeGestion />,
      },
      {
        path: PATHS.nuevaEmpresa.to,
        element: <AgregarEmpresa />,
      },
      {
        path: PATHS.editarEmpresa.to,
        element: <EditarEmpresa />,
      },
      {
        path: PATHS.autorizarUsuario.to,
        element: <ConfirmarAutorizacion />,
      },
    ],
  },
  {
    path: PATHS.adminHome.to,
    element: <LayoutAdmin />,
    children: [
      {
        path: PATHS.adminHome.to,
        element: <AdminHome />,
      },
      {
        path: PATHS.adminRegistros.to,
        element: <Registros />,
      },
      {
        path: PATHS.adminDocumentosLegales.to,
        element: <DocumentosLegales />,
      },
      {
        path: PATHS.adminNuevoDocumentoLegal.to,
        element: <NuevoDocumentoLegal />,
      },
      {
        path: PATHS.adminAutoridades.to,
        element: <GestionarAutoridades />,
      },
      {
        path: PATHS.adminDocumentosSugeridos.to,
        element: <GestionarDocumentosSugeridos />,
      },
    ],
  },
  {
    path: PATHS.widgets.to,
    element: <WidgetsLayout />,
    children: [
      {
        path: PATHS.widgetDocumentosLegales.to,
        element: <WidgetDocumentosLegales />,
      },
    ],
  },
]);

function NoMatch() {
  return (
    <div>
      <h2>GISST</h2>
      <h3>Página no encontrada</h3>
      <Link to={PATHS.inicio.to}>Volver</Link>
    </div>
  );
}

const App: FC = () => {
  useEffect(() => {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.timeout = 10000;
  }, []);

  return (
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  );
};

export default App;
