import { useContext, FC, useState, useEffect } from "react";
import {
  Button,
  Collapse,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
} from "reactstrap";
import { ReactFontSizeByTextLength } from "react-font-size-by-text-length";
import { FaSignOutAlt } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GISSTLogo } from "../../resources/Images";
import { Context } from "../Context";
import LocalStorage from "../../utilities/LocalStorage";
import { PATHS } from "../../utilities/Paths";
import { SeparatorCol } from "../../utilities/utils";

const LogoContainer = styled.div`
  height: 40px;
`;

const HeaderAdmin: FC = () => {
  const navigate = useNavigate();
  const { tituloDeLaPantallaActual } = useContext(Context);
  const [isOpenOffcanvas, setIsOpenOffcanvas] = useState<boolean>(false);

  const toggleOffcanvas = () => {
    setIsOpenOffcanvas(!isOpenOffcanvas);
  };

  useEffect(() => {
    LocalStorage.isAdminLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        navigate(PATHS.adminLogin.to);
      }
    });
  }, [navigate]);

  return (
    <>
      <Navbar light container fixed="top" className="bg-white" expand="md">
        <LinkContainer to="/admin">
          <NavbarBrand>
            <LogoContainer>
              <GISSTLogo />
            </LogoContainer>
          </NavbarBrand>
        </LinkContainer>
        <h2>
          <ReactFontSizeByTextLength startAtChar={15} changePerChar={3} minPercent={50}>
            <b>{tituloDeLaPantallaActual}</b>
          </ReactFontSizeByTextLength>
        </h2>
        <NavbarToggler onClick={toggleOffcanvas} />
        <Collapse navbar className="flex-grow-0">
          <Nav navbar tag="div">
            <NavItem tag="div">
              <Row className="align-items-center h-100 gx-0">
                <SeparatorCol width={56}>
                  <LinkContainer to={PATHS.logout.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted px-2"
                      title="Cerrar sesión"
                    >
                      <FaSignOutAlt size={25} />
                    </Button>
                  </LinkContainer>
                </SeparatorCol>
              </Row>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Offcanvas isOpen={isOpenOffcanvas} toggle={toggleOffcanvas} unmountOnClose={false}>
        <OffcanvasHeader toggle={toggleOffcanvas}>
          <LogoContainer>
            <GISSTLogo />
          </LogoContainer>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Nav pills vertical>
                <NavItem>
                  <NavLink to="/admin/registros" className="nav-link" onClick={toggleOffcanvas}>
                    Registros
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="/admin/documentos-legales"
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Documentos Legales
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/admin/autoridades" className="nav-link" onClick={toggleOffcanvas}>
                    Gestionar Autoridades
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
};

export default HeaderAdmin;
