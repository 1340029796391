import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { OrigenDePlanDeAccion, PlanDeAccion } from "../../../models/PlanDeAccion";
import { PATHS, procesarPath } from "../../../utilities/Paths";

interface Props {
  contextoId: string;
  planesDeAccion: PlanDeAccion[];
}

const BotonPlanDeAccion: FC<Props> = ({ contextoId, planesDeAccion }) => {
  const navigate = useNavigate();

  const planDeAccion = planesDeAccion.find(({ fuente }) => fuente?.toString() === contextoId);

  return (
    <>
      {!!planDeAccion ? (
        <Button
          type="button"
          color="primary"
          className="small"
          onClick={() => navigate(procesarPath(PATHS.planDeAccion, planDeAccion._id))}
        >
          Ver
        </Button>
      ) : (
        <Button
          type="button"
          color="primary"
          className="small"
          onClick={() =>
            navigate(PATHS.planesDeAccion.to, {
              state: { origen: OrigenDePlanDeAccion.Contexto, fuente: contextoId },
            })
          }
        >
          Crear
        </Button>
      )}
    </>
  );
};

export default BotonPlanDeAccion;
