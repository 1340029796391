import { FC, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { confirmarCorreo } from "../services/public-api-client";
import Header from "./Header";

const ConfirmarCorreo: FC = () => {
  const { token } = useParams<{ token: string }>();
  const [valido, setValido] = useState<boolean | null>(null);

  useEffect(() => {
    if (!token) return;
    confirmarCorreo(token).then(({ data }) => {
      setValido(data.valido);
    });
  }, [token]);

  return (
    <>
      <Header />
      <Row>
        <Col>
          {valido === null ? (
            <p>Validando correo...</p>
          ) : valido ? (
            <>
              <h1>Correo validado</h1>
              <LinkContainer to={{ pathname: "/", hash: "login" }}>
                <Button type="button" color="primary">
                  Ir a GISST
                </Button>
              </LinkContainer>
            </>
          ) : (
            <p>Correo no validado</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ConfirmarCorreo;
