import { datetimeToISOString } from "../utilities/utils";
import { Cargo, CargoResponse } from "./Cargo";
import { Empresa } from "./Empresa";
import { Permiso } from "./Permiso";
import { Roles, Usuario, UsuarioResponse } from "./Usuario";

export interface VinculacionBase {
  empresa: string | Empresa;
  rol: Roles;
  cargo?: string | Cargo | CargoResponse;
  permisos: Permiso[];
}

export interface Vinculacion extends VinculacionBase {
  _id: string;
  persona: Usuario;
  cargo?: string | Cargo;
  fechaDeVinculacion: Date;
}

export interface VinculacionResponse extends VinculacionBase {
  _id: string;
  persona: UsuarioResponse;
  cargo?: string | CargoResponse;
  fechaDeVinculacion: string;
}

export class Vinculacion implements Vinculacion {
  constructor({
    _id,
    persona,
    empresa,
    cargo,
    rol,
    permisos,
    fechaDeVinculacion,
  }: VinculacionResponse) {
    this._id = _id;
    this.persona = new Usuario(persona);
    this.empresa = empresa;
    if (cargo) {
      if (typeof cargo === "string") {
        this.cargo = cargo;
      } else {
        this.cargo = new Cargo(cargo);
      }
    }
    this.rol = rol;
    this.permisos = permisos;
    this.fechaDeVinculacion = new Date(fechaDeVinculacion);
  }

  get nombreCompleto() {
    if (!!this.cargo && typeof this.cargo !== "string") {
      return `${this.persona.nombre} ${this.persona.apellidos} (${this.cargo.cargo})`;
    } else {
      return `${this.persona.nombre} ${this.persona.apellidos}`;
    }
  }

  get asResponse(): VinculacionResponse {
    const cargo = !!this.cargo
      ? typeof this.cargo === "string"
        ? this.cargo
        : this.cargo.asResponse
      : undefined;
    return {
      _id: this._id,
      persona: this.persona.asResponse,
      empresa: this.empresa,
      cargo,
      rol: this.rol,
      permisos: this.permisos,
      fechaDeVinculacion: datetimeToISOString(this.fechaDeVinculacion),
    };
  }

  toString(): string {
    return this._id;
  }
}
