import { Empresa } from "./Empresa";

export enum TiposDeServicio {
  Contratista = "contratista",
  Propio = "propio",
  Mixto = "mixto",
}

export enum TiposDeActividad {
  Rutinaria = "rutinaria",
  NoRutinaria = "noRutinaria",
}

export interface Peligro {
  id: number;
  nombre: string;
}

export interface ActividadBase {
  _id: string;
  departamento: string;
  actividad: string;
  servicio: TiposDeServicio;
  tipoDeActividad: TiposDeActividad;
  peligrosFisicos: Peligro[];
  peligrosQuimicos: Peligro[];
  peligrosBiologicos: Peligro[];
  peligrosPsicosociales: Peligro[];
  peligrosErgonomicos: Peligro[];
  peligrosDeSeguridad: Peligro[];
  calificacionMaxima?: number;
  empresa: string | Empresa;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface Actividad extends ActividadBase {
  createdAt: Date;
  updatedAt: Date;
}

export interface ActividadResponse extends ActividadBase {
  createdAt: string;
  updatedAt: string;
}

export class Actividad implements Actividad {
  constructor({
    _id,
    departamento,
    actividad,
    servicio,
    tipoDeActividad,
    peligrosFisicos,
    peligrosQuimicos,
    peligrosBiologicos,
    peligrosPsicosociales,
    peligrosErgonomicos,
    peligrosDeSeguridad,
    calificacionMaxima,
    empresa,
    createdAt,
    updatedAt,
  }: ActividadResponse) {
    this._id = _id;
    this.departamento = departamento;
    this.actividad = actividad;
    this.servicio = servicio;
    this.tipoDeActividad = tipoDeActividad;
    this.peligrosFisicos = peligrosFisicos;
    this.peligrosQuimicos = peligrosQuimicos;
    this.peligrosBiologicos = peligrosBiologicos;
    this.peligrosPsicosociales = peligrosPsicosociales;
    this.peligrosErgonomicos = peligrosErgonomicos;
    this.peligrosDeSeguridad = peligrosDeSeguridad;
    this.calificacionMaxima = calificacionMaxima;
    this.empresa = empresa;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }
}

export function textoDeTipoDeServicio(servicio: TiposDeServicio): string {
  switch (servicio) {
    case TiposDeServicio.Contratista:
      return "Contratista";
    case TiposDeServicio.Propio:
      return "Propio";
    case TiposDeServicio.Mixto:
      return "Mixto";
  }
}

export function textoDeTipoDeActividad(tipoDeActividad: TiposDeActividad): string {
  switch (tipoDeActividad) {
    case TiposDeActividad.Rutinaria:
      return "Rutinaria";
    case TiposDeActividad.NoRutinaria:
      return "No Rutinaria";
  }
}

export interface ActividadPayload {
  departamento: string;
  actividad: string;
  servicio: TiposDeServicio;
  tipoDeActividad: TiposDeActividad;
  peligrosFisicos: Peligro[];
  peligrosQuimicos: Peligro[];
  peligrosBiologicos: Peligro[];
  peligrosPsicosociales: Peligro[];
  peligrosErgonomicos: Peligro[];
  peligrosDeSeguridad: Peligro[];
  empresa: string;
}
