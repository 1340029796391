import { ChangeEvent, FC, FormEvent, useContext, useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  CategoryLMS,
  createCurso,
  fetchCategorias,
  updateCurso,
} from "../../../services/lms-api-client";
import { Cargo } from "../../../models/Cargo";
import { Context } from "../../Context";
import {
  listCargos,
  listarPlanesDeAccion,
  listarRequisitosLegales,
} from "../../../services/usuario-api-client";
import {
  Curso,
  CursoPayload,
  Frecuencia,
  Modalidad,
  textoDeFrecuencia,
} from "../../../models/Curso";
import { dateInputToDate, dateToISOString, scrollToTheBottom } from "../../../utilities/utils";
import { PlanDeAccion } from "../../../models/PlanDeAccion";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import LoadingSpinner from "../../../utilities/LoadingSpinner";

interface CursoFormProps {
  formId: string;
  curso?: Curso;
  onSuccess: () => void;
}

const CursoForm: FC<CursoFormProps> = ({ formId, curso, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [tema, setTema] = useState<string>("");
  const [codigo, setCodigo] = useState<string>("");
  const [planDeAccion, setPlanDeAccion] = useState<string>("");
  const [requisitoLegal, setRequisitoLegal] = useState<string>("");
  const [modalidad, setModalidad] = useState<Modalidad>(Modalidad.Virtual);
  const [objetivo, setObjetivo] = useState<string>("");
  const [contenido, setContenido] = useState<string>("");
  const [duracionEnHoras, setDuracionEnHoras] = useState<number>(0);
  const [frecuencia, setFrecuencia] = useState<Frecuencia>(Frecuencia.Unica);
  const [evaluacionEfectividad, setEvaluacionEfectividad] = useState<boolean>(true);
  const [cargos, setCargos] = useState<string[]>([]);
  const [fechaDeVencimientoString, setFechaDeVencimientoString] = useState<string>(
    dateToISOString(new Date())
  );
  const [categoria, setCategoria] = useState<number>(0);
  const [categorias, setCategorias] = useState<CategoryLMS[]>([]);
  const [cargosDeLaEmpresa, setCargosDeLaEmpresa] = useState<Cargo[]>([]);
  const [planesDeAccion, setPlanesDeAccion] = useState<PlanDeAccion[]>([]);
  const [requisitosLegales, setRequisitosLegales] = useState<RequisitoLegal[]>([]);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleCargosChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const cargoId = target.value;
    if (target.checked) {
      setCargos([...cargos, cargoId]);
    } else {
      setCargos(cargos.filter((id) => id !== cargoId));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body: CursoPayload = {
      id: curso?.id,
      tema,
      codigo,
      planDeAccion: planDeAccion || undefined,
      requisitosLegales: !!requisitoLegal ? [requisitoLegal] : [],
      modalidad,
      objetivo,
      contenido,
      duracionEnHoras,
      frecuencia,
      evaluacionEfectividad,
      cargos,
      fechaDeVencimiento: dateInputToDate(fechaDeVencimientoString),
      categoria,
    };
    setLoadingCounter((prev) => prev + 1);
    if (!!curso) {
      updateCurso(authToken, curso._id, body)
        .then(onSuccess)
        .catch(({ response }) => {
          setMensajeDeError(response?.data?.message || "Error al editar el curso");
          setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
        })
        .finally(() => setLoadingCounter((prev) => prev - 1));
    } else {
      createCurso(authToken, idDeLaEmpresaSeleccionada, body)
        .then(onSuccess)
        .catch(({ response }) => {
          setMensajeDeError(response?.data?.message || "Error al crear el curso");
          setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
        })
        .finally(() => setLoadingCounter((prev) => prev - 1));
    }
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    Promise.all([
      fetchCategorias(authToken),
      listCargos(authToken, idDeLaEmpresaSeleccionada),
      listarPlanesDeAccion(authToken, idDeLaEmpresaSeleccionada),
      listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada),
    ])
      .then(
        ([
          { data: categoriasData },
          { data: cargosData },
          { data: planesDeAccionData },
          { data: requisitosLegalesData },
        ]) => {
          setCategorias(categoriasData);
          const mappedCargos: Cargo[] = cargosData.map((cargo) => new Cargo(cargo));
          setCargosDeLaEmpresa(mappedCargos);
          setPlanesDeAccion(
            planesDeAccionData.map((planDeAccion) => new PlanDeAccion(planDeAccion))
          );
          setRequisitosLegales(
            requisitosLegalesData.map((requisitoLegal) => new RequisitoLegal(requisitoLegal))
          );
        }
      )
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    if (!curso) return;
    setTema(curso.tema);
    setCodigo(curso.codigo);
    setPlanDeAccion(curso.planDeAccion?.toString() || "");
    setRequisitoLegal(curso.requisitosLegales[0]?.toString() || "");
    setModalidad(curso.modalidad);
    setObjetivo(curso.objetivo);
    setContenido(curso.contenido);
    setDuracionEnHoras(curso.duracionEnHoras);
    setFrecuencia(curso.frecuencia);
    setEvaluacionEfectividad(curso.evaluacionEfectividad);
    setCargos(curso.cargos.map((cargo) => cargo.toString()));
    setFechaDeVencimientoString(dateToISOString(curso.fechaDeVencimiento));
    setCategoria(curso.categoria);
  }, [curso]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="tema"
                placeholder="Tema"
                value={tema}
                onChange={({ target }) => setTema(target.value)}
                required
              />
              <Label for="tema">Tema</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="codigo"
                placeholder="Código"
                value={codigo}
                onChange={({ target }) => setCodigo(target.value)}
                required
              />
              <Label for="codigo">Código</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="planDeAccion"
                placeholder="Plan de acción"
                value={planDeAccion}
                onChange={({ target }) => setPlanDeAccion(target.value)}
              >
                <option value="">Ninguno</option>
                {planesDeAccion.map((planDeAccion) => (
                  <option key={planDeAccion._id} value={planDeAccion._id}>
                    {planDeAccion.descripcion}
                  </option>
                ))}
              </Input>
              <Label for="planDeAccion">Plan de acción</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="requisitoLegal"
                placeholder="Requisito legal"
                value={requisitoLegal}
                onChange={({ target }) => setRequisitoLegal(target.value)}
              >
                <option value="">Ninguno</option>
                {requisitosLegales.map((requisitoLegal) => (
                  <option key={requisitoLegal._id} value={requisitoLegal._id}>
                    {requisitoLegal.nombre}
                  </option>
                ))}
              </Input>
              <Label for="requisitoLegal">Requisito legal</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="modalidad"
                placeholder="Modalidad"
                value={modalidad}
                onChange={({ target }) => setModalidad(target.value as Modalidad)}
                required
              >
                <option value={Modalidad.Virtual}>Virtual</option>
                <option value={Modalidad.Presencial}>Presencial</option>
              </Input>
              <Label for="modalidad">Modalidad</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                name="objetivo"
                placeholder="Objetivo"
                value={objetivo}
                onChange={({ target }) => setObjetivo(target.value)}
                required
                style={{ height: "138px" }}
              />
              <Label for="objetivo">Objetivo</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                name="contenido"
                placeholder="Contenido"
                value={contenido}
                onChange={({ target }) => setContenido(target.value)}
                required
                style={{ height: "138px" }}
              />
              <Label for="contenido">Contenido</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="number"
                name="duracionEnHoras"
                placeholder="Duración en horas"
                value={duracionEnHoras}
                onChange={({ target }) => setDuracionEnHoras(parseInt(target.value))}
                required
              />
              <Label for="duracionEnHoras">Duración en horas</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="frecuencia"
                placeholder="Frecuencia"
                value={frecuencia}
                onChange={({ target }) => setFrecuencia(target.value as Frecuencia)}
                required
              >
                {Object.values(Frecuencia).map((value) => (
                  <option key={value} value={value}>
                    {textoDeFrecuencia(value as Frecuencia)}
                  </option>
                ))}
              </Input>
              <Label for="frecuencia">Frecuencia</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="evaluacionEfectividad"
                placeholder="Evaluación de efectividad"
                value={evaluacionEfectividad ? 1 : 0}
                onChange={({ target }) => setEvaluacionEfectividad(target.value === "1")}
                required
              >
                <option value={1}>Sí</option>
                <option value={0}>No</option>
              </Input>
              <Label for="evaluacionEfectividad">Evaluación de efectividad</Label>
            </FormGroup>
          </Col>
        </Row>
        <Card className="mb-3">
          <CardBody className="py-1">
            <Label className="small text-muted">Cargos</Label>
            <Row className="row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-5">
              {cargosDeLaEmpresa.map((cargo) => (
                <Col key={cargo._id}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="cargos"
                        value={cargo._id}
                        onChange={handleCargosChange}
                        checked={cargos.some((id) => id === cargo._id)}
                      />
                      {cargo.cargo}
                    </Label>
                  </FormGroup>
                </Col>
              ))}
              {cargosDeLaEmpresa.length === 0 && (
                <Col>
                  <Row>
                    <Col className="text-center">
                      <Label className="small">No hay cargos</Label>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="date"
                name="fechaDeVencimiento"
                placeholder="Fecha de vencimiento"
                value={fechaDeVencimientoString}
                onChange={({ target }) => setFechaDeVencimientoString(target.value)}
                min={dateToISOString(new Date())}
              />
              <Label for="fechaDeVencimiento">Fecha de vencimiento</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="categoria"
                value={categoria}
                onChange={({ target }) => setCategoria(parseInt(target.value))}
              >
                <option value="0">Ninguna</option>
                {categorias.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Input>
              <Label for="categoria">Categoría</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <Collapse isOpen={!!mensajeDeError} onEntered={scrollToTheBottom}>
        <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
          {mensajeDeError}
        </Alert>
      </Collapse>
    </>
  );
};

export default CursoForm;
