import { useContext, FC, useState, FormEvent, useEffect } from "react";
import { Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { Incidente } from "../../../models/Incidente";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { createArchivoDeIncidente } from "../../../services/usuario-api-client";

interface AdjuntarArchivoFormProps {
  formId: string;
  incidente: Incidente;
  onSuccess: () => void;
}

const AdjuntarArchivoForm: FC<AdjuntarArchivoFormProps> = ({ formId, incidente, onSuccess }) => {
  const { authToken } = useContext(Context);
  const [archivo, setArchivos] = useState<File | null>(null);
  const [nombre, setNombre] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const body = {
      archivo: archivo as File,
      nombre,
    };
    createArchivoDeIncidente(authToken, incidente._id, body)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (archivo && nombre === "") {
      const { name } = archivo;
      const lastDotIndex = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDotIndex);
      setNombre(fileName);
    }
  }, [archivo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <Label>
          Incidente: <b>{incidente.tituloDelIncidente}</b>
        </Label>
        <FormGroup>
          <Input
            type="file"
            name="archivo"
            id="archivo"
            onChange={(e) => setArchivos(e.target.files ? e.target.files[0] : null)}
            required
          />
          <FormText color="muted">Selecciona el archivo que deseas subir.</FormText>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            name="nombre"
            id="nombre"
            placeholder="Nombre del archivo"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <Label for="nombre">Nombre del archivo</Label>
        </FormGroup>
      </Form>
    </>
  );
};

export default AdjuntarArchivoForm;
