import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import RequisitoLegalForm from "./RequisitoLegalForm";
import { Context } from "../../Context";
import { SeparatorRow } from "../../../utilities/utils";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { crearRequisitoLegal } from "../../../services/usuario-api-client";
import { RequisitoLegalBody } from "../../../models/RequisitoLegal";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const AGREGAR_REQUISITO_LEGAL_FORM_ID = "agregarRequisitoLegalForm";

const NuevoRequisitoLegal: FC = () => {
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = (body: RequisitoLegalBody) => {
    if (!authToken) return;
    setIsLoading(true);
    crearRequisitoLegal(authToken, body)
      .then(() => navigate({ pathname: PATHS.requisitosLegales.to, hash: "internos" }))
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.nuevoRequisitoLegal.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="mb-3">
        <Col xs="auto">
          <Breadcrumbs
            items={[
              PATHS.usuarioHome,
              {
                ...PATHS.requisitosLegales,
                to: { pathname: PATHS.requisitosLegales.to, hash: "internos" },
              },
            ]}
          />
        </Col>
      </Row>
      <RequisitoLegalForm formId={AGREGAR_REQUISITO_LEGAL_FORM_ID} onSubmit={handleFormSubmit} />
      <SeparatorRow height={30} />
      <Navbar light container fixed="bottom" className="bg-white">
        <Button
          form={AGREGAR_REQUISITO_LEGAL_FORM_ID}
          type="submit"
          color="primary"
          className="ms-auto"
        >
          Crear
        </Button>
      </Navbar>
    </>
  );
};

export default NuevoRequisitoLegal;
