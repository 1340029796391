import { FC, useContext, useEffect } from "react";
import { Col, Row } from "reactstrap";
import HexButton, { HexMenuContainer } from "../../utilities/HexButton";
import { SeparatorCol } from "../../utilities/utils";
import { Context } from "../Context";
import { PATHS } from "../../utilities/Paths";

const AdminHome: FC = () => {
  const { setTituloDeLaPantallaActual } = useContext(Context);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.adminHome.name), [setTituloDeLaPantallaActual]);

  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <HexMenuContainer className="gx-2">
          <Col xs="auto">
            <HexButton
              label={PATHS.adminRegistros.name}
              url={PATHS.adminRegistros.to}
              backgroundColor="#2D6617"
            />
          </Col>
          <Col xs="auto">
            <HexButton
              label={PATHS.adminDocumentosLegales.name}
              url={PATHS.adminDocumentosLegales.to}
              backgroundColor="#79BF35"
            />
          </Col>
          <div className="w-100 d-sm-none" />
          <SeparatorCol width={59} className="d-sm-none" />
          <Col xs="auto">
            <HexButton
              label={PATHS.adminAutoridades.name}
              url={PATHS.adminAutoridades.to}
              backgroundColor="#15A43F"
            />
          </Col>
          <div className="w-100 d-none d-sm-block d-lg-none" />
          <SeparatorCol width={59} className="d-none d-sm-block d-lg-none" />
          <Col xs="auto">
            <HexButton
              label={PATHS.adminDocumentosSugeridos.name}
              url={PATHS.adminDocumentosSugeridos.to}
              backgroundColor="#34A6CB"
            />
          </Col>
        </HexMenuContainer>
      </Col>
    </Row>
  );
};

export default AdminHome;
