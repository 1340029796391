import { useState, FC, useContext, useEffect, FormEvent } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { FaPlus, FaTools } from "react-icons/fa";
import { EquipoHerramienta } from "../../../../models/EquipoHerramienta";
import { Context } from "../../../Context";
import { listEquiposYHerramientas } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { CustomTable, IconsContainer, TableContainer } from "../../../../utilities/utils";

interface BusquedaDeEquiposYHerramientasProps {
  formId: string;
  equiposYHerramientasPreseleccionados: EquipoHerramienta[];
  isCreating: boolean;
  onCreateToggle: () => void;
  onSubmit: (equiposYHerramientasSeleccionados: EquipoHerramienta[]) => void;
}

const BusquedaDeEquiposYHerramientas: FC<BusquedaDeEquiposYHerramientasProps> = ({
  formId,
  equiposYHerramientasPreseleccionados,
  isCreating,
  onCreateToggle,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [equiposYHerramientasSeleccionados, setEquiposYHerramientasSeleccionados] = useState<
    EquipoHerramienta[]
  >(equiposYHerramientasPreseleccionados);
  const [equiposYHerramientasFiltrados, setEquiposYHerramientasFiltrados] = useState<
    EquipoHerramienta[]
  >([]);
  const [equiposYHerramientas, setEquiposYHerramientas] = useState<EquipoHerramienta[]>([]);
  const [busqueda, setBusqueda] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(equiposYHerramientasSeleccionados);
  };

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setEquiposYHerramientasFiltrados(
      equiposYHerramientas.filter(
        (equipoOHerramienta) =>
          equipoOHerramienta.identificador.toLowerCase().includes(busquedaLower) ||
          equipoOHerramienta.nombre.toLowerCase().includes(busquedaLower) ||
          (!!equipoOHerramienta.marca &&
            equipoOHerramienta.marca.toLowerCase().includes(busquedaLower))
      )
    );
  }, [equiposYHerramientas, busqueda]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || isCreating) return;
    setIsLoading(true);
    listEquiposYHerramientas(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const equiposYHerramientas = data.map(
          (equipoOHerramienta) => new EquipoHerramienta(equipoOHerramienta)
        );
        setEquiposYHerramientas(equiposYHerramientas);
        setEquiposYHerramientasFiltrados(equiposYHerramientas);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada, isCreating]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="align-items-center">
        <Col xs sm={6} md={4}>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Filtrar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Filtrar</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form id={formId} onSubmit={handleSubmit}>
        <TableContainer>
          <CustomTable>
            <thead>
              <tr>
                <th>
                  <Input
                    type="checkbox"
                    id="seleccionar-todos"
                    checked={
                      equiposYHerramientasFiltrados.length > 0 &&
                      equiposYHerramientasFiltrados.every((equipoOHerramienta) =>
                        equiposYHerramientasSeleccionados.some(
                          (v) => v._id === equipoOHerramienta._id
                        )
                      )
                    }
                    onChange={({ target }) => {
                      if (target.checked) {
                        setEquiposYHerramientasSeleccionados(equiposYHerramientasFiltrados);
                      } else {
                        setEquiposYHerramientasSeleccionados([]);
                      }
                    }}
                    disabled={equiposYHerramientasFiltrados.length === 0}
                  />
                </th>
                <th>Identificador</th>
                <th>Nombre</th>
                <th>Marca</th>
              </tr>
            </thead>
            <tbody>
              {equiposYHerramientasFiltrados.map((equipoOHerramienta) => (
                <tr key={equipoOHerramienta._id}>
                  <td className="text-center">
                    <Input
                      type="checkbox"
                      id={equipoOHerramienta._id}
                      checked={equiposYHerramientasSeleccionados.some(
                        (v) => v._id === equipoOHerramienta._id
                      )}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setEquiposYHerramientasSeleccionados([
                            ...equiposYHerramientasSeleccionados,
                            equipoOHerramienta,
                          ]);
                        } else {
                          setEquiposYHerramientasSeleccionados(
                            equiposYHerramientasSeleccionados.filter(
                              (v) => v._id !== equipoOHerramienta._id
                            )
                          );
                        }
                      }}
                    />
                  </td>
                  <td>{equipoOHerramienta.identificador}</td>
                  <td>{equipoOHerramienta.nombre}</td>
                  <td>{equipoOHerramienta.marca}</td>
                </tr>
              ))}
              {equiposYHerramientas.length === 0 ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    No hay equipos ni herramientas registrados
                  </td>
                </tr>
              ) : (
                equiposYHerramientasFiltrados.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No hay equipos ni herramientas que coincidan con la búsqueda
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </CustomTable>
        </TableContainer>
      </Form>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button type="button" color="primary" title="Agregar vehículo" onClick={onCreateToggle}>
            <IconsContainer>
              <FaTools size={20} textAnchor="middle" alignmentBaseline="middle" />
              <FaPlus
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{
                  fontSize: ".5em",
                  position: "absolute",
                  left: "2.35em",
                  bottom: "2em",
                }}
              />
            </IconsContainer>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BusquedaDeEquiposYHerramientas;
