import { Empresa } from "./Empresa";

import { TiposDeIdentificacion } from "./Usuario";

export interface UsuarioNoRegistradoBase {
  nombre: string;
  apellido: string;
  tipoDeIdentificacion: TiposDeIdentificacion;
  identificacion: string;
  nacionalidad: string;
  telefono: string;
  direccionDeResidencia: string;
  empresa: string | Empresa;
}

export interface UsuarioNoRegistrado extends UsuarioNoRegistradoBase {
  _id: string;
  empresa: string | Empresa;
}

export interface UsuarioNoRegistradoResponse extends UsuarioNoRegistradoBase {
  _id: string;
  empresa: string | Empresa;
}

export class UsuarioNoRegistrado implements UsuarioNoRegistrado {
  constructor({
    _id,
    nombre,
    apellido,
    tipoDeIdentificacion,
    identificacion,
    nacionalidad,
    telefono,
    direccionDeResidencia,
    empresa,
  }: UsuarioNoRegistradoResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.apellido = apellido;
    this.tipoDeIdentificacion = tipoDeIdentificacion;
    this.identificacion = identificacion;
    this.nacionalidad = nacionalidad;
    this.telefono = telefono;
    this.direccionDeResidencia = direccionDeResidencia;
    this.empresa = empresa;
  }

  get nombreCompleto() {
    return `${this.nombre} ${this.apellido}`;
  }

  toString() {
    return this._id;
  }
}

export interface UsuarioNoRegistradoPayload {
  nombre: string;
  apellido: string;
  tipoDeIdentificacion: TiposDeIdentificacion;
  identificacion: string;
  nacionalidad: string;
  telefono: string;
  direccionDeResidencia: string;
}
