import axios from "axios";
import { DocumentoLegalResponse } from "../models/DocumentoLegal";

export const listDocumentosLegales = async (busqueda?: string) => {
  const searchParams = new URLSearchParams();
  if (busqueda) {
    searchParams.append("busqueda", busqueda);
  }
  return axios.get<DocumentoLegalResponse[]>(`/api/widgets/documentos-legales?${searchParams}`);
};
