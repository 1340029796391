import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, Navbar, Row } from "reactstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Incidente, IncidenteBase } from "../../../models/Incidente";
import { editIncidente, getIncidente } from "../../../services/usuario-api-client";
import IncidenteForm from "./IncidenteForm";
import { useRevertableState } from "../../../utilities/useRevertableState";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const EDITAR_INCIDENTE_FORM_ID = "editarIncidenteForm";

const EditarIncidente: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [incidente, setIncidente] = useState<Incidente>();
  const [errorMessage, setErrorMessage] = useState("");
  const [mensajeDeErrorIsOpen, setMensajeDeErrorIsOpen] = useRevertableState<boolean>(false, 5000);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (body: IncidenteBase) => {
    setIsLoading(true);
    setErrorMessage("");
    editIncidente(authToken, incidente!._id, body)
      .then(() => navigate(PATHS.investigacionDeIncidentes.to))
      .catch(({ response }) =>
        setErrorMessage(response?.data?.message || "Error al modificar el incidente")
      )
      .finally(() => setIsLoading(false));
  };

  const limpiarMensajeDeError = () => setErrorMessage("");

  const toggleMensajeDeError = () => setMensajeDeErrorIsOpen(!mensajeDeErrorIsOpen);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.editarIncidente.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    getIncidente(authToken, id)
      .then(({ data }) => setIncidente(new Incidente(data)))
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(() => {
    if (!!errorMessage) setMensajeDeErrorIsOpen(true);
  }, [errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.investigacionDeIncidentes]} />
      <IncidenteForm
        formId={EDITAR_INCIDENTE_FORM_ID}
        incidente={incidente as Incidente}
        onSubmit={handleSubmit}
      />
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={EDITAR_INCIDENTE_FORM_ID} type="submit" color="primary" className="ms-auto">
          Guardar
        </Button>
      </Navbar>
      <Modal
        isOpen={mensajeDeErrorIsOpen}
        centered
        size="sm"
        toggle={toggleMensajeDeError}
        onClosed={limpiarMensajeDeError}
      >
        <ModalBody>
          <Row className="mb-2 justify-content-center">
            <Col xs="auto">
              <FaExclamationCircle color="red" size={60} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">{errorMessage}</Col>
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="primary" onClick={toggleMensajeDeError}>
            Entendido
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditarIncidente;
