import { FC, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardHeader, ListGroup, ListGroupItem, Table } from "reactstrap";
import { Context } from "../../../Context";
import { PATHS, procesarPath } from "../../../../utilities/Paths";
import { DocumentoDelContrato } from "../../../../models/DocumentoDelContrato";
import { getDocumentoDelContrato } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { VerticalTableContainer } from "../../../../utilities/utils";
import {
  FrecuenciaDeActualizacion,
  textoDeLaFrecuenciaDeActualizacion,
  textoDelTipoDeDocumento,
  textoDePeriodoDeActualizacion,
  tituloDeActivos,
} from "../../../../models/Documento";

const breadcrumbs = [
  PATHS.usuarioHome,
  PATHS.gestionDocumental,
  PATHS.gestionDocumentalContratos,
  PATHS.configuracionDeContrato,
];

const DocumentoDelContratoView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [documento, setDocumento] = useState<DocumentoDelContrato | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.documentoDelContrato.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    getDocumentoDelContrato(authToken, id)
      .then(({ data }) => {
        const documento = new DocumentoDelContrato(data);
        setDocumento(documento);
        breadcrumbs[3] = {
          ...PATHS.configuracionDeContrato,
          to: procesarPath(PATHS.configuracionDeContrato, documento.contrato?.toString() || ""),
        };
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={breadcrumbs} />
      {!!documento &&
        (({ nombre, documentoVinculado, requisitosLegales }: DocumentoDelContrato) => (
          <>
            <VerticalTableContainer>
              <Table borderless style={{ tableLayout: "auto" }}>
                <tbody>
                  <tr>
                    <th scope="row">Nombre</th>
                    <td>{nombre}</td>
                  </tr>
                  {!!documentoVinculado &&
                    (({
                      tipoDeDocumento,
                      frecuenciaDeActualizacion,
                      valorDeLaFrecuenciaDeActualizacion,
                      unidadDeLaFrecuenciaDeActualizacion,
                    }) => (
                      <>
                        <tr>
                          <th scope="row">Tipo de documento</th>
                          <td>{textoDelTipoDeDocumento(tipoDeDocumento)}</td>
                        </tr>
                        <tr>
                          <th scope="row">Frecuencia de actualización</th>
                          <td>
                            {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                            !!valorDeLaFrecuenciaDeActualizacion &&
                            !!unidadDeLaFrecuenciaDeActualizacion
                              ? textoDePeriodoDeActualizacion(
                                  valorDeLaFrecuenciaDeActualizacion,
                                  unidadDeLaFrecuenciaDeActualizacion
                                )
                              : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                          </td>
                        </tr>
                      </>
                    ))(documentoVinculado)}
                </tbody>
              </Table>
            </VerticalTableContainer>
            {!!documentoVinculado &&
              (({ personasResponsables, tipoDeActivo, activos }) => (
                <>
                  <Card className="mb-3">
                    <CardHeader>
                      <b>Personas responsables</b>
                    </CardHeader>
                    <ListGroup flush>
                      {personasResponsables.map((persona) => (
                        <ListGroupItem key={persona._id}>{persona.nombreCompleto}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </Card>
                  <Card className="mb-3">
                    <CardHeader>
                      <b>Activos ({tituloDeActivos(tipoDeActivo)})</b>
                    </CardHeader>
                    <ListGroup flush>
                      {activos?.map((activo) => (
                        <ListGroupItem key={activo._id}>{activo.nombreCompleto}</ListGroupItem>
                      ))}
                    </ListGroup>
                  </Card>
                  {!!requisitosLegales && requisitosLegales.length > 0 && (
                    <Card className="mb-3">
                      <CardHeader>
                        <b>Requisitos legales</b>
                      </CardHeader>
                      <ListGroup flush>
                        {requisitosLegales.map((requisitoLegal) => (
                          <ListGroupItem key={requisitoLegal.toString()}>
                            <Link
                              to={procesarPath(PATHS.requisitoLegal, requisitoLegal.toString())}
                            >
                              {typeof requisitoLegal === "string"
                                ? requisitoLegal
                                : requisitoLegal.nombre}
                            </Link>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Card>
                  )}
                </>
              ))(documentoVinculado)}
          </>
        ))(documento)}
    </>
  );
};

export default DocumentoDelContratoView;
