import { type FC, ChangeEvent, useState, Dispatch, SetStateAction, Fragment } from "react";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Collapse,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { SeparatorCol } from "../../utilities/utils";
import { validarNumeroDeCedula } from "../../resources/tipos-de-identificacion";
import { EmpresaBase } from "../../models/Empresa";
import TiposDeIndustriaModal from "./TiposDeIndustriaModal";

export interface EmpresaBody extends EmpresaBase {
  isPersonaNatural: boolean;
}

interface Props {
  nombreDeLaEmpresa: string;
  ruc: string;
  digitoVerificador: string;
  tipoDeIndustria: string;
  direccion: string;
  isPersonaNatural: boolean | null;
  nombreDelPropietario: string;
  cedulaDelPropietario: string;
  errorRuc: string;
  setNombreDeLaEmpresa: Dispatch<SetStateAction<string>>;
  setRuc: Dispatch<SetStateAction<string>>;
  setDigitoVerificador: Dispatch<SetStateAction<string>>;
  setTipoDeIndustria: Dispatch<SetStateAction<string>>;
  setDireccion: Dispatch<SetStateAction<string>>;
  setIsPersonaNatural: Dispatch<SetStateAction<boolean | null>>;
  setNombreDelPropietario: Dispatch<SetStateAction<string>>;
  setCedulaDelPropietario: Dispatch<SetStateAction<string>>;
}

const EmpresaForm: FC<Props> = ({
  nombreDeLaEmpresa,
  ruc,
  digitoVerificador,
  tipoDeIndustria,
  direccion,
  isPersonaNatural,
  nombreDelPropietario,
  cedulaDelPropietario,
  errorRuc,
  setNombreDeLaEmpresa,
  setRuc,
  setDigitoVerificador,
  setTipoDeIndustria,
  setDireccion,
  setIsPersonaNatural,
  setNombreDelPropietario,
  setCedulaDelPropietario,
}) => {
  const [tiposDeIndustriaModalIsOpen, setTiposDeIndustriaModalIsOpen] = useState(false);

  const handleTipoDeIndustriaSelect = (tipoDeIndustria: string) => {
    setTipoDeIndustria(tipoDeIndustria);
    toggleTiposDeIndustriaModal();
  };

  const toggleTiposDeIndustriaModal = () =>
    setTiposDeIndustriaModalIsOpen(!tiposDeIndustriaModalIsOpen);

  const handleTipoDeEmpresaChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    setIsPersonaNatural(currentTarget.value === "true");
  };

  const { isValid, isComplete } = validarNumeroDeCedula(cedulaDelPropietario);
  const cedulaIsValid = isValid && isComplete;

  return (
    <>
      <FormGroup floating>
        <Input
          id="nombreDeLaEmpresa"
          name="nombreDeLaEmpresa"
          placeholder="Nombre de la empresa"
          type="text"
          required
          value={nombreDeLaEmpresa}
          onChange={({ target }) => setNombreDeLaEmpresa(target.value)}
        />
        <Label htmlFor="nombreDeLaEmpresa">Nombre de la empresa</Label>
      </FormGroup>
      <Row>
        <Col>
          <FormGroup floating>
            <Input
              id="ruc"
              name="ruc"
              placeholder="RUC"
              type="text"
              invalid={!!errorRuc}
              required
              value={ruc}
              onChange={({ target }) => setRuc(target.value)}
            />
            <Label htmlFor="ruc">RUC</Label>
            <FormFeedback tooltip dangerouslySetInnerHTML={{ __html: errorRuc }} />
          </FormGroup>
        </Col>
        <SeparatorCol width={72}>
          <FormGroup floating>
            <Input
              id="digitoVerificador"
              name="digitoVerificador"
              placeholder="DV"
              type="text"
              required
              value={digitoVerificador}
              onChange={({ target }) => setDigitoVerificador(target.value)}
            />
            <Label htmlFor="digitoVerificador">DV</Label>
          </FormGroup>
        </SeparatorCol>
      </Row>
      <Row className="align-items-center">
        <Col>
          <FormGroup floating>
            <Input
              id="tipoDeIndustria"
              name="tipoDeIndustria"
              placeholder="Tipo de industria"
              type="text"
              required
              value={tipoDeIndustria}
              onChange={({ target }) => setTipoDeIndustria(target.value)}
              readOnly
            />
            <Label htmlFor="tipoDeIndustria">Tipo de industria</Label>
          </FormGroup>
        </Col>
        <Col xs="auto">
          <Button
            type="button"
            color="primary"
            onClick={toggleTiposDeIndustriaModal}
            className="mb-3"
          >
            Seleccionar
          </Button>
        </Col>
      </Row>
      <FormGroup floating>
        <Input
          id="direccion"
          name="direccion"
          placeholder="Dirección"
          type="text"
          required
          value={direccion}
          onChange={({ target }) => setDireccion(target.value)}
        />
        <Label htmlFor="direccion">Dirección</Label>
      </FormGroup>
      <FormGroup>
        <Label className="w-100">Tipo de empresa:</Label>
        <FormGroup check inline>
          <Input
            name="tipo-de-empresa"
            id="empresa-persona-natural"
            type="radio"
            value="true"
            required
            checked={isPersonaNatural === true}
            onChange={handleTipoDeEmpresaChange}
          />
          <Label check htmlFor="empresa-persona-natural">
            Persona Natural
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Input
            name="tipo-de-empresa"
            id="empresa-persona-juridica"
            type="radio"
            value="false"
            required
            checked={isPersonaNatural === false}
            onChange={handleTipoDeEmpresaChange}
          />
          <Label check htmlFor="empresa-persona-juridica">
            Persona Jurídica
          </Label>
        </FormGroup>
      </FormGroup>
      <Collapse isOpen={!!isPersonaNatural}>
        <Card body>
          <CardTitle tag="h5">Información del propietario:</CardTitle>
          <CardText>
            <FormGroup floating>
              <Input
                id="nombreDelPropietario"
                name="nombreDelPropietario"
                placeholder="Nombre completo"
                type="text"
                required={!!isPersonaNatural}
                value={nombreDelPropietario}
                onChange={({ target }) => setNombreDelPropietario(target.value)}
              />
              <Label htmlFor="nombreDelPropietario">Nombre completo</Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                id="cedulaDelPropietario"
                name="cedulaDelPropietario"
                placeholder="Cédula"
                type="text"
                required={!!isPersonaNatural}
                valid={!!isPersonaNatural && cedulaIsValid}
                value={cedulaDelPropietario}
                onChange={({ target }) => setCedulaDelPropietario(target.value)}
              />
              <Label htmlFor="cedulaDelPropietario">Cédula</Label>
            </FormGroup>
          </CardText>
        </Card>
      </Collapse>
      <TiposDeIndustriaModal
        isOpen={tiposDeIndustriaModalIsOpen}
        onSelect={handleTipoDeIndustriaSelect}
        toggle={toggleTiposDeIndustriaModal}
      />
    </>
  );
};

export default EmpresaForm;
