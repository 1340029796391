import { useEffect, FC, useContext, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Context } from "../Context";
import HexagonalMenu from "./HexagonalMenu";
import LoadingSpinner from "../../utilities/LoadingSpinner";
import { Empresa } from "../../models/Empresa";
import {
  actualizarUltimaEmpresaSeleccionada,
  fetchVinculacionesDelUsuario,
} from "../../services/usuario-api-client";
import { PATHS } from "../../utilities/Paths";

const UsuarioHome: FC = () => {
  const {
    authToken,
    idDeLaEmpresaSeleccionada,
    setIdDeLaEmpresaSeleccionada,
    setTituloDeLaPantallaActual,
  } = useContext(Context);
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSelectEmpresa = (empresaId: string) => () => {
    setIdDeLaEmpresaSeleccionada(empresaId);
    actualizarUltimaEmpresaSeleccionada(authToken, empresaId);
  };

  useEffect(() => {
    if (!authToken || isLoading || empresas.length > 0) return;
    setIsLoading(true);
    fetchVinculacionesDelUsuario(authToken)
      .then(({ data }) => {
        const empresas = data.map(({ empresa }) => empresa as Empresa);
        setEmpresas(empresas);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setTituloDeLaPantallaActual("Menú principal"), [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      {empresas.length > 1 && (
        <Row className="justify-content-center mb-md-3">
          <Col xs="auto">
            <UncontrolledDropdown>
              <DropdownToggle color="primary" caret>
                {empresas.find(({ _id }) => _id === idDeLaEmpresaSeleccionada)?.nombreDeLaEmpresa}
              </DropdownToggle>
              <DropdownMenu>
                {empresas.map(({ _id, nombreDeLaEmpresa }) => (
                  <DropdownItem
                    key={_id}
                    onClick={handleSelectEmpresa(_id)}
                    active={_id === idDeLaEmpresaSeleccionada}
                  >
                    {nombreDeLaEmpresa}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
      )}
      <Row className="align-items-center">
        <Col md="auto" className="my-3">
          <LinkContainer to={PATHS.administracionDeUsuarios.to}>
            <Button type="button" color="primary" className="w-100 shadow">
              Adm. Usuarios
            </Button>
          </LinkContainer>
        </Col>
        <Col>
          <HexagonalMenu />
        </Col>
      </Row>
    </>
  );
};

export default UsuarioHome;
