import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "reactstrap";

const Layout: FC = () => {
  return (
    <Container fluid>
      <Outlet />
    </Container>
  );
};

export default Layout;
