import { FC, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { CategoryLMS } from "../../../services/lms-api-client";

interface NuevaCategoria {
  nombre: string;
  parent: number;
}

interface FormularioDeNuevaCategoriaProps {
  formId: string;
  categoriasExistentes: CategoryLMS[];
  hideBotones?: boolean;
  onSubmit: (body: NuevaCategoria, callback: () => void) => void;
}

const FormularioDeNuevaCategoria: FC<FormularioDeNuevaCategoriaProps> = ({
  formId,
  categoriasExistentes,
  hideBotones,
  onSubmit,
}) => {
  const [nombre, setNombre] = useState<string>("");
  const [parent, setParent] = useState<number>(0);

  const handleClear = () => {
    setNombre("");
    setParent(0);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ nombre, parent }, handleClear);
  };

  return (
    <Form id={formId} onSubmit={handleSubmit}>
      <FormGroup floating>
        <Input
          type="text"
          name="nombre"
          placeholder="Nombre"
          value={nombre}
          onChange={({ target }) => setNombre(target.value)}
          required
        />
        <Label for="nombre">Nombre</Label>
      </FormGroup>
      <FormGroup floating>
        <Input
          type="select"
          name="parent"
          value={parent}
          onChange={({ target }) => setParent(parseInt(target.value))}
        >
          <option value="0">Ninguno</option>
          {categoriasExistentes.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Input>
        <Label for="parent">Categoría padre</Label>
      </FormGroup>
      {!hideBotones && (
        <FormGroup>
          <Button type="submit" color="primary">
            Crear
          </Button>
        </FormGroup>
      )}
    </Form>
  );
};

export default FormularioDeNuevaCategoria;
