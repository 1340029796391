import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FaEdit, FaFile, FaFolderOpen, FaPaperclip, FaPlus, FaTrash } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { Context } from "../../Context";
import { ArchivoDeIncidente } from "../../../models/ArchivoDeIncidente";
import { EstadoACR, Incidente, textoDelEstadoACR } from "../../../models/Incidente";
import { archivarIncidente, listIncidentes } from "../../../services/usuario-api-client";
import {
  CustomTable,
  IconsContainer,
  procesarLinkDeDocumento,
  shortDatetimeToString,
  shortDateToString,
  TableCaption,
  TableContainer,
  TableMessage,
} from "../../../utilities/utils";
import AdjuntarArchivoForm from "./AdjuntarArchivoForm";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS, procesarPath } from "../../../utilities/Paths";

const ADJUNTAR_INCIDENTE_FORM_ID = "adjuntarIncidenteForm";

const InvestigacionDeIncidentes: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [incidentes, setIncidentes] = useState<Incidente[]>([]);
  const [incidenteSeleccionado, setIncidenteSeleccionado] = useState<Incidente | null>(null);
  const [adjuntarImagenModalIsOpen, setAdjuntarImagenModalIsOpen] = useState<boolean>(false);
  const [confirmarArchivarModalIsOpen, setConfirmarArchivarModalIsOpen] = useState<boolean>(false);
  const [personasResponsablesModalIsOpen, setPersonasResponsablesModalIsOpen] =
    useState<boolean>(false);
  const [isloading, setIsLoading] = useState<boolean>(false);

  const cargarIncidentes = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listIncidentes(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setIncidentes(data.map((incidente) => new Incidente(incidente)));
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const listadoDePersonasResponsables = (idDelIncidente: string) => {
    const incidente = incidentes.find(({ _id }) => _id === idDelIncidente);
    if (!incidente) return;
    setPersonasResponsablesModalIsOpen(true);
    setIncidenteSeleccionado(incidente);
  };

  const toggleAdjuntarImagenModal = () => setAdjuntarImagenModalIsOpen(!adjuntarImagenModalIsOpen);

  const handleAdjuntarImagenClick = (idDelIncidente: string) => {
    const incidente = incidentes.find(({ _id }) => _id === idDelIncidente);
    if (!incidente) return;
    setIncidenteSeleccionado(incidente);
    toggleAdjuntarImagenModal();
  };

  const handleAdjuntarImagenSuccess = () => {
    toggleAdjuntarImagenModal();
    cargarIncidentes();
  };

  const handleArchivarClick = (idDelIncidente: string) => {
    const incidente = incidentes.find(({ _id }) => _id === idDelIncidente);
    if (!incidente) return;
    setIncidenteSeleccionado(incidente);
    setConfirmarArchivarModalIsOpen(true);
  };

  const confirmarArchivar = () => {
    if (!authToken || !incidenteSeleccionado) return;
    setIsLoading(true);
    archivarIncidente(authToken, incidenteSeleccionado._id)
      .then(() => {
        setConfirmarArchivarModalIsOpen(false);
        cargarIncidentes();
      })
      .finally(() => setIsLoading(false));
  };

  const limpiarSeleccion = () => {
    setIncidenteSeleccionado(null);
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.investigacionDeIncidentes.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(cargarIncidentes, [cargarIncidentes]);

  return (
    <>
      <LoadingSpinner isLoading={isloading} />
      <Row className="mb-3">
        <Col xs="auto">
          <Breadcrumbs items={[PATHS.usuarioHome]} />
        </Col>
        <Col xs="auto" className="ms-auto">
          <LinkContainer to={PATHS.nuevoIncidente.to}>
            <Button color="primary" title="Nuevo incidente">
              <IconsContainer>
                <FaFile size={20} textAnchor="middle" alignmentBaseline="middle" />
                <FaPlus
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{
                    fontSize: ".5em",
                    position: "absolute",
                    left: "2.35em",
                    bottom: "1.5em",
                  }}
                />
              </IconsContainer>
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableCaption>Lista de incidentes</TableCaption>
        </Col>
      </Row>
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Titulo del incidente</th>
              <th>Fecha de ocurrencia</th>
              <th>Fecha Limite de Investigación</th>
              <th>Responsable(s) ACR</th>
              <th>Estado ACR</th>
              <th>Archivos</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {incidentes.map(
              (
                {
                  _id,
                  tituloDelIncidente,
                  fechaOcurrencia,
                  fechaLimite,
                  personasResponsables,
                  estadoACR,
                  archivos,
                },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{tituloDelIncidente}</td>
                  <td>{shortDatetimeToString(fechaOcurrencia)}</td>
                  <td>{!!fechaLimite ? shortDateToString(fechaLimite) : "N/A"}</td>
                  <td>
                    {personasResponsables.length === 0 ? (
                      <span>Ninguno Asignado</span>
                    ) : (
                      <>
                        <ul className="mb-0 ps-3">
                          {personasResponsables
                            .filter((_, index) => index < 3)
                            .map(({ _id, nombreCompleto }) => (
                              <li key={_id}>{nombreCompleto}</li>
                            ))}
                          {personasResponsables.length === 4 && <li>1 persona más</li>}
                          {personasResponsables.length > 4 && (
                            <li>{personasResponsables.length - 3} personas más</li>
                          )}
                        </ul>
                        {personasResponsables.length > 3 && (
                          <Button
                            color="link"
                            size="sm"
                            className="p-0 small"
                            onClick={() => listadoDePersonasResponsables(_id)}
                          >
                            Ver todos
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {estadoACR !== EstadoACR.Realizado && fechaLimite && fechaLimite < new Date()
                      ? "Vencido"
                      : textoDelEstadoACR(estadoACR)}
                  </td>
                  <td>
                    {!!archivos && archivos.length > 0 ? (
                      <>
                        <ul className="mb-0 ps-3">
                          {(archivos as ArchivoDeIncidente[])
                            .filter((_, index) => index < 3)
                            .map(({ _id, nombre, filepath }) => (
                              <li key={_id}>
                                <a
                                  key={_id}
                                  href={procesarLinkDeDocumento(filepath)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {nombre}
                                </a>
                              </li>
                            ))}
                          {archivos.length === 4 && <li>1 archivo más</li>}
                          {archivos.length > 4 && <li>{archivos.length - 3} archivos más</li>}
                        </ul>
                        {archivos.length > 3 && (
                          <Button color="link" size="sm" className="p-0 small">
                            Ver todos
                          </Button>
                        )}
                      </>
                    ) : (
                      <span>Ninguno</span>
                    )}
                  </td>
                  <td>
                    <Row className="justify-content-center gx-0 flex-nowrap">
                      <Col xs="auto">
                        <LinkContainer to={procesarPath(PATHS.incidente, _id)}>
                          <Button color="link" title="Ver detalles" size="sm">
                            <FaFolderOpen size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Adjuntar imagen"
                          size="sm"
                          onClick={() => handleAdjuntarImagenClick(_id)}
                        >
                          <FaPaperclip size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <LinkContainer to={procesarPath(PATHS.editarIncidente, _id)}>
                          <Button color="link" title="Modificar" size="sm">
                            <FaEdit size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="danger"
                          outline
                          title="Eliminar" // TODO: revertir a archivar cuando se implemente
                          size="sm"
                          className="border-0"
                          onClick={() => handleArchivarClick(_id)}
                        >
                          <FaTrash size={15} />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {incidentes.length === 0 && (
              <tr>
                <td colSpan={11} className="text-center">
                  <TableMessage>No hay incidentes</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Modal
        isOpen={adjuntarImagenModalIsOpen}
        toggle={toggleAdjuntarImagenModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleAdjuntarImagenModal}>Adjuntar archivo</ModalHeader>
        <ModalBody>
          <AdjuntarArchivoForm
            formId={ADJUNTAR_INCIDENTE_FORM_ID}
            incidente={incidenteSeleccionado as Incidente}
            onSuccess={handleAdjuntarImagenSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" form={ADJUNTAR_INCIDENTE_FORM_ID} color="primary">
            Agregar
          </Button>
          <Button color="secondary" onClick={toggleAdjuntarImagenModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarArchivarModalIsOpen}
        toggle={() => setConfirmarArchivarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setConfirmarArchivarModalIsOpen(false)}>
          Confirmar eliminacion
        </ModalHeader>
        <ModalBody>
          <p>¿Está seguro que desea eliminar el incidente?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmarArchivar}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={() => setConfirmarArchivarModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InvestigacionDeIncidentes;
