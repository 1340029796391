import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export enum ClasificacionDePlanDeAccion {
  Correctiva = "correctiva",
  Preventiva = "preventiva",
  Mejora = "mejora",
}

export interface AccionBase {
  accion: string;
  clasificacion: ClasificacionDePlanDeAccion;
  responsable: string | VinculacionResponse | Vinculacion;
  fechaLimite: string | Date;
  estado: string;
  verificador: string | VinculacionResponse | Vinculacion;
  requisitosLegales?: (string | RequisitoLegal | RequisitoLegalResponse)[];
}

export interface Accion extends AccionBase {
  _id: string;
  responsable: string | Vinculacion;
  verificador: string | Vinculacion;
  planDeAccion: string;
  fechaLimite: Date;
  requisitosLegales?: (string | RequisitoLegal)[];
}

export interface AccionResponse extends AccionBase {
  _id: string;
  responsable: string | VinculacionResponse;
  verificador: string | VinculacionResponse;
  planDeAccion: string;
  fechaLimite: string;
  requisitosLegales?: (string | RequisitoLegalResponse)[];
}

export interface AccionPayload {
  accion: string;
  clasificacion: ClasificacionDePlanDeAccion;
  responsable: string;
  fechaLimite: Date;
  verificador: string;
  planDeAccion: string;
}

export class Accion implements Accion {
  constructor({
    _id,
    accion,
    clasificacion,
    responsable,
    fechaLimite,
    estado,
    verificador,
    planDeAccion,
    requisitosLegales = [],
  }: AccionResponse) {
    this._id = _id;
    this.accion = accion;
    this.clasificacion = clasificacion;
    if (typeof responsable === "string") {
      this.responsable = responsable;
    } else {
      this.responsable = new Vinculacion(responsable);
    }
    if (typeof verificador === "string") {
      this.verificador = verificador;
    } else {
      this.verificador = new Vinculacion(verificador);
    }
    this.planDeAccion = planDeAccion;
    this.fechaLimite = new Date(fechaLimite);
    this.estado = estado;
    this.requisitosLegales = requisitosLegales.map((requisitoLegal) =>
      typeof requisitoLegal === "string" ? requisitoLegal : new RequisitoLegal(requisitoLegal)
    );
  }

  toString(): string {
    return this.accion;
  }
}

export function textoDeClasificacionDeAccion(clasificacion: ClasificacionDePlanDeAccion): string {
  switch (clasificacion) {
    case ClasificacionDePlanDeAccion.Correctiva:
      return "Correctiva";
    case ClasificacionDePlanDeAccion.Preventiva:
      return "Preventiva";
    case ClasificacionDePlanDeAccion.Mejora:
      return "Mejora";
  }
}
