import { Cargo, CargoResponse } from "./Cargo";
import { PlanDeAccion, PlanDeAccionResponse } from "./PlanDeAccion";
import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";

export enum Modalidad {
  Virtual = "virtual",
  Presencial = "presencial",
}

export enum Frecuencia {
  Unica = "unica",
  Mensual = "mensual",
  Trimestral = "trimestral",
  Semestral = "semestral",
  Anual = "anual",
}

export interface CursoBase {
  _id: string;
  id: number;
  tema: string;
  codigo: string;
  planDeAccion?: string | PlanDeAccion | PlanDeAccionResponse;
  requisitosLegales?: (string | RequisitoLegal | RequisitoLegalResponse)[];
  modalidad: Modalidad;
  objetivo: string;
  contenido: string;
  contenidoEsHTML: boolean;
  duracionEnHoras: number;
  frecuencia: Frecuencia;
  evaluacionEfectividad: boolean;
  cargos: (string | Cargo | CargoResponse)[];
  categoria: number;
  avance?: number;
  fechaDeVencimiento?: string | Date;
}

export interface Curso extends CursoBase {
  planDeAccion?: string | PlanDeAccion;
  requisitosLegales: (string | RequisitoLegal)[];
  cargos: (string | Cargo)[];
  fechaDeVencimiento?: Date;
}

export interface CursoResponse extends CursoBase {
  planDeAccion?: string | PlanDeAccionResponse;
  requisitosLegales: (string | RequisitoLegalResponse)[];
  cargos: (string | CargoResponse)[];
  fechaDeVencimiento?: string;
}

export class Curso implements Curso {
  constructor({
    _id,
    id,
    tema,
    codigo,
    planDeAccion,
    requisitosLegales,
    modalidad,
    objetivo,
    contenido,
    contenidoEsHTML,
    duracionEnHoras,
    frecuencia,
    evaluacionEfectividad,
    cargos,
    categoria,
    fechaDeVencimiento,
  }: CursoResponse) {
    this._id = _id;
    this.id = id;
    this.tema = tema;
    this.codigo = codigo;
    if (typeof planDeAccion === "string") {
      this.planDeAccion = planDeAccion;
    } else if (!!planDeAccion) {
      this.planDeAccion = new PlanDeAccion(planDeAccion);
    }
    this.requisitosLegales = requisitosLegales.map((requisito) => {
      if (typeof requisito === "string") {
        return requisito;
      }
      return new RequisitoLegal(requisito);
    });
    this.modalidad = modalidad;
    this.objetivo = objetivo;
    this.contenido = contenido;
    this.contenidoEsHTML = contenidoEsHTML;
    this.duracionEnHoras = duracionEnHoras;
    this.frecuencia = frecuencia;
    this.evaluacionEfectividad = evaluacionEfectividad;
    if (cargos) {
      this.cargos = cargos.map((cargo) => {
        if (typeof cargo === "string") {
          return cargo;
        }
        return new Cargo(cargo);
      });
    }
    this.categoria = categoria;
    this.fechaDeVencimiento = fechaDeVencimiento ? new Date(fechaDeVencimiento) : undefined;
  }

  toString() {
    return this.tema;
  }
}

export const textoDeModalidad = (modalidad: Modalidad) => {
  switch (modalidad) {
    case Modalidad.Virtual:
      return "Virtual";
    case Modalidad.Presencial:
      return "Presencial";
  }
};

export const textoDeFrecuencia = (frecuencia: Frecuencia) => {
  switch (frecuencia) {
    case Frecuencia.Unica:
      return "Única";
    case Frecuencia.Mensual:
      return "Mensual";
    case Frecuencia.Trimestral:
      return "Trimestral";
    case Frecuencia.Semestral:
      return "Semestral";
    case Frecuencia.Anual:
      return "Anual";
  }
};

export interface CursoPayload {
  id?: number;
  tema: string;
  codigo: string;
  planDeAccion?: string;
  requisitosLegales?: string[];
  modalidad: string;
  objetivo: string;
  contenido: string;
  duracionEnHoras: number;
  frecuencia: string;
  evaluacionEfectividad: boolean;
  cargos: string[];
  fechaDeVencimiento: Date;
  categoria: number;
}

export interface IndicadorDeFormacion {
  _id?: string;
  idDelCurso: number;
  curso: string;
  horas: number;
  trabajadores: number;
}
