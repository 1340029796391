import { FC, Fragment, useCallback, useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { Context } from "../../Context";
import {
  OrigenDePlanDeAccion,
  PlanDeAccion,
  TiposDeAuditoria,
  origenEsDeFuenteManual,
  procesarLinkDeLaFuente,
  textoDeOrigenDePlanDeAccion,
  textoDeTipoDeAuditoria,
} from "../../../models/PlanDeAccion";
import {
  obtenerPlanDeAccion,
  vincularAnalisisConAccion,
} from "../../../services/usuario-api-client";
import { PATHS } from "../../../utilities/Paths";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { textoDeClasificacionDeAccion } from "../../../models/Accion";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  TableMessage,
  VerticalTableContainer,
  shortDateToString,
} from "../../../utilities/utils";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import AccionForm from "./AccionForm";

const AGREGAR_ACCIONES_FORM_ID = "agregar-acciones-form";

const PlanDeAccionView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [planDeAccion, setPlanDeAccion] = useState<PlanDeAccion>();
  const [accionesModalIsOpen, setAccionesModalIsOpen] = useState<boolean>(false);
  const [analisisId, setAnalisisId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const toggleAccionesModal = () => setAccionesModalIsOpen(!accionesModalIsOpen);

  const handleModalClose = () => setAnalisisId(null);

  const cargarPlanDeAccion = useCallback(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    obtenerPlanDeAccion(authToken, id)
      .then(({ data }) => {
        setPlanDeAccion(new PlanDeAccion(data));
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  const handleAccionesSuccess = () => {
    if (id && analisisId) {
      setIsLoading(true);
      vincularAnalisisConAccion(authToken, analisisId, id)
        .then(() => {
          toggleAccionesModal();
          cargarPlanDeAccion();
        })
        .finally(() => setIsLoading(false));
    } else {
      toggleAccionesModal();
      cargarPlanDeAccion();
    }
  };

  useEffect(cargarPlanDeAccion, [cargarPlanDeAccion]);

  useEffect(() => {
    if (!state || !planDeAccion) return;
    const { analisisId } = state;
    if (analisisId) {
      setAnalisisId(analisisId);
      setAccionesModalIsOpen(true);
    }
  }, [state, planDeAccion]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.planDeAccion.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.planesDeAccion]} />
      {!!planDeAccion &&
        (({
          _id,
          descripcion,
          origen,
          fuente,
          fuenteManual,
          tipoDeHallazgo,
          acciones,
        }: PlanDeAccion) => (
          <Fragment key={_id}>
            <VerticalTableContainer>
              <Table borderless style={{ tableLayout: "auto" }}>
                <tbody>
                  <tr>
                    <th scope="row">Descripción:</th>
                    <td>{descripcion}</td>
                  </tr>
                  <tr>
                    <th scope="row">Origen:</th>
                    <td>{textoDeOrigenDePlanDeAccion(origen)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Fuente:</th>
                    <td>
                      {origen === OrigenDePlanDeAccion.Otra && !!fuenteManual ? (
                        fuenteManual
                      ) : origen === OrigenDePlanDeAccion.Auditoria && !!fuenteManual ? (
                        textoDeTipoDeAuditoria(fuenteManual as TiposDeAuditoria)
                      ) : !origenEsDeFuenteManual(origen) && !!fuente ? (
                        <a
                          href={procesarLinkDeLaFuente(origen, fuente)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {typeof fuente === "string" ? fuente : fuente.nombreCompleto}
                        </a>
                      ) : (
                        "—"
                      )}
                    </td>
                  </tr>
                  {!!fuenteManual && (
                    <tr>
                      <th scope="row">Fuente:</th>
                      <td>{fuenteManual}</td>
                    </tr>
                  )}
                  {!!tipoDeHallazgo && (
                    <tr>
                      <th scope="row">Tipo de hallazgo:</th>
                      <td>{tipoDeHallazgo}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </VerticalTableContainer>
            <Row className="justify-content-end mb-3">
              <Col xs="auto">
                <Button
                  type="button"
                  color="primary"
                  title="Agregar acción"
                  onClick={toggleAccionesModal}
                >
                  <FaPlus className="mr-2" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <TableCaption>Acciones</TableCaption>
              </Col>
            </Row>
            <TableContainer>
              <CustomTable>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Acción</th>
                    <th>Clasificación</th>
                    <th>Responsable</th>
                    <th>Fecha límite</th>
                  </tr>
                </thead>
                <tbody>
                  {!!acciones.length ? (
                    acciones.map(
                      ({ _id, accion, clasificacion, responsable, fechaLimite }, index) => (
                        <tr key={_id}>
                          <td>{index + 1}</td>
                          <td>{accion}</td>
                          <td>{textoDeClasificacionDeAccion(clasificacion)}</td>
                          <td>
                            {typeof responsable === "string"
                              ? responsable
                              : responsable.nombreCompleto}
                          </td>
                          <td>{shortDateToString(fechaLimite)}</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        <TableMessage>
                          <Row className="gx-0">
                            <Col className="text-center">No hay acciones registradas</Col>
                          </Row>
                          <Row className="justify-content-center gx-0 mt-3">
                            <Col xs="auto">
                              <Button type="button" color="primary" onClick={toggleAccionesModal}>
                                Agregar acción
                              </Button>
                            </Col>
                          </Row>
                        </TableMessage>
                      </td>
                    </tr>
                  )}
                </tbody>
              </CustomTable>
            </TableContainer>
          </Fragment>
        ))(planDeAccion)}
      <Modal isOpen={accionesModalIsOpen} toggle={toggleAccionesModal} onClosed={handleModalClose}>
        <ModalHeader toggle={toggleAccionesModal}>Nueva Acción</ModalHeader>
        <ModalBody>
          <AccionForm
            formId={AGREGAR_ACCIONES_FORM_ID}
            planDeAccion={planDeAccion as PlanDeAccion}
            nuevaAccion={!!analisisId}
            onSuccess={handleAccionesSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={AGREGAR_ACCIONES_FORM_ID}>
            Guardar
          </Button>
          <Button type="button" color="secondary" onClick={toggleAccionesModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PlanDeAccionView;
