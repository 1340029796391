import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { indicadoresDeRequisitosLegales } from "../../../services/usuario-api-client";
import { COLORS } from "../../../utilities/utils";

const TruncatedYAxisLabel: FC<any> = ({ x, y, width, height, payload }) => {
  const maxLength = 37;
  const truncatedLabel =
    payload.value.length > maxLength
      ? payload.value.substring(0, maxLength) + "..."
      : payload.value;

  return (
    <text x={x} y={y} width={width} height={height} textAnchor="end" fontSize={10}>
      {truncatedLabel}
    </text>
  );
};

const RequisitosLegales: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [indicadores, setIndicadores] = useState<{ autoridad: string; cantidad: number }[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    indicadoresDeRequisitosLegales(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setIndicadores(data);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row className="mb-3">
        <Col className="text-center h5">Cantidad de Requisitos Legales por autoridad</Col>
      </Row>
      <Row className="h-100 align-items-stretch">
        <Col>
          <ResponsiveContainer
            width="100%"
            height="100%"
            minWidth="100%"
            minHeight={40 * indicadores.length}
          >
            <BarChart width={500} height={500} data={indicadores} barSize={20} layout="vertical">
              <YAxis
                dataKey="autoridad"
                type="category"
                width={200}
                interval={0}
                tick={<TruncatedYAxisLabel />}
              />
              <XAxis type="number" allowDecimals={false} />
              <Tooltip />
              <CartesianGrid />
              <Bar dataKey="cantidad" name="Cantidad" fill="#12416B">
                {indicadores.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
};

export default RequisitosLegales;
