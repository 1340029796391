import { ParteInteresada, ParteInteresadaResponse } from "./ParteInteresada";
import { TipoDeContexto } from "./TipoDecontexto";

export enum ClasificacionDelContexto {
  Riesgo = "riesgo",
  Oportunidad = "oportunidad",
}

export interface Contexto {
  _id: string;
  tipo: TipoDeContexto;
  clasificacion: ClasificacionDelContexto;
  descripcion: string;
}

export function textoDeClasificacionDelContexto(clasificacion: ClasificacionDelContexto): string {
  switch (clasificacion) {
    case ClasificacionDelContexto.Riesgo:
      return "Riesgo";
    case ClasificacionDelContexto.Oportunidad:
      return "Oportunidad";
  }
}

export interface ContextoPayload {
  tipo: string;
  clasificacion: ClasificacionDelContexto;
  descripcion: string;
}

export interface ContextoConParteInteresadaBase extends Contexto {
  parteInteresada: ParteInteresada | ParteInteresadaResponse;
  consecutivo?: number;
}

export interface ContextoConParteInteresada extends ContextoConParteInteresadaBase {
  parteInteresada: ParteInteresada;
  consecutivo?: number;
}

export interface ContextoConParteInteresadaResponse extends ContextoConParteInteresadaBase {
  parteInteresada: ParteInteresadaResponse;
  consecutivo?: number;
}

export class ContextoConParteInteresada implements ContextoConParteInteresada {
  constructor(contexto: ContextoConParteInteresada);
  constructor(contexto: ContextoConParteInteresadaResponse);
  constructor({
    _id,
    tipo,
    clasificacion,
    descripcion,
    parteInteresada,
    consecutivo,
  }: ContextoConParteInteresadaBase) {
    this._id = _id;
    this.tipo = tipo;
    this.clasificacion = clasificacion;
    this.descripcion = descripcion;
    if (parteInteresada instanceof ParteInteresada) {
      this.parteInteresada = parteInteresada;
    } else {
      this.parteInteresada = new ParteInteresada(parteInteresada as ParteInteresadaResponse);
    }
    this.consecutivo = consecutivo;
  }

  toString(): string {
    return this._id;
  }

  get nombreCompleto(): string {
    return `${this.parteInteresada.nombre} - ${this.tipo.nombre}${
      this.consecutivo ? ` ${this.consecutivo}` : ""
    }`;
  }
}
