import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ClampLines from "react-clamp-lines";
import { LinkContainer } from "react-router-bootstrap";
import { FaBalanceScale, FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import {
  CustomTable,
  IconsContainer,
  TableContainer,
  TableMessage,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../utilities/utils";
import { DocumentoLegal } from "../../../models/DocumentoLegal";
import { Context } from "../../Context";
import {
  eliminarDocumentoLegal,
  obtenerDocumentosLegales,
} from "../../../services/admin-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { textoClasificacion, textoTipoDeRequisitoLegal } from "../../../models/RequisitoLegal";
import EditarDocumentoLegalForm from "./EditarDocumentoLegalForm";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const EDITAR_DOCUMENTO_LEGAL_FORM_ID = "editarDocumentoLegalForm";

const DocumentosLegales: FC = () => {
  const { adminAuthToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [documentosLegales, setDocumentosLegales] = useState<DocumentoLegal[]>([]);
  const [documentoLegalSeleccionado, setDocumentoLegalSeleccionado] =
    useState<DocumentoLegal | null>(null);
  const [editarModalIsOpen, setEditarModalIsOpen] = useState<boolean>(false);
  const [confirmarEliminacionModalIsOpen, setConfirmarEliminacionModalIsOpen] =
    useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleEditarModal = () => setEditarModalIsOpen(!editarModalIsOpen);

  const handleEditarClick = (id: string) => {
    const documentoLegal = documentosLegales.find((documentoLegal) => documentoLegal._id === id);
    if (!documentoLegal) return;
    setDocumentoLegalSeleccionado(documentoLegal);
    toggleEditarModal();
  };

  const handleEditarDocumentoLegalSuccess = () => {
    toggleEditarModal();
    cargarDocumentosLegales();
  };

  const toggleConfirmarEliminacionModal = () =>
    setConfirmarEliminacionModalIsOpen(!confirmarEliminacionModalIsOpen);

  const handleEliminarClick = (id: string) => {
    const documentoLegal = documentosLegales.find((documentoLegal) => documentoLegal._id === id);
    if (!documentoLegal) return;
    setDocumentoLegalSeleccionado(documentoLegal);
    toggleConfirmarEliminacionModal();
  };

  const handleConfirmarEliminacion = () => {
    if (!adminAuthToken || !documentoLegalSeleccionado) return;
    setIsLoading(true);
    eliminarDocumentoLegal(adminAuthToken, documentoLegalSeleccionado._id)
      .then(() => {
        cargarDocumentosLegales();
        toggleConfirmarEliminacionModal();
      })
      .catch(({ response }) => setMensajeDeError(response.data.error))
      .finally(() => setIsLoading(false));
  };

  const limpiarSeleccion = () => {
    setDocumentoLegalSeleccionado(null);
    setMensajeDeError("");
  };

  const cargarDocumentosLegales = useCallback(() => {
    if (!adminAuthToken) return;
    setIsLoading(true);
    obtenerDocumentosLegales(adminAuthToken)
      .then(({ data }) => {
        const documentosLegales = data.map((documentoLegal) => new DocumentoLegal(documentoLegal));
        setDocumentosLegales(documentosLegales);
      })
      .finally(() => setIsLoading(false));
  }, [adminAuthToken]);

  useEffect(cargarDocumentosLegales, [cargarDocumentosLegales]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.adminDocumentosLegales.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="mb-3">
        <Col>
          <Breadcrumbs items={[PATHS.adminHome]} />
        </Col>
        <Col xs="auto">
          <LinkContainer to="/admin/documentos-legales/nuevo">
            <Button type="button" color="primary" title="Añadir Requisito Legal">
              <IconsContainer>
                <FaBalanceScale size={20} textAnchor="middle" alignmentBaseline="middle" />
                <FaPlus
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{
                    fontSize: ".5em",
                    position: "absolute",
                    left: "2.35em",
                    bottom: "1.5em",
                  }}
                />
              </IconsContainer>
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Documento Legal</th>
              <th>Tipo</th>
              <th>Autoridad</th>
              <th>Clasificación</th>
              <th>Fecha de emisión</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentosLegales.map(
              (
                { _id, nombre, filepath: url, tipo, autoridad, clasificacion, fechaDeEmision },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>
                    <a href={procesarLinkDeDocumento(url)} target="_blank" rel="noreferrer">
                      <ClampLines
                        text={nombre}
                        id={`documento-legal-${_id}`}
                        lines={2}
                        buttons={false}
                      />
                    </a>
                  </td>
                  <td>{textoTipoDeRequisitoLegal(tipo)}</td>
                  <td>{autoridad.nombre}</td>
                  <td>{textoClasificacion(clasificacion)}</td>
                  <td>{shortDateToString(fechaDeEmision)}</td>
                  <td>
                    <Row className="justify-content-center gx-0 flex-nowrap">
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="link"
                          title="Editar"
                          onClick={() => handleEditarClick(_id)}
                        >
                          <FaEdit />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="danger"
                          outline
                          title="Eliminar"
                          className="border-0"
                          onClick={() => handleEliminarClick(_id)}
                        >
                          <FaTrash />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {documentosLegales.length === 0 && (
              <tr>
                <td colSpan={7} className="text-center">
                  <TableMessage>No hay documentos legales registrados</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Modal isOpen={editarModalIsOpen} toggle={toggleEditarModal} onClosed={limpiarSeleccion}>
        <ModalHeader toggle={toggleEditarModal}>Editar documento legal</ModalHeader>
        <ModalBody>
          <EditarDocumentoLegalForm
            documentoLegal={documentoLegalSeleccionado as DocumentoLegal}
            formId={EDITAR_DOCUMENTO_LEGAL_FORM_ID}
            onSuccess={handleEditarDocumentoLegalSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" form={EDITAR_DOCUMENTO_LEGAL_FORM_ID} color="primary">
            Guardar cambios
          </Button>
          <Button type="button" color="secondary" onClick={toggleEditarModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarEliminacionModalIsOpen}
        toggle={toggleConfirmarEliminacionModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarEliminacionModal}>Confirmar eliminación</ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro de que quieres eliminar el documento legal{" "}
            <strong>{documentoLegalSeleccionado?.nombre}</strong>?
          </p>
          {mensajeDeError && <p className="text-danger">{mensajeDeError}</p>}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="danger" onClick={handleConfirmarEliminacion}>
            Eliminar
          </Button>
          <Button type="button" color="secondary" onClick={toggleConfirmarEliminacionModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DocumentosLegales;
