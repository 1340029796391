import { PartesAfectadas } from "./Incidente";
import { Usuario, UsuarioResponse } from "./Usuario";
import { UsuarioNoRegistrado, UsuarioNoRegistradoResponse } from "./UsuarioNoRegistrado";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export interface AfectadoBase {
  afectado:
    | Vinculacion
    | VinculacionResponse
    | UsuarioNoRegistrado
    | UsuarioNoRegistradoResponse
    | Usuario
    | UsuarioResponse;
  model: "Vinculacion" | "UsuarioNoRegistrado" | "Usuario";
  partesAfectadas: PartesAfectadas[];
  detalle: string;
}

export interface AfectadoResponse extends AfectadoBase {
  afectado: VinculacionResponse | UsuarioNoRegistradoResponse | UsuarioResponse;
}

export interface Afectado extends AfectadoBase {
  afectado: Vinculacion | UsuarioNoRegistrado | Usuario;
}

export class Afectado implements Afectado {
  constructor(afectado: Afectado);
  constructor(afectado: AfectadoResponse);
  constructor({ afectado, model, partesAfectadas, detalle }: AfectadoBase) {
    switch (model) {
      case "Vinculacion":
        if (afectado instanceof Vinculacion) {
          this.afectado = afectado;
        } else {
          this.afectado = new Vinculacion(afectado as VinculacionResponse);
        }
        break;
      case "UsuarioNoRegistrado":
        if (afectado instanceof UsuarioNoRegistrado) {
          this.afectado = afectado;
        } else {
          this.afectado = new UsuarioNoRegistrado(afectado as UsuarioNoRegistradoResponse);
        }
        break;
      case "Usuario":
        if (afectado instanceof Usuario) {
          this.afectado = afectado;
        } else {
          this.afectado = new Usuario(afectado as UsuarioResponse);
        }
        break;
    }
    this.model = model;
    this.partesAfectadas = partesAfectadas;
    this.detalle = detalle;
  }

  get nombreCompleto() {
    return this.afectado.nombreCompleto;
  }

  get identificacion() {
    switch (this.model) {
      case "Vinculacion":
        return (this.afectado as Vinculacion).persona.identificacion;
      case "UsuarioNoRegistrado":
        return (this.afectado as UsuarioNoRegistrado).identificacion;
      case "Usuario":
        return (this.afectado as Usuario).identificacion;
      default:
        return "";
    }
  }

  toString() {
    return this.afectado._id;
  }
}

export interface AfectadoPayload {
  afectado: string;
  model: "Vinculacion" | "UsuarioNoRegistrado" | "Usuario";
  partesAfectadas: PartesAfectadas[];
  detalle: string;
}
