import { ArchivoDeDocumento, ArchivoDeDocumentoResponse } from "./ArchivoDeDocumento";
import { EquipoHerramienta, EquipoHerramientaResponse } from "./EquipoHerramienta";
import { PlanDeAccion } from "./PlanDeAccion";
import { RequisitoLegal } from "./RequisitoLegal";
import { SustanciaQuimica, SustanciaQuimicaResponse } from "./SustanciaQuimica";
import { Vehiculo, VehiculoResponse } from "./Vehiculo";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export enum TiposDeDocumento {
  DocumentacionGeneralDeLaEmpresa = "documentacionGeneralDeLaEmpresa",
  DocumentacionDeUsuariosDirectos = "documentacionDeUsuariosDirectos",
  DocumentacionDeEquiposDeLaEmpresa = "documentacionDeEquiposDeLaEmpresa",
  DocumentacionDeVehiculosDeLaEmpresa = "documentacionDeVehiculosDeLaEmpresa",
  DocumentacionDeSustanciasQuimicas = "documentacionDeSustanciasQuimicas",
}

export enum FrecuenciaDeActualizacion {
  Diaria = "diaria",
  Mensual = "mensual",
  Bimestral = "bimestral",
  Trimestral = "trimestral",
  Semestral = "semestral",
  Anual = "anual",
  Otra = "otra",
  GestionUnica = "gestionUnica",
}

export enum UnidadesDeTiempo {
  Minutos = "minutos",
  Horas = "horas",
  Dias = "dias",
  Semanas = "semanas",
  Meses = "meses",
  Años = "años",
}

export enum TiposDeActivo {
  Vinculacion = "Vinculacion",
  Vehiculo = "Vehiculo",
  EquipoHerramienta = "EquipoHerramienta",
  SustanciaQuimica = "SustanciaQuimica",
}

export type ActivoResponse =
  | VinculacionResponse
  | VehiculoResponse
  | EquipoHerramientaResponse
  | SustanciaQuimicaResponse;

export type Activo = Vinculacion | Vehiculo | EquipoHerramienta | SustanciaQuimica;

export interface DocumentoBase {
  nombreDelDocumento: string;
  tipoDeDocumento: TiposDeDocumento;
  personasResponsables: (Vinculacion | VinculacionResponse)[];
  frecuenciaDeActualizacion: FrecuenciaDeActualizacion;
  valorDeLaFrecuenciaDeActualizacion?: number;
  unidadDeLaFrecuenciaDeActualizacion?: UnidadesDeTiempo;
  activos?: (Activo | ActivoResponse)[];
  tipoDeActivo?: TiposDeActivo;
}

export interface Documento extends DocumentoBase {
  _id: string;
  personasResponsables: Vinculacion[];
  planDeAccion?: PlanDeAccion;
  requisitoLegal?: RequisitoLegal;
  archivos?: ArchivoDeDocumento[];
  activos?: Activo[];
}

export interface DocumentoResponse extends DocumentoBase {
  _id: string;
  personasResponsables: VinculacionResponse[];
  planDeAccion?: PlanDeAccion;
  requisitoLegal?: RequisitoLegal;
  archivos?: ArchivoDeDocumentoResponse[];
}

export class Documento implements Documento {
  constructor({
    _id,
    nombreDelDocumento,
    tipoDeDocumento,
    personasResponsables,
    frecuenciaDeActualizacion,
    valorDeLaFrecuenciaDeActualizacion,
    unidadDeLaFrecuenciaDeActualizacion,
    planDeAccion,
    requisitoLegal,
    archivos = [],
    activos = [],
    tipoDeActivo,
  }: DocumentoResponse) {
    this._id = _id;
    this.nombreDelDocumento = nombreDelDocumento;
    this.tipoDeDocumento = tipoDeDocumento;
    this.personasResponsables = personasResponsables.map((persona) => new Vinculacion(persona));
    this.frecuenciaDeActualizacion = frecuenciaDeActualizacion;
    this.valorDeLaFrecuenciaDeActualizacion = valorDeLaFrecuenciaDeActualizacion;
    this.unidadDeLaFrecuenciaDeActualizacion = unidadDeLaFrecuenciaDeActualizacion;
    this.planDeAccion = planDeAccion;
    this.requisitoLegal = requisitoLegal;
    const archivosDesordenados = archivos.map((archivo) => new ArchivoDeDocumento(archivo));
    this.archivos = archivosDesordenados.sort((a, b) => {
      if (a.fechaDeVencimiento < b.fechaDeVencimiento) {
        return 1;
      } else if (a.fechaDeVencimiento > b.fechaDeVencimiento) {
        return -1;
      } else {
        return 0;
      }
    });
    this.tipoDeActivo = tipoDeActivo;
    if (tipoDeActivo) {
      switch (tipoDeActivo) {
        case "Vinculacion":
          this.activos = activos.map((usuario) => new Vinculacion(usuario as VinculacionResponse));
          break;
        case "Vehiculo":
          this.activos = activos.map((vehiculo) => new Vehiculo(vehiculo as VehiculoResponse));
          break;
        case "EquipoHerramienta":
          this.activos = activos.map(
            (equipo) => new EquipoHerramienta(equipo as EquipoHerramientaResponse)
          );
          break;
        case "SustanciaQuimica":
          this.activos = activos.map(
            (sustancia) => new SustanciaQuimica(sustancia as SustanciaQuimicaResponse)
          );
          break;
      }
    }
  }

  toString() {
    return this.nombreDelDocumento;
  }
}

export function textoDelTipoDeDocumento(tipo: TiposDeDocumento) {
  switch (tipo) {
    case TiposDeDocumento.DocumentacionGeneralDeLaEmpresa:
      return "Documentación General de la Empresa";
    case TiposDeDocumento.DocumentacionDeUsuariosDirectos:
      return "Documentación de Usuarios directos (colaboradores)";
    case TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa:
      return "Documentación de Equipos de la Empresa";
    case TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa:
      return "Documentación de Vehículos de la Empresa";
    case TiposDeDocumento.DocumentacionDeSustanciasQuimicas:
      return "Documentación de Sustancias Químicas";
  }
}

export function textoDeLaFrecuenciaDeActualizacion(frecuencia: FrecuenciaDeActualizacion) {
  switch (frecuencia) {
    case FrecuenciaDeActualizacion.Diaria:
      return "Diaria";
    case FrecuenciaDeActualizacion.Mensual:
      return "Mensual";
    case FrecuenciaDeActualizacion.Bimestral:
      return "Bimestral";
    case FrecuenciaDeActualizacion.Trimestral:
      return "Trimestral";
    case FrecuenciaDeActualizacion.Semestral:
      return "Semestral";
    case FrecuenciaDeActualizacion.Anual:
      return "Anual";
    case FrecuenciaDeActualizacion.Otra:
      return "Otra";
    case FrecuenciaDeActualizacion.GestionUnica:
      return "Gestión Única";
  }
}

export function textoDeLaUnidadDeTiempo(unidad: UnidadesDeTiempo) {
  switch (unidad) {
    case UnidadesDeTiempo.Minutos:
      return "minutos";
    case UnidadesDeTiempo.Horas:
      return "horas";
    case UnidadesDeTiempo.Dias:
      return "días";
    case UnidadesDeTiempo.Semanas:
      return "semanas";
    case UnidadesDeTiempo.Meses:
      return "meses";
    case UnidadesDeTiempo.Años:
      return "años";
  }
}

export function textoDePeriodoDeActualizacion(valor: number, unidad: UnidadesDeTiempo) {
  if (valor === 1) {
    switch (unidad) {
      case UnidadesDeTiempo.Minutos:
        return "Cada minuto";
      case UnidadesDeTiempo.Horas:
        return "Cada hora";
      case UnidadesDeTiempo.Dias:
        return textoDeLaFrecuenciaDeActualizacion(FrecuenciaDeActualizacion.Diaria);
      case UnidadesDeTiempo.Semanas:
        return "Semanal";
      case UnidadesDeTiempo.Meses:
        return textoDeLaFrecuenciaDeActualizacion(FrecuenciaDeActualizacion.Mensual);
      case UnidadesDeTiempo.Años:
        return textoDeLaFrecuenciaDeActualizacion(FrecuenciaDeActualizacion.Anual);
    }
  } else {
    return `Cada ${valor} ${textoDeLaUnidadDeTiempo(unidad)}`;
  }
}

export function tituloDeActivos(tipo?: TiposDeActivo) {
  switch (tipo) {
    case TiposDeActivo.Vinculacion:
      return "Usuarios";
    case TiposDeActivo.EquipoHerramienta:
      return "Equipos y Herramientas";
    case TiposDeActivo.Vehiculo:
      return "Vehículos";
    case TiposDeActivo.SustanciaQuimica:
      return "Sustancias Químicas";
  }
}
