import { useState, FC, FormEvent, useContext, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  TableMessage,
} from "../../../../utilities/utils";
import { Empresa } from "../../../../models/Empresa";
import { Vinculacion } from "../../../../models/Vinculacion";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Context } from "../../../Context";
import { buscarEmpresaParaContratar } from "../../../../services/usuario-api-client";
import { Cargo } from "../../../../models/Cargo";

interface Response {
  empresa: Empresa;
  usuario: Vinculacion;
}

interface BusquedaDeEmpresaProps {
  empresaPreseleccionada: Empresa | null;
  usuarioPreseleccionado: Vinculacion | null;
  onChange: (response: Response) => void;
}

const BusquedaDeEmpresa: FC<BusquedaDeEmpresaProps> = ({
  empresaPreseleccionada,
  usuarioPreseleccionado,
  onChange,
}) => {
  const { authToken } = useContext(Context);
  const [empresasCoincidentes, setEmpresasCoincidentes] = useState<Empresa[]>([]);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState<Empresa | null>(
    empresaPreseleccionada
  );
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<Vinculacion | null>(
    usuarioPreseleccionado
  );
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [vinculacionesDeLaEmpresaSeleccionada, setVinculacionesDeLaEmpresaSeleccionada] = useState<
    Vinculacion[]
  >([]);
  const [search, setSearch] = useState<string>("");
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [desambiguationModalIsOpen, setDesambiguationModalIsOpen] = useState<boolean>(false);
  const [alreadySearched, setAlreadySearched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearchFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!authToken || !search) return;
    setIsLoading(true);
    setEmpresaSeleccionada(null);
    setUsuarioSeleccionado(null);
    setVinculaciones([]);
    setVinculacionesDeLaEmpresaSeleccionada([]);
    setEmpresasCoincidentes([]);
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    buscarEmpresaParaContratar(authToken, search)
      .then(({ data }) => {
        const { empresas, vinculaciones } = data;
        if (empresas.length === 1) {
          const empresa = empresas[0];
          setEmpresaSeleccionada(empresa);
          setVinculacionesDeLaEmpresaSeleccionada(vinculaciones);
        } else {
          setEmpresasCoincidentes(empresas);
          if (empresas.length > 1) {
            setDesambiguationModalIsOpen(true);
          }
        }
        setVinculaciones(vinculaciones);
      })
      .catch(({ response }) => {
        if (response.status !== 404) {
          setMensajeDeError(response?.data?.message || "Ocurrió un error inesperado");
          setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
        }
      })
      .finally(() => {
        setIsLoading(false);
        setAlreadySearched(true);
      });
  };

  const handleSelectUsuario = (id: string) => {
    const usuario = vinculaciones.find(({ _id }) => _id === id);
    if (usuario) {
      setUsuarioSeleccionado(usuario);
      onChange({ empresa: empresaSeleccionada!, usuario });
    }
  };

  const handleSelectEmpresa = (empresaSeleccionada: Empresa) => {
    setEmpresaSeleccionada(empresaSeleccionada);
    const vinculacionesDeLaEmpresa = vinculaciones.filter(({ empresa }) =>
      typeof empresa === "string"
        ? empresa === empresaSeleccionada._id
        : empresa._id === empresaSeleccionada._id
    );
    setVinculacionesDeLaEmpresaSeleccionada(vinculacionesDeLaEmpresa);
    setDesambiguationModalIsOpen(false);
  };

  useEffect(() => {
    if (!!authToken && empresaPreseleccionada) {
      setIsLoading(true);
      buscarEmpresaParaContratar(authToken, empresaPreseleccionada._id)
        .then(({ data }) => {
          const { empresas, vinculaciones } = data;
          if (empresas.length === 1) {
            const empresa = empresas[0];
            setEmpresaSeleccionada(empresa);
          }
          setVinculaciones(vinculaciones);
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  }, [authToken, empresaPreseleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form onSubmit={handleSearchFormSubmit}>
        <Row className="align-items-center mb-3">
          <Col>
            <FormGroup floating>
              <Input
                type="search"
                name="ruc"
                id="ruc"
                placeholder="Nombre o RUC de la empresa"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Label for="ruc">Nombre o RUC de la empresa</Label>
            </FormGroup>
          </Col>
          <Col xs="auto">
            <FormGroup>
              <Button type="submit" color="primary">
                Buscar
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <TableCaption>
            {empresaSeleccionada ? empresaSeleccionada.nombreDeLaEmpresa : "Datos de contacto"}
          </TableCaption>
        </Col>
      </Row>
      <TableContainer>
        <CustomTable hover={vinculacionesDeLaEmpresaSeleccionada.length > 0}>
          <thead>
            <tr>
              <th>#</th>
              <th>Selección</th>
              <th>Nombre del Usuario</th>
              <th>Cargo</th>
              <th>Correo</th>
              <th>Teléfono</th>
            </tr>
          </thead>
          <tbody>
            {vinculacionesDeLaEmpresaSeleccionada.map(({ _id, persona, cargo }, index) => (
              <tr key={_id} onClick={() => handleSelectUsuario(_id)}>
                <td>{index + 1}</td>
                <td className="text-center">
                  <Input type="checkbox" checked={usuarioSeleccionado?._id === _id} />
                </td>
                <td>
                  {persona.nombre} {persona.apellidos}
                </td>
                <td>{(cargo as Cargo)?.cargo}</td>
                <td>{persona.correo}</td>
                <td>{persona.telefono}</td>
              </tr>
            ))}
            {vinculacionesDeLaEmpresaSeleccionada.length === 0 && (
              <tr>
                <td colSpan={6} className="text-center">
                  {empresaSeleccionada ? (
                    <TableMessage>No hay usuarios vinculados a esta empresa</TableMessage>
                  ) : alreadySearched ? (
                    <TableMessage>No se encontró una empresa con estos datos</TableMessage>
                  ) : (
                    <TableMessage>Ingrese el RUC y DV de la empresa</TableMessage>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Collapse isOpen={!!mensajeDeError}>
        <Alert color="danger" className="mt-3" onClick={() => alert(mensajeDeErrorInsider)}>
          {mensajeDeError}
        </Alert>
      </Collapse>
      <Modal isOpen={desambiguationModalIsOpen} toggle={() => setDesambiguationModalIsOpen(false)}>
        <ModalHeader toggle={() => setDesambiguationModalIsOpen(false)}>
          Se encontraron varias empresas con estos datos
        </ModalHeader>
        <ModalBody>
          <TableContainer>
            <CustomTable hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre de la empresa</th>
                  <th>RUC</th>
                  <th>DV</th>
                </tr>
              </thead>
              <tbody>
                {empresasCoincidentes.map((empresa, index) => (
                  <tr
                    key={empresa._id}
                    onClick={() => handleSelectEmpresa(empresa)}
                    className="cursor-pointer"
                  >
                    <td>{index + 1}</td>
                    <td>{empresa.nombreDeLaEmpresa}</td>
                    <td>{empresa.ruc}</td>
                    <td>{empresa.digitoVerificador}</td>
                  </tr>
                ))}
              </tbody>
            </CustomTable>
          </TableContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default BusquedaDeEmpresa;
