import { datetimeToISOString } from "../utilities/utils";
import { Empresa } from "./Empresa";

export interface CargoBase {
  cargo: string;
  empresa: string | Empresa;
}

export interface Cargo extends CargoBase {
  _id: string;
  fechaDeCreacion: Date;
}

export interface CargoResponse extends CargoBase {
  _id: string;
  createdAt: string;
}

export class Cargo implements Cargo {
  constructor({ _id, cargo, empresa, createdAt }: CargoResponse) {
    this._id = _id;
    this.cargo = cargo;
    this.empresa = empresa;
    this.fechaDeCreacion = new Date(createdAt);
  }

  get asResponse(): CargoResponse {
    return {
      _id: this._id,
      cargo: this.cargo,
      empresa: this.empresa,
      createdAt: datetimeToISOString(this.fechaDeCreacion),
    };
  }

  toString() {
    return this._id;
  }
}
