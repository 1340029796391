import { FormEvent, FC, useContext, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { DocumentoDelContrato } from "../../../../models/DocumentoDelContrato";
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Row } from "reactstrap";
import { FaPaperPlane } from "react-icons/fa";
import { shortDateToString } from "../../../../utilities/utils";
import { Context } from "../../../Context";
import { crearComentario } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { ComentarioDeDocumento } from "../../../../models/ComentarioDeDocumento";

const ComentariosContainer = styled.div`
  max-height: calc(100vh - 311px);
  overflow-y: auto;
`;

interface ComentariosDelDocumentoProps {
  documentoDelContrato: DocumentoDelContrato;
  onComentarioSubmitSuccess: () => void;
}

const ComentariosDelDocumento: FC<ComentariosDelDocumentoProps> = ({
  documentoDelContrato,
  onComentarioSubmitSuccess,
}) => {
  const { authToken } = useContext(Context);
  const containerRef = useRef<HTMLDivElement>(null);
  const [comentarios, setComentarios] = useState<ComentarioDeDocumento[]>(
    documentoDelContrato.comentarios
  );
  const [comentario, setComentario] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitComentario = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    crearComentario(authToken, documentoDelContrato._id, comentario)
      .then(({ data }) => {
        setComentario("");
        onComentarioSubmitSuccess();
        setComentarios([...comentarios, new ComentarioDeDocumento(data)]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [comentarios, containerRef]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Card>
        <CardBody>
          <ComentariosContainer className="mb-n3" ref={containerRef}>
            {comentarios.map(({ _id, comentario, usuario, createdAt }) => (
              <Row key={_id} className="mb-3 gx-0">
                <Col>
                  <strong>
                    {shortDateToString(createdAt)} {usuario.nombreCompleto}:
                  </strong>{" "}
                  {comentario}
                </Col>
              </Row>
            ))}
            {comentarios.length === 0 && (
              <Row className="mb-3">
                <Col className="text-center">Aún no hay comentarios</Col>
              </Row>
            )}
          </ComentariosContainer>
        </CardBody>
        <CardFooter>
          <Form onSubmit={handleSubmitComentario}>
            <Row>
              <Col>
                <Input
                  type="textarea"
                  placeholder="Escribe un comentario"
                  value={comentario}
                  onChange={(e) => setComentario(e.target.value)}
                />
              </Col>
              <Col xs="auto">
                <Button color="primary" className="rounded-circle">
                  <Row className="justify-content-center align-items-center gx-0">
                    <Col xs="auto" className="pe-1 pt-1 pb-2">
                      <FaPaperPlane size={20} />
                    </Col>
                  </Row>
                </Button>
              </Col>
            </Row>
          </Form>
        </CardFooter>
      </Card>
    </>
  );
};

export default ComentariosDelDocumento;
