import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Button, Col, Input, Row, Table } from "reactstrap";
import styled from "styled-components";

export function toCamelCase(input: string): string {
  return input
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, character) => character.toUpperCase())
    .replace(/^./, (firstCharacter) => firstCharacter.toLowerCase());
}

export function toTitleCase(input: string): string {
  return input
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, character) => character.toUpperCase())
    .replace(/^./, (firstCharacter) => firstCharacter.toUpperCase());
}

export function validarContraseña(contraseña: string): boolean {
  const longitudMinima = 8;
  const tieneMayuscula = /[A-Z]/.test(contraseña);
  const tieneMinuscula = /[a-z]/.test(contraseña);
  const tieneNumero = /[0-9]/.test(contraseña);
  const tieneCaracterEspecial = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(contraseña);

  if (contraseña.length < longitudMinima) {
    return false;
  }

  if (!tieneMayuscula) {
    return false;
  }

  if (!tieneMinuscula) {
    return false;
  }

  if (!tieneNumero) {
    return false;
  }

  if (!tieneCaracterEspecial) {
    return false;
  }

  return true;
}

export const range = (start: number, end: number, step = 1) => {
  if (!Number.isInteger(start)) {
    throw new TypeError("start should be an integer");
  }
  if (!Number.isInteger(end)) {
    throw new TypeError("end should be an integer");
  }
  if (!Number.isInteger(step)) {
    throw new TypeError("step should be an integer");
  }
  if (end < start) {
    throw new RangeError("end should be greater than start");
  }
  if (step < 1) {
    throw new RangeError("step should be a positive integer");
  }

  return Array.from({ length: (end - start) / step + 1 }, (_, i) => start + i * step);
};

export const dateToString = (date: Date) => {
  return format(date, "PP", { locale: es });
};

export const shortDateToString = (date: Date) => {
  return format(date, "P", { locale: es });
};

export const shortDatetimeToString = (date: Date) => {
  return format(date, "Pp", { locale: es });
};

export const longDateToString = (date: Date) => {
  return format(date, "PPP", { locale: es });
};

export const dateToISOString = (date?: Date) => {
  if (!date) return "";
  const isoString = date.toISOString();
  const splitted = isoString.split("T")[0];
  return splitted;
};

export const datetimeToISOString = (date?: Date) => {
  if (!date) return "";
  const isoString = date.toISOString();
  const splitted = isoString.split(".")[0];
  return splitted.slice(0, -3);
};

export const dateInputToDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const datetimeInputToDate = (datetimeString: string) => {
  const [dateString, timeString] = datetimeString.split("T");
  const [year, month, day] = dateString.split("-").map(Number);
  const [hours, minutes] = timeString.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes);
};

enum Vigencia {
  Vigente = "Vigente",
  Vencido = "Vencido",
  PorVencer = "Por vencer",
}

export const calcularVigencia = (fechaFin: Date): Vigencia => {
  const hoy = new Date();
  const diasDeVigencia = (fechaFin.getTime() - hoy.getTime()) / (1000 * 3600 * 24);
  if (diasDeVigencia < 0) {
    return Vigencia.Vencido;
  }
  if (diasDeVigencia < 30) {
    return Vigencia.PorVencer;
  }
  return Vigencia.Vigente;
};

export const procesarLinkDeDocumento = (filepath: string) => {
  const url = `/api/files/${filepath}`;
  return url;
};

export const obtenerURLCompleta = (filepath: string) => {
  const url = new URL(procesarLinkDeDocumento(filepath), window.location.origin);
  return url.toString();
};

export const abrirEnlaceEnNuevaPestaña = (filepath: string) => {
  const url = obtenerURLCompleta(filepath);
  window.open(url, "_blank");
};

interface SeparatorRowProps {
  height: number;
}

export const SeparatorRow = styled(Row).attrs(({ height }) => ({
  style: { height: `${height}px` },
}))<SeparatorRowProps>``;

interface SeparatorColProps {
  width: number;
}

export const SeparatorCol = styled(Col).attrs(({ width }) => ({
  xs: "auto",
  style: { width: `${width}px` },
}))<SeparatorColProps>``;

export const ImageButton = styled.button`
  &,
  &:focus,
  &:active,
  &:hover {
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    background: transparent;
    display: block;
    pointer-events: all;
  }
`;

export const TableCaption = styled.div`
  font-size: 16px;
  background-color: var(--bs-primary);
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;

export const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

export const VerticalTableContainer = styled.div`
  th {
    width: 0;
    min-width: fit-content;
    @media (min-width: 768px) {
      white-space: nowrap;
    }
  }
`;

export const CustomTable = styled(Table)`
  caption-side: top;
  caption {
    font-size: 16px;
    background-color: var(--bs-primary);
    text-align: center;
    color: #fff;
    font-weight: bold;
  }
  thead > tr > th,
  tfoot > tr > td {
    text-align: center;
    font-size: 8px;
    color: #000;
    font-weight: bold;
    background-color: #c6daf0;
  }
  tbody > tr {
    > td {
      font-size: 9px;
      background-color: #fff;
      button,
      a {
        &.small {
          font-size: 9px;
        }
      }
    }
    &:nth-child(odd) > td {
      background-color: #fff;
    }
  }
  thead > tr > th,
  tbody > tr > td,
  tfoot > tr > td {
    border: 1px solid #a0a7af;
    vertical-align: middle;
    &.calificacion-baja {
      background-color: #84bf4d;
    }
    &.calificacion-media {
      background-color: #ffff09;
    }
    &.calificacion-alta {
      background-color: #fb0006;
    }
  }
`;

export const TableMessage = styled.span`
  font-size: 14px;
`;

export const PasswordInput = styled(Input)`
  &.form-control.not-empty {
    padding-right: calc(1.5em + 0.75rem);
    &.is-valid,
    &.is-invalid {
      background-position: right calc(0.375em + 1.5875rem) center;
      padding-right: calc(1.5em + 1.75rem);
    }
  }
  ::-ms-reveal {
    display: none;
  }
`;

export const MostrarContraseñaButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 0px;
`;

export const IconsContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const COLORS = [
  "#000080",
  "#0bb4ff",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
];

export const scrollToTheBottom = () => {
  window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
};

export const darkenHexColor = (hex: string, amount: number) => {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  r = Math.max(0, r - amount);
  g = Math.max(0, g - amount);
  b = Math.max(0, b - amount);

  const newHex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
    .toString(16)
    .padStart(2, "0")}`;

  return newHex;
};
