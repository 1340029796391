import { useContext, FC, useState, FormEvent, useEffect } from "react";
import { Card, CardBody, Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import styled from "styled-components";
import {
  Documento,
  FrecuenciaDeActualizacion,
  TiposDeDocumento,
  UnidadesDeTiempo,
} from "../../../../models/Documento";
import { Context } from "../../../Context";
import { ImageButton, dateToISOString } from "../../../../utilities/utils";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { createArchivoDeDocumento } from "../../../../services/usuario-api-client";

const ActivosContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

interface AgregarActualizacionFormProps {
  formId: string;
  documento: Documento;
  onSuccess: () => void;
}

const AgregarActualizacionForm: FC<AgregarActualizacionFormProps> = ({
  formId,
  documento,
  onSuccess,
}) => {
  const { authToken, usuarioEnSesion } = useContext(Context);
  const [archivo, setArchivo] = useState<File | null>(null);
  const [nombre, setNombre] = useState<string>("");
  const [fechaDeVencimientoString, setFechaDeVencimientoString] = useState<string>("");
  const [activosSeleccionados, setActivosSeleccionados] = useState<string[]>([]);
  const [creadoPor, setCreadoPor] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleActivo = (activoId: string) => {
    if (activosSeleccionados.includes(activoId)) {
      setActivosSeleccionados(activosSeleccionados.filter((v) => v !== activoId));
    } else {
      setActivosSeleccionados([...activosSeleccionados, activoId]);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!authToken) return;
    setIsLoading(true);
    const body = {
      archivo: archivo as File,
      nombre,
      fechaDeVencimientoString,
      creadoPor,
      activos: activosSeleccionados,
    };
    createArchivoDeDocumento(authToken, documento._id, body)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (archivo && nombre === "") {
      const { name } = archivo;
      const lastDotIndex = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDotIndex);
      setNombre(fileName);
    }
  }, [archivo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documento) {
      const now = new Date();
      const {
        frecuenciaDeActualizacion,
        valorDeLaFrecuenciaDeActualizacion,
        unidadDeLaFrecuenciaDeActualizacion,
      } = documento;
      if (frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra) {
        if (!!valorDeLaFrecuenciaDeActualizacion && !!unidadDeLaFrecuenciaDeActualizacion) {
          let fechaDeVencimiento: Date;
          switch (unidadDeLaFrecuenciaDeActualizacion) {
            case UnidadesDeTiempo.Años:
              fechaDeVencimiento = new Date(
                now.getFullYear() + valorDeLaFrecuenciaDeActualizacion,
                now.getMonth(),
                now.getDate()
              );
              break;
            case UnidadesDeTiempo.Meses:
              fechaDeVencimiento = new Date(
                now.getFullYear(),
                now.getMonth() + valorDeLaFrecuenciaDeActualizacion,
                now.getDate()
              );
              break;
            case UnidadesDeTiempo.Semanas:
              fechaDeVencimiento = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + valorDeLaFrecuenciaDeActualizacion * 7
              );
              break;
            case UnidadesDeTiempo.Dias:
              fechaDeVencimiento = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + valorDeLaFrecuenciaDeActualizacion
              );
              break;
            case UnidadesDeTiempo.Horas:
              fechaDeVencimiento = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                now.getHours() + valorDeLaFrecuenciaDeActualizacion
              );
              break;
            case UnidadesDeTiempo.Minutos:
              fechaDeVencimiento = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate(),
                now.getHours(),
                now.getMinutes() + valorDeLaFrecuenciaDeActualizacion
              );
              break;
            default:
              fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth(), now.getDate());
              break;
          }
          setFechaDeVencimientoString(dateToISOString(fechaDeVencimiento));
        }
      } else {
        let fechaDeVencimiento: Date;
        switch (frecuenciaDeActualizacion) {
          case FrecuenciaDeActualizacion.Anual:
            fechaDeVencimiento = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
            break;
          case FrecuenciaDeActualizacion.Mensual:
            fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
            break;
          case FrecuenciaDeActualizacion.Bimestral:
            fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth() + 2, now.getDate());
            break;
          case FrecuenciaDeActualizacion.Trimestral:
            fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth() + 3, now.getDate());
            break;
          case FrecuenciaDeActualizacion.Semestral:
            fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth() + 6, now.getDate());
            break;
          case FrecuenciaDeActualizacion.Diaria:
            fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
            break;
          default:
            fechaDeVencimiento = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            break;
        }
        setFechaDeVencimientoString(dateToISOString(fechaDeVencimiento));
      }
    }
  }, [documento]);

  useEffect(() => {
    if (usuarioEnSesion) {
      const nombreYCargoDelUsuario = usuarioEnSesion.nombreCompleto;
      setCreadoPor(nombreYCargoDelUsuario);
      if (documento.tipoDeDocumento === TiposDeDocumento.DocumentacionDeUsuariosDirectos) {
        setActivosSeleccionados([...activosSeleccionados, usuarioEnSesion._id]);
      }
    }
  }, [usuarioEnSesion]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            type="file"
            name="archivo"
            id="archivo"
            placeholder="Archivo"
            accept="application/pdf"
            onChange={(e) => setArchivo(e.target.files?.[0] || null)}
            required
          />
          <FormText>El archivo debe ser un PDF.</FormText>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            name="nombre"
            id="nombre"
            placeholder="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <Label for="nombre">Nombre</Label>
        </FormGroup>
        {!!documento.activos &&
          documento.tipoDeDocumento !== TiposDeDocumento.DocumentacionGeneralDeLaEmpresa && (
            <Row>
              <Col>
                <Card className="mb-3">
                  <CardBody className="pt-1">
                    <Row className="justify-content-between">
                      <Col>
                        <Label className="small text-muted">Activo(s)</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ActivosContainer>
                          {documento.activos?.map(({ _id, nombreCompleto }) => (
                            <Row key={_id}>
                              <Col xs="auto">
                                <Row
                                  className="gx-1 align-items-center cursor-pointer"
                                  onClick={() => toggleActivo(_id)}
                                >
                                  <Col xs="auto">
                                    <ImageButton
                                      type="button"
                                      title={
                                        activosSeleccionados.includes(_id)
                                          ? "Deseleccionar"
                                          : "Seleccionar"
                                      }
                                      className="bg-primary"
                                      style={{ lineHeight: 0 }}
                                    >
                                      {activosSeleccionados.includes(_id) ? (
                                        <FaCheckSquare size={15} color="#EAEAEA" />
                                      ) : (
                                        <FaSquare size={15} color="#EAEAEA" />
                                      )}
                                    </ImageButton>
                                  </Col>
                                  <Col>
                                    <small>{nombreCompleto}</small>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          ))}
                          {documento.activos.length === 0 && (
                            <Row className="gx-0">
                              <Col className="text-center">
                                <Label className="small">No hay activos registrados</Label>
                              </Col>
                            </Row>
                          )}
                        </ActivosContainer>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        <FormGroup floating>
          <Input
            type="date"
            name="fechaDeVencimiento"
            id="fechaDeVencimiento"
            placeholder="Fecha de vencimiento"
            value={fechaDeVencimientoString}
            onChange={(e) => setFechaDeVencimientoString(e.target.value)}
            required
          />
          <Label for="fechaDeVencimiento">Fecha de vencimiento</Label>
          <FormText>
            La fecha de vencimiento inicial es calculada a partir de la frecuencia de actualización
            del documento.
          </FormText>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            name="creadoPor"
            id="creadoPor"
            placeholder="Creado por"
            defaultValue={creadoPor}
            disabled
          />
          <Label for="creadoPor">Creado por</Label>
        </FormGroup>
      </Form>
    </>
  );
};

export default AgregarActualizacionForm;
