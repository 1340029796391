export const GISSTLogoFull = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height="100%"
    viewBox="0 0 551.84 743.1"
    style={{ maxWidth: "100%" }}
  >
    <defs>
      <symbol id="GISST" data-name="GISST" viewBox="0 0 551.84 743.1">
        <path
          d="M275.27,367.21c-52.87.75-101.35-44-98.49-103.66,2.44-50.8,44.08-92.18,95.59-93.55,28.21-.75,52.79,9.05,72.86,29.09a95.62,95.62,0,0,1,28.47,61.18c2.37,28.6-6.47,54-25.81,75.19A96.71,96.71,0,0,1,275.27,367.21Z"
          fill="#fad201"
        />
        <path
          d="M211.86,407.31a69.38,69.38,0,0,1-37.23,62.94,70.66,70.66,0,0,1-48.9,6.23,65.46,65.46,0,0,1-19.53-8.12,75.91,75.91,0,0,1-17.56-15.2A69.77,69.77,0,0,1,76,431.1a60.62,60.62,0,0,1-3.44-15.77,72.73,72.73,0,0,1,.93-20,70.54,70.54,0,0,1,9.06-23.44A71.91,71.91,0,0,1,96.1,355.85,5.69,5.69,0,0,0,98,351.3c.25-8-2.92-14.83-7.42-21.05a23.72,23.72,0,0,0-6.75-6,4.3,4.3,0,0,0-3.17-.67,49.8,49.8,0,0,1-44.12-14.28,47,47,0,0,1-12.11-21.7,48.82,48.82,0,0,1-.77-22.25,47.82,47.82,0,0,1,6.82-17,50.12,50.12,0,0,1,18.62-17,49.22,49.22,0,0,1,27.14-5.53,45.73,45.73,0,0,1,20.23,6.17,48,48,0,0,1,12.79,10.53,50,50,0,0,1,10.08,18.09,47.3,47.3,0,0,1,1.86,19.6,51.91,51.91,0,0,1-8.54,22.85c-1.5,2.2-3,4.66-5.08,6.14-3.14,2.22-2.59,5.1-2.21,7.88a33.43,33.43,0,0,0,10.31,20.4,34.2,34.2,0,0,0,4.13,3.49,4.11,4.11,0,0,0,3.27.15A71.34,71.34,0,0,1,151,339.17a66.88,66.88,0,0,1,42,21.35,71.1,71.1,0,0,1,15.69,26.21C211,393.66,212.4,400.89,211.86,407.31Z"
          fill="#fad201"
        />
        <path
          d="M480.65,141.8c-.31,18.38-5.57,33.61-16.52,46.7a51.23,51.23,0,0,1-7.87,7.78c-2.95,2.27-3,5-2.73,8.15a34,34,0,0,0,6.05,16.9,29.54,29.54,0,0,0,8.36,8.12c1.89,1.22,3.4-.31,5.07-.4,13.26-.76,25.12,2.61,35.57,10.91a48.84,48.84,0,0,1,17.52,48.27A51.33,51.33,0,0,1,518.81,306a49,49,0,0,1-13.17,13.17,46.39,46.39,0,0,1-17.68,7.5,48.08,48.08,0,0,1-15.45.88,50.51,50.51,0,0,1-23.05-8.66,49.67,49.67,0,0,1-21-40.22,53.85,53.85,0,0,1,2.86-16.55c2.1-6.84,6.15-12.31,10.62-17.61a8.94,8.94,0,0,1,2.34-2.29,2.77,2.77,0,0,0,1.27-2.68c-.16-6.07-1.18-11.82-4.52-17.19-2.73-4.39-5.69-8.33-10.09-11.13a2.17,2.17,0,0,0-2-.4c-5.93,2.14-12.16,2.45-18.32,2.55a63.68,63.68,0,0,1-17.44-2.31,75.26,75.26,0,0,1-18.8-7.91A66.72,66.72,0,0,1,356,186.94a64.68,64.68,0,0,1-10.39-17.77,71.06,71.06,0,0,1-4.62-20.31,73,73,0,0,1,.65-16.92,68.07,68.07,0,0,1,4-14.31,64.92,64.92,0,0,1,9.05-16,76,76,0,0,1,16.08-15.56,66.76,66.76,0,0,1,22.58-10.35c8.6-2.07,17.31-3,26.25-1.49a87.48,87.48,0,0,1,16.09,4,67.26,67.26,0,0,1,23.63,15A68.56,68.56,0,0,1,476,118.79,83,83,0,0,1,480.65,141.8Z"
          fill="#fad201"
        />
        <path
          d="M408,478.55a49.05,49.05,0,0,1-18.66-2.19,65.29,65.29,0,0,1-20-9.49c-5-3.58-9.87-7.24-13.63-12.19-2-2.65-5-1.41-7.34-1.25-10.48.68-18.6,5.74-24.57,14.34a2.86,2.86,0,0,0-.31,2.46,49.64,49.64,0,0,1-.06,17.82c-2.25,12.54-8.74,22.46-18.57,30.18a46.55,46.55,0,0,1-17.91,8.53,50,50,0,0,1-20.86.74,49.52,49.52,0,0,1-30-18.28,50.85,50.85,0,0,1-9.94-23.9,48.31,48.31,0,0,1,9.37-35.88,49.05,49.05,0,0,1,21.87-16.55,47.39,47.39,0,0,1,24.83-2.66,51.28,51.28,0,0,1,20.74,8.07,75,75,0,0,1,7.76,6.47,4,4,0,0,0,3.14,1.2c7.91-.53,15-3,21.09-8.24a33.53,33.53,0,0,0,5.85-6.58,3.6,3.6,0,0,0,.45-3.35,68.54,68.54,0,0,1-1.81-29.31c2-13.88,7.9-25.77,17-36.19a66.61,66.61,0,0,1,23.46-17.13,70.63,70.63,0,0,1,23.92-5.89,68.56,68.56,0,0,1,35.81,7.05A67.57,67.57,0,0,1,458,359.74,69.14,69.14,0,0,1,478,415.11a67.79,67.79,0,0,1-16.42,39.22,70,70,0,0,1-26.16,19.13C426.76,477,417.71,479.25,408,478.55Z"
          fill="#fad201"
        />
        <path
          d="M141.81,212.64c-10.23.58-19.87-2-29.09-6A65,65,0,0,1,99,198.26a69.44,69.44,0,0,1-16.78-18.74,70.34,70.34,0,0,1-7.78-17.83,66.2,66.2,0,0,1-2.57-23.34,70.28,70.28,0,0,1,23.87-48,71.14,71.14,0,0,1,47.21-17.43,60,60,0,0,1,17.59,2.61,71.26,71.26,0,0,1,33.93,21.54,4.89,4.89,0,0,0,4.16,1.77c11.2-.21,20.25-4.58,26.92-13.71a4.29,4.29,0,0,0,.91-3.29c-.63-5.45-1.25-10.93-.19-16.38a52.76,52.76,0,0,1,8-20,48.56,48.56,0,0,1,14-13.91,47.2,47.2,0,0,1,19.06-7.21,52.38,52.38,0,0,1,16.39.07,50,50,0,0,1,22.17,10A47.36,47.36,0,0,1,320.69,54a49.14,49.14,0,0,1,3.59,21.88c-.55,11.24-4.78,20.89-12,29.39a46.52,46.52,0,0,1-20.93,14.18c-13.49,4.56-26.74,3.65-39.41-3-3.79-2-7.56-4.3-10.39-7.51-2.6-3-5.6-3.34-9-2.79a34.19,34.19,0,0,0-22.66,13.48,4.57,4.57,0,0,0-1,4.4c1.83,5.33,2.27,10.89,2.57,16.44a69.23,69.23,0,0,1-6.35,31.6,69.45,69.45,0,0,1-52.44,39.83A81.39,81.39,0,0,1,141.81,212.64Z"
          fill="#fad201"
        />
        <path
          d="M368.93,503.55c13.56.61,24.35,10.8,24.15,24.78-.21,15.19-10.95,24.79-25.06,24.65-13.67-.14-23.9-9.79-24.28-24.14C343.35,514.07,355,503.56,368.93,503.55Z"
          fill="#fad201"
        />
        <path
          d="M158.62,24.59C158.12,11,169.75-.34,183.68,0a24.69,24.69,0,1,1-1.28,49.37C170.05,49.08,158.24,39,158.62,24.59Z"
          fill="#fad201"
        />
        <path
          d="M24.52,347.94c13.9-.48,24.91,11.34,24.91,24.36A24.85,24.85,0,0,1,26.25,397C9.64,398,.67,383.8.05,374-.88,359.05,12.23,347.11,24.52,347.94Z"
          fill="#fad201"
        />
        <path
          d="M551.83,182.8c.12,13.72-10.12,24.29-23.7,25S503.62,197,502.67,185.27a25,25,0,0,1,23.67-26.92C539.58,157.92,552.22,169,551.83,182.8Z"
          fill="#fad201"
        />
        <path
          d="M527.35,351.86a16.88,16.88,0,0,1,16.41,17.76c-.22,8-8.77,16.17-17,15.52-9.31-.73-16.08-7.69-16-16.47C510.76,358.79,517.8,352.24,527.35,351.86Z"
          fill="#fad201"
        />
        <path
          d="M23.76,201.05c-9.26,0-16.87-6.65-17-15.87-.17-10.13,6.34-16.34,16.56-17.42,7.72-.81,16.18,7.79,16.49,15.92C40.17,193.4,31.92,200.9,23.76,201.05Z"
          fill="#fad201"
        />
        <path
          d="M199.36,528c.33,9.87-7.17,16.69-16.3,16.71a16.48,16.48,0,0,1-.29-33C191.58,511.54,200,519.09,199.36,528Z"
          fill="#fad201"
        />
        <path
          d="M384.67,24.88c.82,8.22-7.65,16.4-16.18,16.21A16.49,16.49,0,0,1,352,23.72a16.71,16.71,0,0,1,16.66-15.6C376.76,8.24,385.32,15.71,384.67,24.88Z"
          fill="#fad201"
        />
        <path
          d="M131.36,652.73c0,29.78-19.44,50.94-47.94,50.94-27.88,0-48.48-20-48.48-47.73s20.22-47.88,48.26-47.88c23.85,0,43.36,14.93,47.21,36.08H104.14c-3.29-7.67-11.44-12.6-20.72-12.6-13.63,0-22.81,9.86-22.81,24.4,0,14.39,9,24.25,22.81,24.25,9.57,0,17.78-4.63,20.64-11.65H81.46V652.73Z"
          fill="#000080"
        />
        <path d="M170,610.36h25.4v91H170Z" fill="#000080" />
        <path
          d="M234.62,672h25.86c0,6.5,5,9.67,10.74,9.67,5.25,0,10.26-2.8,10.26-7.77,0-5.71-7.08-7.36-15.74-9.46-13.16-3.27-30-7.3-30-28,0-17.92,13.17-28.19,34.63-28.19,21.81,0,34.33,11.2,34.33,29.72H279.6c0-5.76-4.29-8.46-9.48-8.46-4.47,0-8.76,1.94-8.76,6.19,0,5.17,6.86,6.87,15.43,9,13.39,3.45,30.91,7.93,30.91,28.71,0,19.88-15.28,30.19-36.41,30.19C249,703.59,234.62,691.82,234.62,672Z"
          fill="#000080"
        />
        <path
          d="M342.39,672h25.86c0,6.5,5,9.67,10.74,9.67,5.25,0,10.26-2.8,10.26-7.77,0-5.71-7.08-7.36-15.74-9.46-13.16-3.27-29.95-7.3-29.95-28,0-17.92,13.17-28.19,34.63-28.19,21.81,0,34.32,11.2,34.32,29.72H387.37c0-5.76-4.29-8.46-9.48-8.46-4.47,0-8.76,1.94-8.76,6.19,0,5.17,6.86,6.87,15.43,9,13.39,3.45,30.9,7.93,30.9,28.71,0,19.88-15.27,30.19-36.4,30.19C356.79,703.59,342.39,691.82,342.39,672Z"
          fill="#000080"
        />
        <path d="M521,632.94H496.29v68.43H471.11V632.94H446.39V610.36H521Z" fill="#000080" />
        <path
          d="M316.82,266.7c0,25.19-16.45,43.09-40.57,43.09-23.59,0-41-17-41-40.38s17.11-40.51,40.83-40.51c20.19,0,36.7,12.63,39.95,30.52H293.78c-2.78-6.48-9.67-10.65-17.53-10.65-11.53,0-19.3,8.34-19.3,20.64s7.64,20.52,19.3,20.52c8.1,0,15-3.92,17.46-9.86H274.59V266.7Z"
          fill="#000080"
        />
      </symbol>
    </defs>
    <use width="551.84" height="743.1" xlinkHref="#GISST" />
  </svg>
);

export const GISSTLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="100%" viewBox="0 0 551.84 552.98">
    <path
      d="M395.39,616.43c-52.87.75-101.35-44-98.49-103.66,2.44-50.8,44.08-92.18,95.59-93.55,28.21-.75,52.79,9,72.86,29.09a95.62,95.62,0,0,1,28.47,61.18c2.37,28.6-6.47,54-25.81,75.19A96.71,96.71,0,0,1,395.39,616.43Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M332,656.53a69.38,69.38,0,0,1-37.23,62.94,70.66,70.66,0,0,1-48.9,6.23,65.46,65.46,0,0,1-19.53-8.12,75.91,75.91,0,0,1-17.56-15.2,69.77,69.77,0,0,1-12.63-22.06,60.62,60.62,0,0,1-3.44-15.77,72.73,72.73,0,0,1,.93-20,70.54,70.54,0,0,1,9.06-23.44,71.91,71.91,0,0,1,13.54-16.06,5.69,5.69,0,0,0,1.93-4.55c.25-8-2.92-14.83-7.42-21a23.72,23.72,0,0,0-6.75-6,4.3,4.3,0,0,0-3.17-.67,49.8,49.8,0,0,1-44.12-14.28,47,47,0,0,1-12.11-21.7,48.82,48.82,0,0,1-.77-22.25,47.82,47.82,0,0,1,6.82-17,50.12,50.12,0,0,1,18.62-16.95,49.22,49.22,0,0,1,27.14-5.53,45.73,45.73,0,0,1,20.23,6.17,48,48,0,0,1,12.79,10.53,50,50,0,0,1,10.08,18.09,47.3,47.3,0,0,1,1.86,19.6,51.91,51.91,0,0,1-8.54,22.85c-1.5,2.2-3,4.66-5.08,6.14-3.14,2.22-2.59,5.1-2.21,7.88a33.43,33.43,0,0,0,10.31,20.4,34.2,34.2,0,0,0,4.13,3.49,4.11,4.11,0,0,0,3.27.15,71.34,71.34,0,0,1,27.88-2.05,66.88,66.88,0,0,1,42,21.35A71.1,71.1,0,0,1,328.76,636C331.07,642.88,332.52,650.11,332,656.53Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M600.77,391c-.31,18.38-5.57,33.61-16.52,46.7a51.23,51.23,0,0,1-7.87,7.78c-3,2.27-3,5-2.73,8.15a34,34,0,0,0,6.05,16.9,29.54,29.54,0,0,0,8.36,8.12c1.89,1.22,3.4-.31,5.07-.4,13.26-.76,25.12,2.61,35.57,10.91a48.84,48.84,0,0,1,17.52,48.27,51.33,51.33,0,0,1-7.29,17.77,49,49,0,0,1-13.17,13.17,46.39,46.39,0,0,1-17.68,7.5,48.08,48.08,0,0,1-15.45.88,50.51,50.51,0,0,1-23-8.66,49.67,49.67,0,0,1-21.05-40.22,53.85,53.85,0,0,1,2.86-16.55c2.1-6.84,6.15-12.31,10.62-17.61a8.94,8.94,0,0,1,2.34-2.29,2.77,2.77,0,0,0,1.27-2.68c-.16-6.07-1.18-11.82-4.52-17.19-2.73-4.39-5.69-8.33-10.09-11.13a2.17,2.17,0,0,0-2-.4c-5.93,2.14-12.16,2.45-18.32,2.55a63.68,63.68,0,0,1-17.44-2.31,75.26,75.26,0,0,1-18.8-7.91,66.72,66.72,0,0,1-18.42-16.21,64.68,64.68,0,0,1-10.39-17.77,71.06,71.06,0,0,1-4.62-20.31,73,73,0,0,1,.65-16.92,68.07,68.07,0,0,1,4-14.31,64.92,64.92,0,0,1,9-16,76,76,0,0,1,16.08-15.56,66.76,66.76,0,0,1,22.58-10.35c8.6-2.07,17.31-3,26.25-1.49a87.48,87.48,0,0,1,16.09,4,67.26,67.26,0,0,1,23.63,15A68.56,68.56,0,0,1,596.1,368,83,83,0,0,1,600.77,391Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M528.09,727.77a49.05,49.05,0,0,1-18.66-2.19,65.29,65.29,0,0,1-20-9.49c-5-3.58-9.87-7.24-13.63-12.19-2-2.65-5-1.41-7.34-1.25-10.48.68-18.6,5.74-24.57,14.34a2.86,2.86,0,0,0-.31,2.46,49.64,49.64,0,0,1-.06,17.82c-2.25,12.54-8.74,22.46-18.57,30.18A46.55,46.55,0,0,1,407,776a50,50,0,0,1-20.86.74,49.52,49.52,0,0,1-30-18.28,50.85,50.85,0,0,1-9.94-23.9,48.31,48.31,0,0,1,9.37-35.88,49.05,49.05,0,0,1,21.87-16.55,47.39,47.39,0,0,1,24.83-2.66A51.28,51.28,0,0,1,423,687.52a75,75,0,0,1,7.76,6.47,4,4,0,0,0,3.14,1.2c7.91-.53,15-3,21.09-8.24a33.53,33.53,0,0,0,5.85-6.58,3.6,3.6,0,0,0,.45-3.35,68.54,68.54,0,0,1-1.81-29.31c2-13.88,7.9-25.77,17-36.19a66.61,66.61,0,0,1,23.46-17.13,70.63,70.63,0,0,1,23.92-5.89,68.56,68.56,0,0,1,35.81,7A67.57,67.57,0,0,1,578.14,609a69.14,69.14,0,0,1,19.94,55.37,67.79,67.79,0,0,1-16.42,39.22,70,70,0,0,1-26.16,19.13C546.88,726.23,537.83,728.47,528.09,727.77Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M261.93,461.86c-10.23.58-19.87-2-29.09-6a65,65,0,0,1-13.73-8.41,69.44,69.44,0,0,1-16.78-18.74,70.34,70.34,0,0,1-7.78-17.83A66.2,66.2,0,0,1,192,387.57a70.28,70.28,0,0,1,23.87-48,71.14,71.14,0,0,1,47.21-17.43,60,60,0,0,1,17.59,2.61,71.26,71.26,0,0,1,33.93,21.54,4.89,4.89,0,0,0,4.16,1.77c11.2-.21,20.25-4.58,26.92-13.71a4.29,4.29,0,0,0,.91-3.29c-.63-5.45-1.25-10.93-.19-16.38a52.76,52.76,0,0,1,8-20,48.56,48.56,0,0,1,14-13.91,47.2,47.2,0,0,1,19.06-7.21,52.38,52.38,0,0,1,16.39.07,50,50,0,0,1,22.17,10,47.36,47.36,0,0,1,14.77,19.66,49.14,49.14,0,0,1,3.59,21.88c-.55,11.24-4.78,20.89-12,29.39a46.52,46.52,0,0,1-20.93,14.18c-13.49,4.56-26.74,3.65-39.41-3-3.79-2-7.56-4.3-10.39-7.51-2.6-2.95-5.6-3.34-9-2.79a34.19,34.19,0,0,0-22.66,13.48,4.57,4.57,0,0,0-1,4.4c1.83,5.33,2.27,10.89,2.57,16.44a69.23,69.23,0,0,1-6.35,31.6,69.45,69.45,0,0,1-52.44,39.83A81.39,81.39,0,0,1,261.93,461.86Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M489.05,752.77c13.56.61,24.35,10.8,24.15,24.78-.21,15.19-11,24.79-25.06,24.65-13.67-.14-23.9-9.79-24.28-24.14C463.47,763.29,475.11,752.78,489.05,752.77Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M278.74,273.81c-.5-13.55,11.13-24.93,25.06-24.58a24.69,24.69,0,1,1-1.28,49.37C290.17,298.3,278.36,288.2,278.74,273.81Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M144.64,597.16c13.9-.48,24.91,11.34,24.91,24.36a24.85,24.85,0,0,1-23.18,24.73c-16.61,1-25.58-13.23-26.2-23.06C119.24,608.27,132.35,596.33,144.64,597.16Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M672,432c.12,13.72-10.12,24.29-23.7,25s-24.51-10.76-25.46-22.53a25,25,0,0,1,23.67-26.92C659.7,407.14,672.34,418.22,672,432Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M647.47,601.08a16.88,16.88,0,0,1,16.41,17.76c-.22,8-8.77,16.17-17,15.52-9.31-.73-16.08-7.69-16-16.47C630.88,608,637.92,601.46,647.47,601.08Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M143.88,450.27c-9.26,0-16.87-6.65-17-15.87-.17-10.13,6.34-16.34,16.56-17.42,7.72-.81,16.18,7.79,16.49,15.92C160.29,442.62,152,450.12,143.88,450.27Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M319.48,777.24c.33,9.87-7.17,16.69-16.3,16.71a16.48,16.48,0,0,1-.29-33C311.7,760.76,320.11,768.31,319.48,777.24Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M504.79,274.1c.82,8.22-7.65,16.4-16.18,16.21a16.49,16.49,0,0,1-16.5-17.37,16.71,16.71,0,0,1,16.66-15.6C496.88,257.46,505.44,264.93,504.79,274.1Z"
      fill="#fad201"
      transform="translate(-120.12 -249.22)"
    />
    <path
      d="M436.94,515.92c0,25.2-16.45,43.1-40.56,43.1-23.6,0-41-17-41-40.39s17.11-40.51,40.83-40.51c20.18,0,36.69,12.63,39.95,30.53H413.91c-2.79-6.49-9.68-10.66-17.53-10.66-11.54,0-19.31,8.34-19.31,20.64s7.65,20.52,19.31,20.52c8.09,0,15-3.92,17.46-9.85H394.71V515.92Z"
      fill="#000080"
      transform="translate(-120.12 -249.22)"
    />
  </svg>
);

interface HexagonProps {
  backgroundColor: string;
}

export const Hexagon = ({ backgroundColor }: HexagonProps) => (
  <svg
    width="110"
    height="120"
    viewBox="0 0 110 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M55 0L110 30V90L55 120L0 90V30L55 0Z" fill={backgroundColor} stroke="#F4DF7E" />
  </svg>
);
