import { useContext, FC, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { FaClipboardList, FaPlus } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { IconsContainer } from "../../../utilities/utils";
import { Context } from "../../Context";
import TablaDeActividad from "./Actividad/TablaDeActividad";
import { listarActividades } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Actividad } from "../../../models/Actividad";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const IdentificacionDePeligrosYEvaluacionDeRiesgos: FC = () => {
  const { setTituloDeLaPantallaActual, authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actividades, setActividades] = useState<Actividad[]>([]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.miper.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarActividades(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => setActividades(data.map((actividad) => new Actividad(actividad))))
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="mb-3">
        <Col>
          <Breadcrumbs items={[PATHS.usuarioHome]} />
        </Col>
        <Col xs="auto" className="ms-auto">
          <LinkContainer to={PATHS.nuevaActividad.to}>
            <Button color="primary" title="Agregar actividad">
              <IconsContainer>
                <FaClipboardList size={20} textAnchor="middle" alignmentBaseline="middle" />
                <FaPlus
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{
                    fontSize: ".5em",
                    position: "absolute",
                    left: "2.35em",
                    bottom: "1.5em",
                  }}
                />
              </IconsContainer>
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-center">
        <Col xs="12">
          <TablaDeActividad actividades={actividades} />
        </Col>
      </Row>
    </>
  );
};

export default IdentificacionDePeligrosYEvaluacionDeRiesgos;
