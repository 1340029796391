import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import tiposDeConsecuencia from "../../../../resources/tipos-de-consecuencia.json";
import tiposDeExposicion from "../../../../resources/tipos-de-exposicion.json";
import tiposDeProbabilidad from "../../../../resources/tipos-de-probabilidad.json";
import todosLosPeligros from "../../../../resources/peligros.json";
import {
  TipoDeConsecuencia,
  TipoDeExposicion,
  TipoDeProbabilidad,
} from "../../../../models/Riesgo";
import { ActividadResponse, Peligro } from "../../../../models/Actividad";
import { TiposDePeligro } from "../Actividad/FormularioDeActividad";
import { RequisitoLegal } from "../../../../models/RequisitoLegal";
import {
  EvaluacionDeRiesgo,
  EvaluacionDeRiesgoPayload,
} from "../../../../models/EvaluacionDeRiesgo";

interface FormularioDeNuevoRiesgoProps {
  formId: string;
  actividad: ActividadResponse;
  requisitoLegales: RequisitoLegal[];
  peligrosEvaluados: EvaluacionDeRiesgo[];
  calificarDeRiesgo: (body: { calificacionMaxima: number }) => void;
  onSubmit: (body: EvaluacionDeRiesgoPayload, callback: () => void) => void;
}

const FormularioDeNuevoRiesgo: FC<FormularioDeNuevoRiesgoProps> = ({
  formId,
  actividad,
  requisitoLegales,
  peligrosEvaluados,
  calificarDeRiesgo,
  onSubmit,
}) => {
  const [descripcionDeRiesgo, setDescripcionDeRiesgo] = useState<string>("");
  const [medidasDeControl, setMedidasDeControl] = useState<string>("");
  const [tipoDePeligro, setTipoDePeligro] = useState<number>(0);
  const [peligro, setPeligro] = useState<Peligro | undefined>();
  const [peligros, setPeligros] = useState<Peligro[]>([]);
  const [tiposDePeligro, setTiposDePeligro] = useState<TiposDePeligro[]>([]);
  const [requisitoLegal, setRequisitoLegal] = useState<string>("");
  const [tipoDeConsecuencia, setTipoDeConsecuencia] = useState<TipoDeConsecuencia>(
    tiposDeConsecuencia[0]
  );
  const [tipoDeExposicion, setTipoDeExposicion] = useState<TipoDeExposicion>(tiposDeExposicion[0]);
  const [tipoDeProbabilidad, setTipoDeProbabilidad] = useState<TipoDeProbabilidad>(
    tiposDeProbabilidad[0]
  );

  const handleClear = () => {
    setDescripcionDeRiesgo("");
    setMedidasDeControl("");
    setRequisitoLegal("");
    setTipoDeConsecuencia(tiposDeConsecuencia[0]);
    setTipoDeExposicion(tiposDeExposicion[0]);
    setTipoDeProbabilidad(tiposDeProbabilidad[0]);
    setTipoDePeligro(0);
    setPeligros([]);
    setPeligro(undefined);
    cargarTiposDePeligro();
  };

  const handleTipoDeExposicionChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const exposicionId = target.value;
    const exposicion = tiposDeExposicion.find(({ id }) => id === parseInt(exposicionId));
    if (!!exposicion) setTipoDeExposicion(exposicion);
  };

  const handleTipoDeConsecuenciaChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const consecuenciaId = target.value;
    const consecuencia = tiposDeConsecuencia.find(({ id }) => id === parseInt(consecuenciaId));
    if (!!consecuencia) setTipoDeConsecuencia(consecuencia);
  };

  const handleTipoDeProbabilidadChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const probabilidadId = target.value;
    const probabilidad = tiposDeProbabilidad.find(({ id }) => id === parseInt(probabilidadId));
    if (!!probabilidad) setTipoDeProbabilidad(probabilidad);
  };

  const handleTipoDePeligroChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const tipoDePeligro = target.value;
    const peligros = getPeligrosPorTipo(parseInt(tipoDePeligro));
    setTipoDePeligro(parseInt(tipoDePeligro));
    setPeligros(peligros);
  };

  const getPeligrosPorTipo = useCallback(
    (tipoDePeligro: number) => {
      const {
        peligrosFisicos,
        peligrosBiologicos,
        peligrosDeSeguridad,
        peligrosErgonomicos,
        peligrosPsicosociales,
        peligrosQuimicos,
      } = actividad;

      switch (tipoDePeligro) {
        case TiposDePeligro.PeligroFisico:
          return peligrosFisicos || [];
        case TiposDePeligro.PeligrosQuimico:
          return peligrosQuimicos || [];
        case TiposDePeligro.PeligrosBiologico:
          return peligrosBiologicos || [];
        case TiposDePeligro.PeligrosErgonomicos:
          return peligrosErgonomicos || [];
        case TiposDePeligro.PeligrosPsicosocial:
          return peligrosPsicosociales || [];
        case TiposDePeligro.PeligrosDeSeguridad:
          return peligrosDeSeguridad || [];
        default:
          return [];
      }
    },
    [actividad]
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tipoDeConsecuencia || !tipoDeExposicion || !tipoDeProbabilidad) return;
    onSubmit(
      {
        riesgo: descripcionDeRiesgo,
        actualesMedidasDeControl: medidasDeControl,
        tipoDePeligroId: tipoDePeligro,
        tipoDePeligro: todosLosPeligros.find(({ id }) => id === tipoDePeligro)?.tipoDePeligro || "",
        peligroId: peligro?.id || 0,
        nombreDePeligro: peligro?.nombre || "",
        exposicion: tipoDeExposicion,
        probabilidad: tipoDeProbabilidad,
        consecuencia: tipoDeConsecuencia,
        calificacionDeRiesgo:
          tipoDeExposicion.valor * tipoDeProbabilidad.valor * tipoDeConsecuencia.valor,
        requisitoLegal: requisitoLegal || undefined,
      },
      handleClear
    );
  };

  const peligroIdYaFueEvaluado = useCallback(
    (id: number) => !!peligrosEvaluados.find(({ peligroId }) => peligroId === id),
    [peligrosEvaluados]
  );

  const estanEvaluadosTodosLosPeligros = useCallback(
    (currentPeligros: Peligro[], tipo: TiposDePeligro) => {
      const evaluados = peligrosEvaluados.filter(({ tipoDePeligroId }) => tipoDePeligroId === tipo);
      return currentPeligros.length === evaluados.length;
    },
    [peligrosEvaluados]
  );

  const obtenerCalificacionMaxima = useCallback(
    () =>
      Math.max(...peligrosEvaluados.map(({ calificacionDeRiesgo }) => calificacionDeRiesgo || 0)),
    [peligrosEvaluados]
  );

  const cargarTiposDePeligro = useCallback(() => {
    const {
      peligrosFisicos,
      peligrosBiologicos,
      peligrosDeSeguridad,
      peligrosErgonomicos,
      peligrosPsicosociales,
      peligrosQuimicos,
    } = actividad;
    const tiposDePeligro: TiposDePeligro[] = [];

    if (
      peligrosFisicos &&
      peligrosFisicos.length > 0 &&
      !estanEvaluadosTodosLosPeligros(peligrosFisicos, TiposDePeligro.PeligroFisico)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligroFisico);
    }
    if (
      peligrosQuimicos &&
      peligrosQuimicos.length > 0 &&
      !estanEvaluadosTodosLosPeligros(peligrosQuimicos, TiposDePeligro.PeligrosQuimico)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosQuimico);
    }
    if (
      peligrosBiologicos &&
      peligrosBiologicos.length > 0 &&
      !estanEvaluadosTodosLosPeligros(peligrosBiologicos, TiposDePeligro.PeligrosBiologico)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosBiologico);
    }
    if (
      peligrosErgonomicos &&
      peligrosErgonomicos.length > 0 &&
      !estanEvaluadosTodosLosPeligros(peligrosErgonomicos, TiposDePeligro.PeligrosErgonomicos)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosErgonomicos);
    }
    if (
      peligrosPsicosociales &&
      peligrosPsicosociales.length > 0 &&
      !estanEvaluadosTodosLosPeligros(peligrosPsicosociales, TiposDePeligro.PeligrosPsicosocial)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosPsicosocial);
    }
    if (
      peligrosDeSeguridad &&
      peligrosDeSeguridad.length > 0 &&
      !estanEvaluadosTodosLosPeligros(peligrosDeSeguridad, TiposDePeligro.PeligrosDeSeguridad)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosDeSeguridad);
    }

    setTiposDePeligro(tiposDePeligro);
    if (tiposDePeligro.length === 0) {
      calificarDeRiesgo({ calificacionMaxima: obtenerCalificacionMaxima() });
    } else {
      setTipoDePeligro(tiposDePeligro[0]);
      const currentPeligros = getPeligrosPorTipo(tiposDePeligro[0]);
      const siguientePeligro = currentPeligros.find(({ id }) => !peligroIdYaFueEvaluado(id));
      setPeligros(currentPeligros);
      setPeligro(siguientePeligro);
    }
  }, [
    actividad,
    calificarDeRiesgo,
    estanEvaluadosTodosLosPeligros,
    getPeligrosPorTipo,
    obtenerCalificacionMaxima,
    peligroIdYaFueEvaluado,
  ]);

  const handlePeligroChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    const peligro = peligros.find(({ id }) => id === parseInt(peligroId));
    if (peligro) setPeligro(peligro);
  };

  useEffect(cargarTiposDePeligro, [cargarTiposDePeligro]);

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="tipoDePeligro"
                placeholder="Tipo de Peligro"
                value={tipoDePeligro === 0 ? "" : tipoDePeligro}
                onChange={handleTipoDePeligroChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDePeligro.map((item) => (
                  <option value={item}>
                    {todosLosPeligros.find(({ id }) => id === item)?.tipoDePeligro}
                  </option>
                ))}
              </Input>
              <Label for="tipoDePeligro">Tipo de Peligro</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="peligro"
                placeholder="Peligro"
                value={peligro?.id || ""}
                onChange={handlePeligroChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {peligros.map(({ nombre, id }) => (
                  <option
                    value={id}
                    className={classNames({ "d-none": peligroIdYaFueEvaluado(id) })}
                  >
                    {nombre}
                  </option>
                ))}
              </Input>
              <Label for="peligro">Peligro</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                name="descripcionDelRiesgo"
                placeholder="Descripción del riesgo"
                value={descripcionDeRiesgo}
                onChange={({ target }) => setDescripcionDeRiesgo(target.value)}
                required
                style={{ height: "138px" }}
              />
              <Label for="descripcionDelRiesgo">Descripción del riesgo</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                name="medidas-de-control"
                placeholder="Actuales Medidas de Control"
                value={medidasDeControl}
                onChange={({ target }) => setMedidasDeControl(target.value)}
                required
                style={{ height: "138px" }}
              />
              <Label for="medidas-de-control">Actuales Medidas de Control</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="requisito-legal"
                placeholder="Requisito Legal u Otro Requisito"
                value={requisitoLegal}
                onChange={({ target }) => setRequisitoLegal(target.value)}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {requisitoLegales.map(({ _id, nombre }) => (
                  <option key={_id} value={_id}>
                    {nombre}
                  </option>
                ))}
              </Input>
              <Label for="equisito-legal">Requisito Legal u Otro Requisito</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="exposicion"
                placeholder="Exposición"
                value={tipoDeExposicion?.id || ""}
                onChange={handleTipoDeExposicionChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDeExposicion.map(({ name, id, valor }) => (
                  <option key={id} value={id}>
                    {name} <b>({valor})</b>
                  </option>
                ))}
              </Input>
              <Label for="exposicion">Exposición</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="probabilidad"
                placeholder="Probabilidad"
                value={tipoDeProbabilidad?.id || ""}
                onChange={handleTipoDeProbabilidadChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDeProbabilidad.map(({ name, id, valor }) => (
                  <option key={id} value={id}>
                    {name} <b>({valor})</b>
                  </option>
                ))}
              </Input>
              <Label for="probabilidad">Probabilidad</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="consecuencia"
                placeholder="Consecuencia"
                value={tipoDeConsecuencia?.id || ""}
                onChange={handleTipoDeConsecuenciaChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDeConsecuencia.map(({ name, id, valor }) => {
                  return (
                    <option key={id} value={id}>
                      {name} <b>({valor})</b>
                    </option>
                  );
                })}
              </Input>
              <Label for="consecuencia">Consecuencia</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormularioDeNuevoRiesgo;
