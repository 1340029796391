import { Empresa } from "./Empresa";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export interface ContratoBody {
  _id?: string;
  idDeLaEmpresaContratante: string;
  idDeLaEmpresaContratista: string;
  idDelContacto: string;
  numeroDeDocumento: string;
  tituloDelContrato: string;
}

export enum TiposDeContrato {
  Cliente = "cliente",
  Contratista = "contratista",
}

export enum EstadosDeContrato {
  Solicitado = "solicitado",
  EnRevision = "enRevision",
  Autorizado = "autorizado",
  Cerrado = "cerrado",
}

export interface Contrato {
  _id: string;
  tipo: TiposDeContrato;
  numeroDeDocumento: string;
  empresaContratista: Empresa;
  contactoContratista: Vinculacion;
  titulo: string;
  contactoContratante: Vinculacion;
  empresaContratante: Empresa;
  estado: EstadosDeContrato;
  fechaDeSolicitud: Date;
  fechaDeAceptacion?: Date;
  fechaDeAprobacion?: Date;
  fechaDeFinalizacion?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface ContratoResponse {
  _id: string;
  tipo: TiposDeContrato;
  numeroDeDocumento: string;
  empresaContratista: Empresa;
  contactoContratista: VinculacionResponse;
  titulo: string;
  contactoContratante: VinculacionResponse;
  empresaContratante: Empresa;
  estado: EstadosDeContrato;
  fechaDeSolicitud: string;
  fechaDeAceptacion?: string;
  fechaDeAprobacion?: string;
  fechaDeFinalizacion?: string;
  createdAt: string;
  updatedAt: string;
}

export class Contrato implements Contrato {
  constructor({
    _id,
    tipo,
    numeroDeDocumento,
    empresaContratista,
    contactoContratista,
    titulo,
    contactoContratante,
    empresaContratante,
    estado,
    fechaDeSolicitud,
    fechaDeAceptacion,
    fechaDeAprobacion,
    fechaDeFinalizacion,
    createdAt,
    updatedAt,
  }: ContratoResponse) {
    this._id = _id;
    this.tipo = tipo;
    this.numeroDeDocumento = numeroDeDocumento;
    this.empresaContratista = empresaContratista;
    this.contactoContratista = new Vinculacion(contactoContratista);
    this.titulo = titulo;
    this.contactoContratante = new Vinculacion(contactoContratante);
    this.empresaContratante = empresaContratante;
    this.estado = estado;
    this.fechaDeSolicitud = new Date(fechaDeSolicitud);
    this.fechaDeAceptacion = fechaDeAceptacion ? new Date(fechaDeAceptacion) : undefined;
    this.fechaDeAprobacion = fechaDeAprobacion ? new Date(fechaDeAprobacion) : undefined;
    this.fechaDeFinalizacion = fechaDeFinalizacion ? new Date(fechaDeFinalizacion) : undefined;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }

  toString() {
    return this._id;
  }
}

export function textoDeTipoDeContrato(tipo: TiposDeContrato) {
  switch (tipo) {
    case TiposDeContrato.Cliente:
      return "Cliente";
    case TiposDeContrato.Contratista:
      return "Contratista";
  }
}

export function textoDeEstadoDeContrato(estado: EstadosDeContrato) {
  switch (estado) {
    case EstadosDeContrato.Solicitado:
      return "Solicitado";
    case EstadosDeContrato.EnRevision:
      return "En revisión";
    case EstadosDeContrato.Autorizado:
      return "Autorizado";
    case EstadosDeContrato.Cerrado:
      return "Cerrado";
  }
}
