import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { Context } from "../../../Context";
import { getActividadById, listarRiesgosEvaluados } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import TablaDeRiesgoDeUnaActividad from "./TablaDeRiesgosDeUnaActividad";
import { EvaluacionDeRiesgo } from "../../../../models/EvaluacionDeRiesgo";
import { Actividad } from "../../../../models/Actividad";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";

const ActividadesEvaluadas: FC = () => {
  const { id } = useParams();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [riesgosEvaluados, setRiesgosEvaluados] = useState<EvaluacionDeRiesgo[]>([]);
  const [actividad, setActividad] = useState<Actividad>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    Promise.all([getActividadById(authToken, id), listarRiesgosEvaluados(authToken, id)])
      .then(([actividadResponse, riesgosResponse]) => {
        setActividad(new Actividad(actividadResponse.data));
        setRiesgosEvaluados(riesgosResponse.data.map((riesgo) => new EvaluacionDeRiesgo(riesgo)));
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.resultadosDeRiesgos.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.miper]} />
      <Row>
        <Col>
          Departamento: <b>{actividad?.departamento}</b>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          Actividad: <b>{actividad?.actividad}</b>
        </Col>
      </Row>
      <Row className="mb-3 justify-content-center">
        <Col>
          <TablaDeRiesgoDeUnaActividad riesgosEvaluados={riesgosEvaluados} />
        </Col>
      </Row>
    </>
  );
};

export default ActividadesEvaluadas;
