import { useContext, FC, useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FaHistory, FaEdit, FaArchive, FaFile, FaPlus, FaFileUpload } from "react-icons/fa";
import styled from "styled-components";
import { Context } from "../../../Context";
import {
  CustomTable,
  IconsContainer,
  TableCaption,
  TableContainer,
  TableMessage,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  Documento,
  DocumentoBase,
  FrecuenciaDeActualizacion,
  TiposDeDocumento,
  textoDeLaFrecuenciaDeActualizacion,
  textoDePeriodoDeActualizacion,
  textoDelTipoDeDocumento,
} from "../../../../models/Documento";
import {
  archivarDocumento,
  listDocumentos,
  listDocumentosSugeridos,
  updateDocumento,
} from "../../../../services/usuario-api-client";
import { ArchivoDeDocumento } from "../../../../models/ArchivoDeDocumento";
import AgregarActualizacionForm from "./AgregarActualizacionForm";
import HistoricoDeDocumentos from "./HistoricoDeDocumentos";
import DocumentoForm from "./DocumentoForm";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";
import { Cargo } from "../../../../models/Cargo";
import { DocumentoSugerido } from "../../../../models/DocumentoSugerido";

const CustomNavLink = styled(NavLink)`
  height: 66px;
  white-space: nowrap;
`;

const AGREGAR_DOCUMENTO_INTERNO_FORM_ID = "agregar-documento-interno-form";
const MODIFICAR_DOCUMENTO_FORM_ID = "modificar-documento-form";

const tabs = [
  {
    tipoDeDocumento: "todosLosDocumentos",
    texto: (
      <>
        Todos los
        <br />
        Documentos
      </>
    ),
    hash: "#documentos",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionGeneralDeLaEmpresa,
    texto: (
      <>
        Documentos de
        <br />
        la Empresa
      </>
    ),
    hash: "#empresa",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeUsuariosDirectos,
    texto: <>Personal</>,
    hash: "#personal",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa,
    texto: (
      <>
        Equipos y <br />
        Herramientas
      </>
    ),
    hash: "#equipos-herramientas",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa,
    texto: <>Vehículos</>,
    hash: "#vehiculos",
  },
  {
    tipoDeDocumento: TiposDeDocumento.DocumentacionDeSustanciasQuimicas,
    texto: (
      <>
        Sustancias
        <br />
        Químicas
      </>
    ),
    hash: "#sustancias-quimicas",
  },
];

const GestionDocumentalInterna: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [documentosInternos, setDocumentosInternos] = useState<Documento[]>([]);
  const [documentosInternosFiltrados, setDocumentosInternosFiltrados] = useState<Documento[]>([]);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState<Documento | null>(null);
  const [activeTab, setActiveTab] = useState<string>(tabs[0].tipoDeDocumento);
  const [actualizacionModalIsOpen, setActualizacionModalIsOpen] = useState<boolean>(false);
  const [historicoModalIsOpen, setHistoricoModalIsOpen] = useState<boolean>(false);
  const [modificarModalIsOpen, setModificarModalIsOpen] = useState<boolean>(false);
  const [confirmarArchivarModalIsOpen, setConfirmarArchivarModalIsOpen] = useState<boolean>(false);
  const [personasResponsablesModalIsOpen, setPersonasResponsablesModalIsOpen] =
    useState<boolean>(false);
  const [documentosSugeridos, setDocumentosSugeridos] = useState<DocumentoSugerido[]>([]);
  const [sugeridosModalIsOpen, setSugeridosModalIsOpen] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const [modificarDocumentoError, setModificarDocumentoError] = useState<string>("");

  const cargarDocumentos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listDocumentos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setDocumentosInternos(data.map((documento) => new Documento(documento)));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const cargarDocumentosSugeridos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listDocumentosSugeridos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => setDocumentosSugeridos(data))
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const toggleSugeridosModal = () => setSugeridosModalIsOpen((prev) => !prev);

  const handleImportarDocumentosSugeridosClick = () => {
    cargarDocumentosSugeridos();
    toggleSugeridosModal();
  };

  const listadoDePersonasResponsables = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setPersonasResponsablesModalIsOpen(true);
    setDocumentoSeleccionado(documento);
  };

  const actualizarDocumento = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setDocumentoSeleccionado(documento);
    setActualizacionModalIsOpen(true);
  };

  const handleSuccessActualizacion = () => {
    setActualizacionModalIsOpen(false);
    cargarDocumentos();
  };

  const historicoDelDocumento = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setHistoricoModalIsOpen(true);
    setDocumentoSeleccionado(documento);
  };

  const modificarDocumento = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setDocumentoSeleccionado(documento);
    setModificarModalIsOpen(true);
  };

  const handleModificarDocumentoSubmit = (body: DocumentoBase) => {
    if (!authToken || !documentoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    setModificarDocumentoError("");
    updateDocumento(authToken, documentoSeleccionado._id, body)
      .then(() => {
        setModificarModalIsOpen(false);
        cargarDocumentos();
      })
      .catch((err) => {
        console.log(err);
        setModificarDocumentoError("Error al modificar el documento.");
        setLoadingCounter((prev) => prev - 1);
      });
  };

  const handleArchivarClick = (idDelDocumento: string) => {
    const documento = documentosInternos.find(({ _id }) => _id === idDelDocumento);
    if (!documento) return;
    setDocumentoSeleccionado(documento);
    setConfirmarArchivarModalIsOpen(true);
  };

  const confirmarArchivar = () => {
    if (!authToken || !documentoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    archivarDocumento(authToken, documentoSeleccionado._id)
      .then(() => {
        setConfirmarArchivarModalIsOpen(false);
        cargarDocumentos();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const limpiarSeleccion = () => {
    setDocumentoSeleccionado(null);
    setModificarDocumentoError("");
  };

  const handleTabChange = (tipoDeDocumento: string) => {
    setActiveTab(tipoDeDocumento);
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumentalInterna.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(cargarDocumentos, [cargarDocumentos]);

  useEffect(() => {
    if (activeTab === "todosLosDocumentos") {
      setDocumentosInternosFiltrados(documentosInternos);
    } else {
      setDocumentosInternosFiltrados(
        documentosInternos.filter(({ tipoDeDocumento }) => tipoDeDocumento === activeTab)
      );
    }
  }, [activeTab, documentosInternos]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row className="mb-3">
        <Col xs md={12}>
          <Breadcrumbs items={[PATHS.usuarioHome, PATHS.gestionDocumental]} />
        </Col>
        <Col xs="auto" md={{ size: "auto", order: 5 }} className="ms-auto">
          <UncontrolledDropdown group>
            <LinkContainer to={PATHS.nuevoDocumentoInterno.to}>
              <Button color="primary" title="Nuevo documento interno">
                <IconsContainer>
                  <FaFile size={20} textAnchor="middle" alignmentBaseline="middle" />
                  <FaPlus
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    style={{
                      fontSize: ".5em",
                      position: "absolute",
                      left: "2.35em",
                      bottom: "1.5em",
                    }}
                  />
                </IconsContainer>
              </Button>
            </LinkContainer>
            <DropdownToggle caret color="primary" />
            <DropdownMenu>
              <DropdownItem header>
                Puedes agregar un documento
                <br />o importar uno sugerido
              </DropdownItem>
              <LinkContainer to={PATHS.nuevoDocumentoInterno.to}>
                <DropdownItem>Agregar documento interno</DropdownItem>
              </LinkContainer>
              <DropdownItem onClick={handleImportarDocumentosSugeridosClick}>
                Importar documento sugerido
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col>
          <TableContainer>
            <Nav tabs className="flex-nowrap">
              {tabs.map(({ tipoDeDocumento, texto, hash }) => (
                <NavItem key={tipoDeDocumento}>
                  <CustomNavLink
                    href={hash}
                    onClick={() => handleTabChange(tipoDeDocumento)}
                    active={activeTab === tipoDeDocumento}
                    className="d-flex align-items-center text-center"
                  >
                    {texto}
                  </CustomNavLink>
                </NavItem>
              ))}
            </Nav>
          </TableContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableCaption>Documentos Internos</TableCaption>
        </Col>
      </Row>
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre del Documento</th>
              <th>Plan de Acción</th>
              <th>Requisito Legal</th>
              <th>Tipo de Documento</th>
              <th>Responsable(s)</th>
              <th>Frecuencia de Actualización</th>
              <th>Fecha de la Última Actualización</th>
              <th>Fecha de Vencimiento</th>
              <th>Archivos</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {documentosInternosFiltrados.map(
              (
                {
                  _id,
                  nombreDelDocumento,
                  planDeAccion,
                  requisitoLegal,
                  tipoDeDocumento,
                  personasResponsables,
                  frecuenciaDeActualizacion,
                  valorDeLaFrecuenciaDeActualizacion,
                  unidadDeLaFrecuenciaDeActualizacion,
                  archivos,
                },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{nombreDelDocumento}</td>
                  <td>
                    {planDeAccion ? (
                      <a
                        href={`/usuario/planes/${planDeAccion._id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {planDeAccion.descripcion}
                      </a>
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                  <td>
                    {requisitoLegal ? (
                      <a href={requisitoLegal.filepath} target="_blank" rel="noreferrer">
                        {requisitoLegal.nombre}
                      </a>
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                  <td>{textoDelTipoDeDocumento(tipoDeDocumento)}</td>
                  <td>
                    {personasResponsables.length === 0 ? (
                      <span>Ninguno asignado</span>
                    ) : (
                      <>
                        <ul className="mb-0 ps-3">
                          {personasResponsables
                            .filter((_, index) => index < 3)
                            .map((persona) => (
                              <li key={persona.toString()}>
                                {typeof persona === "string" ? persona : persona.nombreCompleto}
                              </li>
                            ))}
                          {personasResponsables.length === 4 && <li>1 persona más</li>}
                          {personasResponsables.length > 4 && (
                            <li>{personasResponsables.length - 3} personas más</li>
                          )}
                        </ul>
                        {personasResponsables.length > 3 && (
                          <Button
                            color="link"
                            size="sm"
                            className="p-0 small"
                            onClick={() => listadoDePersonasResponsables(_id)}
                          >
                            Ver todos
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                    !!valorDeLaFrecuenciaDeActualizacion &&
                    !!unidadDeLaFrecuenciaDeActualizacion
                      ? textoDePeriodoDeActualizacion(
                          valorDeLaFrecuenciaDeActualizacion,
                          unidadDeLaFrecuenciaDeActualizacion
                        )
                      : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                  </td>
                  <td>
                    {archivos && archivos.length > 0
                      ? shortDateToString((archivos as ArchivoDeDocumento[])[0].fechaDeCarga)
                      : "N/A"}
                  </td>
                  <td>
                    {archivos && archivos.length > 0
                      ? shortDateToString((archivos as ArchivoDeDocumento[])[0].fechaDeVencimiento)
                      : "N/A"}
                  </td>
                  <td>
                    {!!archivos && archivos.length > 0 ? (
                      <>
                        <ul className="mb-0 ps-3">
                          {(archivos as ArchivoDeDocumento[])
                            .filter((_, index) => index < 3)
                            .map(({ _id, nombre, filepath }) => (
                              <li key={_id}>
                                <a
                                  href={procesarLinkDeDocumento(filepath)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {nombre}
                                </a>
                              </li>
                            ))}
                          {archivos.length === 4 && <li>1 archivo más</li>}
                          {archivos.length > 4 && <li>{archivos.length - 3} archivos más</li>}
                        </ul>
                        {archivos.length > 3 && (
                          <Button
                            color="link"
                            size="sm"
                            className="p-0 small"
                            onClick={() => historicoDelDocumento(_id)}
                          >
                            Ver todos
                          </Button>
                        )}
                      </>
                    ) : (
                      <p className="text-center mb-0">No hay archivos registrados</p>
                    )}
                  </td>
                  <td>
                    <Row className="gx-0 flex-nowrap">
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Actualizar"
                          size="sm"
                          onClick={() => actualizarDocumento(_id)}
                        >
                          <FaFileUpload size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Histórico"
                          size="sm"
                          onClick={() => historicoDelDocumento(_id)}
                        >
                          <FaHistory size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Modificar"
                          size="sm"
                          onClick={() => modificarDocumento(_id)}
                        >
                          <FaEdit size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <Button
                          color="danger"
                          outline
                          title="Archivar"
                          size="sm"
                          className="border-0"
                          onClick={() => handleArchivarClick(_id)}
                        >
                          <FaArchive size={15} />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {documentosInternosFiltrados.length === 0 && (
              <tr>
                <td colSpan={11} className="text-center">
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">
                        {activeTab === "todosLosDocumentos"
                          ? "No hay documentos internos registrados."
                          : "No hay documentos internos de este tipo registrados."}
                      </Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="primary"
                          onClick={handleImportarDocumentosSugeridosClick}
                        >
                          Importar documento sugerido
                        </Button>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Modal isOpen={sugeridosModalIsOpen} toggle={toggleSugeridosModal}>
        <ModalHeader toggle={toggleSugeridosModal}>Importar documento sugerido</ModalHeader>
        <ModalBody>
          <ListGroup>
            {documentosSugeridos.map((documentoSugerido) => (
              <LinkContainer
                key={documentoSugerido._id}
                to={PATHS.nuevoDocumentoInterno.to}
                state={{ documentoSugerido }}
              >
                <ListGroupItem action tag="button">
                  <Row>
                    <Col>{documentoSugerido.nombreDelDocumento}</Col>
                  </Row>
                  <Row>
                    <Col>
                      <small className="text-muted">
                        {textoDelTipoDeDocumento(documentoSugerido.tipoDeDocumento)}
                      </small>
                    </Col>
                  </Row>
                </ListGroupItem>
              </LinkContainer>
            ))}
          </ListGroup>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={actualizacionModalIsOpen}
        toggle={() => setActualizacionModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setActualizacionModalIsOpen(false)}>
          Actualizar {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <AgregarActualizacionForm
            formId={AGREGAR_DOCUMENTO_INTERNO_FORM_ID}
            documento={documentoSeleccionado as Documento}
            onSuccess={handleSuccessActualizacion}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={AGREGAR_DOCUMENTO_INTERNO_FORM_ID}>
            Agregar
          </Button>
          <Button color="secondary" onClick={() => setActualizacionModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={historicoModalIsOpen}
        toggle={() => setHistoricoModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setHistoricoModalIsOpen(false)}>
          {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <HistoricoDeDocumentos documento={documentoSeleccionado as Documento} />
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modificarModalIsOpen}
        toggle={() => setModificarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setModificarModalIsOpen(false)}>
          Modificar {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <DocumentoForm
            formId={MODIFICAR_DOCUMENTO_FORM_ID}
            documento={documentoSeleccionado as Documento}
            onSubmit={handleModificarDocumentoSubmit}
          />
          <Collapse isOpen={!!modificarDocumentoError}>
            <p className="text-danger">{modificarDocumentoError}</p>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={MODIFICAR_DOCUMENTO_FORM_ID}>
            Modificar
          </Button>
          <Button color="secondary" onClick={() => setModificarModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarArchivarModalIsOpen}
        toggle={() => setConfirmarArchivarModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setConfirmarArchivarModalIsOpen(false)}>
          Confirmar eliminación
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea archivar el documento{" "}
            <b>{documentoSeleccionado?.nombreDelDocumento}</b>?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmarArchivar}>
            Archivar
          </Button>
          <Button color="secondary" onClick={() => setConfirmarArchivarModalIsOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={personasResponsablesModalIsOpen}
        toggle={() => setPersonasResponsablesModalIsOpen(false)}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={() => setPersonasResponsablesModalIsOpen(false)}>
          {documentoSeleccionado?.nombreDelDocumento}
        </ModalHeader>
        <ModalBody>
          <TableContainer>
            <TableCaption>Personas responsables</TableCaption>
            <CustomTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nombre</th>
                  <th>Cargo</th>
                </tr>
              </thead>
              <tbody>
                {documentoSeleccionado?.personasResponsables.map((persona, index) =>
                  typeof persona === "string" ? (
                    <tr key={persona}>
                      <td>{index + 1}</td>
                      <td>{persona}</td>
                      <td>N/A</td>
                    </tr>
                  ) : (
                    <tr key={persona._id}>
                      <td>{index + 1}</td>
                      <td>{persona.nombreCompleto}</td>
                      <td>{persona.cargo ? (persona.cargo as Cargo).cargo : "N/A"}</td>
                    </tr>
                  )
                )}
                {documentoSeleccionado?.personasResponsables.length === 0 && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No hay personas responsables asignadas.
                    </td>
                  </tr>
                )}
              </tbody>
            </CustomTable>
          </TableContainer>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GestionDocumentalInterna;
