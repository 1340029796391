import { FC } from "react";
import classNames from "classnames";
import { CustomTable, TableContainer, TableMessage } from "../../../../utilities/utils";
import { EvaluacionDeRiesgo, textoDeCalificacion } from "../../../../models/EvaluacionDeRiesgo";

interface TablaDeActividadProps {
  riesgosEvaluados: EvaluacionDeRiesgo[];
}

const TablaDeRiesgoDeUnaActividad: FC<TablaDeActividadProps> = ({ riesgosEvaluados }) => {
  return (
    <>
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo de Peligro</th>
              <th>Peligro</th>
              <th>Riesgos</th>
              <th>Actuales medidas de control</th>
              <th>C</th>
              <th>E</th>
              <th>P</th>
              <th>Calificación del riesgo</th>
            </tr>
          </thead>
          <tbody>
            {riesgosEvaluados.map(
              (
                {
                  _id,
                  tipoDePeligro,
                  nombreDePeligro,
                  riesgo,
                  actualesMedidasDeControl,
                  consecuencia,
                  exposicion,
                  probabilidad,
                  calificacionDeRiesgo,
                },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{tipoDePeligro}</td>
                  <td className="text-center">{nombreDePeligro}</td>
                  <td className="text-center">{riesgo}</td>
                  <td className="text-center">{actualesMedidasDeControl}</td>
                  <td className="text-center">{consecuencia.valor}</td>
                  <td className="text-center">{exposicion.valor}</td>
                  <td className="text-center">{probabilidad.valor}</td>
                  <td
                    className={classNames("text-center", {
                      "calificacion-baja": calificacionDeRiesgo > 0 && calificacionDeRiesgo < 200,
                      "calificacion-media":
                        calificacionDeRiesgo >= 200 && calificacionDeRiesgo < 400,
                      "calificacion-alta": calificacionDeRiesgo >= 400,
                    })}
                  >
                    {textoDeCalificacion(calificacionDeRiesgo)}
                    <br />({calificacionDeRiesgo})
                  </td>
                </tr>
              )
            )}
            {riesgosEvaluados.length === 0 && (
              <tr>
                <td colSpan={9} className="text-center">
                  <TableMessage>Aún no se han evaluado riesgos para esta actividad</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
    </>
  );
};

export default TablaDeRiesgoDeUnaActividad;
