import axios from "axios";
import { CursoPayload } from "../models/Curso";

export const fetchHost = (authToken: string) => {
  return axios.get<{ host: string }>("/api/lms/host", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export enum SummaryFormats {
  Moodle = 0,
  HTML = 1,
  Plain = 2,
  Markdown = 4,
}

enum BooleanNumbers {
  False = 0,
  True = 1,
}

interface CourseFormatOption {
  name: string;
  value: number;
}

export interface CursoLMS {
  id: number;
  shortname: string;
  categoryid: number;
  categorysortorder: number;
  fullname: string;
  displayname: string;
  idnumber: string;
  summary: string;
  summaryformat: SummaryFormats;
  format: string;
  showgrades: BooleanNumbers;
  newsitems: number;
  startdate: number;
  enddate: number;
  numsections: number;
  maxbytes: number;
  showreports: number;
  visible: BooleanNumbers;
  groupmode: number;
  groupmodeforce: BooleanNumbers;
  defaultgroupingid: number;
  timecreated: number;
  timemodified: number;
  enablecompletion: BooleanNumbers;
  completionnotify: BooleanNumbers;
  lang: string;
  forcetheme: string;
  courseformatoptions: CourseFormatOption[];
  showactivitydates: BooleanNumbers;
  showcompletionconditions: BooleanNumbers | null;
}

export const fetchCursos = (authToken: string) => {
  return axios.get<CursoLMS[]>("/api/lms/cursos", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const fetchCurso = async (authToken: string, idDelCurso: number) => {
  const response = await axios.get<CursoLMS[]>(`/api/lms/cursos/${idDelCurso}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
  return { ...response, data: response.data[0] };
};

export const createCurso = (authToken: string, idDeLaEmpresa: string, curso: CursoPayload) => {
  return axios.post(`/api/cursos/empresa/${idDeLaEmpresa}`, curso, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const updateCurso = (authToken: string, idDelCurso: string, curso: CursoPayload) => {
  return axios.put(`/api/cursos/${idDelCurso}`, curso, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const deleteCurso = (authToken: string, idDelCurso: string) => {
  return axios.delete(`/api/cursos/${idDelCurso}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export const fetchUsuarios = (authToken: string) => {
  return axios.get("/api/lms/usuarios", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

interface UsuarioLMSPayload {
  contrasena: string;
  nombre: string;
  apellidos: string;
  correo: string;
  identificacion: string;
  nacionalidad: string;
  telefono: string;
}

export const createUsuario = (usuario: UsuarioLMSPayload) => {
  return axios.post("/api/lms/usuarios/nuevo", usuario);
};

interface CategoryLMSPayload {
  nombre: string;
  parent: number;
  id?: string;
  descripcion?: string;
  formatoDescripcion?: SummaryFormats;
  tema?: string;
}

export const createCategory = (authToken: string, category: CategoryLMSPayload) => {
  return axios.post<CategoryLMS[]>("/api/lms/categorias/nueva", category, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};

export interface CategoryLMS {
  id: number;
  name: string;
  idnumber: string | null;
  description: string;
  descriptionformat: SummaryFormats;
  parent: number;
  sortorder: number;
  coursecount: number;
  visible: BooleanNumbers;
  visibleold: BooleanNumbers;
  timemodified: number;
  depth: number;
  path: string;
  theme: string;
}

export const fetchCategorias = (authToken: string) => {
  return axios.get<CategoryLMS[]>("/api/lms/categorias", {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};
