import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Navbar, Row } from "reactstrap";
import { Context } from "../../Context";
import CursoForm from "./CursoForm";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";
import { Curso } from "../../../models/Curso";
import { getCurso } from "../../../services/usuario-api-client";

const EDITAR_CURSO_FORM_ID = "editarCursoForm";

const EditarCurso: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [curso, setCurso] = useState<Curso>();

  const handleNuevoCursoSuccess = () => {
    navigate(PATHS.cursos.to);
  };

  useEffect(() => {
    if (!authToken || !id) return;
    getCurso(authToken, id).then(({ data }) => setCurso(new Curso(data)));
  }, [authToken, id]);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.editarCurso.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.formacion, PATHS.cursos]} />
      <Row className="mb-5">
        <Col>
          <CursoForm
            formId={EDITAR_CURSO_FORM_ID}
            curso={curso}
            onSuccess={handleNuevoCursoSuccess}
          />
        </Col>
      </Row>
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={EDITAR_CURSO_FORM_ID} type="submit" color="primary" className="ms-auto">
          Guardar
        </Button>
      </Navbar>
    </>
  );
};

export default EditarCurso;
