import { FC, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import styled from "styled-components";
import {
  SeparatorCol,
  TableCaption,
  longDateToString,
  procesarLinkDeDocumento,
  range,
} from "../../utilities/utils";
import { DocumentoLegal } from "../../models/DocumentoLegal";
import { listDocumentosLegales } from "../../services/widgets-api-client";

const DocumentoContainer = styled.div`
  padding: 30px 0;
  &:not(:last-child) {
    border-bottom: 1px solid #d9d9d9;
  }
`;

const AutoridadLabel = styled.div`
  font-weight: bold;
  color: #000;
  background: #f8cb09;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100%;
`;

const FechaLabel = styled.div`
  font-weight: bold;
  text-align: center;
  padding-inline: 15px;
  border-radius: 5px;
`;

const DocumentosLegales: FC = () => {
  const [documentosLegales, setDocumentosLegales] = useState<DocumentoLegal[]>([]);
  const [documentosLegalesFiltrados, setDocumentosLegalesFiltrados] = useState<DocumentoLegal[]>(
    []
  );
  const [busqueda, setBusqueda] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (busqueda.length > 2) {
      listDocumentosLegales(busqueda).then(({ data }) =>
        setDocumentosLegalesFiltrados(data.map((documento) => new DocumentoLegal(documento)))
      );
    } else {
      setDocumentosLegalesFiltrados(documentosLegales);
    }
  }, [busqueda, documentosLegales]);

  useEffect(() => {
    setIsLoading(true);
    listDocumentosLegales()
      .then(({ data }) =>
        setDocumentosLegales(data.map((documento) => new DocumentoLegal(documento)))
      )
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <Row>
        <Col className="px-0">
          <TableCaption>Actualizaciones Legales</TableCaption>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <FormGroup floating>
            <Input
              type="text"
              className="form-control"
              id="filtrar"
              placeholder="Filtrar"
              value={busqueda}
              onChange={({ target }) => setBusqueda(target.value)}
            />
            <Label for="filtrar">Filtrar</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {documentosLegalesFiltrados.length > 0 ? (
            documentosLegalesFiltrados.map(
              ({ _id, autoridad, fechaDeEmision, nombre, filepath }) => (
                <DocumentoContainer key={_id}>
                  <Row>
                    <Col xs="auto">
                      <Row className="align-items-stretch">
                        <SeparatorCol width={189}>
                          <AutoridadLabel>
                            <Row className="align-items-center h-100">
                              <Col>Autoridad</Col>
                            </Row>
                          </AutoridadLabel>
                        </SeparatorCol>
                        <Col className="text-primary">{autoridad.nombre}</Col>
                      </Row>
                      <Row className="mt-n1">
                        <SeparatorCol width={189}>
                          <FechaLabel className="text-bg-primary">Fecha de emisión</FechaLabel>
                        </SeparatorCol>
                        <Col className="text-primary">{longDateToString(fechaDeEmision)}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col className="text-center">
                      <a
                        href={procesarLinkDeDocumento(filepath)}
                        target="_blank"
                        rel="noreferrer"
                        className="link-primary fs-3"
                      >
                        {nombre}
                      </a>
                    </Col>
                  </Row>
                </DocumentoContainer>
              )
            )
          ) : isLoading ? (
            range(0, 1).map((i) => (
              <DocumentoContainer key={i}>
                <Row>
                  <Col xs="auto">
                    <Row>
                      <Col>
                        <AutoridadLabel>Autoridad</AutoridadLabel>
                      </Col>
                    </Row>
                    <Row className="mt-n1">
                      <Col>
                        <FechaLabel className="text-bg-primary">Fecha de emisión</FechaLabel>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={4}>
                    <Row>
                      <Col className="placeholder-glow">
                        <span className="placeholder col-6" />
                      </Col>
                    </Row>
                    <Row className="mt-n1">
                      <Col className="placeholder-glow">
                        <span className="placeholder col-4" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="text-center">
                    <span className="placeholder-glow">
                      <span className="placeholder col-8 fs-3" />
                    </span>
                  </Col>
                </Row>
              </DocumentoContainer>
            ))
          ) : (
            <Row>
              <Col className="text-center">
                <p>No hay documentos legales disponibles</p>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DocumentosLegales;
