import { FC, FormEvent, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  ClasificacionDeParteInteresada,
  NecesidadExpectativa,
  ParteInteresada,
  ParteInteresadaPayload,
  TiposDeParteInteresada,
  textoTipoDeParteInteresada,
  textoTipoNecesidadExpectativa,
} from "../../../models/ParteInteresada";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Context } from "../../Context";
import { listarRequisitosLegales } from "../../../services/usuario-api-client";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";

const EDITAR_PARTE_INTERESADA_FORM_ID = "editarParteInteresada";

interface ParteInteresadaFormProps {
  formId: string;
  parteInteresada?: ParteInteresada;
  onSubmit: (parteInteresada: ParteInteresadaPayload) => void;
}

const ParteInteresadaForm: FC<ParteInteresadaFormProps> = ({
  formId,
  parteInteresada,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [nombre, setNombre] = useState<string>(parteInteresada?.nombre || "");
  const [tipo, setTipo] = useState<TiposDeParteInteresada | null>(parteInteresada?.tipo || null);
  const [requisitoLegal, setRequisitoLegal] = useState<string>(
    parteInteresada?.requisitoLegal?.toString() || ""
  );
  const [requisitosLegales, setRequisitosLegales] = useState<RequisitoLegal[]>([]);
  const [necesidadesYExpectativas, setNecesidadesYExpectativas] = useState<NecesidadExpectativa[]>(
    parteInteresada?.necesidadesYExpectativas || []
  );
  const [nuevaNecesidadExpectativaTipo, setNuevaNecesidadExpectativaTipo] =
    useState<ClasificacionDeParteInteresada | null>(null);
  const [nuevaNecesidadExpectativaDescripcion, setNuevaNecesidadExpectativaDescripcion] =
    useState<string>("");
  const [indiceDeNecesidadExpectativaSeleccionada, setIndiceDeNecesidadExpectativaSeleccionada] =
    useState<number>(-1);
  const [editarNecesidadExpectativaTipo, setEditarNecesidadExpectativaTipo] =
    useState<ClasificacionDeParteInteresada | null>(null);
  const [editarNecesidadExpectativaDescripcion, setEditarNecesidadExpectativaDescripcion] =
    useState<string>("");
  const [editarNecesidadExpectativaModalIsOpen, setEditarNecesidadExpectativaModalIsOpen] =
    useState<boolean>(false);
  const [
    confirmarEliminarNecesidadExpectativaModalIsOpen,
    setConfirmarEliminarNecesidadExpectativaModalIsOpen,
  ] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tipo) return;
    const body: ParteInteresadaPayload = {
      nombre,
      tipo,
      necesidadesYExpectativas,
      requisitoLegal: requisitoLegal || undefined,
    };
    onSubmit(body);
  };

  const toggleEditarNecesidadExpectativaModal = () =>
    setEditarNecesidadExpectativaModalIsOpen(!editarNecesidadExpectativaModalIsOpen);

  const handleEditarNecesidadExpectativaClick = (index: number) => {
    setIndiceDeNecesidadExpectativaSeleccionada(index);
    setEditarNecesidadExpectativaTipo(necesidadesYExpectativas[index].tipo);
    setEditarNecesidadExpectativaDescripcion(necesidadesYExpectativas[index].descripcion);
    toggleEditarNecesidadExpectativaModal();
  };

  const handleEditarNecesidadExpectativaSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (indiceDeNecesidadExpectativaSeleccionada < 0 || !editarNecesidadExpectativaTipo) return;
    setNecesidadesYExpectativas((prev) =>
      prev.map((necesidadExpectativa, index) =>
        index === indiceDeNecesidadExpectativaSeleccionada
          ? {
              tipo: editarNecesidadExpectativaTipo,
              descripcion: editarNecesidadExpectativaDescripcion,
            }
          : necesidadExpectativa
      )
    );
    toggleEditarNecesidadExpectativaModal();
  };

  const toggleConfirmarEliminarNecesidadExpectativaModal = () =>
    setConfirmarEliminarNecesidadExpectativaModalIsOpen(
      !confirmarEliminarNecesidadExpectativaModalIsOpen
    );

  const handleConfirmarEliminarNecesidadExpectativaClick = (index: number) => {
    setIndiceDeNecesidadExpectativaSeleccionada(index);
    toggleConfirmarEliminarNecesidadExpectativaModal();
  };

  const handleConfirmarEliminarNecesidadExpectativa = () => {
    if (indiceDeNecesidadExpectativaSeleccionada < 0) return;
    setNecesidadesYExpectativas((prev) =>
      prev.filter((_, index) => index !== indiceDeNecesidadExpectativaSeleccionada)
    );
    toggleConfirmarEliminarNecesidadExpectativaModal();
  };

  const limpiarSeleccionDeNecesidadExpectativa = () => {
    setIndiceDeNecesidadExpectativaSeleccionada(-1);
    setEditarNecesidadExpectativaTipo(null);
    setEditarNecesidadExpectativaDescripcion("");
  };

  const handleNecesidadExpectativaSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!nuevaNecesidadExpectativaTipo || !nuevaNecesidadExpectativaDescripcion) return;
    setNecesidadesYExpectativas((prev) => [
      ...prev,
      {
        tipo: nuevaNecesidadExpectativaTipo,
        descripcion: nuevaNecesidadExpectativaDescripcion,
      },
    ]);
    setNuevaNecesidadExpectativaTipo(null);
    setNuevaNecesidadExpectativaDescripcion("");
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) =>
        setRequisitosLegales(data.map((requisitoLegal) => new RequisitoLegal(requisitoLegal)))
      )
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="nombre"
            name="nombre"
            placeholder="Nombre de la parte interesada"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <Label for="nombre">Nombre de la parte interesada</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="tipo"
            name="tipo"
            placeholder="Tipo"
            value={tipo || ""}
            onChange={(e) => setTipo(e.target.value as TiposDeParteInteresada)}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {Object.values(TiposDeParteInteresada).map((tipo) => (
              <option key={tipo} value={tipo}>
                {textoTipoDeParteInteresada(tipo)}
              </option>
            ))}
          </Input>
          <Label for="tipo">Tipo</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="requisitoLegal"
            name="requisitoLegal"
            placeholder="Requisito legal"
            value={requisitoLegal}
            onChange={(e) => setRequisitoLegal(e.target.value)}
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {requisitosLegales.map(({ _id, nombre }) => (
              <option key={_id} value={_id}>
                {nombre}
              </option>
            ))}
          </Input>
          <Label for="requisitoLegal">Requisito legal</Label>
        </FormGroup>
      </Form>
      <Card body className="p-0 mb-3">
        <ListGroup flush>
          <ListGroupItem>
            <Label className="small text-muted">Necesidades y expectativas</Label>
          </ListGroupItem>
          {necesidadesYExpectativas.map(({ tipo, descripcion }, index) => (
            <ListGroupItem key={index}>
              <Row className="align-items-center gx-1">
                <Col xs="auto">
                  <b>{textoTipoNecesidadExpectativa(tipo)}</b>
                </Col>
                <Col>{descripcion}</Col>
                <Col xs="auto">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => handleEditarNecesidadExpectativaClick(index)}
                  >
                    <FaEdit />
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    type="button"
                    color="danger"
                    onClick={() => handleConfirmarEliminarNecesidadExpectativaClick(index)}
                  >
                    <FaTrash />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          ))}
          {necesidadesYExpectativas.length === 0 && (
            <ListGroupItem>
              <Row>
                <Col className="text-center">No hay ninguna registrada</Col>
              </Row>
            </ListGroupItem>
          )}
          <ListGroupItem>
            <Form onSubmit={handleNecesidadExpectativaSubmit}>
              <Row className="align-items-center">
                <Col>
                  <div className="form-floating">
                    <Input
                      type="select"
                      id="nuevaNecesidadExpectativaTipo"
                      name="nuevaNecesidadExpectativaTipo"
                      placeholder="Tipo"
                      value={nuevaNecesidadExpectativaTipo || ""}
                      onChange={(e) =>
                        setNuevaNecesidadExpectativaTipo(
                          e.target.value as ClasificacionDeParteInteresada
                        )
                      }
                      required
                    >
                      <option value="" disabled>
                        Seleccionar
                      </option>
                      {Object.values(ClasificacionDeParteInteresada).map((tipo) => (
                        <option key={tipo} value={tipo}>
                          {textoTipoNecesidadExpectativa(tipo)}
                        </option>
                      ))}
                    </Input>
                    <Label for="nuevaNecesidadExpectativaTipo">Tipo</Label>
                  </div>
                </Col>
                <div className="w-100 d-block d-md-none mb-2" />
                <Col>
                  <div className="form-floating">
                    <Input
                      type="text"
                      id="nuevaNecesidadExpectativaDescripcion"
                      name="nuevaNecesidadExpectativaDescripcion"
                      placeholder="Descripción"
                      value={nuevaNecesidadExpectativaDescripcion}
                      onChange={(e) => setNuevaNecesidadExpectativaDescripcion(e.target.value)}
                      required
                    />
                    <Label for="nuevaNecesidadExpectativaDescripcion">Descripción</Label>
                  </div>
                </Col>
                <Col xs="auto">
                  <Button type="submit" color="primary">
                    <FaPlus />
                  </Button>
                </Col>
              </Row>
            </Form>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <Modal
        isOpen={editarNecesidadExpectativaModalIsOpen}
        toggle={toggleEditarNecesidadExpectativaModal}
        onExited={limpiarSeleccionDeNecesidadExpectativa}
      >
        <ModalHeader toggle={toggleEditarNecesidadExpectativaModal}>
          Editar {necesidadesYExpectativas[indiceDeNecesidadExpectativaSeleccionada]?.tipo}
        </ModalHeader>
        <ModalBody>
          <Form
            id={EDITAR_PARTE_INTERESADA_FORM_ID}
            onSubmit={handleEditarNecesidadExpectativaSubmit}
          >
            <FormGroup floating>
              <Input
                type="select"
                id="editarNecesidadExpectativaTipo"
                name="editarNecesidadExpectativaTipo"
                placeholder="Tipo"
                value={editarNecesidadExpectativaTipo || ""}
                onChange={(e) =>
                  setEditarNecesidadExpectativaTipo(
                    e.target.value as ClasificacionDeParteInteresada
                  )
                }
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {Object.values(ClasificacionDeParteInteresada).map((tipo) => (
                  <option key={tipo} value={tipo}>
                    {textoTipoNecesidadExpectativa(tipo)}
                  </option>
                ))}
              </Input>
              <Label for="editarNecesidadExpectativaTipo">Tipo</Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                type="text"
                id="editarNecesidadExpectativaDescripcion"
                name="editarNecesidadExpectativaDescripcion"
                placeholder="Descripción"
                value={editarNecesidadExpectativaDescripcion}
                onChange={(e) => setEditarNecesidadExpectativaDescripcion(e.target.value)}
                required
              />
              <Label for="editarNecesidadExpectativaDescripcion">Descripción</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={EDITAR_PARTE_INTERESADA_FORM_ID}>
            Guardar
          </Button>
          <Button type="button" color="secondary" onClick={toggleEditarNecesidadExpectativaModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarEliminarNecesidadExpectativaModalIsOpen}
        toggle={toggleConfirmarEliminarNecesidadExpectativaModal}
        onExited={limpiarSeleccionDeNecesidadExpectativa}
      >
        <ModalHeader toggle={toggleConfirmarEliminarNecesidadExpectativaModal}>
          Eliminar {necesidadesYExpectativas[indiceDeNecesidadExpectativaSeleccionada]?.tipo}
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro de que deseas eliminar esta{" "}
            {necesidadesYExpectativas[indiceDeNecesidadExpectativaSeleccionada]?.tipo}?
            <br />
            <b>{necesidadesYExpectativas[indiceDeNecesidadExpectativaSeleccionada]?.descripcion}</b>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="danger"
            onClick={handleConfirmarEliminarNecesidadExpectativa}
          >
            Eliminar
          </Button>
          <Button
            type="button"
            color="secondary"
            onClick={toggleConfirmarEliminarNecesidadExpectativaModal}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ParteInteresadaForm;
