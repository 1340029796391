import { useState, FC, useContext, FormEvent, useEffect } from "react";
import { PatternFormat } from "react-number-format";
import { Alert, Col, Collapse, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import {
  UsuarioNoRegistrado,
  UsuarioNoRegistradoPayload,
} from "../../../models/UsuarioNoRegistrado";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { createUsuarioNoRegistrado } from "../../../services/usuario-api-client";
import tiposDeIdentificacion, {
  TipoDeIdentificacion,
} from "../../../resources/tipos-de-identificacion";
import paises from "../../../resources/paises.json";

interface UsuarioFormProps {
  formId: string;
  usuario?: UsuarioNoRegistrado;
  onSuccess: (usuario: UsuarioNoRegistrado) => void;
}

const UsuarioNoRegistradoForm: FC<UsuarioFormProps> = ({ formId, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [nombre, setNombre] = useState<string>("");
  const [apellido, setApellido] = useState<string>("");
  const [tipoDeIdentificacion, setTipoIdentificacion] = useState<TipoDeIdentificacion>(
    tiposDeIdentificacion[0]
  );
  const [identificacion, setIdentificacion] = useState<string>("");
  const [nacionalidad, setNacionalidad] = useState<string>("Panamá");
  const [telefono, setTelefono] = useState<string>("");
  const [direccionDeResidencia, setDireccionDeResidencia] = useState<string>("");
  const [isValidIdentificacion, setIsValidIdentificacion] = useState<boolean>(false);
  const [errorIdentificacion, setErrorIdentificacion] = useState<string>("");
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorIdentificacion("");
    setMensajeDeError("");
    setIsLoading(true);
    const body: UsuarioNoRegistradoPayload = {
      nombre,
      apellido,
      tipoDeIdentificacion: tipoDeIdentificacion.id,
      identificacion,
      nacionalidad,
      telefono,
      direccionDeResidencia,
    };

    createUsuarioNoRegistrado(authToken, idDeLaEmpresaSeleccionada, body)
      .then(({ data }) => {
        onSuccess(new UsuarioNoRegistrado(data));
        limpiarFormulario();
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          setErrorIdentificacion(response.data.message);
        } else {
          setMensajeDeError("Ocurrió un error al registrar la persona. Intente de nuevo.");
          setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
        }
      })
      .finally(() => setIsLoading(false));
  };

  const limpiarFormulario = () => {
    setNombre("");
    setApellido("");
    setTipoIdentificacion(tiposDeIdentificacion[0]);
    setIdentificacion("");
    setNacionalidad("Panamá");
    setTelefono("");
    setDireccionDeResidencia("");
    setIsValidIdentificacion(false);
    setErrorIdentificacion("");
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
  };

  useEffect(() => {
    const validatorResponse = tipoDeIdentificacion.validador(identificacion);
    const isValid = validatorResponse.isValid && validatorResponse.isComplete;
    setIsValidIdentificacion(isValid);
    setErrorIdentificacion("");
  }, [identificacion, tipoDeIdentificacion]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit} onReset={limpiarFormulario}>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                required
              />
              <Label for="nombre">Nombre</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre"
                value={apellido}
                onChange={(event) => setApellido(event.target.value)}
                required
              />
              <Label for="nombre">Apellidos</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="tipoDeIdentificacion"
                id="tipoDeIdentificacion"
                placeholder="Tipo de identificacion"
                value={tipoDeIdentificacion.id}
                onChange={(event) =>
                  setTipoIdentificacion(
                    tiposDeIdentificacion.find(({ id }) => id === event.target.value)!
                  )
                }
                required
              >
                {tiposDeIdentificacion.map(({ id, nombre }) => (
                  <option key={id} value={id}>
                    {nombre}
                  </option>
                ))}
              </Input>
              <Label for="tipoDeIdentificacion">Tipo de identificacion</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                id="identificacion"
                name="identificacion"
                placeholder={tipoDeIdentificacion.nombre}
                type="text"
                required
                invalid={!!errorIdentificacion}
                valid={isValidIdentificacion}
                value={identificacion}
                onChange={(event) => setIdentificacion(event.target.value)}
              />
              <Label for="identificacion">Identificacion</Label>
              <FormFeedback tooltip>{errorIdentificacion}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Col>
          <FormGroup floating>
            <Input
              id="nacionalidad"
              name="nacionalidad"
              type="select"
              value={nacionalidad}
              onChange={(e) => setNacionalidad(e.target.value)}
            >
              {paises.map(({ nombre, bandera }) => (
                <option value={nombre} key={nombre}>
                  {nombre} {bandera}
                </option>
              ))}
            </Input>
            <Label htmlFor="nacionalidad">Nacionalidad</Label>
          </FormGroup>
        </Col>
        <FormGroup floating>
          <PatternFormat
            customInput={Input}
            id="telefono"
            name="telefono"
            placeholder="Teléfono"
            type="tel"
            format={telefono.startsWith("7") ? "###-####" : "####-####"}
            mask="_"
            required
            value={telefono}
            onValueChange={({ formattedValue }) => setTelefono(formattedValue)}
          />
          <Label htmlFor="telefono">Teléfono</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            id="direccion"
            name="direccion"
            placeholder="Dirección de residencia"
            type="text"
            required
            value={direccionDeResidencia}
            onChange={(e) => setDireccionDeResidencia(e.target.value)}
          />
          <Label htmlFor="direccion">Dirección de residencia</Label>
        </FormGroup>
      </Form>
      <Collapse isOpen={!!mensajeDeError}>
        <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
          {mensajeDeError}
        </Alert>
      </Collapse>
    </>
  );
};

export default UsuarioNoRegistradoForm;
