import { useCallback, useContext, FC, useState, useEffect } from "react";
import {
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Placeholder,
  Row,
} from "reactstrap";
import { ReactFontSizeByTextLength } from "react-font-size-by-text-length";
import { FaSignOutAlt, FaTh, FaUserCircle } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { GISSTLogo } from "../../resources/Images";
import { Context } from "../Context";
import LocalStorage from "../../utilities/LocalStorage";
import { range, toTitleCase } from "../../utilities/utils";
import { getStatus } from "../../services/usuario-api-client";
import CambiarContrasenaModal from "./CambiarContrasenaModal";
import { Usuario } from "../../models/Usuario";
import { Vinculacion } from "../../models/Vinculacion";
import { PATHS } from "../../utilities/Paths";

const LogoContainer = styled.div`
  height: 40px;
`;

const InfoContainer = styled.div`
  cursor: pointer;
  @media (min-width: 768px) {
    font-size: 8px;
    border: 2px solid #474747;
    border-radius: 10px;
    margin: auto 12px;
    padding: 4px 8px;
    height: 48px;
    min-width: 100px;
  }
`;

const HeaderUsuario: FC = () => {
  const navigate = useNavigate();
  const {
    authToken,
    idDeLaEmpresaSeleccionada,
    tituloDeLaPantallaActual,
    setIdDeLaEmpresaSeleccionada,
    setUsuarioEnSesion,
  } = useContext(Context);
  const [nombreDelUsuario, setNombreDelUsuario] = useState<string>("");
  const [tipoDeUsuario, setTipoDeUsuario] = useState<string>("");
  const [nombreDeLaEmpresa, setNombreDeLaEmpresa] = useState<string>("");
  const [rucYDigitoVerificador, setRucYDigitoVerificador] = useState<string>("");
  const [isOpenOffcanvas, setIsOpenOffcanvas] = useState<boolean>(false);
  const [cambiarContraseñaModalIsOpen, setCambiarContraseñaModalIsOpen] = useState<boolean>(false);
  const [contraseñaCambiadaModalIsOpen, setContraseñaCambiadaModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleOffcanvas = () => {
    setIsOpenOffcanvas(!isOpenOffcanvas);
  };

  const handleContraseñaCambiada = () => {
    setCambiarContraseñaModalIsOpen(false);
    setContraseñaCambiadaModalIsOpen(true);
  };

  const handleUsuarioEnSesionChange = useCallback(
    (nuevoUsuario: Usuario | Vinculacion | null) => {
      setUsuarioEnSesion(nuevoUsuario);
    },
    [setUsuarioEnSesion]
  );

  useEffect(() => {
    if (!authToken || isLoading) return;
    setIsLoading(true);
    getStatus(authToken)
      .then(({ data }) => {
        const prevIdDeLaEmpresaSeleccionada = idDeLaEmpresaSeleccionada;
        LocalStorage.getIdEmpresa().then((idDeLaEmpresaAlmacenado) => {
          const { persona, vinculaciones, cambiarContrasena } = data;
          setNombreDelUsuario(`${persona.nombre} ${persona.apellidos}`);
          let idDeLaEmpresaSeleccionada: string;
          if (!prevIdDeLaEmpresaSeleccionada && !!persona.ultimaEmpresaSeleccionada) {
            idDeLaEmpresaSeleccionada = persona.ultimaEmpresaSeleccionada;
            setIdDeLaEmpresaSeleccionada(idDeLaEmpresaSeleccionada);
          }
          if (!!idDeLaEmpresaAlmacenado) {
            idDeLaEmpresaSeleccionada = idDeLaEmpresaAlmacenado;
          } else if (!!prevIdDeLaEmpresaSeleccionada) {
            idDeLaEmpresaSeleccionada = prevIdDeLaEmpresaSeleccionada;
          } else {
            const empresa = vinculaciones[0].empresa;
            idDeLaEmpresaSeleccionada = typeof empresa === "string" ? empresa : empresa._id;
            setIdDeLaEmpresaSeleccionada(idDeLaEmpresaSeleccionada);
          }
          const currentVinculacion = vinculaciones.find(({ empresa }) =>
            typeof empresa === "string"
              ? empresa === idDeLaEmpresaSeleccionada
              : empresa._id === idDeLaEmpresaSeleccionada
          );
          if (!!currentVinculacion) {
            const rol = toTitleCase(currentVinculacion.rol);
            const { cargo } = currentVinculacion;
            if (!!cargo && typeof cargo !== "string") {
              if (rol === "Administrador") {
                setTipoDeUsuario(`${rol}/${cargo.cargo}`);
              } else {
                setTipoDeUsuario(cargo.cargo);
              }
            } else {
              setTipoDeUsuario(rol);
            }
            const { empresa } = currentVinculacion;
            if (typeof empresa !== "string") {
              setNombreDeLaEmpresa(empresa.nombreDeLaEmpresa);
              setRucYDigitoVerificador(`${empresa.ruc} DV ${empresa.digitoVerificador}`);
            }
            handleUsuarioEnSesionChange(new Vinculacion({ ...currentVinculacion, persona }));
          } else {
            // handleUsuarioEnSesionChange(persona);
          }
          if (cambiarContrasena) {
            setCambiarContraseñaModalIsOpen(true);
          }
        });
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    LocalStorage.isLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        navigate(PATHS.inicio.to);
      }
    });
  }, [navigate]);

  return (
    <>
      <Navbar light container fixed="top" className="bg-white" expand="md">
        <LinkContainer to={PATHS.usuarioHome.to}>
          <NavbarBrand>
            <LogoContainer>
              <GISSTLogo />
            </LogoContainer>
          </NavbarBrand>
        </LinkContainer>
        <h2>
          <ReactFontSizeByTextLength startAtChar={15} changePerChar={3} minPercent={50}>
            <b>{tituloDeLaPantallaActual}</b>
          </ReactFontSizeByTextLength>
        </h2>
        <NavbarToggler onClick={toggleOffcanvas} />
        <Collapse navbar className="flex-grow-0">
          <Nav navbar tag="div">
            <NavItem tag="div">
              <InfoContainer onClick={toggleOffcanvas}>
                <Row className="align-items-center h-100">
                  <Col>
                    {isLoading ? (
                      range(1, 3).map((i) => (
                        <Placeholder animation="wave" key={i}>
                          <Placeholder size="md" xs={Math.floor(Math.random() * 5 + 8)} />
                        </Placeholder>
                      ))
                    ) : (
                      <>
                        <Row>
                          <Col>{nombreDelUsuario}</Col>
                        </Row>
                        <Row>
                          <Col>{tipoDeUsuario}</Col>
                        </Row>
                        <Row>
                          <Col>{nombreDeLaEmpresa}</Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </InfoContainer>
            </NavItem>
            <NavItem tag="div">
              <Row className="align-items-center h-100 gx-0">
                <Col xs="auto">
                  <LinkContainer to={PATHS.usuarioHome.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted px-2"
                      title="Menú principal"
                    >
                      <FaTh size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            </NavItem>
            <NavItem tag="div">
              <Row className="align-items-center h-100 gx-0">
                <Col xs="auto">
                  <LinkContainer to={PATHS.perfil.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted px-2"
                      title="Mi perfil"
                    >
                      <FaUserCircle size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            </NavItem>
            <NavItem tag="div">
              <Row className="align-items-center h-100 gx-0">
                <Col xs="auto">
                  <LinkContainer to={PATHS.logout.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted px-2"
                      title="Cerrar sesión"
                    >
                      <FaSignOutAlt size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Offcanvas isOpen={isOpenOffcanvas} toggle={toggleOffcanvas} unmountOnClose={false}>
        <OffcanvasHeader toggle={toggleOffcanvas}>
          <LogoContainer>
            <GISSTLogo />
          </LogoContainer>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    to={PATHS.nuevaEmpresa.to}
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Registrar empresa
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to={PATHS.editarEmpresa.to}
                    className="nav-link"
                    onClick={toggleOffcanvas}
                  >
                    Editar datos de la empresa
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div>
              <Row>
                <Col>{nombreDelUsuario}</Col>
              </Row>
              <Row>
                <Col>{tipoDeUsuario}</Col>
              </Row>
              <Row>
                <Col>{nombreDeLaEmpresa}</Col>
              </Row>
              <Row>
                <Col>{rucYDigitoVerificador}</Col>
              </Row>
              <Row className="mb-3">
                <Col xs="auto">
                  <LinkContainer to={PATHS.usuarioHome.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted"
                      title="Menú principal"
                      onClick={toggleOffcanvas}
                    >
                      <FaTh size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
                <Col xs="auto">
                  <LinkContainer to={PATHS.perfil.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted"
                      title="Mi perfil"
                      onClick={toggleOffcanvas}
                    >
                      <FaUserCircle size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
                <Col xs="auto">
                  <LinkContainer to={PATHS.logout.to}>
                    <Button
                      type="button"
                      color="link"
                      className="text-muted"
                      title="Cerrar sesión"
                      onClick={toggleOffcanvas}
                    >
                      <FaSignOutAlt size={25} />
                    </Button>
                  </LinkContainer>
                </Col>
              </Row>
            </div>
          </div>
        </OffcanvasBody>
      </Offcanvas>
      <CambiarContrasenaModal
        isOpen={cambiarContraseñaModalIsOpen}
        forzarCambio
        onContraseñaCambiada={handleContraseñaCambiada}
        setIsLoading={setIsLoading}
      />
      <Modal
        isOpen={contraseñaCambiadaModalIsOpen}
        toggle={() => setContraseñaCambiadaModalIsOpen(false)}
      >
        <ModalHeader toggle={() => setContraseñaCambiadaModalIsOpen(false)}>
          Contraseña Cambiada
        </ModalHeader>
        <ModalBody>
          <p>La contraseña se ha cambiado correctamente.</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setContraseñaCambiadaModalIsOpen(false);
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default HeaderUsuario;
