import { ArchivoDeDocumento, ArchivoDeDocumentoResponse } from "./ArchivoDeDocumento";
import { ComentarioDeDocumento, ComentarioDeDocumentoResponse } from "./ComentarioDeDocumento";
import { Contrato } from "./Contrato";
import {
  Activo,
  ActivoResponse,
  Documento,
  DocumentoResponse,
  TiposDeDocumento,
} from "./Documento";
import { EquipoHerramienta, EquipoHerramientaResponse } from "./EquipoHerramienta";
import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";
import { SustanciaQuimica, SustanciaQuimicaResponse } from "./SustanciaQuimica";
import { Vehiculo, VehiculoResponse } from "./Vehiculo";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export enum Evaluacion {
  Aprobado = "aprobado",
  Rechazado = "rechazado",
  Pendiente = "pendiente",
}

export interface DocumentoDelContratoBase {
  nombre: string;
  contrato?: string | Contrato;
  tipoDeDocumentoPreliminar?: TiposDeDocumento;
  tipoDeActivo?: string;
  requisitosLegales?: (string | RequisitoLegal | RequisitoLegalResponse)[];
}

export interface DocumentoDelContrato extends DocumentoDelContratoBase {
  _id: string;
  documentoVinculado?: Documento;
  comentarios: ComentarioDeDocumento[];
  evaluacion: Evaluacion;
  archivos?: ArchivoDeDocumento[];
  archivosAprobados: string[];
  archivosRechazados: string[];
  activos?: Activo[];
  requisitosLegales?: (string | RequisitoLegal)[];
  createdAt: Date;
  updatedAt: Date;
}

export interface DocumentoDelContratoResponse extends DocumentoDelContratoBase {
  _id: string;
  documentoVinculado?: DocumentoResponse;
  comentarios: ComentarioDeDocumentoResponse[];
  evaluacion: Evaluacion;
  archivos?: ArchivoDeDocumentoResponse[];
  archivosAprobados?: string[];
  archivosRechazados?: string[];
  activos?: ActivoResponse[];
  requisitosLegales?: (string | RequisitoLegalResponse)[];
  createdAt: string;
  updatedAt: string;
}

export class DocumentoDelContrato implements DocumentoDelContrato {
  constructor({
    _id,
    nombre,
    contrato,
    tipoDeDocumentoPreliminar,
    comentarios = [],
    archivos = [],
    archivosAprobados = [],
    archivosRechazados = [],
    activos = [],
    tipoDeActivo,
    documentoVinculado,
    evaluacion,
    requisitosLegales = [],
    createdAt,
    updatedAt,
  }: DocumentoDelContratoResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.tipoDeDocumentoPreliminar = tipoDeDocumentoPreliminar;
    this.contrato = contrato;
    this.comentarios = comentarios.map((comentario) => new ComentarioDeDocumento(comentario));
    this.documentoVinculado = !!documentoVinculado ? new Documento(documentoVinculado) : undefined;
    this.evaluacion = evaluacion;
    const archivosDesordenados = archivos.map((archivo) => new ArchivoDeDocumento(archivo));
    this.archivos = archivosDesordenados.sort((a, b) => {
      if (a.fechaDeVencimiento < b.fechaDeVencimiento) {
        return 1;
      } else if (a.fechaDeVencimiento > b.fechaDeVencimiento) {
        return -1;
      } else {
        return 0;
      }
    });
    this.archivosAprobados = archivosAprobados;
    this.archivosRechazados = archivosRechazados;
    if (tipoDeActivo) {
      switch (tipoDeActivo) {
        case "Vinculacion":
          this.activos = activos.map((usuario) => new Vinculacion(usuario as VinculacionResponse));
          break;
        case "Vehiculo":
          this.activos = activos.map((vehiculo) => new Vehiculo(vehiculo as VehiculoResponse));
          break;
        case "EquipoHerramienta":
          this.activos = activos.map(
            (equipo) => new EquipoHerramienta(equipo as EquipoHerramientaResponse)
          );
          break;
        case "SustanciaQuimica":
          this.activos = activos.map(
            (sustancia) => new SustanciaQuimica(sustancia as SustanciaQuimicaResponse)
          );
          break;
      }
    }
    this.requisitosLegales = requisitosLegales.map((requisitoLegal) =>
      typeof requisitoLegal === "string" ? requisitoLegal : new RequisitoLegal(requisitoLegal)
    );
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }

  toString() {
    return this.nombre;
  }
}
