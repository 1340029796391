import { useState, FC, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Form, Row } from "reactstrap";
import { Context } from "../Context";

const ConfirmarAutorizacion: FC = () => {
  const location = useLocation();
  const { authToken } = useContext(Context);
  const [clientId, setClientId] = useState<string>("");
  const [responseType, setResponseType] = useState<string>("");
  const [redirectUri, setRedirectUri] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [scope, setScope] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setClientId(params.get("client_id") || "");
    setResponseType(params.get("response_type") || "");
    setRedirectUri(params.get("redirect_uri") || "");
    setState(params.get("state") || "");
    setScope(params.get("scope") || "");
  }, [location]);

  return (
    <>
      <h1>Confirmar Autorización</h1>
      <p>¿Quiere autorizar a Moodle para iniciar sesión con su cuenta de GISST?</p>
      <Row>
        <Col>
          <Form action="/api/oauth/approve" method="GET">
            <input type="hidden" name="client_id" value={clientId} />
            <input type="hidden" name="response_type" value={responseType} />
            <input type="hidden" name="redirect_uri" value={redirectUri} />
            <input type="hidden" name="state" value={state} />
            <input type="hidden" name="scope" value={scope} />
            <input type="hidden" name="token" value={authToken} />
            <Button type="submit" color="primary">
              Autorizar
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmarAutorizacion;
