import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export interface ComentarioDeDocumentoBase {
  comentario: string;
}

export interface ComentarioDeDocumento extends ComentarioDeDocumentoBase {
  _id: string;
  documento: string;
  usuario: Vinculacion;
  createdAt: Date;
  updatedAt: Date;
}

export interface ComentarioDeDocumentoResponse extends ComentarioDeDocumentoBase {
  _id: string;
  documento: string;
  usuario: VinculacionResponse;
  createdAt: string;
  updatedAt: string;
}

export class ComentarioDeDocumento implements ComentarioDeDocumento {
  constructor({
    _id,
    comentario,
    documento,
    usuario,
    createdAt,
    updatedAt,
  }: ComentarioDeDocumentoResponse) {
    this._id = _id;
    this.comentario = comentario;
    this.documento = documento;
    this.usuario = new Vinculacion(usuario);
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }
}
