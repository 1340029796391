import { FaSquare, FaCheckSquare } from "react-icons/fa";
import { ImageButton } from "./utils";

type CheckboxProps = {
  checked: boolean;
  disabled?: boolean;
  toggle?: () => void;
};

const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled = false, toggle }) => {
  return (
    <ImageButton
      type="button"
      title={checked ? "Deseleccionar" : "Seleccionar"}
      className="d-inline-block bg-primary lh-0"
      onClick={toggle}
      disabled={disabled}
    >
      {checked ? (
        <FaCheckSquare size={15} color="#EAEAEA" />
      ) : (
        <FaSquare size={15} color="#EAEAEA" />
      )}
    </ImageButton>
  );
};

export default Checkbox;
