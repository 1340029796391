import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  editCalificacion,
  editEvaluacionDeRiesgo,
  getActividadById,
  listarRequisitosLegales,
  listarRiesgosEvaluados,
} from "../../../../services/usuario-api-client";
import { Actividad } from "../../../../models/Actividad";
import { RequisitoLegal } from "../../../../models/RequisitoLegal";
import {
  EvaluacionDeRiesgo,
  EvaluacionDeRiesgoPayload,
} from "../../../../models/EvaluacionDeRiesgo";
import FormularioDeEditarRiesgo from "./FormularioDeEditarRiesgo";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";

const EDITAR_RIESGO_FORM_ID = "editarRiesgoForm";

let reevaluarCalificacion = false;

const EditarRiesgo: FC = () => {
  const { id } = useParams();
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [actividad, setActividad] = useState<Actividad>();
  const [requisitosLegales, setRequisitosLegales] = useState<RequisitoLegal[]>([]);
  const [peligrosEvaluados, setPeligrosEvaluados] = useState<EvaluacionDeRiesgo[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleEditarRiesgoSubmit = (
    body: EvaluacionDeRiesgoPayload,
    idRiesgo: string,
    callback: () => void
  ) => {
    if (!actividad || !actividad._id) return;
    setLoadingCounter((prev) => prev + 1);

    const evaluacion = peligrosEvaluados.find(({ _id }) => _id === idRiesgo);
    reevaluarCalificacion =
      !!actividad.calificacionMaxima &&
      (evaluacion?.consecuencia.id !== body.consecuencia.id ||
        evaluacion?.exposicion.id !== body.exposicion.id ||
        evaluacion?.probabilidad.id !== body.probabilidad.id);
    editEvaluacionDeRiesgo(authToken, idRiesgo, body)
      .then(() => {
        cargarDatosActualizados();
        callback();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const cargarDatosActualizados = useCallback(() => {
    if (!id) return;
    setLoadingCounter((prev) => prev + 1);

    listarRiesgosEvaluados(authToken, id)
      .then(({ data }) => {
        const peligrosEvaluados = data.map((evaluacion) => new EvaluacionDeRiesgo(evaluacion));
        setPeligrosEvaluados(peligrosEvaluados);
        if (reevaluarCalificacion) {
          reevaluarCalificacion = false;
          const calificacionMaxima = Math.max(
            ...peligrosEvaluados.map((peligro) => peligro.calificacionDeRiesgo || 0)
          );
          setLoadingCounter((prev) => prev + 1);
          editCalificacion(authToken, id, { calificacionMaxima }).finally(() =>
            setLoadingCounter((prev) => prev - 1)
          );
        }
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, id]);

  const cargarDatosIniciales = useCallback(() => {
    if (!authToken || !id) return;
    setLoadingCounter((prev) => prev + 1);
    Promise.all([
      getActividadById(authToken, id),
      listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada),
      listarRiesgosEvaluados(authToken, id),
    ])
      .then(([{ data }, { data: requisitoLegalResponse }, { data: peligrosEvaluados }]) => {
        setActividad(new Actividad(data));
        const requisitosLegales = requisitoLegalResponse.map(
          (requisitoLegal) => new RequisitoLegal(requisitoLegal)
        );
        setRequisitosLegales(requisitosLegales);
        setPeligrosEvaluados(
          peligrosEvaluados.map((evaluacion) => new EvaluacionDeRiesgo(evaluacion))
        );
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, id, idDeLaEmpresaSeleccionada]);

  useEffect(cargarDatosIniciales, [cargarDatosIniciales]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.editarEvaluacion.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.miper]} />
      <Row>
        <Col>
          Departamento: <b>{actividad?.departamento}</b>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          Actividad: <b>{actividad?.actividad}</b>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <FormularioDeEditarRiesgo
            formId={EDITAR_RIESGO_FORM_ID}
            actividad={actividad as Actividad}
            requisitoLegales={requisitosLegales}
            peligrosEvaluados={peligrosEvaluados}
            onSubmit={handleEditarRiesgoSubmit}
          />
        </Col>
      </Row>
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={EDITAR_RIESGO_FORM_ID} type="submit" color="primary" className="ms-auto">
          Guardar
        </Button>
      </Navbar>
    </>
  );
};

export default EditarRiesgo;
