import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import {
  CategoriasDeRequisitosLegales,
  TiposDeRequisitosLegales,
  textoClasificacion,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import { DocumentoLegal, DocumentoLegalEditBody } from "../../../models/DocumentoLegal";
import { dateInputToDate, dateToISOString, obtenerURLCompleta } from "../../../utilities/utils";
import { Autoridad } from "../../../models/Autoridad";
import { Context } from "../../Context";
import { editarDocumentoLegal, listarAutoridades } from "../../../services/admin-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";

interface Props {
  documentoLegal: DocumentoLegal;
  formId: string;
  onSuccess: () => void;
}

const EditarDocumentoLegalForm: FC<Props> = ({ documentoLegal, formId, onSuccess }) => {
  const { adminAuthToken } = useContext(Context);
  const [nombre, setNombre] = useState<string>(documentoLegal.nombre);
  const [tipo, setTipo] = useState<TiposDeRequisitosLegales>(documentoLegal.tipo);
  const [autoridad, setAutoridad] = useState<string>(documentoLegal.autoridad._id);
  const [numeroDeArticulos, setNumeroDeArticulos] = useState<string>(
    documentoLegal.numeroDeArticulos.toString()
  );
  const [fechaDeEmisionString, setFechaDeEmisionString] = useState<string>(
    dateToISOString(documentoLegal.fechaDeEmision)
  );
  const [clasificacion, setClasificacion] = useState<CategoriasDeRequisitosLegales>(
    documentoLegal.clasificacion
  );
  const [autoridades, setAutoridades] = useState<Autoridad[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditarRequisitoLegalSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!adminAuthToken) return;
    const nuevoRequisitoLegal: DocumentoLegalEditBody = {
      nombre,
      tipo,
      autoridad,
      numeroDeArticulos: parseInt(numeroDeArticulos),
      clasificacion,
      fechaDeEmision: dateInputToDate(fechaDeEmisionString),
    };
    setIsLoading(true);
    editarDocumentoLegal(adminAuthToken, documentoLegal._id, nuevoRequisitoLegal)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!adminAuthToken) return;
    setIsLoading(true);
    listarAutoridades(adminAuthToken)
      .then(({ data }) => {
        setAutoridades(data);
      })
      .finally(() => setIsLoading(false));
  }, [adminAuthToken, documentoLegal]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleEditarRequisitoLegalSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="nombre"
            name="nombre"
            placeholder="Nombre del requisito legal"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <Label for="nombre">Nombre del requisito legal</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            id="url"
            name="url"
            placeholder="Hipervínculo"
            value={obtenerURLCompleta(documentoLegal.filepath)}
            disabled
            required
          />
          <Label for="url">Hipervínculo</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="tipo"
            name="tipo"
            placeholder="Tipo de requisito legal"
            value={tipo}
            onChange={(e) => setTipo(e.target.value as TiposDeRequisitosLegales)}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {Object.values(TiposDeRequisitosLegales).map((tipo) => (
              <option key={tipo} value={tipo}>
                {textoTipoDeRequisitoLegal(tipo)}
              </option>
            ))}
          </Input>
          <Label for="tipo">Tipo de requisito legal</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="autoridad"
            name="autoridad"
            placeholder="Autoridad"
            value={autoridad}
            onChange={(e) => setAutoridad(e.target.value)}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {autoridades.map(({ _id, nombre }) => (
              <option key={_id} value={_id}>
                {nombre}
              </option>
            ))}
          </Input>
          <Label for="autoridad">Autoridad</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="clasificacion"
            name="clasificacion"
            placeholder="Clasificación"
            value={clasificacion}
            onChange={(e) => setClasificacion(e.target.value as CategoriasDeRequisitosLegales)}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {Object.values(CategoriasDeRequisitosLegales).map((clasificacion) => (
              <option key={clasificacion} value={clasificacion}>
                {textoClasificacion(clasificacion)}
              </option>
            ))}
          </Input>
          <Label for="clasificacion">Clasificación</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="number"
            id="numeroDeArticulos"
            name="numeroDeArticulos"
            placeholder="Número de artículos"
            value={numeroDeArticulos}
            onChange={(e) => setNumeroDeArticulos(e.target.value)}
            required
          />
          <Label for="numeroDeArticulos">Número de artículos</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="date"
            id="fechaDeEmisión"
            placeholder="Fecha de emisión"
            value={fechaDeEmisionString}
            onChange={(e) => setFechaDeEmisionString(e.target.value)}
            required
          />
          <Label for="fechaDeEmisión">Fecha de emisión</Label>
        </FormGroup>
      </Form>
    </>
  );
};

export default EditarDocumentoLegalForm;
