import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, Label } from "recharts";
import { Context } from "../../Context";
import { indicadoresDeInvestigacionDeIncidentes } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { textoDelEstadoACR } from "../../../models/Incidente";
import { COLORS } from "../../../utilities/utils";

const InvestigacionDeIncidentes: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [indicadores, setIndicadores] = useState<{ estado: string; cantidad: number }[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    indicadoresDeInvestigacionDeIncidentes(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const indicadores = data.map(({ estado, cantidad }) => ({
          estado: textoDelEstadoACR(estado),
          cantidad,
        }));
        setIndicadores(indicadores);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const totalDeIncidentes = indicadores.reduce((acc, { cantidad }) => acc + cantidad, 0);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row className="mb-3">
        <Col className="text-center h5">Incidentes por estado</Col>
      </Row>
      {!!indicadores.length && (
        <ResponsiveContainer width="100%" height="100%" minHeight={250}>
          <PieChart width={250} height={250}>
            <Pie
              data={indicadores}
              dataKey="cantidad"
              nameKey="estado"
              cx="50%"
              cy="50%"
              label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
              startAngle={90}
              endAngle={450}
              innerRadius={50}
              fill="#12416B"
            >
              {indicadores.map((_, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
              <Label value={`${totalDeIncidentes}`} position="center" fill="#000" />
            </Pie>
            <Tooltip />
            <Legend
              payload={indicadores.map(({ estado, cantidad }, index) => ({
                value: `${estado}: ${cantidad}`,
                type: "circle",
                color: COLORS[index % COLORS.length],
              }))}
            />
          </PieChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default InvestigacionDeIncidentes;
