import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import FormularioDeActividad from "./FormularioDeActividad";
import { editarActividad, getActividadById } from "../../../../services/usuario-api-client";
import { Actividad, ActividadPayload } from "../../../../models/Actividad";
import { PATHS } from "../../../../utilities/Paths";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";

const EDITAR_ACTIVIDAD_FORM_ID = "editarActividadForm";

const EditarActividad: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actividad, setActividad] = useState<Actividad | undefined>(undefined);

  const handleEditarActividadSubmit = (body: ActividadPayload) => {
    if (!id) return;
    setIsLoading(true);
    editarActividad(authToken, id, body)
      .then(() => navigate(PATHS.miper.to))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    getActividadById(authToken, id)
      .then(({ data }) => setActividad(new Actividad(data)))
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.editarActividad.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.miper]} />
      {actividad && (
        <Row className="mb-5">
          <Col>
            <FormularioDeActividad
              formId={EDITAR_ACTIVIDAD_FORM_ID}
              actividadParaEditar={actividad}
              onSubmit={handleEditarActividadSubmit}
            />
          </Col>
        </Row>
      )}
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={EDITAR_ACTIVIDAD_FORM_ID} type="submit" color="primary" className="ms-auto">
          Guardar
        </Button>
      </Navbar>
    </>
  );
};

export default EditarActividad;
