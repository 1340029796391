import { FC, useState, FormEvent, useEffect, useContext } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { FaPlus, FaUser } from "react-icons/fa";
import { Vinculacion } from "../../../models/Vinculacion";
import {
  UsuarioNoRegistrado,
  UsuarioNoRegistradoResponse,
} from "../../../models/UsuarioNoRegistrado";
import { CustomTable, TableContainer, IconsContainer } from "../../../utilities/utils";
import { Context } from "../../Context";
import { listUsuariosNoRegistrados } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Testigo } from "../../../models/Testigo";
import Checkbox from "../../../utilities/Checkbox";

interface BusquedaDeTestigosProps {
  formId: string;
  testigosPreseleccionados: Testigo[];
  vinculaciones: Vinculacion[];
  isCreating: boolean;
  onCreateToggle: () => void;
  onSubmit: (testigosSeleccionados: Testigo[]) => void;
}

const BusquedaDeTestigos: FC<BusquedaDeTestigosProps> = ({
  formId,
  testigosPreseleccionados,
  vinculaciones,
  isCreating,
  onCreateToggle,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [testigosSeleccionados, setTestigosSeleccionados] =
    useState<Testigo[]>(testigosPreseleccionados);
  const [testigosFiltrados, setTestigosFiltrados] = useState<(Vinculacion | UsuarioNoRegistrado)[]>(
    []
  );
  const [testigos, setTestigos] = useState<(Vinculacion | UsuarioNoRegistrado)[]>([]);
  const [busqueda, setBusqueda] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleTodos = () => {
    if (testigosSeleccionados.length === testigosFiltrados.length) {
      setTestigosSeleccionados([]);
    } else {
      const testigos = testigosFiltrados.map(
        (testigo) =>
          new Testigo({
            testigo,
            model: testigo instanceof Vinculacion ? "Vinculacion" : "UsuarioNoRegistrado",
            nombreCompleto: testigo.nombreCompleto,
          })
      );
      setTestigosSeleccionados(testigos);
    }
  };

  const toggleUsuario = (vinculacion: Vinculacion | UsuarioNoRegistrado) => {
    if (testigosSeleccionados.some((v) => v.toString() === vinculacion._id)) {
      setTestigosSeleccionados(
        testigosSeleccionados.filter((v) => v.toString() !== vinculacion._id)
      );
    } else {
      const nuevoTestigo = new Testigo({
        testigo: vinculacion,
        model: vinculacion instanceof Vinculacion ? "Vinculacion" : "UsuarioNoRegistrado",
        nombreCompleto: vinculacion.nombreCompleto,
      });
      setTestigosSeleccionados([...testigosSeleccionados, nuevoTestigo]);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(testigosSeleccionados);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || isCreating) return;
    setIsLoading(true);
    listUsuariosNoRegistrados(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const usuariosNoRegistrados = data.map(
          (usuarioNoRegistrado) =>
            new UsuarioNoRegistrado(usuarioNoRegistrado as UsuarioNoRegistradoResponse)
        );
        const combinedData = [...usuariosNoRegistrados, ...vinculaciones];
        setTestigos(combinedData);
        setTestigosFiltrados(combinedData);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada, isCreating, vinculaciones]);

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setTestigosFiltrados(
      testigos.filter((testigo) => {
        if (!testigo) return "";
        if (testigo instanceof Vinculacion) {
          return (
            testigo.persona.nombre.toLowerCase().includes(busquedaLower) ||
            testigo.persona.apellidos.toLowerCase().includes(busquedaLower) ||
            testigo.persona.identificacion.toLowerCase().includes(busquedaLower) ||
            testigo.persona.correo.toLowerCase().includes(busquedaLower)
          );
        } else if (testigo instanceof UsuarioNoRegistrado) {
          return (
            testigo.nombre.toLowerCase().includes(busquedaLower) ||
            testigo.apellido.toLowerCase().includes(busquedaLower) ||
            testigo.identificacion.toLowerCase().includes(busquedaLower)
          );
        } else {
          return "";
        }
      })
    );
  }, [testigos, busqueda]);

  useEffect(() => {
    const nuevosTestigos: Testigo[] = [...testigosSeleccionados];
    testigosPreseleccionados.forEach((testigo) => {
      const testigoEncontrado = testigos.find((t) => t._id === testigo.toString());
      const yaSeleccionado = nuevosTestigos.some((t) => t.toString() === testigo.toString());
      if (testigoEncontrado && !yaSeleccionado) {
        nuevosTestigos.push(testigo);
      }
    });
    setTestigosSeleccionados(nuevosTestigos);
  }, [testigosPreseleccionados, testigos]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="align-items-center">
        <Col xs="auto">
          <Label>Filtrar</Label>
        </Col>
        <div className="w-100 d-sm-none" />
        <Col>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Buscar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Buscar</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} id={formId}>
        <TableContainer>
          <CustomTable>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={testigosSeleccionados.length === testigosFiltrados.length}
                    toggle={toggleTodos}
                  />
                </th>
                <th>Identificación</th>
                <th>Nombre</th>
                <th>Apellido(s)</th>
              </tr>
            </thead>
            <tbody>
              {testigosFiltrados.map((vinculacion) => (
                <tr key={vinculacion._id} onClick={() => toggleUsuario(vinculacion)}>
                  <td className="text-center">
                    <Checkbox
                      checked={testigosSeleccionados.some((v) => v.toString() === vinculacion._id)}
                    />
                  </td>
                  <td>
                    {vinculacion instanceof Vinculacion
                      ? vinculacion.persona.identificacion
                      : vinculacion.identificacion}
                  </td>
                  <td>
                    {vinculacion instanceof Vinculacion
                      ? vinculacion.persona.nombre
                      : vinculacion.nombre}
                  </td>
                  <td>
                    {vinculacion instanceof Vinculacion
                      ? vinculacion.persona.apellidos
                      : vinculacion.apellido}
                  </td>
                </tr>
              ))}
              {vinculaciones.length === 0 ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    No hay usuarios registrados
                  </td>
                </tr>
              ) : (
                testigosFiltrados.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No hay usuarios que coincidan con la búsqueda
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </CustomTable>
        </TableContainer>
      </Form>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button
            type="button"
            color="primary"
            title="Agregar usuario no registrado"
            onClick={onCreateToggle}
          >
            <IconsContainer>
              <FaUser size={20} textAnchor="middle" alignmentBaseline="middle" />
              <FaPlus
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{
                  fontSize: ".5em",
                  position: "absolute",
                  left: "2.35em",
                  bottom: "2em",
                }}
              />
            </IconsContainer>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BusquedaDeTestigos;
