import { useContext, FC, useEffect, useState, HTMLAttributes } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import { Context } from "../../Context";
import { fetchCurso, fetchHost } from "../../../services/lms-api-client";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  TableMessage,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../utilities/utils";
import { Curso, textoDeFrecuencia, textoDeModalidad } from "../../../models/Curso";
import {
  asignarCurso,
  listCursos,
  listCursosAsignados,
  listCursosDeLaEmpresa,
  listHistorialDeCursos,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";
import { useLocation } from "react-router-dom";
import { Cargo } from "../../../models/Cargo";
import { PlanDeAccion } from "../../../models/PlanDeAccion";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import BusquedaDeUsuarios from "./BusquedaDeUsuarios";
import { Vinculacion } from "../../../models/Vinculacion";

const USUARIOS_FORM_ID = "usuariosForm";

enum Vistas {
  CursosAsignados = "cursosAsignados",
  CursosDeLaEmpresa = "cursosDeLaEmpresa",
  CursosLibres = "cursosLibres",
  Historial = "historial",
}

const textoDeVista = {
  [Vistas.CursosAsignados]: "Cursos Asignados",
  [Vistas.CursosDeLaEmpresa]: "Cursos de la empresa",
  [Vistas.CursosLibres]: "Cursos Libres",
  [Vistas.Historial]: "Historial",
};

interface BotonesDeAccionProps {
  curso: Curso;
  vistaActual: Vistas;
  lmsHost: string;
  asignarCurso: (curso: Curso) => void;
  verCurso: (curso: Curso) => void;
}

const BotonesDeAccion: FC<BotonesDeAccionProps> = ({
  curso,
  vistaActual,
  lmsHost,
  asignarCurso,
  verCurso,
}) => {
  switch (vistaActual) {
    case Vistas.CursosAsignados:
      return (
        <Button
          tag="a"
          color="primary"
          size="sm"
          href={`${lmsHost}/course/view.php?id=${curso.id}`}
          target="_blank"
        >
          Ir al curso
        </Button>
      );
    case Vistas.CursosDeLaEmpresa:
    case Vistas.CursosLibres:
      return (
        <Button type="button" color="primary" size="sm" onClick={() => asignarCurso(curso)}>
          Asignar
        </Button>
      );
    case Vistas.Historial:
      return (
        <Button type="button" color="primary" size="sm" onClick={() => verCurso(curso)}>
          Ver/Descargar certificado
        </Button>
      );
  }
};

interface ProgressOuterRimProps
  extends React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  progress: number;
}

const ProgressOuterRim = styled.div<ProgressOuterRimProps>`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${({ progress }) => `conic-gradient(transparent 0%, transparent ${
    progress * 100
  }%, #fff ${progress * 100}%, #fff 100%),
    radial-gradient(at 0px 0px, rgb(255, 255, 255) 0%, rgb(17, 85, 204) 100%)`};
  border: 1px solid #595959;
  transition: all 0.5s ease-in-out;
`;

const ProgressCenter = styled.div`
  margin: 15px;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 1px solid #595959;
  background: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
`;

const FormacionYTomaDeConciencia: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual, authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [vistaActual, setVistaActual] = useState<Vistas>(Vistas.CursosAsignados);
  const [cursos, setCursos] = useState<Curso[]>([]);
  const [cursoSeleccionado, setCursoSeleccionado] = useState<Curso | null>(null);
  const [lmsHost, setLmsHost] = useState<string>("");
  const [progress] = useState<number>(0.75);
  const [asignarCursoModalIsOpen, setAsignarCursoModalIsOpen] = useState<boolean>(false);
  const [alreadyFetched, setAlreadyFetched] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const toggleAsignarCursoModal = () => setAsignarCursoModalIsOpen(!asignarCursoModalIsOpen);

  const handleAsignarCursoClick = ({ id }: Curso) => {
    const curso = cursos.find((c) => c.id === id);
    if (!curso) return;
    setCursoSeleccionado(curso);
    toggleAsignarCursoModal();
  };

  const handleAsignarCursosSubmit = (vinculaciones: Vinculacion[]) => {
    if (!authToken || !cursoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    const vinculacionesIds = vinculaciones.map((v) => v._id);
    asignarCurso(authToken, cursoSeleccionado._id, vinculacionesIds)
      .then(toggleAsignarCursoModal)
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const verCurso = ({ id }: Curso) => {
    if (!authToken) return;
    fetchCurso(authToken, id).then(({ data }) => {
      console.log("verCurso", data);
      alert("Funcionalidad en desarrollo");
    });
  };

  useEffect(() => setTituloDeLaPantallaActual(PATHS.formacion.name), [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    setAlreadyFetched(false);
    setCursos([]);
    switch (vistaActual) {
      case Vistas.CursosAsignados:
        listCursosAsignados(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
      case Vistas.CursosDeLaEmpresa:
        listCursosDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
      case Vistas.CursosLibres:
        listCursos(authToken)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
      case Vistas.Historial:
        listHistorialDeCursos(authToken)
          .then(({ data }) => setCursos(data.map((curso) => new Curso(curso))))
          .finally(() => {
            setLoadingCounter((prev) => prev - 1);
            setAlreadyFetched(true);
          });
        break;
    }
  }, [vistaActual, authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    if (!authToken) return;
    fetchHost(authToken).then(({ data }) => setLmsHost(data.host));
  }, [authToken]);

  useEffect(() => {
    if (location.hash) {
      setVistaActual(location.hash.substring(1) as Vistas);
    }
  }, [location.hash]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      <Row>
        <Col>
          <TableContainer className="mb-3">
            <Nav tabs className="flex-nowrap">
              {Object.values(Vistas).map((tab) => (
                <NavItem key={tab}>
                  <NavLink
                    active={vistaActual === tab}
                    onClick={() => setVistaActual(tab)}
                    href={`#${tab}`}
                    className="text-nowrap"
                  >
                    {textoDeVista[tab]}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </TableContainer>
        </Col>
        <Col xs="auto" className="mb-1 d-none">
          <ProgressOuterRim progress={progress}>
            <ProgressCenter>
              <div className="d-flex flex-column h-100 justify-content-center text-center">
                <NumericFormat
                  value={progress * 100}
                  displayType="text"
                  decimalScale={0}
                  suffix="%"
                />
              </div>
            </ProgressCenter>
          </ProgressOuterRim>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableCaption>Requisitos de Formación</TableCaption>
        </Col>
      </Row>
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>ID</th>
              <th>Tema del curso (Código)</th>
              <th>Plan de acción</th>
              <th>Requisito legal</th>
              <th>Modalidad</th>
              <th>Objetivo</th>
              <th>Contenido</th>
              <th>Duración (horas)</th>
              <th>Frecuencia</th>
              <th>Evaluación de efectividad</th>
              <th>Cargo</th>
              <th>Fecha de vencimiento</th>
              <th>Avance</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cursos.map((curso) => (
              <tr key={curso.id}>
                <td>{curso.id}</td>
                <td>
                  {curso.tema} ({curso.codigo})
                </td>
                <td>
                  {curso.planDeAccion ? (
                    (curso.planDeAccion as PlanDeAccion).descripcion
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
                <td>
                  {curso.requisitosLegales.length > 0 ? (
                    curso.requisitosLegales.map((requisito) => (
                      <a
                        href={procesarLinkDeDocumento((requisito as RequisitoLegal).filepath)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {(requisito as RequisitoLegal).nombre}
                      </a>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
                <td>{textoDeModalidad(curso.modalidad)}</td>
                <td>{curso.objetivo}</td>
                {curso.contenidoEsHTML ? (
                  <td dangerouslySetInnerHTML={{ __html: curso.contenido }} />
                ) : (
                  <td>{curso.contenido}</td>
                )}
                <td>{curso.duracionEnHoras}</td>
                <td>{textoDeFrecuencia(curso.frecuencia)}</td>
                <td>{curso.evaluacionEfectividad ? "Sí" : "No"}</td>
                <td>{curso.cargos.map((cargo) => (cargo as Cargo).cargo).join(", ")}</td>
                <td>
                  {curso.fechaDeVencimiento ? shortDateToString(curso.fechaDeVencimiento) : ""}
                </td>
                <td>{curso.avance}</td>
                <td>
                  <BotonesDeAccion
                    curso={curso}
                    vistaActual={vistaActual}
                    lmsHost={lmsHost}
                    asignarCurso={handleAsignarCursoClick}
                    verCurso={verCurso}
                  />
                </td>
              </tr>
            ))}
            {cursos.length === 0 && alreadyFetched && (
              <tr>
                <td colSpan={14} className="text-center">
                  <TableMessage>No hay cursos disponibles</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Row>
        <Col xs="auto">
          <LinkContainer to={PATHS.cursos.to}>
            <Button type="button" color="primary">
              Gestionar cursos
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Modal size="lg" isOpen={asignarCursoModalIsOpen} toggle={toggleAsignarCursoModal}>
        <ModalHeader toggle={toggleAsignarCursoModal}>Asignar curso</ModalHeader>
        <ModalBody>
          <BusquedaDeUsuarios
            formId={USUARIOS_FORM_ID}
            curso={cursoSeleccionado as Curso}
            onSubmit={handleAsignarCursosSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={USUARIOS_FORM_ID}>
            Asignar
          </Button>
          <Button color="secondary" onClick={toggleAsignarCursoModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FormacionYTomaDeConciencia;
