import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ClampLines from "react-clamp-lines";
import { FaLinkSlash } from "react-icons/fa6";
import { FaClipboardCheck, FaEdit, FaFolderOpen, FaTrash } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import {
  CustomTable,
  TableContainer,
  TableMessage,
  abrirEnlaceEnNuevaPestaña,
  obtenerURLCompleta,
  shortDateToString,
} from "../../../utilities/utils";
import { Context } from "../../Context";
import {
  RequisitoLegal,
  textoClasificacion,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import {
  desvincularDocumentoLegal,
  eliminarRequisitoLegal,
  listarRequisitosLegales,
  listarRequisitosLegalesInternos,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import EditarRequisitoLegalForm from "./EditarRequisitoLegalForm";
import { PATHS, procesarPath } from "../../../utilities/Paths";

const EDITAR_REQUISITO_LEGAL_MODAL_ID = "editarRequisitoLegalModal";

interface Props {
  internos?: boolean;
}

const TablaDeRequisitosLegales: FC<Props> = ({ internos }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [requisitosLegales, setRequisitosLegales] = useState<RequisitoLegal[]>([]);
  const [requisitoLegalSeleccionado, setRequisitoLegalSeleccionado] =
    useState<RequisitoLegal | null>(null);
  const [confirmarEliminarModalIsOpen, setConfirmarEliminarModalIsOpen] = useState<boolean>(false);
  const [confirmarDesvincularModalIsOpen, setConfirmarDesvincularModalIsOpen] =
    useState<boolean>(false);
  const [editarRequisitoLegalModalIsOpen, setEditarRequisitoLegalModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cargarRequisitosLegales = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const requisitosLegales = data.map((requisitoLegal) => new RequisitoLegal(requisitoLegal));
        setRequisitosLegales(requisitosLegales);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const cargarRequisitosLegalesInternos = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarRequisitosLegalesInternos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const requisitosLegales = data.map((requisitoLegal) => new RequisitoLegal(requisitoLegal));
        setRequisitosLegales(requisitosLegales);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const toggleConfirmarEliminarModal = () =>
    setConfirmarEliminarModalIsOpen(!confirmarEliminarModalIsOpen);

  const handleEliminarClick = (id: string) => {
    const requisitoLegal = requisitosLegales.find((requisitoLegal) => requisitoLegal._id === id);
    if (!requisitoLegal) return;
    setRequisitoLegalSeleccionado(requisitoLegal);
    toggleConfirmarEliminarModal();
  };

  const handleConfirmarEliminarClick = () => {
    if (!authToken || !requisitoLegalSeleccionado) return;
    setIsLoading(true);
    eliminarRequisitoLegal(authToken, requisitoLegalSeleccionado._id)
      .then(() => {
        if (internos) {
          cargarRequisitosLegalesInternos();
        } else {
          cargarRequisitosLegales();
        }
        toggleConfirmarEliminarModal();
      })
      .catch(() => setIsLoading(false));
  };

  const toggleConfirmarDesvincularModal = () =>
    setConfirmarDesvincularModalIsOpen(!confirmarDesvincularModalIsOpen);

  const handleDesvincularClick = (id: string) => {
    const requisitoLegal = requisitosLegales.find((requisitoLegal) => requisitoLegal._id === id);
    if (!requisitoLegal) return;
    setRequisitoLegalSeleccionado(requisitoLegal);
    toggleConfirmarDesvincularModal();
  };

  const handleConfirmarDesvincularClick = () => {
    if (!authToken || !requisitoLegalSeleccionado) return;
    setIsLoading(true);
    desvincularDocumentoLegal(authToken, requisitoLegalSeleccionado._id)
      .then(() => {
        if (internos) {
          cargarRequisitosLegalesInternos();
        } else {
          cargarRequisitosLegales();
        }
        toggleConfirmarDesvincularModal();
      })
      .catch(() => setIsLoading(false));
  };

  const toggleEditarRequisitoLegalModal = () =>
    setEditarRequisitoLegalModalIsOpen(!editarRequisitoLegalModalIsOpen);

  const handleEditarRequisitoLegalClick = (id: string) => {
    const requisitoLegal = requisitosLegales.find((requisitoLegal) => requisitoLegal._id === id);
    if (!requisitoLegal) return;
    setRequisitoLegalSeleccionado(requisitoLegal);
    toggleEditarRequisitoLegalModal();
  };

  const handleEditarSuccess = () => {
    toggleEditarRequisitoLegalModal();
    if (internos) {
      cargarRequisitosLegalesInternos();
    } else {
      cargarRequisitosLegales();
    }
  };

  const limpiarSeleccion = () => {
    setRequisitoLegalSeleccionado(null);
  };

  useEffect(() => {
    setRequisitosLegales([]);
    if (internos) {
      cargarRequisitosLegalesInternos();
    } else {
      cargarRequisitosLegales();
    }
  }, [internos, cargarRequisitosLegales, cargarRequisitosLegalesInternos]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Requisito Legal</th>
              <th>Tipo</th>
              <th>Autoridad</th>
              <th>Clasificación</th>
              <th>Fecha de emisión</th>
              {!internos && <th>Fecha de vinculación</th>}
              <th>Estados</th>
              <th>Fecha límite de análisis</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {requisitosLegales.map(
              (
                {
                  _id,
                  nombre,
                  filepath,
                  tipo,
                  autoridad,
                  clasificacion,
                  fechaDeEmision,
                  fechaDeVinculacion,
                  estado,
                  fechaLimiteDeAnalisis,
                  documentoLegal,
                },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>
                    <Button
                      type="button"
                      color="link"
                      className="p-0 text-start small"
                      onClick={() => abrirEnlaceEnNuevaPestaña(filepath)}
                      title={obtenerURLCompleta(filepath)}
                    >
                      <ClampLines
                        text={nombre}
                        id={`requisito-legal-${_id}`}
                        lines={2}
                        buttons={false}
                      />
                    </Button>
                  </td>
                  <td>{textoTipoDeRequisitoLegal(tipo)}</td>
                  <td>{autoridad.nombre}</td>
                  <td>{textoClasificacion(clasificacion)}</td>
                  <td>{fechaDeEmision && shortDateToString(fechaDeEmision)}</td>
                  {!internos && (
                    <td>{fechaDeVinculacion && shortDateToString(fechaDeVinculacion)}</td>
                  )}
                  <td>{estado}</td>
                  <td>{fechaLimiteDeAnalisis && shortDateToString(fechaLimiteDeAnalisis)}</td>
                  <td>
                    <Row className="gx-0 flex-nowrap">
                      <Col xs="auto">
                        <LinkContainer
                          to={procesarPath(PATHS.requisitoLegal, _id)}
                          state={{ prevHash: internos ? "#internos" : "#todos" }}
                        >
                          <Button type="button" color="link" size="sm" title="Ver">
                            <FaFolderOpen size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <Button
                          type="button"
                          color="link"
                          size="sm"
                          title="Editar"
                          onClick={() => handleEditarRequisitoLegalClick(_id)}
                        >
                          <FaEdit size={15} />
                        </Button>
                      </Col>
                      <Col xs="auto">
                        <LinkContainer
                          to={procesarPath(PATHS.analisisDeRequisitoLegal, _id)}
                          state={{ prevHash: internos ? "#internos" : "#todos" }}
                        >
                          <Button type="button" color="link" size="sm" title="Análisis">
                            <FaClipboardCheck size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        {!!documentoLegal ? (
                          <Button
                            type="button"
                            color="danger"
                            outline
                            size="sm"
                            className="border-0"
                            title="Desvincular"
                            onClick={() => handleDesvincularClick(_id)}
                          >
                            <FaLinkSlash size={15} />
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            color="danger"
                            outline
                            size="sm"
                            className="border-0"
                            title="Eliminar"
                            onClick={() => handleEliminarClick(_id)}
                          >
                            <FaTrash size={15} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {requisitosLegales.length === 0 && (
              <tr>
                <td colSpan={internos ? 10 : 11} className="text-center">
                  <TableMessage>No hay requisitos legales registrados</TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Modal
        isOpen={confirmarEliminarModalIsOpen}
        toggle={toggleConfirmarEliminarModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarEliminarModal}>Confirmar eliminación</ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro que deseas eliminar el requisito legal{" "}
            <b>{requisitoLegalSeleccionado?.nombre}</b>?
          </p>
          <p className="text-muted">Esta acción no se puede deshacer.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmarEliminarClick}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={toggleConfirmarEliminarModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarDesvincularModalIsOpen}
        toggle={toggleConfirmarDesvincularModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarDesvincularModal}>Confirmar desvinculación</ModalHeader>
        <ModalBody>
          <p>
            ¿Estás seguro que deseas desvincular el requisito legal{" "}
            <b>{requisitoLegalSeleccionado?.nombre}</b>?
          </p>
          <p className="text-muted">Esta acción no se puede deshacer.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleConfirmarDesvincularClick}>
            Desvincular
          </Button>
          <Button color="secondary" onClick={toggleConfirmarDesvincularModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={editarRequisitoLegalModalIsOpen}
        toggle={toggleEditarRequisitoLegalModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleEditarRequisitoLegalModal}>Editar requisito legal</ModalHeader>
        <ModalBody>
          <EditarRequisitoLegalForm
            requisitoLegal={requisitoLegalSeleccionado as RequisitoLegal}
            formId={EDITAR_REQUISITO_LEGAL_MODAL_ID}
            onSuccess={handleEditarSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={EDITAR_REQUISITO_LEGAL_MODAL_ID}>
            Guardar cambios
          </Button>
          <Button color="secondary" onClick={toggleEditarRequisitoLegalModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TablaDeRequisitosLegales;
