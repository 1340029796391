import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import tiposDeConsecuencia from "../../../../resources/tipos-de-consecuencia.json";
import tiposDeExposicion from "../../../../resources/tipos-de-exposicion.json";
import tiposDeProbabilidad from "../../../../resources/tipos-de-probabilidad.json";
import todosLosPeligros from "../../../../resources/peligros.json";
import {
  TipoDeConsecuencia,
  TipoDeExposicion,
  TipoDeProbabilidad,
} from "../../../../models/Riesgo";
import { Actividad, Peligro } from "../../../../models/Actividad";
import { TiposDePeligro } from "../Actividad/FormularioDeActividad";
import { RequisitoLegal } from "../../../../models/RequisitoLegal";
import {
  EvaluacionDeRiesgo,
  EvaluacionDeRiesgoPayload,
} from "../../../../models/EvaluacionDeRiesgo";

interface FormularioEditarRiesgoProps {
  formId: string;
  actividad: Actividad;
  requisitoLegales: RequisitoLegal[];
  peligrosEvaluados: EvaluacionDeRiesgo[];
  onSubmit: (body: EvaluacionDeRiesgoPayload, evaluacionId: string, callback: () => void) => void;
}

const FormularioDeEditarRiesgo: FC<FormularioEditarRiesgoProps> = ({
  formId,
  actividad,
  requisitoLegales,
  peligrosEvaluados,
  onSubmit,
}) => {
  const [descripcionDeRiesgo, setDescripcionDeRiesgo] = useState<string>("");
  const [medidasDeControl, setMedidasDeControl] = useState<string>("");
  const [tipoDePeligro, setTipoDePeligro] = useState<number>(0);
  const [peligro, setPeligro] = useState<Peligro | undefined>();
  const [peligros, setPeligros] = useState<Peligro[]>([]);
  const [tiposDePeligro, setTiposDePeligro] = useState<TiposDePeligro[]>([]);
  const [requisitoLegal, setRequisitoLegal] = useState<string>("");
  const [evaluacionId, setEvaluacionId] = useState<string>("");
  const [tipoDeConsecuencia, setTipoDeConsecuencia] = useState<TipoDeConsecuencia | undefined>();
  const [tipoDeExposicion, setTipoDeExposicion] = useState<TipoDeExposicion | undefined>();
  const [tipoDeProbabilidad, setTipoDeProbabilidad] = useState<TipoDeProbabilidad | undefined>();

  const handleClear = () => {
    setDescripcionDeRiesgo("");
    setMedidasDeControl("");
    setRequisitoLegal("");
    setTipoDeConsecuencia(undefined);
    setTipoDeExposicion(undefined);
    setTipoDeProbabilidad(undefined);
    setTipoDePeligro(0);
    setPeligros([]);
    setPeligro(undefined);
  };

  const handleTipoDeExposicionChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const exposicionId = target.value;
    const exposicion = tiposDeExposicion.find(({ id }) => id === parseInt(exposicionId));
    if (exposicion) setTipoDeExposicion(exposicion);
  };

  const handleTipoDeConsecuenciaChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const consecuenciaId = target.value;
    const consecuencia = tiposDeConsecuencia.find(({ id }) => id === parseInt(consecuenciaId));
    if (consecuencia) setTipoDeConsecuencia(consecuencia);
  };

  const handleTipoDeProbabilidadChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const probabilidadId = target.value;
    const probabilidad = tiposDeProbabilidad.find(({ id }) => id === parseInt(probabilidadId));
    if (probabilidad) setTipoDeProbabilidad(probabilidad);
  };

  const handleTipoDePeligroChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const tipoDePeligro = target.value;
    const peligros = getPeligrosPorTipo(parseInt(tipoDePeligro));
    setTipoDePeligro(parseInt(tipoDePeligro));
    setPeligros(peligros);
    setPeligro(peligros[0]);
    cargarInformacionDeLaEvaluacionDeRiesgo(peligros[0], parseInt(tipoDePeligro));
  };

  const getPeligrosPorTipo = (tipoDePeligro: number) => {
    const {
      peligrosFisicos,
      peligrosBiologicos,
      peligrosDeSeguridad,
      peligrosErgonomicos,
      peligrosPsicosociales,
      peligrosQuimicos,
    } = actividad;
    switch (tipoDePeligro) {
      case TiposDePeligro.PeligroFisico:
        return peligrosFisicos || [];
      case TiposDePeligro.PeligrosQuimico:
        return peligrosQuimicos || [];
      case TiposDePeligro.PeligrosBiologico:
        return peligrosBiologicos || [];
      case TiposDePeligro.PeligrosErgonomicos:
        return peligrosErgonomicos || [];
      case TiposDePeligro.PeligrosPsicosocial:
        return peligrosPsicosociales || [];
      case TiposDePeligro.PeligrosDeSeguridad:
        return peligrosDeSeguridad || [];
      default:
        return [];
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tipoDeConsecuencia || !tipoDeExposicion || !tipoDeProbabilidad) return;
    onSubmit(
      {
        riesgo: descripcionDeRiesgo,
        actualesMedidasDeControl: medidasDeControl,
        tipoDePeligroId: tipoDePeligro,
        tipoDePeligro:
          todosLosPeligros.find((peligro) => peligro.id === tipoDePeligro)?.tipoDePeligro || "",
        peligroId: peligro?.id || 0,
        nombreDePeligro: peligro?.nombre || "",
        exposicion: tipoDeExposicion,
        probabilidad: tipoDeProbabilidad,
        consecuencia: tipoDeConsecuencia,
        calificacionDeRiesgo:
          tipoDeExposicion.valor * tipoDeProbabilidad.valor * tipoDeConsecuencia.valor,
        requisitoLegal: requisitoLegal || undefined,
      },
      evaluacionId,
      handleClear
    );
  };

  const hayPeligrosEvaluados = useCallback(
    (tipo: TiposDePeligro) => {
      const evaluados = peligrosEvaluados.some(({ tipoDePeligroId }) => tipoDePeligroId === tipo);
      return evaluados;
    },
    [peligrosEvaluados]
  );

  const cargarTiposDePeligro = useCallback(() => {
    if (!actividad) return;
    const {
      peligrosFisicos,
      peligrosBiologicos,
      peligrosDeSeguridad,
      peligrosErgonomicos,
      peligrosPsicosociales,
      peligrosQuimicos,
    } = actividad;
    const tiposDePeligro: TiposDePeligro[] = [];

    if (
      peligrosFisicos &&
      peligrosFisicos.length > 0 &&
      hayPeligrosEvaluados(TiposDePeligro.PeligroFisico)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligroFisico);
    }
    if (
      peligrosQuimicos &&
      peligrosQuimicos.length > 0 &&
      hayPeligrosEvaluados(TiposDePeligro.PeligrosQuimico)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosQuimico);
    }
    if (
      peligrosBiologicos &&
      peligrosBiologicos.length > 0 &&
      hayPeligrosEvaluados(TiposDePeligro.PeligrosBiologico)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosBiologico);
    }
    if (
      peligrosErgonomicos &&
      peligrosErgonomicos.length > 0 &&
      hayPeligrosEvaluados(TiposDePeligro.PeligrosErgonomicos)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosErgonomicos);
    }
    if (
      peligrosPsicosociales &&
      peligrosPsicosociales.length > 0 &&
      hayPeligrosEvaluados(TiposDePeligro.PeligrosPsicosocial)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosPsicosocial);
    }
    if (
      peligrosDeSeguridad &&
      peligrosDeSeguridad.length > 0 &&
      hayPeligrosEvaluados(TiposDePeligro.PeligrosDeSeguridad)
    ) {
      tiposDePeligro.push(TiposDePeligro.PeligrosDeSeguridad);
    }

    setTiposDePeligro(tiposDePeligro);
  }, [actividad, hayPeligrosEvaluados]);

  const handlePeligroChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    const _peligro = peligros.find(({ id }) => id === parseInt(peligroId));

    if (_peligro) {
      setPeligro(_peligro);
      cargarInformacionDeLaEvaluacionDeRiesgo(_peligro, tipoDePeligro);
    }
  };

  const peligroIdYaFueEvaluado = (id: number) => {
    return !!peligrosEvaluados.find(({ peligroId }) => peligroId === id);
  };

  const cargarInformacionDeLaEvaluacionDeRiesgo = (
    currentPeligro: Peligro,
    currentTipoPeligro: TiposDePeligro
  ) => {
    if (!currentTipoPeligro || !currentPeligro) return;
    const evaluacionDeRiesgo = peligrosEvaluados.find(
      ({ peligroId, tipoDePeligroId }) =>
        peligroId === currentPeligro?.id && tipoDePeligroId === currentTipoPeligro
    );
    setEvaluacionId(evaluacionDeRiesgo?._id || "");
    setDescripcionDeRiesgo(evaluacionDeRiesgo?.riesgo || "");
    setMedidasDeControl(evaluacionDeRiesgo?.actualesMedidasDeControl || "");
    setRequisitoLegal(evaluacionDeRiesgo?.requisitoLegal?.toString() || "");
    setTipoDeConsecuencia(evaluacionDeRiesgo?.consecuencia);
    setTipoDeExposicion(evaluacionDeRiesgo?.exposicion);
    setTipoDeProbabilidad(evaluacionDeRiesgo?.probabilidad);
  };

  useEffect(cargarTiposDePeligro, [cargarTiposDePeligro]);

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="tipoDePeligro"
                placeholder="Tipo de Peligro"
                value={tipoDePeligro === 0 ? "" : tipoDePeligro}
                onChange={handleTipoDePeligroChange}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDePeligro.map((item) => (
                  <option value={item}>
                    {todosLosPeligros.find(({ id }) => id === item)?.tipoDePeligro}
                  </option>
                ))}
              </Input>
              <Label for="tipoDePeligro">Tipo de Peligro</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="peligro"
                placeholder="Peligro"
                value={peligro?.id || ""}
                onChange={handlePeligroChange}
                required
                disabled={!tipoDePeligro}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {peligros.map(({ nombre, id }) => (
                  <option
                    value={id}
                    className={classNames({ "d-none": !peligroIdYaFueEvaluado(id) })}
                  >
                    {nombre}
                  </option>
                ))}
              </Input>
              <Label for="peligro">Peligro</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                name="descripcionDelRiesgo"
                placeholder="Descripción del riesgo"
                value={descripcionDeRiesgo}
                onChange={({ target }) => setDescripcionDeRiesgo(target.value)}
                required
                style={{ height: "138px" }}
                disabled={!peligro}
              />
              <Label for="descripcionDelRiesgo">Descripción del riesgo</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                name="medidas-de-control"
                placeholder="Actuales Medidas de Control"
                value={medidasDeControl}
                onChange={({ target }) => setMedidasDeControl(target.value)}
                required
                style={{ height: "138px" }}
                disabled={!peligro}
              />
              <Label for="medidas-de-control">Actuales Medidas de Control</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="requisito-legal"
                placeholder="Requisito Legal u Otro Requisito"
                value={requisitoLegal}
                onChange={({ target }) => setRequisitoLegal(target.value)}
                disabled={!peligro}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {requisitoLegales.map(({ _id, nombre }) => (
                  <option key={_id} value={_id}>
                    {nombre}
                  </option>
                ))}
              </Input>
              <Label for="equisito-legal">Requisito Legal u Otro Requisito</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="exposicion"
                placeholder="Exposición"
                value={tipoDeExposicion?.id}
                onChange={handleTipoDeExposicionChange}
                required
                disabled={!peligro}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDeExposicion.map(({ name, id, valor }) => (
                  <option key={id} value={id}>
                    {name} ({valor})
                  </option>
                ))}
              </Input>
              <Label for="exposicion">Exposición</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="probabilidad"
                placeholder="Probabilidad"
                value={tipoDeProbabilidad?.id}
                onChange={handleTipoDeProbabilidadChange}
                required
                disabled={!peligro}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDeProbabilidad.map(({ name, id, valor }) => (
                  <option key={id} value={id}>
                    {name} ({valor})
                  </option>
                ))}
              </Input>
              <Label for="probabilidad">Probabilidad</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="consecuencia"
                placeholder="Consecuencia"
                value={tipoDeConsecuencia?.id}
                onChange={handleTipoDeConsecuenciaChange}
                required
                disabled={!peligro}
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {tiposDeConsecuencia.map(({ name, id, valor }) => {
                  return (
                    <option key={id} value={id}>
                      {name} ({valor})
                    </option>
                  );
                })}
              </Input>
              <Label for="consecuencia">Consecuencia</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormularioDeEditarRiesgo;
