import axios from "axios";

interface LoginPayload {
  correo: string;
  contrasena: string;
}

interface LoginResponse {
  token: string;
}

export const login = (data: LoginPayload) => {
  return axios.post<LoginResponse>("/api/login", data);
};

export const adminLogin = (data: LoginPayload) => {
  return axios.post<LoginResponse>("/api/login/admin", data);
};

interface RegistroPayload {
  creado: boolean;
  mensaje: string;
  autorizado: boolean;
}

export const nuevoRegistro = (body: any) => {
  return axios.post<RegistroPayload>("/api/register", body);
};

export const restablecerContrasena = (correo: string) => {
  return axios.post("/api/restablecer-contrasena", {
    correo,
  });
};

export const confirmarCorreo = (token: string) => {
  return axios.get<{ valido: boolean }>("/api/confirmar-correo", {
    params: {
      token,
    },
  });
};

export const logout = () => {
  return axios.post("/api/logout");
};
