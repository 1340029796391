import { FC, useState, FormEvent, useEffect, useContext } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { FaPlus, FaUser } from "react-icons/fa";
import { Vinculacion } from "../../../models/Vinculacion";
import {
  UsuarioNoRegistrado,
  UsuarioNoRegistradoResponse,
} from "../../../models/UsuarioNoRegistrado";
import { CustomTable, IconsContainer } from "../../../utilities/utils";
import { Context } from "../../Context";
import { listUsuariosNoRegistrados } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Afectado } from "../../../models/Afectado";
import Checkbox from "../../../utilities/Checkbox";
import { PartesAfectadas, textoDePartesAfectadas } from "../../../models/Incidente";

interface BusquedaDeAfectadosProps {
  formId: string;
  afectadosPreseleccionados: Afectado[];
  vinculaciones: Vinculacion[];
  isCreating: boolean;
  onCreateToggle: () => void;
  onSubmit: (afectadosSeleccionados: Afectado[]) => void;
}

const BusquedaDeAfectados: FC<BusquedaDeAfectadosProps> = ({
  formId,
  afectadosPreseleccionados,
  vinculaciones,
  isCreating,
  onCreateToggle,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [afectadosSeleccionados, setAfectadosSeleccionados] =
    useState<Afectado[]>(afectadosPreseleccionados);
  const [afectadosFiltrados, setAfectadosFiltrados] = useState<Afectado[]>([]);
  const [afectados, setAfectados] = useState<Afectado[]>([]);
  const [busqueda, setBusqueda] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleTodos = () => {
    if (afectadosSeleccionados.length === afectadosFiltrados.length) {
      setAfectadosSeleccionados([]);
    } else {
      setAfectadosSeleccionados(afectados);
    }
  };

  const toggleUsuario = (nuevoTestigo: Afectado) => {
    if (afectadosSeleccionados.some((a) => a.toString() === nuevoTestigo.toString())) {
      setAfectadosSeleccionados(
        afectadosSeleccionados.filter((a) => a.toString() !== nuevoTestigo.toString())
      );
    } else {
      setAfectadosSeleccionados([...afectadosSeleccionados, nuevoTestigo]);
    }
  };

  const handlePartesAfectadasToggle = (id: string, parteAfectada: PartesAfectadas) => {
    setAfectados(
      afectados.map((afectado) => {
        if (afectado.toString() === id) {
          if (parteAfectada === PartesAfectadas.Ninguna) {
            afectado.partesAfectadas = [PartesAfectadas.Ninguna];
          } else {
            let partesAfectadas = afectado.partesAfectadas.filter(
              (pa) => pa !== PartesAfectadas.Ninguna
            );
            if (partesAfectadas.includes(parteAfectada)) {
              partesAfectadas = partesAfectadas.filter((parte) => parte !== parteAfectada);
            } else {
              partesAfectadas = [...partesAfectadas, parteAfectada];
            }
            if (partesAfectadas.length === 0) {
              partesAfectadas = [PartesAfectadas.Ninguna];
            }
            afectado.partesAfectadas = partesAfectadas;
          }
        }
        return afectado;
      })
    );
  };

  const handleDetalleChange = (id: string, detalle: string) => {
    setAfectados(
      afectados.map((afectado) => {
        if (afectado.toString() === id) {
          afectado.detalle = detalle;
        }
        return afectado;
      })
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(afectadosSeleccionados);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || isCreating) return;
    setIsLoading(true);
    listUsuariosNoRegistrados(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const usuariosNoRegistrados = data.map(
          (usuarioNoRegistrado) =>
            new UsuarioNoRegistrado(usuarioNoRegistrado as UsuarioNoRegistradoResponse)
        );
        const combinedData = [...usuariosNoRegistrados, ...vinculaciones].map(
          (afectado) =>
            new Afectado({
              afectado,
              model: afectado instanceof Vinculacion ? "Vinculacion" : "UsuarioNoRegistrado",
              nombreCompleto: afectado.nombreCompleto,
              partesAfectadas: [PartesAfectadas.Ninguna],
              detalle: "",
              identificacion: "",
            })
        );
        setAfectados(
          combinedData.map((afectado) => {
            const preseleccionado = afectadosPreseleccionados.find(
              (a) => a.toString() === afectado.toString()
            );
            if (preseleccionado) return preseleccionado;
            return afectado;
          })
        );
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada, isCreating, vinculaciones, afectadosPreseleccionados]);

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setAfectadosFiltrados(
      afectados.filter((afectado) => {
        if (!afectado) return "";
        return (
          afectado.nombreCompleto.toLowerCase().includes(busquedaLower) ||
          afectado.identificacion.toLowerCase().includes(busquedaLower)
        );
      })
    );
  }, [afectados, busqueda]);

  useEffect(() => {
    const nuevosAfectados: Afectado[] = [...afectadosSeleccionados];
    afectadosPreseleccionados.forEach((afectado) => {
      const afectadoEncontrado = afectados.find((a) => a.toString() === afectado.toString());
      const yaSeleccionado = nuevosAfectados.some((a) => a.toString() === afectado.toString());
      if (afectadoEncontrado && !yaSeleccionado) {
        nuevosAfectados.push(afectado);
      }
    });
    setAfectadosSeleccionados(nuevosAfectados);
  }, [afectadosPreseleccionados, afectados]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="align-items-center">
        <Col xs="auto">
          <Label>Filtrar</Label>
        </Col>
        <div className="w-100 d-sm-none" />
        <Col>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Buscar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Buscar</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} id={formId}>
        <CustomTable>
          <thead>
            <tr>
              <th>
                <Checkbox
                  checked={afectadosSeleccionados.length === afectadosFiltrados.length}
                  disabled={afectadosFiltrados.length === 0}
                  toggle={toggleTodos}
                />
              </th>
              <th>Identificación</th>
              <th>Nombre</th>
              <th>Partes afectadas</th>
              <th>Detalle</th>
            </tr>
          </thead>
          <tbody>
            {afectadosFiltrados.map((afectado) => (
              <tr key={afectado.toString()}>
                <td className="text-center">
                  <Checkbox
                    checked={afectadosSeleccionados.some(
                      (v) => v.toString() === afectado.toString()
                    )}
                    toggle={() => toggleUsuario(afectado)}
                  />
                </td>
                <td>{afectado.identificacion}</td>
                <td>{afectado.nombreCompleto}</td>
                <td>
                  <UncontrolledDropdown className="text-center">
                    <DropdownToggle
                      color="primary"
                      caret
                      disabled={
                        !afectadosSeleccionados.some((v) => v.toString() === afectado.toString())
                      }
                    >
                      Partes afectadas
                    </DropdownToggle>
                    <DropdownMenu>
                      {Object.values(PartesAfectadas).map((parteAfectada) => (
                        <DropdownItem
                          key={parteAfectada}
                          toggle={false}
                          onClick={() =>
                            handlePartesAfectadasToggle(afectado.toString(), parteAfectada)
                          }
                          active={afectado.partesAfectadas.includes(parteAfectada)}
                        >
                          {textoDePartesAfectadas(parteAfectada)}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
                <td>
                  <Input
                    type="text"
                    name="detalle"
                    id="detalle"
                    placeholder="Detalle..."
                    value={afectado.detalle}
                    onChange={({ target }) =>
                      handleDetalleChange(afectado.toString(), target.value)
                    }
                    disabled={
                      !afectadosSeleccionados.some((v) => v.toString() === afectado.toString())
                    }
                  />
                </td>
              </tr>
            ))}
            {afectados.length === 0 ? (
              <tr>
                <td colSpan={6} className="text-center">
                  No hay usuarios registrados
                </td>
              </tr>
            ) : (
              afectadosFiltrados.length === 0 && (
                <tr>
                  <td colSpan={6} className="text-center">
                    No hay usuarios que coincidan con la búsqueda
                  </td>
                </tr>
              )
            )}
          </tbody>
        </CustomTable>
      </Form>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button
            type="button"
            color="primary"
            title="Agregar usuario no registrado"
            onClick={onCreateToggle}
          >
            <IconsContainer>
              <FaUser size={20} textAnchor="middle" alignmentBaseline="middle" />
              <FaPlus
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{
                  fontSize: ".5em",
                  position: "absolute",
                  left: "2.35em",
                  bottom: "2em",
                }}
              />
            </IconsContainer>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BusquedaDeAfectados;
