import { useState, FC, FormEvent, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { FaMinusCircle, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import {
  Incidente,
  IncidenteBase,
  TiposDeIncidente,
  textoDelTipoDeIncidente,
  EstadoACR,
  textoDelEstadoACR,
  textoDePartesAfectadas,
  PartesAfectadas,
} from "../../../models/Incidente";
import UsuarioNoRegistradoForm from "./UsuarioNoRegistradoForm";
import { Vinculacion } from "../../../models/Vinculacion";
import { Cargo } from "../../../models/Cargo";
import { Context } from "../../Context";
import { listCargos, fetchVinculaciones } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import BusquedaDeUsuarios from "./BusquedaDeUsuarios";
import BusquedaDeAfectados from "./BusquedaDeAfectados";
import BusquedaDeTestigos from "./BusquedaDeTestigos";
import { Afectado } from "../../../models/Afectado";
import { Testigo } from "../../../models/Testigo";
import classNames from "classnames";
import {
  dateInputToDate,
  dateToISOString,
  datetimeInputToDate,
  datetimeToISOString,
} from "../../../utilities/utils";
import { UsuarioNoRegistrado } from "../../../models/UsuarioNoRegistrado";

const PERSONAS_RESPONSABLES_FORM_ID = "personasResponsablesForm";
const AFECTADOS_FORM_ID = "afectadosForm";
const TESTIGOS_FORM_ID = "testigosForm";
const CREAR_USUARIO_NO_REGISTRADO_AFECTADO_FORM_ID = "usuarioNoRegistradoAfectadoForm";
const CREAR_USUARIO_NO_REGISTRADO_TESTIGO_FORM_ID = "usuarioNoRegistradoTestigoForm";

const UsuariosContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const fechahoraActual = datetimeToISOString(new Date());

interface IncidenteFormProps {
  formId: string;
  incidente?: Incidente;
  onSubmit: (incidente: IncidenteBase) => void;
}

const IncidenteForm: FC<IncidenteFormProps> = ({ formId, incidente, onSubmit }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tituloDelIncidente, setTituloDelIncidente] = useState<string>(
    incidente?.tituloDelIncidente || ""
  );
  const [descripcionDelIncidente, setDescripcionDelIncidente] = useState<string>(
    incidente?.descripcionDelIncidente || ""
  );
  const [tipoDeIncidente, setTipoDeIncidente] = useState<TiposDeIncidente | null>(
    incidente?.tipoDeIncidente || null
  );
  const [personasResponsables, setPersonasResponsables] = useState<Vinculacion[]>(
    incidente?.personasResponsables || []
  );
  const [testigos, setTestigos] = useState<Testigo[]>(incidente?.testigos || []);
  const [afectados, setAfectados] = useState<Afectado[]>([]);
  const [lugarDelIncidente, setLugarDelIncidente] = useState<string>(
    incidente?.lugarDelIncidente || ""
  );
  const [accionesInmediatas, setAccionesInmediatas] = useState<string>(
    incidente?.accionesInmediatas || ""
  );
  const [fechaOcurrenciaString, setFechaOcurrenciaString] = useState<string>(
    datetimeToISOString(incidente?.fechaOcurrencia) || fechahoraActual
  );
  const [fechaLimiteString, setFechaLimiteString] = useState<string>(
    dateToISOString(incidente?.fechaLimite)
  );
  const [estadoACR, setEstadoACR] = useState<EstadoACR | null>(incidente?.estadoACR || null);
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [cargos, setCargos] = useState<Cargo[]>([]);
  const [busquedaDeVinculacionesModalIsOpen, setBusquedaDeVinculacionesModalIsOpen] =
    useState<boolean>(false);
  const [busquedaDeAfectadosModalIsOpen, setBusquedaDeAfectadosModalIsOpen] =
    useState<boolean>(false);
  const [busquedaDeTestigosModalIsOpen, setBusquedaDeTestigosModalIsOpen] =
    useState<boolean>(false);
  const [crearUsuarioNoRegistradoIsOpen, setCrearUsuarioNoRegistradoIsOpen] =
    useState<boolean>(false);

  const toggleBusquedaDeVinculacionesModal = () =>
    setBusquedaDeVinculacionesModalIsOpen(!busquedaDeVinculacionesModalIsOpen);

  const toggleBusquedaDeAfectadosModal = () =>
    setBusquedaDeAfectadosModalIsOpen(!busquedaDeAfectadosModalIsOpen);

  const toggleBusquedaDeTestigosModal = () =>
    setBusquedaDeTestigosModalIsOpen(!busquedaDeTestigosModalIsOpen);

  const toggleCrearUsuarioNoRegistrado = () =>
    setCrearUsuarioNoRegistradoIsOpen(!crearUsuarioNoRegistradoIsOpen);

  const handlePersonasResponsablesChange = (vinculaciones: Vinculacion[]) => {
    setPersonasResponsables(vinculaciones);
    setBusquedaDeVinculacionesModalIsOpen(false);
  };

  const handleCrearAfectadoSuccess = (usuarioNoRegistrado: UsuarioNoRegistrado) => {
    const afectado = new Afectado({
      afectado: usuarioNoRegistrado,
      model: "UsuarioNoRegistrado",
      nombreCompleto: usuarioNoRegistrado.nombreCompleto,
      identificacion: usuarioNoRegistrado.identificacion,
      detalle: "",
      partesAfectadas: [PartesAfectadas.Ninguna],
    });
    setAfectados([...afectados, afectado]);
    setCrearUsuarioNoRegistradoIsOpen(false);
  };

  const handleAfectadosChange = (afectados: Afectado[]) => {
    setAfectados(afectados);
    toggleBusquedaDeAfectadosModal();
  };

  const handleCrearTestigoSuccess = (usuarioNoRegistrado: UsuarioNoRegistrado) => {
    const testigo = new Testigo({
      testigo: usuarioNoRegistrado,
      model: "UsuarioNoRegistrado",
      nombreCompleto: usuarioNoRegistrado.nombreCompleto,
    });
    setTestigos([...testigos, testigo]);
    setCrearUsuarioNoRegistradoIsOpen(false);
  };

  const handleTestigosChange = (testigos: Testigo[]) => {
    setTestigos(testigos);
    toggleBusquedaDeTestigosModal();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (tipoDeIncidente === null) return;

    const body: IncidenteBase = {
      tituloDelIncidente,
      descripcionDelIncidente,
      tipoDeIncidente,
      personasResponsables,
      testigos,
      lugarDelIncidente,
      afectados,
      fechaOcurrencia: datetimeInputToDate(fechaOcurrenciaString),
      fechaLimite: fechaLimiteString ? dateInputToDate(fechaLimiteString) : undefined,
      estadoACR: !!estadoACR ? estadoACR : EstadoACR.Pendiente,
      accionesInmediatas,
    };
    onSubmit(body);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    Promise.all([
      fetchVinculaciones(authToken, idDeLaEmpresaSeleccionada),
      listCargos(authToken, idDeLaEmpresaSeleccionada),
    ])
      .then(([vinculacionesResponse, cargosResponse]) => {
        const vinculaciones = vinculacionesResponse.data.map(
          (vinculacion) => new Vinculacion(vinculacion)
        );
        setVinculaciones(vinculaciones);
        setCargos(cargosResponse.data.map((cargo) => new Cargo(cargo)));
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    if (incidente) {
      setTituloDelIncidente(incidente.tituloDelIncidente);
      setDescripcionDelIncidente(incidente.descripcionDelIncidente);
      setTipoDeIncidente(incidente.tipoDeIncidente);
      setPersonasResponsables(incidente.personasResponsables);
      setTestigos(incidente.testigos);
      setAfectados(incidente.afectados);
      setLugarDelIncidente(incidente.lugarDelIncidente);
      setAccionesInmediatas(incidente.accionesInmediatas || "");
      setFechaOcurrenciaString(datetimeToISOString(incidente.fechaOcurrencia) || fechahoraActual);
      setFechaLimiteString(dateToISOString(incidente.fechaLimite));
      setEstadoACR(incidente.estadoACR);
    }
  }, [incidente]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="tituloDelIncidente"
            name="tituloDelIncidente"
            placeholder="Título del incidente"
            value={tituloDelIncidente}
            onChange={({ target }) => setTituloDelIncidente(target.value)}
            required
          />
          <Label for="tituloDelIncidente">Título del incidente</Label>
        </FormGroup>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                id="descripcionDelIncidente"
                name="descripcionDelIncidente"
                placeholder="Descripción del incidente"
                value={descripcionDelIncidente}
                onChange={({ target }) => setDescripcionDelIncidente(target.value)}
                style={{ height: 100 }}
                required
              />
              <Label for="descripcionDelIncidente">Descripción del incidente</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="textarea"
                id="accionesInmediatas"
                name="accionesInmediatas"
                placeholder="Acciones inmediatas"
                value={accionesInmediatas}
                onChange={({ target }) => setAccionesInmediatas(target.value)}
                style={{ height: 100 }}
                required
              />
              <Label for="accionesInmediaras">Acciones inmediatas</Label>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup floating>
          <Input
            type="textarea"
            id="lugarDelIncidente"
            name="lugarDelIncidente"
            placeholder="Lugar del incidente"
            value={lugarDelIncidente}
            onChange={({ target }) => setLugarDelIncidente(target.value)}
            style={{ height: 80 }}
            required
          />
          <Label for="lugarDelIncidente">Lugar del incidente</Label>
        </FormGroup>
        <Row className={classNames("row-cols-1", !!incidente ? "row-cols-md-3" : "row-cols-md-2")}>
          <Col>
            <FormGroup floating>
              <Input
                type="datetime-local"
                id="fechaOcurrencia"
                name="fechaOcurrencia"
                value={fechaOcurrenciaString}
                onChange={({ target }) => setFechaOcurrenciaString(target.value)}
                required
                max={datetimeToISOString(new Date())}
              />
              <Label for="fechaOcurrencia">Fecha de ocurrencia</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                id="tipoDeIncidente"
                name="tipoDeIncidente"
                value={tipoDeIncidente || ""}
                onChange={({ target }) => setTipoDeIncidente(target.value as TiposDeIncidente)}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {Object.values(TiposDeIncidente).map((value) => (
                  <option key={value} value={value}>
                    {textoDelTipoDeIncidente(value as TiposDeIncidente)}
                  </option>
                ))}
              </Input>
              <Label for="tipoDeIncidente">Tipo de incidente</Label>
            </FormGroup>
          </Col>
          {!!incidente && (
            <Col>
              <FormGroup floating>
                <Input
                  type="select"
                  id="estadoACR"
                  name="estadoACR"
                  value={estadoACR || "pendiente"}
                  onChange={({ target }) => setEstadoACR(target.value as EstadoACR)}
                >
                  <option value="" disabled>
                    Seleccionar
                  </option>
                  {Object.values(EstadoACR).map((value) => (
                    <option key={value} value={value}>
                      {textoDelEstadoACR(value as EstadoACR)}
                    </option>
                  ))}
                </Input>
                <Label for="tipoDeIncidente">Estado de Análisis de Causa Raíz</Label>
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardBody className="pt-1">
                <Row className="justify-content-between">
                  <Col>
                    <Label className="small text-muted">Afectado(s)</Label>
                  </Col>
                  {afectados.length > 0 && (
                    <Col xs="auto">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        className="border-0"
                        title="Buscar"
                        onClick={toggleBusquedaDeAfectadosModal}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <UsuariosContainer>
                      {afectados.map((afectado) => (
                        <Row key={afectado.toString()} className="gx-0">
                          <Col>
                            <small>{afectado.nombreCompleto}</small>
                          </Col>
                          <Col>
                            <small>
                              {afectado.partesAfectadas
                                .map((pa) => textoDePartesAfectadas(pa))
                                .join(", ")}
                            </small>
                          </Col>
                          <Col>
                            <small>{afectado.detalle}</small>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setAfectados(
                                  afectados.filter((a) => a.toString() !== afectado.toString())
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {afectados.length === 0 && (
                        <>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Label className="small">No hay personas seleccionados</Label>
                            </Col>
                          </Row>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Button
                                color="primary"
                                size="sm"
                                className="mb-1"
                                onClick={toggleBusquedaDeAfectadosModal}
                              >
                                Agregar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </UsuariosContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardBody className="pt-1">
                <Row className="justify-content-between">
                  <Col>
                    <Label className="small text-muted">Testigo(s)</Label>
                  </Col>
                  {testigos.length > 0 && (
                    <Col xs="auto">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        className="border-0"
                        title="Buscar"
                        onClick={toggleBusquedaDeTestigosModal}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <UsuariosContainer>
                      {testigos.map((testigo) => (
                        <Row key={testigo.toString()} className="gx-0">
                          <Col>
                            <small>{testigo.nombreCompleto}</small>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setTestigos(
                                  testigos.filter((v) => v.toString() !== testigo.toString())
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {testigos.length === 0 && (
                        <>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Label className="small">No hay personas seleccionados</Label>
                            </Col>
                          </Row>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Button
                                color="primary"
                                size="sm"
                                className="mb-1"
                                onClick={toggleBusquedaDeTestigosModal}
                              >
                                Agregar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </UsuariosContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <FormGroup floating>
          <Input
            type="date"
            id="fechaLimite"
            name="fechaLimite"
            placeholder="Fecha límite de ACR"
            value={!!fechaLimiteString ? fechaLimiteString : ""}
            onChange={({ target }) => setFechaLimiteString(target.value)}
          />
          <Label for="fechaLimite">Fecha limite de ACR</Label>
        </FormGroup>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardBody className="pt-1">
                <Row className="justify-content-between">
                  <Col>
                    <Label className="small text-muted">Persona(s) responsable(s) de ACR</Label>
                  </Col>
                  {personasResponsables.length > 0 && (
                    <Col xs="auto">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        className="border-0"
                        title="Buscar"
                        onClick={toggleBusquedaDeVinculacionesModal}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <UsuariosContainer>
                      {personasResponsables.map((vinculacion) => (
                        <Row key={vinculacion._id} className="gx-0">
                          <Col>
                            <small>{vinculacion.nombreCompleto}</small>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setPersonasResponsables(
                                  personasResponsables.filter((v) => v._id !== vinculacion._id)
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {personasResponsables.length === 0 && (
                        <>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Label className="small">No hay personas seleccionados</Label>
                            </Col>
                          </Row>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Button
                                color="primary"
                                size="sm"
                                className="mb-1"
                                onClick={toggleBusquedaDeVinculacionesModal}
                              >
                                Agregar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </UsuariosContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
      <Row className="mb-5" />
      <Modal
        size="lg"
        isOpen={busquedaDeVinculacionesModalIsOpen}
        toggle={toggleBusquedaDeVinculacionesModal}
      >
        <ModalHeader toggle={toggleBusquedaDeVinculacionesModal}>Buscar usuarios</ModalHeader>
        <ModalBody>
          <BusquedaDeUsuarios
            formId={PERSONAS_RESPONSABLES_FORM_ID}
            vinculacionesPreseleccionadas={personasResponsables}
            vinculaciones={vinculaciones}
            cargos={cargos}
            onSubmit={handlePersonasResponsablesChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={PERSONAS_RESPONSABLES_FORM_ID}>
            Aceptar
          </Button>
          <Button color="secondary" onClick={toggleBusquedaDeVinculacionesModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={busquedaDeAfectadosModalIsOpen}
        toggle={toggleBusquedaDeAfectadosModal}
      >
        <Collapse isOpen={!crearUsuarioNoRegistradoIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeAfectadosModal}>Buscar personas</ModalHeader>
          <ModalBody>
            <BusquedaDeAfectados
              formId={AFECTADOS_FORM_ID}
              afectadosPreseleccionados={afectados}
              vinculaciones={vinculaciones}
              isCreating={crearUsuarioNoRegistradoIsOpen}
              onCreateToggle={toggleCrearUsuarioNoRegistrado}
              onSubmit={handleAfectadosChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={AFECTADOS_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleBusquedaDeAfectadosModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={crearUsuarioNoRegistradoIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeAfectadosModal}>Crear persona</ModalHeader>
          <ModalBody>
            <UsuarioNoRegistradoForm
              formId={CREAR_USUARIO_NO_REGISTRADO_AFECTADO_FORM_ID}
              onSuccess={handleCrearAfectadoSuccess}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              form={CREAR_USUARIO_NO_REGISTRADO_AFECTADO_FORM_ID}
            >
              Crear
            </Button>
            <Button
              type="reset"
              color="secondary"
              form={CREAR_USUARIO_NO_REGISTRADO_AFECTADO_FORM_ID}
              onClick={toggleCrearUsuarioNoRegistrado}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
      <Modal
        size="lg"
        isOpen={busquedaDeTestigosModalIsOpen}
        toggle={toggleBusquedaDeTestigosModal}
        onClosed={() => setCrearUsuarioNoRegistradoIsOpen(false)}
      >
        <Collapse isOpen={!crearUsuarioNoRegistradoIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeTestigosModal}>Buscar personas</ModalHeader>
          <ModalBody>
            <BusquedaDeTestigos
              formId={TESTIGOS_FORM_ID}
              testigosPreseleccionados={testigos}
              vinculaciones={vinculaciones}
              isCreating={crearUsuarioNoRegistradoIsOpen}
              onCreateToggle={toggleCrearUsuarioNoRegistrado}
              onSubmit={handleTestigosChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={TESTIGOS_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleBusquedaDeTestigosModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={crearUsuarioNoRegistradoIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeTestigosModal}>Crear persona</ModalHeader>
          <ModalBody>
            <UsuarioNoRegistradoForm
              formId={CREAR_USUARIO_NO_REGISTRADO_TESTIGO_FORM_ID}
              onSuccess={handleCrearTestigoSuccess}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              form={CREAR_USUARIO_NO_REGISTRADO_TESTIGO_FORM_ID}
            >
              Crear
            </Button>
            <Button
              type="reset"
              color="secondary"
              form={CREAR_USUARIO_NO_REGISTRADO_TESTIGO_FORM_ID}
              onClick={toggleCrearUsuarioNoRegistrado}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
    </>
  );
};
export default IncidenteForm;
