import { FC, FormEvent, useContext, useEffect, useState } from "react";
import {
  ClasificacionDelContexto,
  Contexto,
  ContextoPayload,
  textoDeClasificacionDelContexto,
} from "../../../models/Contexto";
import { Button, Form, FormGroup, Input, InputGroup, Label } from "reactstrap";
import { Context } from "../../Context";
import { TipoDeContexto } from "../../../models/TipoDecontexto";
import { listTiposDeContexto } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { FaPlus } from "react-icons/fa";

interface ContextoFormProps {
  formId: string;
  contexto?: Contexto;
  isOpen: boolean;
  toggleGestionarTiposDeContexto: () => void;
  onSubmit: (contextos: ContextoPayload) => void;
}

const ContextoForm: FC<ContextoFormProps> = ({
  formId,
  contexto,
  isOpen,
  toggleGestionarTiposDeContexto,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [tipo, setTipo] = useState<string>("");
  const [clasificacion, setClasificacion] = useState<ClasificacionDelContexto | null>(null);
  const [descripcion, setDescripcion] = useState<string>("");
  const [tiposDeContexto, setTiposDeContexto] = useState<TipoDeContexto[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tipo || !clasificacion) return;
    const contexto: ContextoPayload = {
      tipo,
      clasificacion,
      descripcion,
    };
    onSubmit(contexto);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || !isOpen) return;
    setLoadingCounter((prev) => prev + 1);
    listTiposDeContexto(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setTiposDeContexto(data);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada, isOpen]);

  useEffect(() => {
    if (!contexto) return;
    setTipo(contexto.tipo._id);
    setClasificacion(contexto.clasificacion);
    setDescripcion(contexto.descripcion);
  }, [contexto]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Form id={formId} onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <div className="form-floating">
            <Input
              type="select"
              name="tipoDeContexto"
              id="tipoDeContexto"
              placeholder="Tipo de contexto"
              value={tipo}
              onChange={({ target }) => setTipo(target.value)}
              required
            >
              <option value="" disabled>
                Seleccionar
              </option>
              {tiposDeContexto.map((tipoDeContexto) => (
                <option key={tipoDeContexto._id} value={tipoDeContexto._id}>
                  {tipoDeContexto.nombre}
                </option>
              ))}
            </Input>
            <Label for="tipoDeContexto">Tipo de contexto</Label>
          </div>
          <Button
            type="button"
            color="primary"
            title="Agregar un nuevo tipo de contexto"
            onClick={toggleGestionarTiposDeContexto}
          >
            <FaPlus />
          </Button>
        </InputGroup>
        <FormGroup floating>
          <Input
            type="select"
            name="clasificacion"
            id="clasificacion"
            placeholder="Clasificación"
            value={clasificacion || ""}
            onChange={({ target }) => setClasificacion(target.value as ClasificacionDelContexto)}
            required
          >
            <option value="" disabled>
              Seleccionar
            </option>
            {Object.values(ClasificacionDelContexto).map((clasificacion) => (
              <option key={clasificacion} value={clasificacion}>
                {textoDeClasificacionDelContexto(clasificacion)}
              </option>
            ))}
          </Input>
          <Label for="clasificacion">Clasificación</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            name="descripcion"
            id="descripcion"
            placeholder="Descripción"
            value={descripcion}
            onChange={({ target }) => setDescripcion(target.value)}
            required
          />
          <Label for="descripcion">Descripción</Label>
        </FormGroup>
      </Form>
    </>
  );
};

export default ContextoForm;
