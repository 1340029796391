import { FC } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Row } from "reactstrap";
import styled from "styled-components";
import { darkenHexColor } from "./utils";

export const HexMenuContainer = styled(Row)`
  max-width: 295px;
  margin-bottom: 30px;
  @media (min-width: 576px) {
    max-width: 354px;
  }
  @media (min-width: 992px) {
    max-width: 531px;
  }
`;

const HexagonalButton = styled.button<{ backgroundColor: string }>`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  border: none;
  --btn-raise: 29px;
  clip-path: polygon(
    50% 0%,
    100% var(--btn-raise),
    100% calc(100% - var(--btn-raise)),
    50% 100%,
    0% calc(100% - var(--btn-raise)),
    0% var(--btn-raise)
  );
  height: 120px;
  width: 110px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: -22px;
  background-color: ${(props) => props.backgroundColor}ff;
  &:hover {
    background-color: ${(props) => darkenHexColor(props.backgroundColor, 20)};
  }
`;

interface HexButtonProps {
  label: string;
  url: string;
  backgroundColor: string;
}

const HexButton: FC<HexButtonProps> = ({ label, url, backgroundColor }) => (
  <LinkContainer to={url}>
    <HexagonalButton type="button" backgroundColor={backgroundColor}>
      {label}
    </HexagonalButton>
  </LinkContainer>
);

export default HexButton;
