import { Afectado, AfectadoPayload, AfectadoResponse } from "./Afectado";
import { ArchivoDeIncidente, ArchivoDeIncidenteResponse } from "./ArchivoDeIncidente";
import { Testigo, TestigoPayload, TestigoResponse } from "./Testigo";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

export enum TiposDeIncidente {
  CasiIncidente = "casiIncidente",
  IncidenteSinTiempoPerdido = "incidenteSinTiempoPerdido",
  IncidenteConTiempoPerdido = "incidenteConTiempoPerdido",
  IncidenteConFatalidad = "incidenteConFatalidad",
}

export function textoDelTipoDeIncidente(tipo: TiposDeIncidente) {
  switch (tipo) {
    case TiposDeIncidente.CasiIncidente:
      return "Casi incidente";
    case TiposDeIncidente.IncidenteSinTiempoPerdido:
      return "Incidente sin tiempo perdido";
    case TiposDeIncidente.IncidenteConTiempoPerdido:
      return "Incidente con tiempo perdido";
    case TiposDeIncidente.IncidenteConFatalidad:
      return "Incidente con fatalidad";
  }
}

export enum PartesAfectadas {
  Cabeza = "cabeza",
  Pecho = "pecho",
  Espalda = "espalda",
  Brazos = "brazos",
  Manos = "manos",
  Piernas = "piernas",
  Pies = "pies",
  Ninguna = "ninguna",
}

export function textoDePartesAfectadas(partes: PartesAfectadas) {
  switch (partes) {
    case PartesAfectadas.Cabeza:
      return "Cabeza";
    case PartesAfectadas.Pecho:
      return "Pecho";
    case PartesAfectadas.Espalda:
      return "Espalda";
    case PartesAfectadas.Brazos:
      return "Brazos";
    case PartesAfectadas.Manos:
      return "Manos";
    case PartesAfectadas.Piernas:
      return "Piernas";
    case PartesAfectadas.Pies:
      return "Pies";
    case PartesAfectadas.Ninguna:
      return "Ninguna";
  }
}

export enum EstadoACR {
  Pendiente = "pendiente",
  Realizado = "realizado",
  Vencido = "vencido",
}

export function textoDelEstadoACR(estado: EstadoACR) {
  switch (estado) {
    case EstadoACR.Pendiente:
      return "Pendiente";
    case EstadoACR.Realizado:
      return "Realizado";
    case EstadoACR.Vencido:
      return "Vencido";
  }
}

export interface IncidenteBase {
  tituloDelIncidente: string;
  descripcionDelIncidente: string;
  tipoDeIncidente: TiposDeIncidente;
  personasResponsables: (Vinculacion | VinculacionResponse)[];
  lugarDelIncidente: string;
  afectados: (Afectado | AfectadoResponse)[];
  testigos: (Testigo | TestigoResponse)[];
  fechaOcurrencia: string | Date;
  fechaLimite?: string | Date;
  estadoACR?: EstadoACR;
  accionesInmediatas?: string;
}

export interface Incidente extends IncidenteBase {
  _id: string;
  testigos: Testigo[];
  personasResponsables: Vinculacion[];
  afectados: Afectado[];
  fechaLimite?: Date;
  fechaOcurrencia: Date;
  estadoACR: EstadoACR;
  archivos?: ArchivoDeIncidente[];
}

export interface IncidenteResponse extends IncidenteBase {
  _id: string;
  personasResponsables: VinculacionResponse[];
  testigos: TestigoResponse[];
  afectados: AfectadoResponse[];
  fechaOcurrencia: string;
  fechaLimite?: string;
  estadoACR: EstadoACR;
  accionesInmediatas: string;
  archivos?: ArchivoDeIncidenteResponse[];
}

export class Incidente implements Incidente {
  constructor({
    _id,
    tituloDelIncidente,
    descripcionDelIncidente,
    tipoDeIncidente,
    personasResponsables,
    lugarDelIncidente,
    afectados,
    fechaOcurrencia,
    testigos,
    fechaLimite,
    estadoACR,
    accionesInmediatas,
    archivos = [],
  }: IncidenteResponse) {
    this._id = _id;
    this.tituloDelIncidente = tituloDelIncidente;
    this.descripcionDelIncidente = descripcionDelIncidente;
    this.tipoDeIncidente = tipoDeIncidente;
    this.personasResponsables = personasResponsables.map((persona) => new Vinculacion(persona));
    this.lugarDelIncidente = lugarDelIncidente;
    this.afectados = afectados.map((afectado) => new Afectado(afectado));
    this.fechaOcurrencia = new Date(fechaOcurrencia);
    this.testigos = testigos.map((testigo) => new Testigo(testigo));
    this.fechaLimite = typeof fechaLimite === "string" ? new Date(fechaLimite) : fechaLimite;
    this.estadoACR = estadoACR;
    this.accionesInmediatas = accionesInmediatas;
    this.archivos = archivos.map((archivo) => new ArchivoDeIncidente(archivo));
  }

  get nombreCompleto() {
    return this.tituloDelIncidente;
  }
}

export interface IncidentePayload {
  tituloDelIncidente: string;
  descripcionDelIncidente: string;
  tipoDeIncidente: TiposDeIncidente;
  personasResponsables: string[];
  lugarDelIncidente: string;
  afectados: AfectadoPayload[];
  testigos: TestigoPayload[];
  fechaOcurrencia: Date;
  fechaLimite?: Date;
  estadoACR?: EstadoACR;
  accionesInmediatas?: string;
}

export interface IndicadorDeInvestigacionDeIncidentes {
  estado: EstadoACR;
  cantidad: number;
}
