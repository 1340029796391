import classNames from "classnames";
import { useState, FC, ChangeEvent, useEffect, useContext } from "react";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { MostrarContraseñaButton, PasswordInput, validarContraseña } from "../../utilities/utils";
import { Context } from "../Context";
import { cambiarContrasena } from "../../services/usuario-api-client";

const CAMBIAR_CONTRASENA_FORM_ID = "cambiarContraseñaForm";

interface CambiarContrasenaModalProps {
  isOpen: boolean;
  forzarCambio?: boolean;
  toggle?: () => void;
  onContraseñaCambiada: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

const CambiarContrasenaModal: FC<CambiarContrasenaModalProps> = ({
  isOpen,
  forzarCambio,
  toggle,
  onContraseñaCambiada,
  setIsLoading,
}) => {
  const { authToken } = useContext(Context);
  const [contraseñaActual, setContraseñaActual] = useState<string>("");
  const [contraseñaNueva, setContraseñaNueva] = useState<string>("");
  const [confirmarContraseñaNueva, setConfirmarContraseñaNueva] = useState<string>("");
  const [mostrarContraseñaActual, setMostrarContraseñaActual] = useState<boolean>(false);
  const [mostrarContraseñaNueva, setMostrarContraseñaNueva] = useState<boolean>(false);
  const [mostrarConfirmarContraseñaNueva, setMostrarConfirmarContraseñaNueva] =
    useState<boolean>(false);

  const [isValidContraseñaNueva, setIsValidContraseñaNueva] = useState<boolean>(false);

  const [errorContraseñaActual, setErrorContraseñaActual] = useState<string>("");
  const [errorContraseñaNueva, setErrorContraseñaNueva] = useState<string>("");
  const [errorConfirmarContraseñaNueva, setErrorConfirmarContraseñaNueva] = useState<string>("");

  const handleContraseñaActualChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContraseñaActual(e.target.value);
  };

  const handleContraseñaNuevaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContraseñaNueva(e.target.value);
  };

  const handleConfirmarContraseñaNuevaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmarContraseñaNueva(e.target.value);
  };

  const limpiarErrores = () => {
    setErrorContraseñaActual("");
    setErrorContraseñaNueva("");
    setErrorConfirmarContraseñaNueva("");
  };

  const validarFormulario = () => {
    limpiarErrores();
    let valido = true;
    const contraseñaNuevaIsValid = validarContraseña(contraseñaNueva);
    if (!contraseñaNuevaIsValid) {
      setErrorContraseñaNueva(
        "La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y caracteres especiales."
      );
      valido = false;
    }
    if (contraseñaNueva !== confirmarContraseñaNueva) {
      setErrorConfirmarContraseñaNueva("Las contraseñas no coinciden");
      valido = false;
    }
    return valido;
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validarFormulario()) {
      registrar();
    }
  };

  const registrar = () => {
    setIsLoading(true);

    const body = {
      contraseñaActual,
      contraseñaNueva,
    };

    cambiarContrasena(authToken, body)
      .then(() => {
        onContraseñaCambiada();
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          setErrorContraseñaActual("La contraseña actual es incorrecta");
        } else {
          setErrorContraseñaActual("Ocurrió un error al intentar cambiar la contraseña");
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    const contraseñaValida = () => {
      if (contraseñaNueva === "" || confirmarContraseñaNueva === "") return false;
      return contraseñaNueva === confirmarContraseñaNueva && validarContraseña(contraseñaNueva);
    };
    setIsValidContraseñaNueva(contraseñaValida());
  }, [contraseñaNueva, confirmarContraseñaNueva]);

  useEffect(() => {
    setErrorContraseñaActual("");
  }, [contraseñaActual]);

  useEffect(() => {
    setErrorContraseñaNueva("");
  }, [contraseñaNueva]);

  useEffect(() => {
    setErrorConfirmarContraseñaNueva("");
  }, [confirmarContraseñaNueva]);

  useEffect(() => {
    if (isOpen) {
      setContraseñaActual("");
      setContraseñaNueva("");
      setConfirmarContraseñaNueva("");
      setMostrarContraseñaActual(false);
      setMostrarContraseñaNueva(false);
      setMostrarConfirmarContraseñaNueva(false);
      limpiarErrores();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Cambiar Contraseña</ModalHeader>
      <ModalBody>
        <Form id={CAMBIAR_CONTRASENA_FORM_ID} onSubmit={handleSubmit}>
          {!forzarCambio && (
            <FormGroup floating>
              <PasswordInput
                type={mostrarContraseñaActual ? "text" : "password"}
                id="contraseñaActual"
                name="contraseñaActual"
                placeholder="Contraseña actual"
                invalid={!!errorContraseñaActual}
                className={classNames({ "not-empty": contraseñaActual.length > 0 })}
                value={contraseñaActual}
                onChange={handleContraseñaActualChange}
                required
              />
              <Label for="contraseñaActual">Contraseña actual</Label>
              <FormFeedback>{errorContraseñaActual}</FormFeedback>
              {contraseñaActual.length > 0 && (
                <MostrarContraseñaButton
                  type="button"
                  color="link"
                  className="text-muted"
                  tabIndex={-1}
                  onClick={() => setMostrarContraseñaActual(!mostrarContraseñaActual)}
                >
                  {mostrarContraseñaActual ? <FaEye /> : <FaEyeSlash />}
                </MostrarContraseñaButton>
              )}
            </FormGroup>
          )}
          <FormGroup floating>
            <PasswordInput
              type={mostrarContraseñaNueva ? "text" : "password"}
              id="contraseñaNueva"
              name="contraseñaNueva"
              placeholder="Contraseña nueva"
              valid={isValidContraseñaNueva}
              invalid={!!errorContraseñaNueva}
              className={classNames({ "not-empty": contraseñaNueva.length > 0 })}
              value={contraseñaNueva}
              onChange={handleContraseñaNuevaChange}
              required
            />
            <Label for="contraseñaNueva">Contraseña nueva</Label>
            <FormFeedback>{errorContraseñaNueva}</FormFeedback>
            {contraseñaNueva.length > 0 && (
              <MostrarContraseñaButton
                type="button"
                color="link"
                className="text-muted"
                tabIndex={-1}
                onClick={() => setMostrarContraseñaNueva(!mostrarContraseñaNueva)}
              >
                {mostrarContraseñaNueva ? <FaEye /> : <FaEyeSlash />}
              </MostrarContraseñaButton>
            )}
          </FormGroup>
          <FormGroup floating>
            <PasswordInput
              type={mostrarConfirmarContraseñaNueva ? "text" : "password"}
              id="confirmarContraseñaNueva"
              name="confirmarContraseñaNueva"
              placeholder="Confirmar contraseña nueva"
              valid={isValidContraseñaNueva}
              invalid={!!errorConfirmarContraseñaNueva}
              className={classNames({ "not-empty": confirmarContraseñaNueva.length > 0 })}
              value={confirmarContraseñaNueva}
              onChange={handleConfirmarContraseñaNuevaChange}
              required
            />
            <Label for="confirmarContraseñaNueva">Confirmar contraseña nueva</Label>
            <FormFeedback>{errorConfirmarContraseñaNueva}</FormFeedback>
            {confirmarContraseñaNueva.length > 0 && (
              <MostrarContraseñaButton
                type="button"
                color="link"
                className="text-muted"
                tabIndex={-1}
                onClick={() => setMostrarConfirmarContraseñaNueva(!mostrarConfirmarContraseñaNueva)}
              >
                {mostrarConfirmarContraseñaNueva ? <FaEye /> : <FaEyeSlash />}
              </MostrarContraseñaButton>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" form={CAMBIAR_CONTRASENA_FORM_ID} color="primary">
          Cambiar contraseña
        </Button>
        {!forzarCambio && (
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CambiarContrasenaModal;
