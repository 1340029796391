import { FC } from "react";
import { Button, Col, Row } from "reactstrap";
import { FaClipboardCheck, FaEdit, FaPen, FaPoll, FaRegClipboard } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import {
  CustomTable,
  IconsContainer,
  TableCaption,
  TableContainer,
  TableMessage,
} from "../../../../utilities/utils";
import {
  textoDeTipoDeServicio,
  textoDeTipoDeActividad,
  Actividad,
} from "../../../../models/Actividad";
import { textoDeCalificacion } from "../../../../models/EvaluacionDeRiesgo";
import { PATHS, procesarPath } from "../../../../utilities/Paths";

const TdCustom = styled.td`
  background-color: ${(props) => (props.color ? props.color : "white")} !important;
`;

interface TablaDeActividadProps {
  actividades: Actividad[];
}

const getColorByCalificacion = (calificacion: number) => {
  if (calificacion > 0 && calificacion < 200) {
    return "#84bf4d";
  } else if (calificacion >= 200 && calificacion < 400) {
    return "#ffff09";
  } else if (calificacion >= 400) {
    return "#fb0006";
  }
  return "";
};

const TablaDeActividad: FC<TablaDeActividadProps> = ({ actividades }) => {
  return (
    <>
      <TableContainer>
        <TableCaption>Actividades</TableCaption>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Departamento</th>
              <th>Actividad</th>
              <th>Servicio</th>
              <th>Tipo de Actividad</th>
              <th>Clasificación del Riesgo</th>
              <th>Prioridad</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {actividades.map(
              (
                { _id, departamento, actividad, servicio, tipoDeActividad, calificacionMaxima },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{departamento}</td>
                  <td className="text-center">{actividad}</td>
                  <td className="text-center">{textoDeTipoDeServicio(servicio)}</td>
                  <td className="text-center">{textoDeTipoDeActividad(tipoDeActividad)}</td>
                  <td className="text-center">{calificacionMaxima || "—"}</td>
                  <TdCustom
                    className="text-center"
                    color={getColorByCalificacion(calificacionMaxima || 0)}
                  >
                    <b>{textoDeCalificacion(calificacionMaxima || 0)}</b>
                  </TdCustom>
                  <td>
                    <Row className="justify-content-center gx-2 flex-nowrap">
                      <Col xs="auto">
                        <LinkContainer to={procesarPath(PATHS.editarActividad, _id)}>
                          <Button color="link" size="sm" title="Editar actividad">
                            <FaEdit size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <LinkContainer to={procesarPath(PATHS.asignarRiesgos, _id)}>
                          <Button color="link" size="sm" title="Evaluar riesgos">
                            <FaClipboardCheck size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <LinkContainer to={procesarPath(PATHS.editarEvaluacion, _id)}>
                          <Button color="link" size="sm" title="Editar evaluación">
                            <IconsContainer>
                              <FaRegClipboard
                                size={15}
                                textAnchor="middle"
                                alignmentBaseline="middle"
                              />
                              <FaPen
                                textAnchor="middle"
                                alignmentBaseline="middle"
                                style={{
                                  fontSize: ".5em",
                                  position: "absolute",
                                  left: ".65em",
                                  bottom: ".6em",
                                }}
                              />
                            </IconsContainer>
                          </Button>
                        </LinkContainer>
                      </Col>
                      <Col xs="auto">
                        <LinkContainer to={procesarPath(PATHS.resultadosDeRiesgos, _id)}>
                          <Button color="link" size="sm" title="Ver resultados">
                            <FaPoll size={15} />
                          </Button>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {actividades.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">No hay actividades registradas</Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <LinkContainer to={PATHS.nuevaActividad.to}>
                          <Button type="button" color="primary">
                            Agregar actividad
                          </Button>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
    </>
  );
};

export default TablaDeActividad;
