import { FC } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Button, Col, Row } from "reactstrap";
import { ArchivoDeDocumento } from "../../../../models/ArchivoDeDocumento";
import {
  CustomTable,
  TableCaption,
  TableContainer,
  procesarLinkDeDocumento,
  shortDateToString,
} from "../../../../utilities/utils";
import { TiposDeDocumento } from "../../../../models/Documento";

interface TablaDeArchivosProps {
  archivos: ArchivoDeDocumento[];
  tipoDeDocumento: TiposDeDocumento;
  titulo?: string;
}

const TablaDeArchivos: FC<TablaDeArchivosProps> = ({ archivos, tipoDeDocumento, titulo }) => {
  return (
    <>
      {!!titulo && (
        <Row>
          <Col>
            <TableCaption>{titulo}</TableCaption>
          </Col>
        </Row>
      )}
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              {
                {
                  [TiposDeDocumento.DocumentacionGeneralDeLaEmpresa]: <></>,
                  [TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa]: (
                    <th>Equipo(s)/Herramienta(s)</th>
                  ),
                  [TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa]: <th>Vehículo(s)</th>,
                  [TiposDeDocumento.DocumentacionDeUsuariosDirectos]: <th>Colaborador(es)</th>,
                  [TiposDeDocumento.DocumentacionDeSustanciasQuimicas]: (
                    <th>Sustancia(s) química(s)</th>
                  ),
                }[tipoDeDocumento]
              }
              <th>Creado por</th>
              <th>Fecha de la actualización</th>
              <th>Fecha de vencimiento</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {archivos.map(
              (
                { _id, nombre, filepath, creadoPor, fechaDeCarga, fechaDeVencimiento, activos },
                index
              ) => (
                <tr key={_id}>
                  <td>{index + 1}</td>
                  <td>{nombre}</td>
                  {!!activos && activos.length > 0 && (
                    <td>{activos.map(({ nombreCompleto }) => nombreCompleto).join(", ")}</td>
                  )}
                  <td>{creadoPor.nombreCompleto}</td>
                  <td className="text-center">{shortDateToString(fechaDeCarga)}</td>
                  <td className="text-center">{shortDateToString(fechaDeVencimiento)}</td>
                  <td>
                    <Row className="justify-content-center align-items-center gx-0 flex-nowrap">
                      <Col xs="auto">
                        <Button
                          color="link"
                          title="Descargar"
                          size="sm"
                          href={procesarLinkDeDocumento(filepath)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaExternalLinkAlt size={15} />
                        </Button>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )
            )}
            {archivos.length === 0 && (
              <tr>
                <td
                  colSpan={
                    tipoDeDocumento === TiposDeDocumento.DocumentacionGeneralDeLaEmpresa ? 6 : 7
                  }
                  className="text-center"
                >
                  No hay archivos registrados.
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
    </>
  );
};

export default TablaDeArchivos;
