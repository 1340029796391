import { FormEvent, FC, useState, useContext } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { DocumentoDelContrato } from "../../../../models/DocumentoDelContrato";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { editDocumentoDelContrato } from "../../../../services/usuario-api-client";
import { Context } from "../../../Context";
import { TiposDeDocumento, textoDelTipoDeDocumento } from "../../../../models/Documento";

interface DocumentoDelContratoFormProps {
  formId: string;
  documentoDelContrato: DocumentoDelContrato;
  onSuccess: () => void;
}

const DocumentoDelContratoForm: FC<DocumentoDelContratoFormProps> = ({
  formId,
  documentoDelContrato,
  onSuccess,
}) => {
  const { authToken } = useContext(Context);
  const [nombre, setNombre] = useState<string>(documentoDelContrato.nombre);
  const [tipoDeDocumentoPreliminar, setTipoDeDocumentoPreliminar] = useState<
    TiposDeDocumento | undefined
  >(documentoDelContrato.tipoDeDocumentoPreliminar);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTipoDeDocumentoPreliminarChange = (value: string) => {
    if (value === "") setTipoDeDocumentoPreliminar(undefined);
    else setTipoDeDocumentoPreliminar(value as TiposDeDocumento);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!documentoDelContrato.contrato) return;
    setIsLoading(true);
    const body = { nombre, tipoDeDocumentoPreliminar };
    editDocumentoDelContrato(
      authToken,
      documentoDelContrato.contrato.toString(),
      documentoDelContrato._id,
      body
    )
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            id="nombre"
            name="nombre"
            type="text"
            placeholder="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <Label for="nombre">Nombre</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            id="tipoDeDocumentoPreliminar"
            name="tipoDeDocumentoPreliminar"
            type="select"
            placeholder="Tipo de documento preliminar"
            value={tipoDeDocumentoPreliminar}
            onChange={(e) => handleTipoDeDocumentoPreliminarChange(e.target.value)}
          >
            <option value="">No seleccionado</option>
            {Object.values(TiposDeDocumento).map((value) => (
              <option key={value} value={value}>
                {textoDelTipoDeDocumento(value as TiposDeDocumento)}
              </option>
            ))}
          </Input>
          <Label for="tipoDeDocumentoPreliminar">Tipo de documento preliminar</Label>
        </FormGroup>
      </Form>
    </>
  );
};

export default DocumentoDelContratoForm;
