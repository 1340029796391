import { FC, useContext, useEffect } from "react";
import { FaGlobe, FaPlus } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Col, Row } from "reactstrap";
import { IconsContainer } from "../../../utilities/utils";
import { Context } from "../../Context";
import TablaDePartesInteresadas from "./TablaDePartesInteresadas";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const Contexto: FC = () => {
  const { setTituloDeLaPantallaActual } = useContext(Context);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.contexto.name), [setTituloDeLaPantallaActual]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Breadcrumbs items={[PATHS.usuarioHome]} />
        </Col>
        <Col xs="auto" className="ms-auto">
          <LinkContainer to={PATHS.nuevaParteInteresada.to}>
            <Button color="primary">
              <IconsContainer>
                <FaGlobe size={20} textAnchor="middle" alignmentBaseline="middle" />
                <FaPlus
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{
                    fontSize: ".5em",
                    position: "absolute",
                    left: "2.35em",
                    bottom: "1.5em",
                  }}
                />
              </IconsContainer>
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <TablaDePartesInteresadas />
    </>
  );
};

export default Contexto;
