import { datetimeToISOString } from "../utilities/utils";
import { Contacto } from "./Contacto";

export enum TiposDeIdentificacion {
  Cedula = "cedula",
  Pasaporte = "pasaporte",
}

export enum Roles {
  Administrador = "administrador",
  Empleado = "empleado",
}

export interface UsuarioBase {
  nombre: string;
  apellidos: string;
  tipoDeIdentificacion: TiposDeIdentificacion;
  identificacion: string;
  correo: string;
  nacionalidad: string;
  tipoDeSangre: string;
  telefono: string;
  direccionDeResidencia: string;
  alergias: string[];
  discapacidades: string[];
  enfermedades: string[];
  medicamentos: string[];
  contactosDeEmergencia: Contacto[];
}

export interface Usuario extends UsuarioBase {
  _id: string;
  ultimaEmpresaSeleccionada: string;
  correoConfirmado?: boolean;
  tiempoDePrueba?: Date;
  createdAt: Date;
}

export interface UsuarioResponse extends UsuarioBase {
  _id: string;
  ultimaEmpresaSeleccionada: string;
  correoConfirmado?: boolean;
  tiempoDePrueba?: string;
  createdAt: string;
}

export class Usuario implements Usuario {
  constructor({
    _id,
    nombre,
    apellidos,
    tipoDeIdentificacion,
    identificacion,
    correo,
    nacionalidad,
    tipoDeSangre,
    telefono,
    direccionDeResidencia,
    alergias,
    discapacidades,
    enfermedades,
    medicamentos,
    contactosDeEmergencia,
    ultimaEmpresaSeleccionada,
    correoConfirmado,
    tiempoDePrueba,
    createdAt,
  }: UsuarioResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.apellidos = apellidos;
    this.tipoDeIdentificacion = tipoDeIdentificacion;
    this.identificacion = identificacion;
    this.correo = correo;
    this.nacionalidad = nacionalidad;
    this.tipoDeSangre = tipoDeSangre;
    this.telefono = telefono;
    this.direccionDeResidencia = direccionDeResidencia;
    this.alergias = alergias;
    this.discapacidades = discapacidades;
    this.enfermedades = enfermedades;
    this.medicamentos = medicamentos;
    this.contactosDeEmergencia = contactosDeEmergencia;
    this.ultimaEmpresaSeleccionada = ultimaEmpresaSeleccionada;
    this.correoConfirmado = correoConfirmado;
    this.tiempoDePrueba = tiempoDePrueba ? new Date(tiempoDePrueba) : undefined;
    this.createdAt = new Date(createdAt);
  }

  get nombreCompleto() {
    return `${this.nombre} ${this.apellidos}`;
  }

  get asResponse(): UsuarioResponse {
    return {
      _id: this._id,
      nombre: this.nombre,
      apellidos: this.apellidos,
      tipoDeIdentificacion: this.tipoDeIdentificacion,
      identificacion: this.identificacion,
      correo: this.correo,
      nacionalidad: this.nacionalidad,
      tipoDeSangre: this.tipoDeSangre,
      telefono: this.telefono,
      direccionDeResidencia: this.direccionDeResidencia,
      alergias: this.alergias,
      discapacidades: this.discapacidades,
      enfermedades: this.enfermedades,
      medicamentos: this.medicamentos,
      contactosDeEmergencia: this.contactosDeEmergencia,
      ultimaEmpresaSeleccionada: this.ultimaEmpresaSeleccionada,
      correoConfirmado: this.correoConfirmado,
      tiempoDePrueba: this.tiempoDePrueba ? datetimeToISOString(this.tiempoDePrueba) : undefined,
      createdAt: datetimeToISOString(this.createdAt),
    };
  }
}
