import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Context } from "../../Context";
import { indicadoresDeFormacion } from "../../../services/usuario-api-client";
import { COLORS, CustomTable, TableCaption, TableContainer } from "../../../utilities/utils";
import { IndicadorDeFormacion } from "../../../models/Curso";

const Formacion: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [indicadores, setIndicadores] = useState<IndicadorDeFormacion[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const cargarIndicadores = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    indicadoresDeFormacion(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setIndicadores(data);
        setLoadingCounter((prev) => prev - 1);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(cargarIndicadores, [cargarIndicadores]);

  const totalHoras = indicadores.reduce((acc, { horas }) => acc + horas, 0);
  const totalTrabajadores = indicadores.reduce((acc, { trabajadores }) => acc + trabajadores, 0);
  const totalDeHorasHombre = indicadores.reduce(
    (acc, { horas, trabajadores }) => acc + horas * trabajadores,
    0
  );
  const horasHombre = indicadores.map((indicador) => ({
    ...indicador,
    horasHombre: indicador.horas * indicador.trabajadores,
  }));

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row className="mb-3">
        <Col className="text-center h5">Horas hombre por curso</Col>
      </Row>
      <Row>
        <Col xs={12} md>
          <Row>
            <Col>
              <TableCaption>Tabla Histórica de Formación</TableCaption>
            </Col>
          </Row>
          <TableContainer>
            <CustomTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Curso</th>
                  <th>Horas</th>
                  <th>Trabajadores</th>
                </tr>
              </thead>
              <tbody>
                {indicadores.map(({ idDelCurso, curso, horas, trabajadores }, index) => (
                  <tr key={idDelCurso}>
                    <td>{index + 1}</td>
                    <td>{curso}</td>
                    <td className="text-end">{horas}</td>
                    <td className="text-end">{trabajadores}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2} className="text-end">
                    TOTAL
                  </td>
                  <td className="text-end">{totalHoras}</td>
                  <td className="text-end">{totalTrabajadores}</td>
                </tr>
              </tfoot>
            </CustomTable>
          </TableContainer>
          <p>Cantidad de cursos creados: {indicadores.length}</p>
          <p>Total de horas hombre: {totalDeHorasHombre}</p>
        </Col>
        <Col>
          {!!indicadores.length && (
            <ResponsiveContainer width="100%" height="100%" minHeight={250}>
              <PieChart width={250} height={250}>
                <Pie
                  data={horasHombre}
                  dataKey="horasHombre"
                  nameKey="curso"
                  cx="50%"
                  cy="50%"
                  label={({ value }) => `${value}h`}
                  startAngle={90}
                  endAngle={450}
                  fill="#12416B"
                  innerRadius={50}
                >
                  {horasHombre.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                  <Label value={`${totalDeHorasHombre}h`} position="center" fill="#000" />
                </Pie>
                <Tooltip formatter={(value) => `${value}h`} />
                <Legend
                  payload={horasHombre.map(({ curso, horasHombre }, index) => ({
                    value: `${curso}: ${horasHombre}h`,
                    type: "circle",
                    color: COLORS[index % COLORS.length],
                  }))}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Formacion;
