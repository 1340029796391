import localForage from "localforage";

class LocalStorage {
  private static readonly TOKEN_KEY = "token";
  private static readonly ADMIN_TOKEN_KEY = "adminToken";
  private static readonly ID_EMPRESA = "idEmpresa";

  public static async getToken(): Promise<string> {
    const token = await localForage.getItem<string>(LocalStorage.TOKEN_KEY);
    return token || "";
  }

  public static async setToken(value: string) {
    return await localForage.setItem(LocalStorage.TOKEN_KEY, value);
  }

  public static async removeToken() {
    return await localForage.removeItem(LocalStorage.TOKEN_KEY);
  }

  public static async isLoggedIn(): Promise<boolean> {
    return !!(await LocalStorage.getToken());
  }

  public static async getIdEmpresa(): Promise<string> {
    const idEmpresa = await localForage.getItem<string>(LocalStorage.ID_EMPRESA);
    return idEmpresa || "";
  }

  public static async setIdEmpresa(value: string) {
    await localForage.setItem(LocalStorage.ID_EMPRESA, value);
  }

  public static async removeIdEmpresa() {
    return await localForage.removeItem(LocalStorage.ID_EMPRESA);
  }

  public static async getAdminToken(): Promise<string> {
    const token = await localForage.getItem<string>(LocalStorage.ADMIN_TOKEN_KEY);
    return token || "";
  }

  public static async setAdminToken(value: string) {
    return await localForage.setItem(LocalStorage.ADMIN_TOKEN_KEY, value);
  }

  public static async removeAdminToken() {
    return await localForage.removeItem(LocalStorage.ADMIN_TOKEN_KEY);
  }

  public static async isAdminLoggedIn(): Promise<boolean> {
    return !!(await LocalStorage.getAdminToken());
  }
}

export default LocalStorage;
