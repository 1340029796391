import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LocalStorage from "../utilities/LocalStorage";
import { Context } from "./Context";
import { logout } from "../services/public-api-client";
import { PATHS } from "../utilities/Paths";

const Logout: FC = () => {
  const navigate = useNavigate();
  const { adminAuthToken, setAdminAuthToken, setAuthToken, setIdDeLaEmpresaSeleccionada } =
    useContext(Context);

  useEffect(() => {
    const isAdmin = adminAuthToken !== "";
    setAuthToken("");
    setAdminAuthToken("");
    setIdDeLaEmpresaSeleccionada("");
    logout()
      .then(() =>
        Promise.all([
          LocalStorage.removeToken(),
          LocalStorage.removeIdEmpresa(),
          LocalStorage.removeAdminToken(),
        ]).then(() => navigate(isAdmin ? PATHS.adminLogin.to : PATHS.inicio.to))
      )
      .catch(() => navigate(PATHS.inicio.to));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div></div>;
};

export default Logout;
