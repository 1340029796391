import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Navbar, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import FormularioDeActividad from "./FormularioDeActividad";
import { crearActividad } from "../../../../services/usuario-api-client";
import { ActividadPayload } from "../../../../models/Actividad";
import { PATHS } from "../../../../utilities/Paths";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";

const NUEVA_ACTIVIDAD_FORM_ID = "nuevaActividadForm";

const NuevaActividad: FC = () => {
  const navigate = useNavigate();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleNuevaActividadSubmit = (body: ActividadPayload) => {
    setIsLoading(true);
    crearActividad(authToken, body)
      .then(() => navigate(PATHS.miper.to))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.nuevaActividad.name);
  }, [setTituloDeLaPantallaActual]);
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.miper]} />
      <Row className="mb-5">
        <Col>
          <FormularioDeActividad
            formId={NUEVA_ACTIVIDAD_FORM_ID}
            onSubmit={handleNuevaActividadSubmit}
          />
        </Col>
      </Row>
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={NUEVA_ACTIVIDAD_FORM_ID} type="submit" color="primary" className="ms-auto">
          Crear
        </Button>
      </Navbar>
    </>
  );
};

export default NuevaActividad;
