import { useContext, useState } from "react";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Context } from "../../Context";
import { crearAutoridad } from "../../../services/admin-api-client";
import { Autoridad } from "../../../models/Autoridad";

const AGREGAR_AUTORIDAD_FORM_ID = "agregar-autoridad-form";

interface AgregarAutoridadModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSuccess: (autoridad: Autoridad) => void;
}

const AgregarAutoridadModal: React.FC<AgregarAutoridadModalProps> = ({
  isOpen,
  toggle,
  onSuccess,
}) => {
  const { adminAuthToken } = useContext(Context);
  const [nombre, setNombre] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    crearAutoridad(adminAuthToken, nombre)
      .then(({ data }) => onSuccess(data))
      .finally(() => setIsLoading(false));
  };

  const limpiarFormulario = () => {
    setNombre("");
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Modal isOpen={isOpen} toggle={toggle} onClosed={limpiarFormulario}>
        <ModalHeader toggle={toggle}>Agregar autoridad</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit} id={AGREGAR_AUTORIDAD_FORM_ID}>
            <FormGroup floating>
              <Input
                type="text"
                id="nombre"
                placeholder="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
              <Label for="nombre">Nombre</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
          <Button color="primary" type="submit" form={AGREGAR_AUTORIDAD_FORM_ID}>
            Agregar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AgregarAutoridadModal;
