import { useContext, FC, useEffect, useState } from "react";
import { Context } from "../../Context";
import { Fade, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import InvestigacionDeIncidentes from "./InvestigacionDeIncidentes";
import Formacion from "./Formacion";
import PlanesDeAccion from "./PlanesDeAccion";
import RequisitosLegales from "./RequisitosLegales";
import { TableContainer } from "../../../utilities/utils";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

enum Tabs {
  Formacion = "formacion",
  Incidentes = "incidentes",
  PlanesDeAccion = "planesDeAccion",
  RequisitosLegales = "requisitosLegales",
}

const textoDeTab = {
  [Tabs.Formacion]: "Formación",
  [Tabs.Incidentes]: "Investigación de Incidentes",
  [Tabs.PlanesDeAccion]: "Planes de Acción",
  [Tabs.RequisitosLegales]: "Requisitos Legales",
};

const IndicadoresDeGestion: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual } = useContext(Context);
  const [activeTab, setActiveTab] = useState(Tabs.Formacion);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.indicadores.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    if (location.hash) {
      setActiveTab(location.hash.substring(1) as Tabs);
    }
  }, [location.hash]);

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      <TableContainer className="mb-3">
        <Nav tabs className="flex-nowrap">
          {Object.values(Tabs).map((tab) => (
            <NavItem key={tab}>
              <NavLink
                active={activeTab === tab}
                onClick={() => setActiveTab(tab)}
                href={`#${tab}`}
                className="text-nowrap"
              >
                {textoDeTab[tab]}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </TableContainer>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.Formacion}>
          <Fade in={activeTab === Tabs.Formacion}>
            {activeTab === Tabs.Formacion && <Formacion />}
          </Fade>
        </TabPane>
        <TabPane tabId={Tabs.Incidentes}>
          <Fade in={activeTab === Tabs.Incidentes}>
            {activeTab === Tabs.Incidentes && <InvestigacionDeIncidentes />}
          </Fade>
        </TabPane>
        <TabPane tabId={Tabs.PlanesDeAccion}>
          <Fade in={activeTab === Tabs.PlanesDeAccion}>
            {activeTab === Tabs.PlanesDeAccion && <PlanesDeAccion />}
          </Fade>
        </TabPane>
        <TabPane tabId={Tabs.RequisitosLegales}>
          <Fade in={activeTab === Tabs.RequisitosLegales}>
            {activeTab === Tabs.RequisitosLegales && <RequisitosLegales />}
          </Fade>
        </TabPane>
      </TabContent>
    </>
  );
};

export default IndicadoresDeGestion;
