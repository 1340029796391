import { FC, FormEvent, useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { DocumentoLegalBody } from "../../../models/DocumentoLegal";
import {
  TiposDeRequisitosLegales,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import AgregarAutoridadModal from "./AgregarAutoridadModal";
import { Autoridad } from "../../../models/Autoridad";
import { listarAutoridades, obtenerBlobDeUnDocumento } from "../../../services/admin-api-client";
import { Context } from "../../Context";
import { dateInputToDate } from "../../../utilities/utils";

interface DocumentoLegalFormProps {
  formId: string;
  onSubmit: (body: DocumentoLegalBody) => void;
}

const DocumentoLegalForm: FC<DocumentoLegalFormProps> = ({ formId, onSubmit }) => {
  const { adminAuthToken } = useContext(Context);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [nombre, setNombre] = useState<string>("");
  const [attachmentType, setAttachmentType] = useState<string>("file");
  const [hipervinculo, setHipervinculo] = useState<string>("");
  const [validHipervinculo, setValidHipervinculo] = useState<boolean | null>(null);
  const [errorHipervinculo, setErrorHipervinculo] = useState<string>("");
  const [archivoAdjunto, setArchivoAdjunto] = useState<File | null>(null);
  const [tipoDeRequisitoLegal, setTipoDeRequisitoLegal] = useState<TiposDeRequisitosLegales | null>(
    null
  );
  const [autoridad, setAutoridad] = useState<string>("");
  const [numeroDeArticulos, setNumeroDeArticulos] = useState<number>(0);
  const [fechaDeEmisionString, setFechaDeEmisionString] = useState<string>("");
  const [autoridades, setAutoridades] = useState<Autoridad[]>([]);
  const [agregarAutoridadModalIsOpen, setAgregarAutoridadModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cargarAutoridades = useCallback(() => {
    if (!adminAuthToken) return;
    setIsLoading(true);
    listarAutoridades(adminAuthToken)
      .then(({ data }) => setAutoridades(data))
      .finally(() => setIsLoading(false));
  }, [adminAuthToken]);

  const toggleAgregarAutoridadModal = () => {
    setAgregarAutoridadModalIsOpen(!agregarAutoridadModalIsOpen);
  };

  const handleAgregarAutoridadSuccess = (autoridad: Autoridad) => {
    toggleAgregarAutoridadModal();
    setAutoridad(autoridad._id);
    cargarAutoridades();
  };

  const scrollIntoView = (fieldName: string) => {
    if (formRef.current) {
      const formControl = formRef.current.querySelector<HTMLInputElement | HTMLSelectElement>(
        `#${fieldName}`
      );
      if (formControl) {
        formControl.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (attachmentType === "url" && !validHipervinculo) {
      scrollIntoView("hipervinculo");
      return;
    }
    if (!archivoAdjunto) return;
    const body = {
      nombre,
      adjunto: archivoAdjunto as File,
      tipo: tipoDeRequisitoLegal as TiposDeRequisitosLegales,
      autoridad,
      numeroDeArticulos,
      fechaDeEmision: fechaDeEmisionString ? dateInputToDate(fechaDeEmisionString) : null,
    };
    onSubmit(body);
  };

  useEffect(() => {
    setValidHipervinculo(null);
    if (attachmentType === "url") setArchivoAdjunto(null);
    if (attachmentType === "file") setHipervinculo("");
  }, [attachmentType]);

  useEffect(() => {
    if (!hipervinculo) return;
    if (!hipervinculo.startsWith("http")) return;
    if (!hipervinculo.endsWith(".pdf")) return;

    setValidHipervinculo(null);
    setArchivoAdjunto(null);
    setErrorHipervinculo("");
    setIsLoading(true);
    obtenerBlobDeUnDocumento(hipervinculo)
      .then((response) => {
        const file = new File([response.data], "archivo.pdf", { type: "application/pdf" });
        setArchivoAdjunto(file);
        setValidHipervinculo(true);
      })
      .catch(({ response }) => {
        setValidHipervinculo(false);
        setErrorHipervinculo(response?.data?.message || "Error al cargar el archivo.");
      })
      .finally(() => setIsLoading(false));
  }, [hipervinculo]);

  useEffect(cargarAutoridades, [cargarAutoridades]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form onSubmit={handleFormSubmit} id={formId} innerRef={formRef}>
        <Row className="row-cols-1 row-cols-md-3 align-items-center">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                id="nombre"
                placeholder="Nombre del documento"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
              <Label for="nombre">Nombre del documento</Label>
            </FormGroup>
          </Col>
          <Col>
            <UncontrolledDropdown>
              <DropdownToggle color="primary" className="mx-auto mb-3 d-block" caret>
                Tipo de adjunto: {attachmentType === "url" ? "Hipervínculo" : "Archivo"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setAttachmentType("file")}>Archivo</DropdownItem>
                <DropdownItem onClick={() => setAttachmentType("url")}>Hipervínculo</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col>
            {attachmentType === "file" ? (
              <FormGroup>
                <Input
                  type="file"
                  id="archivoAdjunto"
                  accept="application/pdf"
                  onChange={(e) => setArchivoAdjunto(e.target.files?.[0] || null)}
                  required
                />
                <FormText>El archivo debe ser un PDF.</FormText>
              </FormGroup>
            ) : (
              <FormGroup floating>
                <Input
                  type="text"
                  id="hipervinculo"
                  placeholder="Hipervínculo"
                  valid={validHipervinculo === true}
                  invalid={validHipervinculo === false}
                  value={hipervinculo}
                  onChange={(e) => setHipervinculo(e.target.value)}
                  required
                />
                <Label for="hipervinculo">Hipervínculo</Label>
                <FormFeedback tooltip>{errorHipervinculo}</FormFeedback>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-4">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                id="tipoDeRequisitoLegal"
                placeholder="Tipo de requisito legal"
                value={tipoDeRequisitoLegal || ""}
                onChange={(e) =>
                  setTipoDeRequisitoLegal(e.target.value as TiposDeRequisitosLegales)
                }
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {Object.entries(TiposDeRequisitosLegales).map(([key, value]) => (
                  <option key={key} value={value}>
                    {textoTipoDeRequisitoLegal(value)}
                  </option>
                ))}
              </Input>
              <Label for="tipoDeRequisitoLegal">Tipo de requisito legal</Label>
            </FormGroup>
          </Col>
          <Col>
            <InputGroup>
              <FormGroup floating>
                <Input
                  type="select"
                  id="autoridad"
                  placeholder="Autoridad"
                  value={autoridad}
                  onChange={(e) => setAutoridad(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Seleccionar
                  </option>
                  {autoridades.map(({ _id, nombre }) => (
                    <option key={_id} value={_id}>
                      {nombre}
                    </option>
                  ))}
                </Input>
                <Label for="autoridad">Autoridad</Label>
              </FormGroup>
              <Button
                color="primary"
                onClick={toggleAgregarAutoridadModal}
                title="Agregar"
                className="mb-3"
              >
                <FaPlus size={20} />
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="number"
                id="numeroDeArticulos"
                placeholder="Número de artículos"
                value={numeroDeArticulos}
                onChange={(e) => setNumeroDeArticulos(parseInt(e.target.value))}
                required
              />
              <Label for="numeroDeArticulos">Número de artículos</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="date"
                id="fechaDeEmision"
                placeholder="Fecha de emisión"
                value={fechaDeEmisionString}
                onChange={(e) => setFechaDeEmisionString(e.target.value)}
                required
              />
              <Label for="fechaDeEmision">Fecha de emisión</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <AgregarAutoridadModal
        isOpen={agregarAutoridadModalIsOpen}
        toggle={toggleAgregarAutoridadModal}
        onSuccess={handleAgregarAutoridadSuccess}
      />
    </>
  );
};

export default DocumentoLegalForm;
