import { FC, FormEvent, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Alert, Collapse, Form, FormGroup, Input, Label } from "reactstrap";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  OrigenDePlanDeAccion,
  PlanDeAccion,
  PlanDeAccionPayload,
  TiposDeAuditoria,
  TiposDeHallazgo,
  textoDeOrigenDePlanDeAccion,
  textoDeTipoDeAuditoria,
  textoDeTipoDeHallazgo,
} from "../../../models/PlanDeAccion";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import {
  crearPlanDeAccion,
  editarPlanDeAccion,
  listEvaluacionesDeRiesgosDeLaEmpresa,
  listIncidentes,
  listPartesInteresadasDeLaEmpresa,
  listarRequisitosLegales,
} from "../../../services/usuario-api-client";
import { Incidente } from "../../../models/Incidente";
import { EvaluacionDeRiesgo } from "../../../models/EvaluacionDeRiesgo";
import {
  ContextoConParteInteresada,
  textoDeClasificacionDelContexto,
} from "../../../models/Contexto";
import { ParteInteresada } from "../../../models/ParteInteresada";
import { TipoDeContexto } from "../../../models/TipoDecontexto";

interface PlanDeAccionFormProps {
  formId: string;
  planDeAccion?: PlanDeAccion;
  onSuccess: () => void;
}

const PlanDeAccionForm: FC<PlanDeAccionFormProps> = ({ formId, planDeAccion, onSuccess }) => {
  const { state } = useLocation();
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [origenSeleccionado, setOrigenSeleccionado] = useState<OrigenDePlanDeAccion | "">(
    planDeAccion?.origen || ""
  );
  const [fuente, setFuente] = useState<string>(planDeAccion?.fuente?.toString() || "");
  const [fuenteManual, setFuenteManual] = useState<string>(planDeAccion?.fuenteManual || "");
  const [tipoDeHallazgo, setTipoDeHallazgo] = useState<string>(planDeAccion?.tipoDeHallazgo || "");
  const [descripcion, setDescripcion] = useState<string>(planDeAccion?.descripcion || "");
  const [fuentes, setFuentes] = useState<
    (RequisitoLegal | Incidente | EvaluacionDeRiesgo | ContextoConParteInteresada)[]
  >([]);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const origen = origenSeleccionado as OrigenDePlanDeAccion;
    const planDeAccionPayload: PlanDeAccionPayload = {
      origen,
      descripcion,
    };
    if (origen === OrigenDePlanDeAccion.Auditoria) {
    }
    switch (origen) {
      case OrigenDePlanDeAccion.RequisitoLegal:
      case OrigenDePlanDeAccion.MIPER:
      case OrigenDePlanDeAccion.Contexto:
      case OrigenDePlanDeAccion.InvestigacionDeIncidente:
        planDeAccionPayload.fuente = fuente;
        break;
      case OrigenDePlanDeAccion.Auditoria:
        planDeAccionPayload.tipoDeHallazgo = tipoDeHallazgo as TiposDeHallazgo;
      // fallthrough
      case OrigenDePlanDeAccion.Otra:
        planDeAccionPayload.fuenteManual = fuenteManual;
        break;
    }
    setIsLoading(true);
    if (!!planDeAccion) {
      editarPlanDeAccion(authToken, planDeAccion._id, planDeAccionPayload)
        .then(onSuccess)
        .catch(({ response }) => {
          setMensajeDeError(response?.data?.message || "Error al editar la parte interesada");
          setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
        })
        .finally(() => setIsLoading(false));
    } else {
      crearPlanDeAccion(authToken, idDeLaEmpresaSeleccionada, planDeAccionPayload)
        .then(onSuccess)
        .catch(({ response }) => {
          setMensajeDeError(response?.data?.message || "Error al crear la parte interesada");
          setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || origenSeleccionado === "") return;
    switch (origenSeleccionado) {
      case OrigenDePlanDeAccion.RequisitoLegal:
        setIsLoading(true);
        listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => {
            setFuentes(data.map((requisitoLegal) => new RequisitoLegal(requisitoLegal)));
          })
          .finally(() => setIsLoading(false));
        break;
      case OrigenDePlanDeAccion.InvestigacionDeIncidente:
        setIsLoading(true);
        listIncidentes(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => {
            setFuentes(data.map((incidente) => new Incidente(incidente)));
          })
          .finally(() => setIsLoading(false));
        break;
      case OrigenDePlanDeAccion.MIPER:
        setIsLoading(false);
        listEvaluacionesDeRiesgosDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => {
            const evaluaciones = data.map((evaluacion) => new EvaluacionDeRiesgo(evaluacion));
            setFuentes(
              evaluaciones.sort((a, b) => b.calificacionDeRiesgo - a.calificacionDeRiesgo)
            );
          })
          .finally(() => setIsLoading(false));
        break;
      case OrigenDePlanDeAccion.Contexto:
        setIsLoading(true);
        listPartesInteresadasDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada)
          .then(({ data }) => {
            const partesInteresadas = data.map(
              (parteInteresada) => new ParteInteresada(parteInteresada)
            );
            const contextos = partesInteresadas
              .flatMap((parteInteresada) => {
                const tiposDeContexto = new Map<TipoDeContexto, number>();
                parteInteresada.contextos?.forEach((contexto) => {
                  const contador = tiposDeContexto.get(contexto.tipo) || 0;
                  tiposDeContexto.set(contexto.tipo, contador + 1);
                });
                const consecutivos = new Map<TipoDeContexto, number>();
                tiposDeContexto.forEach((cantidad, tipo) => {
                  if (cantidad > 1) consecutivos.set(tipo, 1);
                });
                return parteInteresada.contextos?.map((contexto) => {
                  const consecutivo = consecutivos.get(contexto.tipo);
                  if (consecutivo !== undefined) consecutivos.set(contexto.tipo, consecutivo + 1);
                  return new ContextoConParteInteresada({
                    ...contexto,
                    parteInteresada: { ...parteInteresada, contextos: undefined },
                    consecutivo,
                    nombreCompleto: `${parteInteresada.nombre} - ${contexto.tipo}${
                      consecutivo !== undefined ? ` ${consecutivo}` : ""
                    }`,
                  });
                });
              })
              .filter((contexto): contexto is ContextoConParteInteresada => !!contexto);
            setFuentes(contextos);
          })
          .finally(() => setIsLoading(false));
        break;
      default:
        setIsLoading(false);
        setFuentes([]);
        setFuente("");
        break;
    }
  }, [authToken, idDeLaEmpresaSeleccionada, origenSeleccionado]);

  useEffect(() => {
    if (origenSeleccionado === OrigenDePlanDeAccion.MIPER && !!fuente) {
      const evaluacion = fuentes.find(
        (evaluacion) => evaluacion._id === fuente
      ) as EvaluacionDeRiesgo;
      setDescripcion(evaluacion?.riesgo || "");
    }
    if (origenSeleccionado === OrigenDePlanDeAccion.Contexto && !!fuente) {
      const contexto = fuentes.find(
        (contexto) => contexto._id === fuente
      ) as ContextoConParteInteresada;
      const clasificacion = textoDeClasificacionDelContexto(contexto?.clasificacion);
      const descripcion = `${clasificacion}: ${contexto?.descripcion}`;
      setDescripcion(descripcion);
    }
  }, [fuente, fuentes, origenSeleccionado]);

  useEffect(() => {
    if (!state) return;
    const { origen, fuente } = state;
    setOrigenSeleccionado(origen);
    setFuente(fuente);
  }, [state]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="select"
            name="origen"
            id="origen"
            placeholder="Origen"
            value={origenSeleccionado}
            onChange={(e) => setOrigenSeleccionado(e.target.value as OrigenDePlanDeAccion)}
            required
          >
            <option value="" disabled selected>
              Seleccionar
            </option>
            {Object.values(OrigenDePlanDeAccion).map((origen) => (
              <option key={origen} value={origen}>
                {textoDeOrigenDePlanDeAccion(origen)}
              </option>
            ))}
          </Input>
          <Label for="origen">Origen</Label>
        </FormGroup>
        {origenSeleccionado === OrigenDePlanDeAccion.Otra ? (
          <FormGroup floating>
            <Input
              type="text"
              name="fuenteManual"
              id="fuenteManual"
              placeholder="Fuente"
              value={fuenteManual}
              onChange={(e) => setFuenteManual(e.target.value)}
              required
            />
            <Label for="fuenteManual">Fuente</Label>
          </FormGroup>
        ) : origenSeleccionado === OrigenDePlanDeAccion.Auditoria ? (
          <FormGroup floating>
            <Input
              type="select"
              name="fuente"
              id="fuente"
              placeholder="Fuente / Norma o Artículo / Incidente"
              value={fuenteManual}
              onChange={(e) => setFuenteManual(e.target.value)}
              required
            >
              <option value="" disabled>
                Seleccionar
              </option>
              {Array.from(Object.values(TiposDeAuditoria)).map((tipo) => (
                <option key={tipo} value={tipo}>
                  {textoDeTipoDeAuditoria(tipo)}
                </option>
              ))}
            </Input>
            <Label for="fuente">Fuente / Norma o Artículo / Incidente</Label>
          </FormGroup>
        ) : (
          <FormGroup floating>
            <Input
              type="select"
              name="fuente"
              id="fuente"
              placeholder="Fuente / Norma o Artículo / Incidente"
              value={fuente}
              onChange={(e) => setFuente(e.target.value)}
              required
              disabled={origenSeleccionado === ""}
            >
              <option value="" disabled>
                Seleccionar
              </option>
              {fuentes.map(({ _id, nombreCompleto }) => (
                <option key={_id} value={_id}>
                  {nombreCompleto}
                </option>
              ))}
            </Input>
            <Label for="fuente">Fuente / Norma o Artículo / Incidente</Label>
          </FormGroup>
        )}
        <Collapse isOpen={origenSeleccionado === OrigenDePlanDeAccion.Auditoria}>
          <FormGroup floating>
            <Input
              type="select"
              name="tipoDeHallazgo"
              id="tipoDeHallazgo"
              placeholder="Tipo de hallazgo"
              value={tipoDeHallazgo}
              onChange={(e) => setTipoDeHallazgo(e.target.value)}
              required={origenSeleccionado === OrigenDePlanDeAccion.Auditoria}
            >
              <option value="" disabled selected>
                Seleccionar
              </option>
              {Object.values(TiposDeHallazgo).map((tipo) => (
                <option key={tipo} value={tipo}>
                  {textoDeTipoDeHallazgo(tipo)}
                </option>
              ))}
            </Input>
            <Label for="tipoDeHallazgo">Tipo de hallazgo</Label>
          </FormGroup>
        </Collapse>
        <FormGroup floating>
          <Input
            type="textarea"
            name="descripcion"
            id="descripcion"
            placeholder="Descripción"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
            style={{ height: 100 }}
          />
          <Label for="descripcion">Descripción</Label>
        </FormGroup>
      </Form>
      <Collapse isOpen={!!mensajeDeError}>
        <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
          {mensajeDeError}
        </Alert>
      </Collapse>
    </>
  );
};

export default PlanDeAccionForm;
