import type { FC } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "reactstrap";
import styled from "styled-components";

const Body = styled.div`
  margin-top: calc(66px + 1rem);
`;

const Layout: FC = () => {
  return (
    <Container className="mb-4">
      <Body>
        <Outlet />
      </Body>
    </Container>
  );
};

export default Layout;
