import { FC, useState, FormEvent, useEffect, useContext } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Vinculacion } from "../../../../models/Vinculacion";
import { Empresa } from "../../../../models/Empresa";
import { contratarEmpresa } from "../../../../services/usuario-api-client";
import { Context } from "../../../Context";
import { useNavigate } from "react-router-dom";
import { ContratoBody } from "../../../../models/Contrato";
import BusquedaDeEmpresa from "./BusquedaDeEmpresa";

interface NuevoContratoProps {
  isOpen: boolean;
}

const NuevoContrato: FC<NuevoContratoProps> = ({ isOpen }) => {
  const navigate = useNavigate();
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState<Empresa | null>(null);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState<Vinculacion | null>(null);
  const [numeroDeDocumento, setNumeroDeDocumento] = useState<string>("");
  const [tituloDelContrato, setTituloDelContrato] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEmpresaYContactoChange = ({
    empresa,
    usuario,
  }: {
    empresa: Empresa;
    usuario: Vinculacion;
  }) => {
    setEmpresaSeleccionada(empresa);
    setUsuarioSeleccionado(usuario);
  };

  const handleNuevoContratoSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!idDeLaEmpresaSeleccionada || !empresaSeleccionada || !usuarioSeleccionado) return;
    setIsLoading(true);
    const body: ContratoBody = {
      numeroDeDocumento,
      tituloDelContrato,
      idDeLaEmpresaContratista: empresaSeleccionada._id,
      idDeLaEmpresaContratante: idDeLaEmpresaSeleccionada,
      idDelContacto: usuarioSeleccionado._id,
    };
    contratarEmpresa(authToken, body)
      .then(({ data }) => {
        navigate({ hash: "#lista" });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isOpen) {
      setEmpresaSeleccionada(null);
      setUsuarioSeleccionado(null);
      setNumeroDeDocumento("");
      setTituloDelContrato("");
    }
  }, [isOpen]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row>
        <Col>
          <BusquedaDeEmpresa
            empresaPreseleccionada={null}
            usuarioPreseleccionado={null}
            onChange={handleEmpresaYContactoChange}
          />
        </Col>
        <Col md="auto" />
        <Col xs md="auto">
          <Row>
            <Col className="text-center">
              <Form onSubmit={handleNuevoContratoSubmit}>
                <FormGroup floating>
                  <Input
                    type="text"
                    name="numeroDocumento"
                    id="numeroDocumento"
                    placeholder="Número de Documento"
                    value={numeroDeDocumento}
                    onChange={(e) => setNumeroDeDocumento(e.target.value)}
                    disabled={!empresaSeleccionada}
                    required
                  />
                  <Label for="numeroDocumento">Número de Documento</Label>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    type="text"
                    name="tituloContrato"
                    id="tituloContrato"
                    placeholder="Título del contrato"
                    value={tituloDelContrato}
                    onChange={(e) => setTituloDelContrato(e.target.value)}
                    disabled={!empresaSeleccionada}
                    required
                  />
                  <Label for="tituloContrato">Título del contrato</Label>
                </FormGroup>
                <FormGroup>
                  <Button color="primary" type="submit">
                    Aceptar
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default NuevoContrato;
