import { FC } from "react";
import { Col, Row } from "reactstrap";
import HexButton, { HexMenuContainer } from "../../utilities/HexButton";
import { SeparatorCol } from "../../utilities/utils";
import { PATHS } from "../../utilities/Paths";

const HexagonalMenu: FC = () => {
  return (
    <Row className="justify-content-center">
      <Col xs="auto">
        <HexMenuContainer className="gx-2">
          <Col xs="auto">
            <HexButton
              label="Formación y Toma de Consciencia"
              url={PATHS.formacion.to}
              backgroundColor="#2D6617"
            />
          </Col>
          <Col xs="auto">
            <HexButton
              label="Requisitos Legales"
              url={PATHS.requisitosLegales.to}
              backgroundColor="#79BF35"
            />
          </Col>
          <div className="w-100 d-sm-none" />
          <SeparatorCol width={59} className="d-sm-none" />
          <Col xs="auto">
            <HexButton
              label="Gestión Documental"
              url={PATHS.gestionDocumental.to}
              backgroundColor="#15A43F"
            />
          </Col>
          <div className="w-100 d-none d-sm-block d-lg-none" />
          <SeparatorCol width={59} className="d-none d-sm-block d-lg-none" />
          <Col xs="auto">
            <HexButton
              label="Investigación de Incidentes"
              url={PATHS.investigacionDeIncidentes.to}
              backgroundColor="#34A6CB"
            />
          </Col>
          <div className="w-100 d-none d-lg-block" />
          <SeparatorCol width={59} className="d-none d-lg-block" />
          <Col xs="auto">
            <HexButton
              label="Planes de Acción"
              url={PATHS.planesDeAccion.to}
              backgroundColor="#F69016"
            />
          </Col>
          <div className="w-100 d-none d-sm-block d-lg-none" />
          <Col xs="auto">
            <HexButton label="MIPER" url={PATHS.miper.to} backgroundColor="#12416B" />
          </Col>
          <div className="w-100 d-sm-none" />
          <SeparatorCol width={59} className="d-sm-none" />
          <Col xs="auto">
            <HexButton label="Contexto" url={PATHS.contexto.to} backgroundColor="#2262A2" />
          </Col>
          <Col xs="auto">
            <HexButton
              label="Indicadores de Gestión"
              url={PATHS.indicadores.to}
              backgroundColor="#B03A1A"
            />
          </Col>
        </HexMenuContainer>
      </Col>
    </Row>
  );
};

export default HexagonalMenu;
