import type { FC } from "react";
import { Col, Modal, ModalBody, Row, Spinner } from "reactstrap";
import styled from "styled-components";

const CustomSpinner = styled(Spinner)`
  @media (min-width: 768px) {
    zoom: 2;
  }
`;

interface LoadingSpinnerProps {
  isLoading: boolean;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ isLoading }: LoadingSpinnerProps) => {
  return (
    <Modal isOpen={isLoading} centered fade={false} contentClassName="border-0 bg-transparent">
      <ModalBody>
        <Row className="justify-content-center">
          <Col xs="auto">
            <CustomSpinner color="dark">Loading...</CustomSpinner>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default LoadingSpinner;
