import { FC } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button } from "reactstrap";
import Header from "./Header";

const SolicitudDeRegistro: FC = () => {
  return (
    <>
      <Header />
      <h1>Solicitud de registro enviada</h1>
      <p>
        Su solicitud de registro ha sido enviada y está pendiente de verificación por parte de
        nuestro equipo. Le enviaremos un correo electrónico con instrucciones para verificar su
        cuenta.
      </p>
      <p>Si ya ha verificado su cuenta, puede iniciar sesión.</p>
      <LinkContainer to={{ pathname: "/", hash: "login" }}>
        <Button type="button" color="primary">
          Ir a iniciar sesión
        </Button>
      </LinkContainer>
    </>
  );
};

export default SolicitudDeRegistro;
