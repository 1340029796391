import { useState, FC, useEffect } from "react";
import { Button, Col, Popover, PopoverBody, Row } from "reactstrap";
import { GISSTLogoFull } from "../resources/Images";
import Login from "./Login";
import LocalStorage from "../utilities/LocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../utilities/Paths";

const Home: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [popoverIsOpen, setPopoverIsOpen] = useState<boolean>(false);

  const togglePopover = () => {
    setPopoverIsOpen(!popoverIsOpen);
  };

  useEffect(() => {
    LocalStorage.isLoggedIn().then((isLoggedIn) => {
      if (isLoggedIn) navigate(PATHS.usuarioHome.to);
    });
  }, [navigate]);

  useEffect(() => {
    const { hash } = location;
    if (hash && hash === "#login") {
      setPopoverIsOpen(true);
    }
  }, [location]);

  return (
    <>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button color="primary" type="button" id="loginPopover" onClick={togglePopover}>
            Iniciar sesión
          </Button>
          <Popover
            placement="bottom-end"
            target="loginPopover"
            trigger="legacy"
            isOpen={popoverIsOpen}
            toggle={togglePopover}
            popperClassName="shadow"
          >
            <PopoverBody>
              <Login />
            </PopoverBody>
          </Popover>
        </Col>
      </Row>
      <Row className="justify-content-center gx-0">
        <Col xs="auto" style={{ height: "calc(100vh - 210px)", maxWidth: "calc(100vw - 24px)" }}>
          <GISSTLogoFull />
        </Col>
      </Row>
    </>
  );
};

export default Home;
