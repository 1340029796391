import { FC, FormEvent, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { FaPlus } from "react-icons/fa";
import {
  CustomTable,
  TableContainer,
  dateInputToDate,
  dateToISOString,
} from "../../../utilities/utils";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Empresa } from "../../../models/Empresa";
import { Context } from "../../Context";
import {
  fetchEmpresasVinculadasYSusAdministradores,
  listarAutoridades,
} from "../../../services/usuario-api-client";
import Checkbox from "../../../utilities/Checkbox";
import {
  RequisitoLegalBody,
  TiposDeRequisitosLegales,
  textoTipoDeRequisitoLegal,
} from "../../../models/RequisitoLegal";
import { Vinculacion } from "../../../models/Vinculacion";
import AgregarAutoridadModal from "./AgregarAutoridadModal";
import { Autoridad } from "../../../models/Autoridad";

const now = new Date();
const nowString = dateToISOString(now);
const monthFromNow = new Date();
monthFromNow.setMonth(now.getMonth() + 1);
const monthFromNowString = dateToISOString(monthFromNow);

interface EmpresaYAdministradores {
  empresa: Empresa;
  colaboradores: Vinculacion[];
  responsableDelAnalisis?: string;
}

interface RequisitoLegalFormProps {
  formId: string;
  onSubmit: (body: RequisitoLegalBody) => void;
}

const RequisitoLegalForm: FC<RequisitoLegalFormProps> = ({ formId, onSubmit }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [nombreDelRequisitoLegal, setNombreDelRequisitoLegal] = useState<string>("");
  const [archivoAdjunto, setArchivoAdjunto] = useState<File | null>(null);
  const [tipoDeRequisitoLegal, setTipoDeRequisitoLegal] = useState<TiposDeRequisitosLegales | null>(
    null
  );
  const [autoridad, setAutoridad] = useState<string>("");
  const [numeroDeArticulos, setNumeroDeArticulos] = useState<number>(0);
  const [fechaDeEmisionString, setFechaDeEmisionString] = useState<string>(nowString);
  const [fechaLimiteDeAnalisisString, setFechaLimiteDeAnalisisString] =
    useState<string>(monthFromNowString);
  const [empresasSeleccionadas, setEmpresasSeleccionadas] = useState<string[]>([]);
  const [empresasYAdministradores, setEmpresasYAdministradores] = useState<
    EmpresaYAdministradores[]
  >([]);
  const [agregarAutoridadModalIsOpen, setAgregarAutoridadModalIsOpen] = useState<boolean>(false);
  const [autoridades, setAutoridades] = useState<Autoridad[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleAgregarAutoridadModal = () => {
    setAgregarAutoridadModalIsOpen(!agregarAutoridadModalIsOpen);
  };

  const handleAgregarAutoridadSuccess = (autoridad: Autoridad) => {
    toggleAgregarAutoridadModal();
    setAutoridad(autoridad._id);
    setIsLoading(true);
    listarAutoridades(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => setAutoridades(data))
      .finally(() => setIsLoading(false));
  };

  const handleToggleAllEmpresas = () => {
    if (empresasSeleccionadas.length === empresasYAdministradores.length) {
      setEmpresasSeleccionadas([]);
    } else {
      setEmpresasSeleccionadas(empresasYAdministradores.map(({ empresa }) => empresa._id));
    }
  };

  const handleToggleEmpresa = (idDeLaEmpresa: string) => {
    if (empresasSeleccionadas.includes(idDeLaEmpresa)) {
      setEmpresasSeleccionadas(empresasSeleccionadas.filter((id) => id !== idDeLaEmpresa));
    } else {
      setEmpresasSeleccionadas([...empresasSeleccionadas, idDeLaEmpresa]);
    }
  };

  const handleResponsableDelAnalisisChange = (idDeLaEmpresa: string, idDelResponsable: string) => {
    setEmpresasYAdministradores(
      empresasYAdministradores.map(({ empresa, colaboradores, responsableDelAnalisis }) => ({
        empresa,
        colaboradores,
        responsableDelAnalisis:
          empresa._id === idDeLaEmpresa
            ? colaboradores.find(({ _id }) => _id === idDelResponsable)?._id
            : responsableDelAnalisis,
      }))
    );
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!archivoAdjunto || !empresasSeleccionadas.length) return;
    const fechaDeEmision = dateInputToDate(fechaDeEmisionString);
    const fechaLimiteDeAnalisis = dateInputToDate(fechaLimiteDeAnalisisString);
    const empresasYPersonasResponsables = empresasYAdministradores
      .filter(({ empresa }) => empresasSeleccionadas.includes(empresa._id))
      .map(({ empresa, responsableDelAnalisis }) => ({
        empresa: empresa._id,
        responsableDelAnalisis: responsableDelAnalisis as string,
      }));
    const body = {
      nombreDelRequisitoLegal,
      adjunto: archivoAdjunto as File,
      tipoDeRequisitoLegal: tipoDeRequisitoLegal as TiposDeRequisitosLegales,
      autoridad,
      numeroDeArticulos,
      fechaDeEmision,
      fechaLimiteDeAnalisis,
      empresasYPersonasResponsables,
    };
    onSubmit(body);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    Promise.all([
      fetchEmpresasVinculadasYSusAdministradores(authToken),
      listarAutoridades(authToken, idDeLaEmpresaSeleccionada),
    ])
      .then(([empresasResponse, autoridadesResponse]) => {
        const empresasYAdministradores = empresasResponse.data.map(
          ({ empresa, administradores }) => ({
            empresa,
            colaboradores: administradores.map((administrador) => new Vinculacion(administrador)),
          })
        );
        setEmpresasYAdministradores(empresasYAdministradores);
        if (empresasYAdministradores.length === 1) {
          setEmpresasSeleccionadas([empresasYAdministradores[0].empresa._id]);
        }
        setAutoridades(autoridadesResponse.data);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form onSubmit={handleFormSubmit} id={formId} innerRef={formRef}>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                id="nombreDelRequisitoLegal"
                placeholder="Nombre del requisito legal"
                value={nombreDelRequisitoLegal}
                onChange={(e) => setNombreDelRequisitoLegal(e.target.value)}
                required
              />
              <Label for="nombreDelRequisitoLegal">Nombre del requisito legal</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Input
                type="file"
                id="archivoAdjunto"
                accept="application/pdf"
                onChange={(e) => setArchivoAdjunto(e.target.files?.[0] || null)}
                required
              />
              <FormText>El archivo debe ser un PDF.</FormText>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                id="tipoDeRequisitoLegal"
                placeholder="Tipo de requisito legal"
                value={tipoDeRequisitoLegal || ""}
                onChange={(e) =>
                  setTipoDeRequisitoLegal(e.target.value as TiposDeRequisitosLegales)
                }
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {Object.entries(TiposDeRequisitosLegales).map(([key, value]) => (
                  <option key={key} value={value}>
                    {textoTipoDeRequisitoLegal(value)}
                  </option>
                ))}
              </Input>
              <Label for="tipoDeRequisitoLegal">Tipo de requisito legal</Label>
            </FormGroup>
          </Col>
        </Row>
        <Row className="row-cols-1 row-cols-md-4">
          <Col>
            <InputGroup>
              <FormGroup floating>
                <Input
                  type="select"
                  id="autoridad"
                  placeholder="Autoridad"
                  value={autoridad}
                  onChange={(e) => setAutoridad(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    Seleccionar
                  </option>
                  {autoridades.map(({ _id, nombre }) => (
                    <option key={_id} value={_id}>
                      {nombre}
                    </option>
                  ))}
                </Input>
                <Label for="autoridad">Autoridad</Label>
              </FormGroup>
              <Button
                color="primary"
                onClick={toggleAgregarAutoridadModal}
                title="Agregar"
                className="mb-3"
              >
                <FaPlus size={20} />
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="number"
                id="numeroDeArticulos"
                placeholder="Número de artículos"
                value={numeroDeArticulos}
                onChange={(e) => setNumeroDeArticulos(parseInt(e.target.value))}
                required
                min={1}
              />
              <Label for="numeroDeArticulos">Número de artículos</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="date"
                id="fechaDeEmision"
                placeholder="Fecha de emisión"
                value={fechaDeEmisionString}
                onChange={(e) => setFechaDeEmisionString(e.target.value)}
              />
              <Label for="fechaDeEmision">Fecha de emisión</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="date"
                id="fechaLimiteDeAnalisis"
                placeholder="Fecha límite de análisis"
                value={fechaLimiteDeAnalisisString}
                onChange={(e) => setFechaLimiteDeAnalisisString(e.target.value)}
              />
              <Label for="fechaLimiteDeAnalisis">Fecha límite de análisis</Label>
            </FormGroup>
          </Col>
        </Row>
        <Collapse isOpen={empresasYAdministradores.length > 0}>
          {empresasYAdministradores.length === 1 ? (
            <Row>
              <Col xs md={3}>
                <FormGroup floating>
                  <Input
                    type="select"
                    value={empresasYAdministradores[0].responsableDelAnalisis || ""}
                    onChange={(e) =>
                      handleResponsableDelAnalisisChange(
                        empresasYAdministradores[0].empresa._id,
                        e.target.value
                      )
                    }
                    required
                  >
                    <option value="" disabled>
                      Seleccionar
                    </option>
                    {empresasYAdministradores[0].colaboradores.map(({ _id, nombreCompleto }) => (
                      <option key={_id} value={_id}>
                        {nombreCompleto}
                      </option>
                    ))}
                  </Input>
                  <Label for="responsableDelAnalisis">Responsable del análisis</Label>
                </FormGroup>
              </Col>
            </Row>
          ) : (
            <Row className="justify-content-center gx-0">
              <Col xs="auto">
                <TableContainer>
                  <CustomTable>
                    <thead>
                      <tr>
                        <th>
                          <Checkbox
                            checked={
                              empresasSeleccionadas.length > 0 &&
                              empresasSeleccionadas.length === empresasYAdministradores.length
                            }
                            toggle={handleToggleAllEmpresas}
                          />
                        </th>
                        <th>Empresa</th>
                        <th>Responsable del análisis</th>
                      </tr>
                    </thead>
                    <tbody>
                      {empresasYAdministradores.map(
                        ({ empresa, colaboradores, responsableDelAnalisis }) => (
                          <tr key={empresa._id}>
                            <td
                              className="text-center"
                              onClick={() => handleToggleEmpresa(empresa._id)}
                            >
                              <Checkbox checked={empresasSeleccionadas.includes(empresa._id)} />
                            </td>
                            <td onClick={() => handleToggleEmpresa(empresa._id)}>
                              {empresa.nombreDeLaEmpresa}
                            </td>
                            <td>
                              <Input
                                type="select"
                                value={
                                  empresasSeleccionadas.includes(empresa._id)
                                    ? responsableDelAnalisis || ""
                                    : ""
                                }
                                onChange={(e) =>
                                  handleResponsableDelAnalisisChange(empresa._id, e.target.value)
                                }
                                required={empresasSeleccionadas.includes(empresa._id)}
                                disabled={!empresasSeleccionadas.includes(empresa._id)}
                              >
                                <option value="" disabled>
                                  {empresasSeleccionadas.includes(empresa._id) ? "Seleccionar" : ""}
                                </option>
                                {colaboradores.map(({ _id, nombreCompleto }) => (
                                  <option key={_id} value={_id}>
                                    {nombreCompleto}
                                  </option>
                                ))}
                              </Input>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </CustomTable>
                </TableContainer>
              </Col>
            </Row>
          )}
        </Collapse>
      </Form>
      <AgregarAutoridadModal
        isOpen={agregarAutoridadModalIsOpen}
        toggle={toggleAgregarAutoridadModal}
        onSuccess={handleAgregarAutoridadSuccess}
      />
    </>
  );
};

export default RequisitoLegalForm;
