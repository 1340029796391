import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, ListGroup, ListGroupItem, Table } from "reactstrap";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  Documento,
  FrecuenciaDeActualizacion,
  textoDeLaFrecuenciaDeActualizacion,
  textoDelTipoDeDocumento,
  textoDePeriodoDeActualizacion,
  tituloDeActivos,
} from "../../../../models/Documento";
import { PATHS } from "../../../../utilities/Paths";
import { fetchDocumento } from "../../../../services/usuario-api-client";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { TableContainer } from "../../../../utilities/utils";
import HistoricoDeDocumentos from "./HistoricoDeDocumentos";

const DocumentoView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [documento, setDocumento] = useState<Documento | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.documentoInterno.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    fetchDocumento(authToken, id)
      .then(({ data }) => {
        setDocumento(new Documento(data));
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs
        items={[PATHS.usuarioHome, PATHS.gestionDocumental, PATHS.gestionDocumentalInterna]}
      />
      {!!documento &&
        (({
          nombreDelDocumento,
          tipoDeDocumento,
          frecuenciaDeActualizacion,
          valorDeLaFrecuenciaDeActualizacion,
          unidadDeLaFrecuenciaDeActualizacion,
          tipoDeActivo,
        }: Documento) => (
          <>
            <TableContainer>
              <Table borderless style={{ tableLayout: "auto" }}>
                <tbody>
                  <tr>
                    <th scope="row">Nombre</th>
                    <td>{nombreDelDocumento}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo de documento</th>
                    <td>{textoDelTipoDeDocumento(tipoDeDocumento)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Frecuencia de actualización</th>
                    <td>
                      {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                      !!valorDeLaFrecuenciaDeActualizacion &&
                      !!unidadDeLaFrecuenciaDeActualizacion
                        ? textoDePeriodoDeActualizacion(
                            valorDeLaFrecuenciaDeActualizacion,
                            unidadDeLaFrecuenciaDeActualizacion
                          )
                        : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </TableContainer>
            <Card className="mb-3">
              <CardHeader>
                <b>Personas responsables</b>
              </CardHeader>
              <ListGroup flush>
                {documento.personasResponsables.map((persona) => (
                  <ListGroupItem key={persona._id}>{persona.nombreCompleto}</ListGroupItem>
                ))}
              </ListGroup>
            </Card>
            <Card className="mb-3">
              <CardHeader>
                <b>Activos ({tituloDeActivos(tipoDeActivo)})</b>
              </CardHeader>
              <ListGroup flush>
                {documento.activos?.map((activo) => (
                  <ListGroupItem key={activo._id}>{activo.nombreCompleto}</ListGroupItem>
                ))}
              </ListGroup>
            </Card>
            <HistoricoDeDocumentos documento={documento} />
          </>
        ))(documento)}
    </>
  );
};

export default DocumentoView;
