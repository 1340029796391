import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, Navbar, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import FormularioDeNuevoRiesgo from "./FormularioDeNuevoRiesgo";
import {
  crearEvaluacionDeRiesgo,
  editCalificacion,
  getActividadById,
  listarRequisitosLegales,
  listarRiesgosEvaluados,
} from "../../../../services/usuario-api-client";
import { ActividadResponse } from "../../../../models/Actividad";
import { RequisitoLegal } from "../../../../models/RequisitoLegal";
import {
  EvaluacionDeRiesgo,
  EvaluacionDeRiesgoPayload,
} from "../../../../models/EvaluacionDeRiesgo";
import { FaCheckCircle } from "react-icons/fa";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";

const NUEVO_RIESGO_FORM_ID = "nuevo-riesgo-form";

const NuevoRiesgo: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [actividad, setActividad] = useState<ActividadResponse>();
  const [requisitosLegales, setRequisitosLegales] = useState<RequisitoLegal[]>([]);
  const [peligrosEvaluados, setPeligrosEvaluados] = useState<EvaluacionDeRiesgo[]>([]);
  const [mensajeDeFinalizacionModalIsOpen, setMensajeDeFinalizacionModalIsOpen] =
    useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleNuevoRiesgoSubmit = (body: EvaluacionDeRiesgoPayload, callback: () => void) => {
    if (!actividad?._id) return;
    setLoadingCounter((prev) => prev + 1);
    crearEvaluacionDeRiesgo(authToken, actividad?._id, body)
      .then(() => {
        cargarDatosActualizados();
        callback();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const cargarDatosActualizados = useCallback(() => {
    if (!id) return;
    setLoadingCounter((prev) => prev + 1);

    listarRiesgosEvaluados(authToken, id)
      .then(({ data }) =>
        setPeligrosEvaluados(data.map((evaluacion) => new EvaluacionDeRiesgo(evaluacion)))
      )
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, id]);

  const toggleMensajeDeFinalizacion = () =>
    setMensajeDeFinalizacionModalIsOpen(!mensajeDeFinalizacionModalIsOpen);

  const actualizarCalificacionDeRiesgo = (body: { calificacionMaxima: number }) => {
    if (!actividad || !actividad._id) return;
    setLoadingCounter((prev) => prev + 1);
    editCalificacion(authToken, actividad._id, body)
      .then(toggleMensajeDeFinalizacion)
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const cargarDataInicial = useCallback(() => {
    if (!authToken || !id) return;
    setLoadingCounter((prev) => prev + 1);

    Promise.all([
      getActividadById(authToken, id),
      listarRequisitosLegales(authToken, idDeLaEmpresaSeleccionada),
      listarRiesgosEvaluados(authToken, id),
    ])
      .then(([{ data }, { data: requisitoLegalResponse }, { data: peligrosEvaluados }]) => {
        setActividad(data);
        const requisitosLegales = requisitoLegalResponse.map(
          (requisitoLegal) => new RequisitoLegal(requisitoLegal)
        );
        setRequisitosLegales(requisitosLegales);
        const {
          peligrosBiologicos,
          peligrosDeSeguridad,
          peligrosErgonomicos,
          peligrosFisicos,
          peligrosPsicosociales,
          peligrosQuimicos,
        } = data;
        const peligrosDeLaActividad = [
          ...peligrosBiologicos,
          ...peligrosDeSeguridad,
          ...peligrosErgonomicos,
          ...peligrosFisicos,
          ...peligrosPsicosociales,
          ...peligrosQuimicos,
        ];
        if (peligrosEvaluados.length === peligrosDeLaActividad.length) {
          setMensajeDeFinalizacionModalIsOpen(true);
        } else {
          setPeligrosEvaluados(
            peligrosEvaluados.map((evaluacion) => new EvaluacionDeRiesgo(evaluacion))
          );
        }
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, id, idDeLaEmpresaSeleccionada]);

  useEffect(cargarDataInicial, [cargarDataInicial]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.asignarRiesgos.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.miper]} />
      {actividad && (
        <>
          <Row>
            <Col>
              Departamento: <b>{actividad.departamento}</b>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              Actividad: <b>{actividad.actividad}</b>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <FormularioDeNuevoRiesgo
                formId={NUEVO_RIESGO_FORM_ID}
                actividad={actividad}
                requisitoLegales={requisitosLegales}
                peligrosEvaluados={peligrosEvaluados}
                calificarDeRiesgo={actualizarCalificacionDeRiesgo}
                onSubmit={handleNuevoRiesgoSubmit}
              />
            </Col>
          </Row>
        </>
      )}
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={NUEVO_RIESGO_FORM_ID} type="submit" color="primary" className="ms-auto">
          Guardar
        </Button>
      </Navbar>
      <Modal
        isOpen={mensajeDeFinalizacionModalIsOpen}
        toggle={toggleMensajeDeFinalizacion}
        onClosed={() => navigate(PATHS.miper.to)}
      >
        <ModalBody>
          <Row className="mb-2 justify-content-center">
            <Col xs="auto">
              <FaCheckCircle color="green" size={60} />
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              El riesgo de la actividad <b>{actividad?.actividad}</b> ha sido evaluado
              correctamente.
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleMensajeDeFinalizacion}>
            Entendido
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NuevoRiesgo;
