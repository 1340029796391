import { useEffect, FC, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import Header from "./Header";

const CorreoDeConfirmacionEnviado: FC = () => {
  const location = useLocation();
  const [correo, setCorreo] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const correo = params.get("correo");
    if (correo) {
      setCorreo(correo);
    }
  }, [location]);

  return (
    <>
      <Header />
      <h1>Correo enviado</h1>
      <p>
        Se ha enviado un correo a <b>{correo}</b> con un enlace para verificar su cuenta.
      </p>
      <p>Si ya ha verificado su cuenta, puede iniciar sesión.</p>
      <LinkContainer to={{ pathname: "/", hash: "login" }}>
        <Button type="button" color="primary">
          Ir a iniciar sesión
        </Button>
      </LinkContainer>
    </>
  );
};

export default CorreoDeConfirmacionEnviado;
