import { TiposDeIdentificacion } from "../models/Usuario";
import { validateCedula } from "../utilities/validate-cedula";

interface Validacion {
  isValid: boolean;
  inputString: string;
  isComplete: boolean;
}

export function validarNumeroDeCedula(inputString: string): Validacion {
  const cedulaResponse = validateCedula(inputString);
  return cedulaResponse;
}

export function validarNumeroPasaporte(inputString: string): Validacion {
  const longitudMinima = 6;
  const isComplete = inputString.length >= longitudMinima;

  const isValid = /^[a-zA-Z0-9]+$/.test(inputString);

  return { isValid, inputString, isComplete };
}

export interface TipoDeIdentificacion {
  id: TiposDeIdentificacion;
  nombre: string;
  validador: (inputString: string) => Validacion;
}

const tiposDeIdentificacion: TipoDeIdentificacion[] = [
  {
    id: TiposDeIdentificacion.Cedula,
    nombre: "Cédula",
    validador: validarNumeroDeCedula,
  },
  {
    id: TiposDeIdentificacion.Pasaporte,
    nombre: "Pasaporte",
    validador: validarNumeroPasaporte,
  },
];

export const identificacionMap = new Map<TiposDeIdentificacion, string>([
  [TiposDeIdentificacion.Cedula, "Cédula"],
  [TiposDeIdentificacion.Pasaporte, "Pasaporte"],
]);

export default tiposDeIdentificacion;
