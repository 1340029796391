import { useContext, FC, useState, FormEvent } from "react";
import { EquipoHerramienta, EquipoHerramientaBase } from "../../../../models/EquipoHerramienta";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { createEquipoOHerramienta } from "../../../../services/usuario-api-client";

interface EquipoHerramientaFormProps {
  formId: string;
  onSuccess: (equipoHerramienta: EquipoHerramienta) => void;
}

const EquipoHerramientaForm: FC<EquipoHerramientaFormProps> = ({ formId, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [identificador, setIdentificador] = useState<string>("");
  const [nombre, setNombre] = useState<string>("");
  const [marca, setMarca] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const equipoHerramienta: EquipoHerramientaBase = {
      identificador,
      nombre,
      marca,
    };
    createEquipoOHerramienta(authToken, idDeLaEmpresaSeleccionada, equipoHerramienta)
      .then(({ data }) => onSuccess(data))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="identificador"
                id="identificador"
                placeholder="Identificador"
                value={identificador}
                onChange={(event) => setIdentificador(event.target.value)}
                required
              />
              <Label for="identificador">Identificador</Label>
              <FormText>Puede ser el número de serie o un código único.</FormText>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                required
              />
              <Label for="nombre">Nombre</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="marca"
                id="marca"
                placeholder="Marca"
                value={marca}
                onChange={(event) => setMarca(event.target.value)}
              />
              <Label for="marca">Marca</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EquipoHerramientaForm;
