import { createContext, ReactNode, useEffect, useState } from "react";
import LocalStorage from "../utilities/LocalStorage";
import { Usuario } from "../models/Usuario";
import { Vinculacion } from "../models/Vinculacion";

type ContextType = {
  tituloDeLaPantallaActual: string;
  setTituloDeLaPantallaActual: (titulo: string) => void;
  idDeLaEmpresaSeleccionada: string;
  setIdDeLaEmpresaSeleccionada: (empresa: string) => void;
  authToken: string;
  setAuthToken: (token: string) => void;
  usuarioEnSesion: Usuario | Vinculacion | null;
  setUsuarioEnSesion: (usuario: Usuario | Vinculacion | null) => void;
  adminAuthToken: string;
  setAdminAuthToken: (token: string) => void;
};

export const Context = createContext<ContextType>({
  tituloDeLaPantallaActual: "",
  setTituloDeLaPantallaActual: () => {},
  idDeLaEmpresaSeleccionada: "",
  setIdDeLaEmpresaSeleccionada: () => {},
  authToken: "",
  setAuthToken: () => {},
  usuarioEnSesion: null,
  setUsuarioEnSesion: () => {},
  adminAuthToken: "",
  setAdminAuthToken: () => {},
});

type ContextProviderProps = {
  children: ReactNode;
};

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const [tituloDeLaPantallaActual, setTituloDeLaPantallaActual] = useState<string>("");
  const [idDeLaEmpresaSeleccionada, setIdDeLaEmpresaSeleccionada] = useState<string>("");
  const [authToken, setAuthToken] = useState<string>("");
  const [usuarioEnSesion, setUsuarioEnSesion] = useState<Usuario | Vinculacion | null>(null);
  const [adminAuthToken, setAdminAuthToken] = useState<string>("");

  const handleIdDeLaEmpresaSeleccionadaChange = (nuevoId: string) => {
    if (nuevoId === idDeLaEmpresaSeleccionada) return;
    setIdDeLaEmpresaSeleccionada(nuevoId);
  };

  useEffect(() => {
    LocalStorage.setToken(authToken);
  }, [authToken]);

  useEffect(() => {
    LocalStorage.setIdEmpresa(idDeLaEmpresaSeleccionada);
  }, [idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    LocalStorage.setAdminToken(adminAuthToken);
  }, [adminAuthToken]);

  useEffect(() => {
    Promise.all([
      LocalStorage.getToken(),
      LocalStorage.getIdEmpresa(),
      LocalStorage.getAdminToken(),
    ]).then(([token, id, adminToken]) => {
      if (!!token) setAuthToken(token);
      if (!!id) setIdDeLaEmpresaSeleccionada(id);
      if (!!adminToken) setAdminAuthToken(adminToken);
    });
  }, []);

  return (
    <Context.Provider
      value={{
        tituloDeLaPantallaActual,
        setTituloDeLaPantallaActual,
        idDeLaEmpresaSeleccionada,
        setIdDeLaEmpresaSeleccionada: handleIdDeLaEmpresaSeleccionadaChange,
        authToken,
        setAuthToken,
        usuarioEnSesion,
        setUsuarioEnSesion,
        adminAuthToken,
        setAdminAuthToken,
      }}
    >
      {children}
    </Context.Provider>
  );
};
