import { useContext, FC, useEffect } from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { Context } from "../../Context";
import HexButton from "../../../utilities/HexButton";
import { SeparatorCol } from "../../../utilities/utils";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";

const HexMenuContainer = styled(Row)`
  max-width: 177px;
  margin-bottom: 30px;
`;

const GestionDocumental: FC = () => {
  const { setTituloDeLaPantallaActual } = useContext(Context);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumental.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      <Row className="justify-content-center">
        <Col xs="auto">
          <HexMenuContainer className="gx-2">
            <Col xs="auto">
              <HexButton
                label={PATHS.gestionDocumentalInterna.name}
                url={PATHS.gestionDocumentalInterna.to}
                backgroundColor="#FFD848"
              />
            </Col>
            <div className="w-100" />
            <SeparatorCol width={59} />
            <Col xs="auto">
              <HexButton
                label={PATHS.gestionDocumentalContratos.name}
                url={PATHS.gestionDocumentalContratos.to}
                backgroundColor="#0E0047"
              />
            </Col>
          </HexMenuContainer>
        </Col>
      </Row>
    </>
  );
};

export default GestionDocumental;
