import { useEffect, useRef } from "react";

export const useTimeout = (callback: () => void, delay: number, condition?: boolean) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (condition === undefined || condition) {
      timeoutRef.current = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }
  }, [delay, condition, callback]);

  const clear = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return clear;
};
