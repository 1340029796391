import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useState, FC } from "react";

const AGREGAR_CARGO_FORM_ID = "agregar-cargo-form";

interface AgregarCargoModalProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: (nombreDelCargo: string) => void;
}

const AgregarCargoModal: FC<AgregarCargoModalProps> = ({ isOpen, toggle, onSubmit }) => {
  const [cargo, setCargo] = useState<string>("");

  const clear = () => {
    setCargo("");
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(cargo);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} onClosed={clear}>
      <ModalHeader toggle={toggle}>Agregar Cargo</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit} id={AGREGAR_CARGO_FORM_ID}>
          <FormGroup floating>
            <Input
              type="text"
              name="cargo"
              id="cargo"
              placeholder="Cargo"
              onChange={(event) => setCargo(event.target.value)}
              value={cargo}
              required
            />
            <Label for="cargo">Cargo</Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" form={AGREGAR_CARGO_FORM_ID} color="primary">
          Agregar
        </Button>
        <Button type="button" color="secondary" onClick={toggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AgregarCargoModal;
