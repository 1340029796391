import { FC, FormEvent, useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import {
  PlanDeAccion,
  procesarLinkDeLaFuente,
  textoDeOrigenDePlanDeAccion,
} from "../../../models/PlanDeAccion";
import { ClasificacionDePlanDeAccion, textoDeClasificacionDeAccion } from "../../../models/Accion";
import { Context } from "../../Context";
import {
  agregarAccionAlPlanDeAccion,
  editarAccionDelPlanDeAccion,
  eliminarAccionDelPlanDeAccion,
  fetchVinculaciones,
} from "../../../services/usuario-api-client";
import { Vinculacion } from "../../../models/Vinculacion";
import { FaTrash } from "react-icons/fa";

const now = new Date();
now.setMonth(now.getMonth() + 1);
const defaultDate = now.toISOString().split("T")[0];

interface AccionFormProps {
  formId: string;
  planDeAccion: PlanDeAccion;
  nuevaAccion?: boolean;
  onSuccess: () => void;
}

const AccionForm: FC<AccionFormProps> = ({ formId, planDeAccion, nuevaAccion, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [indiceDeLaAccionSeleccionada, setIndiceDeLaAccionSeleccionada] = useState<number>(-1);
  const [accion, setAccion] = useState<string>("");
  const [clasificacion, setClasificacion] = useState<ClasificacionDePlanDeAccion | null>(null);
  const [responsable, setResponsable] = useState<string>("");
  const [fechaLimiteString, setFechaLimiteString] = useState<string>(defaultDate);
  const [verificador, setVerificador] = useState<string>("");
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [confirmarEliminarAccionModalIsOpen, setConfirmarEliminarAccionModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleConfirmarEliminarAccionModal = () =>
    setConfirmarEliminarAccionModalIsOpen(!confirmarEliminarAccionModalIsOpen);

  const handleEliminarAccionClick = () => {
    toggleConfirmarEliminarAccionModal();
  };

  const confirmarEliminarAccion = () => {
    if (indiceDeLaAccionSeleccionada < 0) return;
    const accionExistente = planDeAccion.acciones[indiceDeLaAccionSeleccionada];
    setIsLoading(true);
    eliminarAccionDelPlanDeAccion(authToken, accionExistente._id)
      .then(() => {
        setIndiceDeLaAccionSeleccionada(-1);
        toggleConfirmarEliminarAccionModal();
        onSuccess();
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!clasificacion) return;
    const body = {
      accion,
      clasificacion,
      responsable,
      fechaLimite: new Date(fechaLimiteString),
      verificador,
      planDeAccion: planDeAccion._id,
    };
    setIsLoading(true);
    if (indiceDeLaAccionSeleccionada < 0) {
      agregarAccionAlPlanDeAccion(authToken, planDeAccion._id, body)
        .then(onSuccess)
        .finally(() => setIsLoading(false));
    } else {
      const accionExistente = planDeAccion.acciones[indiceDeLaAccionSeleccionada];
      editarAccionDelPlanDeAccion(authToken, accionExistente._id, body)
        .then(onSuccess)
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (indiceDeLaAccionSeleccionada < 0) {
      setAccion("");
      setClasificacion(null);
      setResponsable("");
      setFechaLimiteString(defaultDate);
      setVerificador("");
    } else {
      const accion = planDeAccion.acciones[indiceDeLaAccionSeleccionada];
      setAccion(accion?.accion);
      setClasificacion(accion?.clasificacion || null);
      const responsableId =
        typeof accion?.responsable === "string" ? accion?.responsable : accion?.responsable._id;
      setResponsable(responsableId || "");
      setFechaLimiteString(accion?.fechaLimite.toISOString().split("T")[0] || "");
      const verificadorId =
        typeof accion?.verificador === "string" ? accion?.verificador : accion?.verificador._id;
      setVerificador(verificadorId || "");
    }
  }, [indiceDeLaAccionSeleccionada, planDeAccion]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    fetchVinculaciones(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const vinculaciones = data.map((vinculacion) => new Vinculacion(vinculacion));
        setVinculaciones(vinculaciones);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  const { origen, fuente, acciones } = planDeAccion;

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Table borderless>
        <tbody>
          <tr>
            <td>Origen:</td>
            <td>
              <b>{textoDeOrigenDePlanDeAccion(origen)}</b>
            </td>
          </tr>
          <tr>
            <td>Fuente:</td>
            <td>
              <a
                href={fuente && procesarLinkDeLaFuente(origen, fuente)}
                target="_blank"
                rel="noreferrer"
              >
                <b>{typeof fuente === "string" ? fuente : fuente?.nombreCompleto}</b>
              </a>
            </td>
          </tr>
          <tr>
            <td>Descripción:</td>
            <td>
              <b>{planDeAccion.descripcion}</b>
            </td>
          </tr>
        </tbody>
      </Table>
      {!nuevaAccion && (
        <Row>
          <Col>
            <UncontrolledDropdown className="mb-3">
              <DropdownToggle color="primary" block disabled={!acciones.length} caret>
                {indiceDeLaAccionSeleccionada < 0
                  ? "Nueva Acción"
                  : `Acción ${indiceDeLaAccionSeleccionada + 1}`}
              </DropdownToggle>
              <DropdownMenu className="w-100">
                <DropdownItem
                  onClick={() => setIndiceDeLaAccionSeleccionada(-1)}
                  active={indiceDeLaAccionSeleccionada < 0}
                >
                  Nueva Acción
                </DropdownItem>
                {acciones.map(({ _id }, index) => (
                  <DropdownItem
                    key={_id}
                    onClick={() => setIndiceDeLaAccionSeleccionada(index)}
                    active={index === indiceDeLaAccionSeleccionada}
                  >
                    Acción {index + 1}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          <Col xs="auto">
            <Button
              type="button"
              color="danger"
              onClick={handleEliminarAccionClick}
              disabled={indiceDeLaAccionSeleccionada < 0}
            >
              <FaTrash />
            </Button>
          </Col>
        </Row>
      )}
      <Card body>
        <Form id={formId} onSubmit={handleSubmit}>
          <FormGroup floating>
            <Input
              type="textarea"
              name="accion"
              id="accion"
              placeholder="Acción"
              value={accion}
              onChange={({ target }) => setAccion(target.value)}
              required
            />
            <Label for="accion">Acción</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="select"
              name="clasificacion"
              id="clasificacion"
              placeholder="Clasificación"
              value={clasificacion || ""}
              onChange={({ target }) =>
                setClasificacion(target.value as ClasificacionDePlanDeAccion)
              }
              required
            >
              <option value="" disabled>
                Seleccionar
              </option>
              {Object.values(ClasificacionDePlanDeAccion).map((clasificacion) => (
                <option key={clasificacion} value={clasificacion}>
                  {textoDeClasificacionDeAccion(clasificacion)}
                </option>
              ))}
            </Input>
            <Label for="clasificacion">Clasificación</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="select"
              name="responsable"
              id="responsable"
              placeholder="Responsable"
              value={responsable}
              onChange={({ target }) => setResponsable(target.value)}
              required
            >
              <option value="" disabled>
                Seleccionar
              </option>
              {vinculaciones.map(({ _id, nombreCompleto }) => (
                <option key={_id} value={_id}>
                  {nombreCompleto}
                </option>
              ))}
            </Input>
            <Label for="responsable">Responsable</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="date"
              name="fechaLimite"
              id="fechaLimite"
              placeholder="Fecha Límite"
              value={fechaLimiteString}
              onChange={({ target }) => setFechaLimiteString(target.value)}
              required
            />
            <Label for="fechaLimite">Fecha Límite</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              type="select"
              name="verificador"
              id="verificador"
              placeholder="Verificador"
              value={verificador}
              onChange={({ target }) => setVerificador(target.value)}
              required
            >
              <option value="" disabled>
                Seleccionar
              </option>
              {vinculaciones.map(({ _id, nombreCompleto }) => (
                <option key={_id} value={_id}>
                  {nombreCompleto}
                </option>
              ))}
            </Input>
            <Label for="verificador">Verificador</Label>
          </FormGroup>
        </Form>
      </Card>
      <Modal
        size="sm"
        isOpen={confirmarEliminarAccionModalIsOpen}
        toggle={toggleConfirmarEliminarAccionModal}
      >
        <ModalHeader toggle={toggleConfirmarEliminarAccionModal}>Eliminar Acción</ModalHeader>
        <ModalBody>¿Está seguro que desea eliminar esta acción?</ModalBody>
        <ModalFooter>
          <Button type="button" color="danger" onClick={confirmarEliminarAccion}>
            Sí
          </Button>
          <Button type="button" color="secondary" onClick={toggleConfirmarEliminarAccionModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AccionForm;
