import { FC, useContext, useEffect, useState } from "react";
import { Button, Navbar } from "reactstrap";
import { useNavigate } from "react-router-dom";
import DocumentoLegalForm from "./DocumentoLegalForm";
import { Context } from "../../Context";
import { SeparatorRow } from "../../../utilities/utils";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { crearDocumentoLegal } from "../../../services/admin-api-client";
import { DocumentoLegalBody } from "../../../models/DocumentoLegal";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const AGREGAR_DOCUMENTO_LEGAL_FORM_ID = "agregarDocumentoLegalForm";

const NuevoDocumentoLegal: FC = () => {
  const navigate = useNavigate();
  const { adminAuthToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = (body: DocumentoLegalBody) => {
    if (!adminAuthToken) return;
    console.log(body);
    setIsLoading(true);
    crearDocumentoLegal(adminAuthToken, body)
      .then(() => navigate(PATHS.adminDocumentosLegales.to))
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.adminNuevoDocumentoLegal.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.adminHome, PATHS.adminDocumentosLegales]} />
      <DocumentoLegalForm formId={AGREGAR_DOCUMENTO_LEGAL_FORM_ID} onSubmit={handleFormSubmit} />
      <SeparatorRow height={30} />
      <Navbar light container fixed="bottom" className="bg-white">
        <Button
          form={AGREGAR_DOCUMENTO_LEGAL_FORM_ID}
          type="submit"
          color="primary"
          className="ms-auto"
        >
          Crear
        </Button>
      </Navbar>
    </>
  );
};

export default NuevoDocumentoLegal;
