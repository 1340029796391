import { FC, useContext, useEffect } from "react";
import { Button, Col, Navbar, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context";
import CursoForm from "./CursoForm";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";

const NUEVO_CURSO_FORM_ID = "nuevoCursoForm";

const NuevoCurso: FC = () => {
  const navigate = useNavigate();
  const { setTituloDeLaPantallaActual } = useContext(Context);

  const handleNuevoCursoSuccess = () => {
    navigate(PATHS.cursos.to);
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.nuevoCurso.name),
    [setTituloDeLaPantallaActual]
  );

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.formacion, PATHS.cursos]} />
      <Row className="mb-5">
        <Col>
          <CursoForm formId={NUEVO_CURSO_FORM_ID} onSuccess={handleNuevoCursoSuccess} />
        </Col>
      </Row>
      <Navbar light container fixed="bottom" className="bg-white">
        <Button form={NUEVO_CURSO_FORM_ID} type="submit" color="primary" className="ms-auto">
          Crear
        </Button>
      </Navbar>
    </>
  );
};

export default NuevoCurso;
