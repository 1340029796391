import { ChangeEvent, FC, FormEvent, useContext, useState } from "react";
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import styled from "styled-components";
import { Context } from "../../../Context";
import todosLosPeligros from "../../../../resources/peligros.json";
import {
  Actividad,
  ActividadPayload,
  Peligro,
  TiposDeActividad,
  TiposDeServicio,
  textoDeTipoDeActividad,
  textoDeTipoDeServicio,
} from "../../../../models/Actividad";

const ContainerPeligro = styled(Row)`
  height: 252px;
  overflow-y: auto;
`;

interface FormularioDeNuevaActividadProps {
  formId: string;
  actividadParaEditar?: Actividad;
  onSubmit: (body: ActividadPayload, callback: () => void) => void;
}

function getPeligrosPorTipo(tipoDePeligro: number) {
  const peligroPorId = todosLosPeligros.find(({ id }) => id === tipoDePeligro);
  return peligroPorId?.peligros || [];
}

export enum TiposDePeligro {
  PeligroFisico = 1,
  PeligrosQuimico = 2,
  PeligrosBiologico = 3,
  PeligrosPsicosocial = 4,
  PeligrosErgonomicos = 5,
  PeligrosDeSeguridad = 6,
}

const FormularioDeActividad: FC<FormularioDeNuevaActividadProps> = ({
  formId,
  actividadParaEditar,
  onSubmit,
}) => {
  const { idDeLaEmpresaSeleccionada } = useContext(Context);
  const [departamento, setDepartamento] = useState<string>(
    actividadParaEditar ? actividadParaEditar.departamento : ""
  );
  const [servicio, setServicio] = useState<TiposDeServicio | null>(
    actividadParaEditar ? actividadParaEditar.servicio : null
  );
  const [tipoDeActividad, setTipoDeActividad] = useState<TiposDeActividad | null>(
    actividadParaEditar ? actividadParaEditar.tipoDeActividad : null
  );
  const [actividad, setActividad] = useState<string>(
    actividadParaEditar ? actividadParaEditar.actividad : ""
  );
  const [peligrosFisicos, setPeligroFisico] = useState<Peligro[]>(
    actividadParaEditar && actividadParaEditar.peligrosFisicos
      ? actividadParaEditar.peligrosFisicos
      : []
  );
  const [peligrosQuimicos, setPeligrosQuimico] = useState<Peligro[]>(
    actividadParaEditar && actividadParaEditar.peligrosQuimicos
      ? actividadParaEditar.peligrosQuimicos
      : []
  );
  const [peligrosBiologicos, setPeligrosBiologicos] = useState<Peligro[]>(
    actividadParaEditar && actividadParaEditar.peligrosBiologicos
      ? actividadParaEditar.peligrosBiologicos
      : []
  );
  const [peligrosPsicosocial, setPeligroPsicosocial] = useState<Peligro[]>(
    actividadParaEditar && actividadParaEditar.peligrosPsicosociales
      ? actividadParaEditar.peligrosPsicosociales
      : []
  );
  const [peligrosErgonomicos, setPeligrosErgonomicos] = useState<Peligro[]>(
    actividadParaEditar && actividadParaEditar.peligrosErgonomicos
      ? actividadParaEditar.peligrosErgonomicos
      : []
  );
  const [peligrosDeSeguridad, setPeligroDeSeguridad] = useState<Peligro[]>(
    actividadParaEditar && actividadParaEditar.peligrosDeSeguridad
      ? actividadParaEditar.peligrosDeSeguridad
      : []
  );

  const handleClear = () => {
    setDepartamento("");
    setActividad("");
    setServicio(null);
    setTipoDeActividad(null);
    setPeligroFisico([]);
    setPeligrosQuimico([]);
    setPeligrosBiologicos([]);
    setPeligrosErgonomicos([]);
    setPeligroPsicosocial([]);
    setPeligroDeSeguridad([]);
  };

  const handlePeligroFisicoChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    if (target.checked) {
      const peligro = getPeligrosPorTipo(TiposDePeligro.PeligroFisico).find(
        ({ id }) => id === parseInt(peligroId)
      );
      if (peligro) setPeligroFisico([...peligrosFisicos, peligro]);
    } else {
      setPeligroFisico(peligrosFisicos.filter(({ id }) => id !== parseInt(peligroId)));
    }
  };

  const handlePeligroQuimicoChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    if (target.checked) {
      const peligro = getPeligrosPorTipo(TiposDePeligro.PeligrosQuimico).find(
        ({ id }) => id === parseInt(peligroId)
      );
      if (peligro) setPeligrosQuimico([...peligrosQuimicos, peligro]);
    } else {
      setPeligrosQuimico(peligrosQuimicos.filter(({ id }) => id !== parseInt(peligroId)));
    }
  };

  const handlePeligroBiologicosChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    if (target.checked) {
      const peligro = getPeligrosPorTipo(TiposDePeligro.PeligrosBiologico).find(
        ({ id }) => id === parseInt(peligroId)
      );
      if (peligro) setPeligrosBiologicos([...peligrosBiologicos, peligro]);
    } else {
      setPeligrosBiologicos(peligrosBiologicos.filter(({ id }) => id !== parseInt(peligroId)));
    }
  };

  const handlePeligroPsicosocialChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    if (target.checked) {
      const peligro = getPeligrosPorTipo(TiposDePeligro.PeligrosPsicosocial).find(
        ({ id }) => id === parseInt(peligroId)
      );
      if (peligro) setPeligroPsicosocial([...peligrosPsicosocial, peligro]);
    } else {
      setPeligroPsicosocial(peligrosPsicosocial.filter(({ id }) => id !== parseInt(peligroId)));
    }
  };

  const handlePeligroErgonomicosChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    if (target.checked) {
      const peligro = getPeligrosPorTipo(TiposDePeligro.PeligrosErgonomicos).find(
        ({ id }) => id === parseInt(peligroId)
      );
      if (peligro) setPeligrosErgonomicos([...peligrosErgonomicos, peligro]);
    } else {
      setPeligrosErgonomicos(peligrosErgonomicos.filter(({ id }) => id !== parseInt(peligroId)));
    }
  };

  const handlePeligroDeSeguridadChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const peligroId = target.value;
    if (target.checked) {
      const peligro = getPeligrosPorTipo(TiposDePeligro.PeligrosDeSeguridad).find(
        ({ id }) => id === parseInt(peligroId)
      );
      if (peligro) setPeligroDeSeguridad([...peligrosDeSeguridad, peligro]);
    } else {
      setPeligroDeSeguridad(peligrosDeSeguridad.filter(({ id }) => id !== parseInt(peligroId)));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!servicio || !tipoDeActividad) return;
    onSubmit(
      {
        departamento,
        actividad,
        servicio,
        tipoDeActividad,
        peligrosFisicos,
        peligrosQuimicos,
        peligrosBiologicos,
        peligrosPsicosociales: peligrosPsicosocial,
        peligrosErgonomicos,
        peligrosDeSeguridad,
        empresa: idDeLaEmpresaSeleccionada,
      },
      handleClear
    );
  };

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                id="departamento"
                name="departamento"
                placeholder="Departamento"
                type="text"
                required
                value={departamento}
                onChange={(e) => setDepartamento(e.target.value)}
              />
              <Label for="departamento">Departamento</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                id="actividad"
                name="actividad"
                placeholder="Actividad"
                type="text"
                required
                value={actividad}
                onChange={(e) => setActividad(e.target.value)}
              />
              <Label for="actividad">Actividad</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="servicio"
                placeholder="Servicio"
                value={servicio || ""}
                onChange={(e) => setServicio(e.target.value as TiposDeServicio)}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {Object.values(TiposDeServicio).map((tipoDeServicio) => (
                  <option key={tipoDeServicio} value={tipoDeServicio}>
                    {textoDeTipoDeServicio(tipoDeServicio)}
                  </option>
                ))}
              </Input>
              <Label for="servicio">Servicio</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                name="tipoDeActividad"
                placeholder="Tipo de actividad"
                value={tipoDeActividad || ""}
                onChange={(e) => setTipoDeActividad(e.target.value as TiposDeActividad)}
                required
              >
                <option value="" disabled>
                  Seleccionar
                </option>
                {Object.values(TiposDeActividad).map((tipoDeActividad) => (
                  <option key={tipoDeActividad} value={tipoDeActividad}>
                    {textoDeTipoDeActividad(tipoDeActividad)}
                  </option>
                ))}
              </Input>
              <Label for="tipoDeActividad">Tipo de actividad</Label>
            </FormGroup>
          </Col>
          <Col>
            <Card className="mb-3">
              <CardBody className="py-1">
                <Label className="small text-muted">Peligros Fisicos</Label>
                <ContainerPeligro>
                  <Col>
                    <Row className="align-items-start row-cols-2">
                      {getPeligrosPorTipo(TiposDePeligro.PeligroFisico).map(({ nombre, id }) => (
                        <Col key={id}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                name="peligro-fisico"
                                value={id}
                                onChange={handlePeligroFisicoChange}
                                checked={peligrosFisicos.some((p) => p.id === id)}
                              />
                              {nombre}
                            </Label>
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </ContainerPeligro>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <CardBody className="py-1">
                <Label className="small text-muted">Peligros Químicos</Label>
                <ContainerPeligro>
                  <Col>
                    <Row className="align-items-start row-cols-2">
                      {getPeligrosPorTipo(TiposDePeligro.PeligrosQuimico).map(({ nombre, id }) => (
                        <Col key={id}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                name="peligro-quimicos"
                                value={id}
                                onChange={handlePeligroQuimicoChange}
                                checked={peligrosQuimicos.some((p) => p.id === id)}
                              />
                              {nombre}
                            </Label>
                          </FormGroup>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </ContainerPeligro>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <CardBody className="py-1">
                <Label className="small text-muted">Peligros Biológicos</Label>
                <ContainerPeligro>
                  <Col>
                    <Row className="align-items-start row-cols-2">
                      {getPeligrosPorTipo(TiposDePeligro.PeligrosBiologico).map(
                        ({ nombre, id }) => (
                          <Col key={id}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="peligro-biologicos"
                                  value={id}
                                  onChange={handlePeligroBiologicosChange}
                                  checked={peligrosBiologicos.some((p) => p.id === id)}
                                />
                                {nombre}
                              </Label>
                            </FormGroup>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </ContainerPeligro>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <CardBody className="py-1">
                <Label className="small text-muted">Peligros Psicosociales</Label>
                <ContainerPeligro>
                  <Col>
                    <Row className="align-items-start row-cols-2">
                      {getPeligrosPorTipo(TiposDePeligro.PeligrosPsicosocial).map(
                        ({ nombre, id }) => (
                          <Col key={id}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="peligro-psciosociales"
                                  value={id}
                                  onChange={handlePeligroPsicosocialChange}
                                  checked={peligrosPsicosocial.some((p) => p.id === id)}
                                />
                                {nombre}
                              </Label>
                            </FormGroup>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </ContainerPeligro>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <CardBody className="py-1">
                <Label className="small text-muted">Peligros Ergonómicos</Label>
                <ContainerPeligro>
                  <Col>
                    <Row className="align-items-start row-cols-2">
                      {getPeligrosPorTipo(TiposDePeligro.PeligrosErgonomicos).map(
                        ({ nombre, id }) => (
                          <Col key={id}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="peligro-ergonomicos"
                                  value={id}
                                  onChange={handlePeligroErgonomicosChange}
                                  checked={peligrosErgonomicos.some((p) => p.id === id)}
                                />
                                {nombre}
                              </Label>
                            </FormGroup>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </ContainerPeligro>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <CardBody className="py-1">
                <Label className="small text-muted">Peligros de Seguridad</Label>
                <ContainerPeligro>
                  <Col>
                    <Row className="align-items-start row-cols-2">
                      {getPeligrosPorTipo(TiposDePeligro.PeligrosDeSeguridad).map(
                        ({ nombre, id }) => (
                          <Col key={id}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  name="peligro-de-seguridad"
                                  value={id}
                                  onChange={handlePeligroDeSeguridadChange}
                                  checked={peligrosDeSeguridad.some((p) => p.id === id)}
                                />
                                {nombre}
                              </Label>
                            </FormGroup>
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </ContainerPeligro>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormularioDeActividad;
