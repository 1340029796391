export enum TiposDeEmpresa {
  Natural = "natural",
  Juridica = "juridica",
}

export interface EmpresaBase {
  nombreDeLaEmpresa: string;
  ruc: string;
  digitoVerificador: string;
  tipoDeIndustria: string;
  direccion: string;
  nombreDelPropietario?: string;
  cedulaDelPropietario?: string;
}

export interface Empresa extends EmpresaBase {
  _id: string;
  tipoDeEmpresa: TiposDeEmpresa;
  empresasContratadas?: Empresa[];
  empresasContratrantes?: Empresa[];
}
