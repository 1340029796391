import { FC, FormEvent, useCallback, useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import { TipoDeContexto } from "../../../models/TipoDecontexto";
import {
  createTipoDeContexto,
  deleteTipoDeContexto,
  listTiposDeContexto,
  updateTipoDeContexto,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { FaEdit, FaTrash } from "react-icons/fa";

const EDITAR_TIPO_DE_CONTEXTO_FORM_ID = "editarTipoDeContexto";

interface GestionarTiposDeContextoProps {
  isOpen: boolean;
}

const GestionarTiposDeContexto: FC<GestionarTiposDeContextoProps> = ({ isOpen }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [nombre, setNombre] = useState<string>("");
  const [tiposDeContexto, setTiposDeContexto] = useState<TipoDeContexto[]>([]);
  const [tipoDeContextoSeleccionado, setTipoDeContextoSeleccionado] =
    useState<TipoDeContexto | null>(null);
  const [editarModalIsOpen, setEditarModalIsOpen] = useState<boolean>(false);
  const [confirmarEliminarModalIsOpen, setConfirmarEliminarModalIsOpen] = useState<boolean>(false);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const cargarTiposDeContexto = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || !isOpen) return;
    setLoadingCounter((prev) => prev + 1);
    listTiposDeContexto(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setTiposDeContexto(data);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada, isOpen]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingCounter((prev) => prev + 1);
    createTipoDeContexto(authToken, idDeLaEmpresaSeleccionada, nombre)
      .then(() => {
        setNombre("");
        cargarTiposDeContexto();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const toggleEditarModal = () => setEditarModalIsOpen((prev) => !prev);

  const handleEditarClick = (id: string) => {
    const tipoDeContexto = tiposDeContexto.find((tipo) => tipo._id === id);
    if (!tipoDeContexto) return;
    setTipoDeContextoSeleccionado(tipoDeContexto);
    toggleEditarModal();
  };

  const handleEditarSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tipoDeContextoSeleccionado) return;
    const nombre = new FormData(e.currentTarget).get("nombre") as string;
    setLoadingCounter((prev) => prev + 1);
    updateTipoDeContexto(authToken, tipoDeContextoSeleccionado._id, nombre)
      .then(() => {
        cargarTiposDeContexto();
        toggleEditarModal();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const toggleConfirmarEliminarModal = () => setConfirmarEliminarModalIsOpen((prev) => !prev);

  const handleEliminarClick = (id: string) => {
    const tipoDeContexto = tiposDeContexto.find((tipo) => tipo._id === id);
    if (!tipoDeContexto) return;
    setTipoDeContextoSeleccionado(tipoDeContexto);
    toggleConfirmarEliminarModal();
  };

  const confirmarEliminar = () => {
    if (!tipoDeContextoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    deleteTipoDeContexto(authToken, tipoDeContextoSeleccionado._id)
      .then(() => {
        cargarTiposDeContexto();
        toggleConfirmarEliminarModal();
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const limpiarSeleccion = () => {
    setTipoDeContextoSeleccionado(null);
  };

  useEffect(cargarTiposDeContexto, [cargarTiposDeContexto, isOpen]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Form onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            name="nombre"
            id="nombre"
            placeholder="Nombre de tipo de contexto"
            value={nombre}
            onChange={({ target }) => setNombre(target.value)}
            required
          />
          <Label for="nombre">Nombre de tipo de contexto</Label>
        </FormGroup>
        <Row className="justify-content-end mb-3">
          <Col xs="auto">
            <Button color="primary" type="submit">
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <Card body className="p-0">
        <ListGroup flush>
          <ListGroupItem>
            <Label className="small text-muted">Tipos de contexto registrados</Label>
          </ListGroupItem>
          {tiposDeContexto.map(({ _id, nombre }) => (
            <ListGroupItem key={_id}>
              <Row className="align-items-center gx-1">
                <Col>{nombre}</Col>
                <Col xs="auto">
                  <Button
                    type="button"
                    color="primary"
                    title="Editar tipo de contexto"
                    onClick={() => handleEditarClick(_id)}
                  >
                    <FaEdit />
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    type="button"
                    color="danger"
                    title="Eliminar tipo de contexto"
                    onClick={() => handleEliminarClick(_id)}
                  >
                    <FaTrash />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          ))}
          {tiposDeContexto.length === 0 && (
            <ListGroupItem>
              <Row>
                <Col className="text-center">No hay ninguno registrada</Col>
              </Row>
            </ListGroupItem>
          )}
        </ListGroup>
      </Card>
      <Modal
        isOpen={editarModalIsOpen}
        toggle={toggleEditarModal}
        size="sm"
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleEditarModal}>Editar tipo de contexto</ModalHeader>
        <ModalBody>
          <Form id={EDITAR_TIPO_DE_CONTEXTO_FORM_ID} onSubmit={handleEditarSubmit}>
            <FormGroup floating>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre de tipo de contexto"
                defaultValue={tipoDeContextoSeleccionado?.nombre}
                required
              />
              <Label for="nombre">Nombre de tipo de contexto</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" form={EDITAR_TIPO_DE_CONTEXTO_FORM_ID} color="primary">
            Guardar
          </Button>
          <Button color="secondary" onClick={toggleEditarModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={confirmarEliminarModalIsOpen}
        toggle={toggleConfirmarEliminarModal}
        size="sm"
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarEliminarModal}>Eliminar tipo de contexto</ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea eliminar el tipo de contexto{" "}
            <b>{tipoDeContextoSeleccionado?.nombre}</b>?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmarEliminar}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={toggleConfirmarEliminarModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default GestionarTiposDeContexto;
