import { useEffect, FC, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Context } from "../../../Context";
import NuevoContrato from "./NuevoContrato";
import ListadoDeContratos from "./ListadoDeContratos";
import Breadcrumbs from "../../../../utilities/Breadcrumbs";
import { PATHS } from "../../../../utilities/Paths";

enum Tabs {
  Contratos,
  Nuevo,
}

const AdministracionDeContratos: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual } = useContext(Context);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Contratos);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.gestionDocumentalContratos.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(() => {
    location.hash === "#nuevo" && setActiveTab(Tabs.Nuevo);
    location.hash === "#lista" && setActiveTab(Tabs.Contratos);
  }, [location]);

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.gestionDocumental]} />
      <Row className="mb-3">
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                href="#lista"
                active={activeTab === Tabs.Contratos}
                onClick={() => setActiveTab(Tabs.Contratos)}
              >
                Contratos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#nuevo"
                active={activeTab === Tabs.Nuevo}
                onClick={() => setActiveTab(Tabs.Nuevo)}
              >
                Nuevo
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={Tabs.Contratos}>
          <ListadoDeContratos
            isOpen={activeTab === Tabs.Contratos}
            onNuevoContratoClick={() => setActiveTab(Tabs.Nuevo)}
          />
        </TabPane>
        <TabPane tabId={Tabs.Nuevo}>
          <NuevoContrato isOpen={activeTab === Tabs.Nuevo} />
        </TabPane>
      </TabContent>
    </>
  );
};
export default AdministracionDeContratos;
