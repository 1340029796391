import { Usuario, UsuarioResponse } from "./Usuario";
import { UsuarioNoRegistrado, UsuarioNoRegistradoResponse } from "./UsuarioNoRegistrado";
import { Vinculacion, VinculacionResponse } from "./Vinculacion";

interface TestigoBase {
  testigo:
    | Vinculacion
    | VinculacionResponse
    | UsuarioNoRegistrado
    | UsuarioNoRegistradoResponse
    | Usuario
    | UsuarioResponse;
  model: "Vinculacion" | "UsuarioNoRegistrado" | "Usuario";
}

export interface TestigoResponse extends TestigoBase {
  testigo: VinculacionResponse | UsuarioNoRegistradoResponse | UsuarioResponse;
}

export interface Testigo extends TestigoBase {
  testigo: Vinculacion | UsuarioNoRegistrado | Usuario;
}

export class Testigo implements Testigo {
  constructor(testigo: Testigo);
  constructor(testigo: TestigoResponse);
  constructor({ testigo, model }: TestigoBase) {
    switch (model) {
      case "Vinculacion":
        if (testigo instanceof Vinculacion) {
          this.testigo = testigo;
        } else {
          this.testigo = new Vinculacion(testigo as VinculacionResponse);
        }
        break;
      case "UsuarioNoRegistrado":
        if (testigo instanceof UsuarioNoRegistrado) {
          this.testigo = testigo;
        } else {
          this.testigo = new UsuarioNoRegistrado(testigo as UsuarioNoRegistradoResponse);
        }
        break;
      case "Usuario":
        if (testigo instanceof Usuario) {
          this.testigo = testigo;
        } else {
          this.testigo = new Usuario(testigo as UsuarioResponse);
        }
        break;
    }
    this.model = model;
  }

  get nombreCompleto() {
    return this.testigo.nombreCompleto;
  }

  toString() {
    return this.testigo._id;
  }
}

export interface TestigoPayload {
  testigo: string;
  model: "Vinculacion" | "UsuarioNoRegistrado" | "Usuario";
}
