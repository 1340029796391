import { useContext, FC, useEffect, useState, useCallback } from "react";
import classNames from "classnames";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Context } from "../../Context";
import {
  ParteInteresada,
  ParteInteresadaPayload,
  textoTipoDeParteInteresada,
  textoTipoNecesidadExpectativa,
} from "../../../models/ParteInteresada";
import {
  addContextoAParteInteresada,
  deleteContexto,
  listPartesInteresadasDeLaEmpresa,
  listarPlanesDeAccionPorOrigen,
  updateContexto,
  updateParteInteresada,
} from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { CustomTable, TableCaption, TableContainer, TableMessage } from "../../../utilities/utils";
import {
  ClasificacionDelContexto,
  Contexto,
  ContextoPayload,
  textoDeClasificacionDelContexto,
} from "../../../models/Contexto";
import ParteInteresadaForm from "./ParteInteresadaForm";
import GestionarTiposDeContexto from "./GestionarTiposDeContexto";
import ContextoForm from "./ContextoForm";
import { PATHS, procesarPath } from "../../../utilities/Paths";
import { OrigenDePlanDeAccion, PlanDeAccion } from "../../../models/PlanDeAccion";
import BotonPlanDeAccion from "./BotonPlanDeAccion";

const EDITAR_PARTE_INTERESADA_FORM_ID = "editarParteInteresada";
const AGREGAR_CONTEXTO_FORM_ID = "agregarContexto";
const EDITAR_CONTEXTO_FORM_ID = "editarContexto";

const TablaDePartesInteresadas: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [partesInteresadas, setPartesInteresadas] = useState<ParteInteresada[]>([]);
  const [planesDeAccion, setPlanesDeAccion] = useState<PlanDeAccion[]>([]);
  const [parteInteresadaSeleccionada, setParteInteresadaSeleccionada] = useState<ParteInteresada>();
  const [editarParteInteresadaModalIsOpen, setEditarParteInteresadaModalIsOpen] =
    useState<boolean>(false);
  const [agregarContextoModalIsOpen, setAgregarContextoModalIsOpen] = useState<boolean>(false);
  const [contextoSeleccionado, setContextoSeleccionado] = useState<Contexto>();
  const [gestionarTiposDeContextoIsOpen, setGestionarTiposDeContextoIsOpen] =
    useState<boolean>(false);
  const [editarContextoModalIsOpen, setEditarContextoModalIsOpen] = useState<boolean>(false);
  const [confirmarEliminarContextoModalIsOpen, setConfirmarEliminarContextoModalIsOpen] =
    useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const toggleEditarParteInteresadaModal = () =>
    setEditarParteInteresadaModalIsOpen(!editarParteInteresadaModalIsOpen);

  const handleEditarParteInteresadaClick = (id: string) => {
    setParteInteresadaSeleccionada(
      partesInteresadas.find((parteInteresada) => parteInteresada._id === id)
    );
    toggleEditarParteInteresadaModal();
  };

  const handleEditarParteInteresadaSubmit = (parteInteresada: ParteInteresadaPayload) => {
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    if (!parteInteresadaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    updateParteInteresada(authToken, parteInteresadaSeleccionada?._id, parteInteresada)
      .then(() => {
        cargarPartesInteresadas();
        toggleEditarParteInteresadaModal();
      })
      .catch(({ response }) => {
        setMensajeDeError(response?.data?.message || "Error al editar la parte interesada");
        setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const toggleAgregarContextoModal = () =>
    setAgregarContextoModalIsOpen(!agregarContextoModalIsOpen);

  const handleAgregarContextoClick = (id: string) => {
    setParteInteresadaSeleccionada(
      partesInteresadas.find((parteInteresada) => parteInteresada._id === id)
    );
    toggleAgregarContextoModal();
  };

  const handleAgregarContextoSubmit = (contexto: ContextoPayload) => {
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    if (!parteInteresadaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    addContextoAParteInteresada(authToken, parteInteresadaSeleccionada?._id, contexto)
      .then(() => {
        cargarPartesInteresadas();
        toggleAgregarContextoModal();
      })
      .catch(({ response }) => {
        setMensajeDeError(response?.data?.message || "Error al agregar el contexto");
        setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const toggleGestionarTiposDeContexto = () =>
    setGestionarTiposDeContextoIsOpen(!gestionarTiposDeContextoIsOpen);

  const toggleEditarContextoModal = () => setEditarContextoModalIsOpen(!editarContextoModalIsOpen);

  const handleEditarContextoClick = (id: string, contextoId: string) => {
    const parteInteresada = partesInteresadas.find((parteInteresada) => parteInteresada._id === id);
    if (!parteInteresada) return;
    const contexto = parteInteresada.contextos?.find((contexto) => contexto._id === contextoId);
    if (!contexto) return;
    setContextoSeleccionado(contexto);
    toggleEditarContextoModal();
  };

  const handleEditarContextoSubmit = (contexto: ContextoPayload) => {
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    if (!contextoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    updateContexto(authToken, contextoSeleccionado?._id, contexto)
      .then(() => {
        cargarPartesInteresadas();
        toggleEditarContextoModal();
      })
      .catch(({ response }) => {
        setMensajeDeError(response?.data?.message || "Error al agregar el contexto");
        setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const toggleConfirmarEliminarContextoModal = () =>
    setConfirmarEliminarContextoModalIsOpen(!confirmarEliminarContextoModalIsOpen);

  const handleEliminarContextoClick = (id: string, contextoId: string) => {
    const parteInteresada = partesInteresadas.find((parteInteresada) => parteInteresada._id === id);
    if (!parteInteresada) return;
    const contexto = parteInteresada.contextos?.find((contexto) => contexto._id === contextoId);
    if (!contexto) return;
    setContextoSeleccionado(contexto);
    toggleConfirmarEliminarContextoModal();
  };

  const handleConfirmarEliminarContextoClick = () => {
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    if (!contextoSeleccionado) return;
    setLoadingCounter((prev) => prev + 1);
    deleteContexto(authToken, contextoSeleccionado?._id)
      .then(() => {
        cargarPartesInteresadas();
        toggleConfirmarEliminarContextoModal();
      })
      .catch(({ response }) => {
        setMensajeDeError(response?.data?.message || "Error al agregar el contexto");
        setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  const limpiarSeleccion = () => {
    setParteInteresadaSeleccionada(undefined);
    setContextoSeleccionado(undefined);
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
  };

  const cargarPartesInteresadas = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listPartesInteresadasDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setPartesInteresadas(data.map((parteInteresada) => new ParteInteresada(parteInteresada)));
      })
      .finally(() => {
        setLoadingCounter((prev) => prev - 1);
      });
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(cargarPartesInteresadas, [cargarPartesInteresadas]);

  const cargarPlanesDeAccion = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    listarPlanesDeAccionPorOrigen(
      authToken,
      idDeLaEmpresaSeleccionada,
      OrigenDePlanDeAccion.Contexto
    )
      .then(({ data }) =>
        setPlanesDeAccion(data.map((planDeAccion) => new PlanDeAccion(planDeAccion)))
      )
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(cargarPlanesDeAccion, [cargarPlanesDeAccion]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row>
        <Col>
          <TableCaption>Partes Interesadas</TableCaption>
        </Col>
      </Row>
      <TableContainer>
        <CustomTable>
          <thead>
            <tr>
              <th>#</th>
              <th>Parte Interesada</th>
              <th>Tipo</th>
              <th>Necesidad / Expectativa</th>
              <th>Requisito Legal</th>
              <th>Tipo de contexto</th>
              <th>Riesgos / Oportunidades</th>
              <th>Plan de acción</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {partesInteresadas.map(
              ({ _id, nombre, tipo, necesidadesYExpectativas, requisitoLegal, contextos }, index) =>
                contextos && contextos.length > 0 ? (
                  contextos.map(
                    (
                      { _id: contextoId, tipo: { nombre: contexto }, clasificacion, descripcion },
                      indexContexto
                    ) => (
                      <tr key={_id}>
                        {indexContexto === 0 && (
                          <>
                            <td rowSpan={contextos.length}>{index + 1}</td>
                            <td rowSpan={contextos.length}>{nombre}</td>
                            <td rowSpan={contextos.length}>{textoTipoDeParteInteresada(tipo)}</td>
                            <td rowSpan={contextos.length}>
                              {necesidadesYExpectativas.map(({ tipo, descripcion }) => (
                                <Row>
                                  <Col>
                                    <b>{textoTipoNecesidadExpectativa(tipo)}:</b> {descripcion}
                                  </Col>
                                </Row>
                              ))}
                            </td>
                            <td rowSpan={contextos.length}>
                              {!!requisitoLegal ? (
                                <Row className="justify-content-center">
                                  <Col xs="auto">
                                    <LinkContainer
                                      to={procesarPath(
                                        PATHS.requisitoLegal,
                                        requisitoLegal.toString()
                                      )}
                                      state={{ prevHash: "#todos" }}
                                    >
                                      <Button type="button" color="primary" className="small">
                                        Ver
                                      </Button>
                                    </LinkContainer>
                                  </Col>
                                </Row>
                              ) : (
                                <div className="text-center">—</div>
                              )}
                            </td>
                          </>
                        )}
                        <td>{contexto}</td>
                        <td>
                          <b
                            className={classNames({
                              "text-danger": clasificacion === ClasificacionDelContexto.Riesgo,
                              "text-success":
                                clasificacion === ClasificacionDelContexto.Oportunidad,
                            })}
                          >
                            {textoDeClasificacionDelContexto(clasificacion)}
                          </b>
                          <br />
                          {descripcion}
                        </td>
                        <td>
                          <Row className="justify-content-center">
                            <Col xs="auto">
                              <BotonPlanDeAccion
                                contextoId={contextoId}
                                planesDeAccion={planesDeAccion}
                              />
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <Row className="justify-content-center gx-0 flex-nowrap">
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="link"
                                size="sm"
                                title="Editar parte interesada"
                                onClick={() => handleEditarParteInteresadaClick(_id)}
                              >
                                <FaEdit size={15} />
                              </Button>
                            </Col>
                          </Row>
                          <Row className="justify-content-center gx-0 flex-nowrap">
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="link"
                                size="sm"
                                title="Agregar contexto"
                                onClick={() => handleAgregarContextoClick(_id)}
                              >
                                <FaPlus size={15} />
                              </Button>
                            </Col>
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="link"
                                size="sm"
                                title="Editar contexto"
                                onClick={() => handleEditarContextoClick(_id, contextoId)}
                              >
                                <FaEdit size={15} />
                              </Button>
                            </Col>
                            <Col xs="auto">
                              <Button
                                type="button"
                                color="danger"
                                outline
                                className="border-0"
                                size="sm"
                                title="Eliminar contexto"
                                onClick={() => handleEliminarContextoClick(_id, contextoId)}
                              >
                                <FaTrash size={15} />
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{nombre}</td>
                    <td>{textoTipoDeParteInteresada(tipo)}</td>
                    <td>
                      {necesidadesYExpectativas.map(({ tipo, descripcion }) => (
                        <Row>
                          <Col>
                            <b>{textoTipoNecesidadExpectativa(tipo)}:</b> {descripcion}
                          </Col>
                        </Row>
                      ))}
                      {necesidadesYExpectativas.length === 0 && (
                        <div className="text-center">—</div>
                      )}
                    </td>
                    <td>
                      {!!requisitoLegal ? (
                        <Row className="justify-content-center">
                          <Col xs="auto">
                            <LinkContainer
                              to={procesarPath(PATHS.requisitoLegal, requisitoLegal.toString())}
                            >
                              <Button type="button" color="primary" className="small">
                                Ver
                              </Button>
                            </LinkContainer>
                          </Col>
                        </Row>
                      ) : (
                        <div className="text-center">—</div>
                      )}
                    </td>
                    <td className="text-center">—</td>
                    <td className="text-center">—</td>
                    <td className="text-center">—</td>
                    <td>
                      <Row className="justify-content-center gx-0 flex-nowrap">
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Editar parte interesada"
                            onClick={() => handleEditarParteInteresadaClick(_id)}
                          >
                            <FaEdit size={15} />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="justify-content-center gx-0 flex-nowrap">
                        <Col xs="auto">
                          <Button
                            type="button"
                            color="link"
                            size="sm"
                            title="Agregar contexto"
                            onClick={() => handleAgregarContextoClick(_id)}
                          >
                            <FaPlus size={15} />
                          </Button>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )
            )}
            {partesInteresadas.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <TableMessage>
                    <Row className="gx-0">
                      <Col className="text-center">No hay ninguna registrada</Col>
                    </Row>
                    <Row className="justify-content-center gx-0 mt-3">
                      <Col xs="auto">
                        <LinkContainer to={PATHS.nuevaParteInteresada.to}>
                          <Button type="button" color="primary">
                            Agregar parte interesada
                          </Button>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </TableMessage>
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>
      </TableContainer>
      <Modal
        isOpen={editarParteInteresadaModalIsOpen}
        toggle={toggleEditarParteInteresadaModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleEditarParteInteresadaModal}>Editar parte interesada</ModalHeader>
        <ModalBody>
          <ParteInteresadaForm
            formId={EDITAR_PARTE_INTERESADA_FORM_ID}
            parteInteresada={parteInteresadaSeleccionada}
            onSubmit={handleEditarParteInteresadaSubmit}
          />
          <Collapse isOpen={!!mensajeDeError}>
            <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
              {mensajeDeError}
            </Alert>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditarParteInteresadaModal}>
            Cancelar
          </Button>
          <Button color="primary" form={EDITAR_PARTE_INTERESADA_FORM_ID} type="submit">
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={agregarContextoModalIsOpen}
        toggle={toggleAgregarContextoModal}
        onClosed={limpiarSeleccion}
      >
        <Collapse isOpen={!gestionarTiposDeContextoIsOpen}>
          <ModalHeader toggle={toggleAgregarContextoModal}>Agregar contexto</ModalHeader>
          <ModalBody>
            <ContextoForm
              formId={AGREGAR_CONTEXTO_FORM_ID}
              isOpen={agregarContextoModalIsOpen && !gestionarTiposDeContextoIsOpen}
              toggleGestionarTiposDeContexto={toggleGestionarTiposDeContexto}
              onSubmit={handleAgregarContextoSubmit}
            />
            <Collapse isOpen={!!mensajeDeError}>
              <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
                {mensajeDeError}
              </Alert>
            </Collapse>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleAgregarContextoModal}>
              Cancelar
            </Button>
            <Button color="primary" form={AGREGAR_CONTEXTO_FORM_ID} type="submit">
              Guardar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={gestionarTiposDeContextoIsOpen}>
          <ModalHeader toggle={toggleGestionarTiposDeContexto}>
            Gestionar tipos de contexto
          </ModalHeader>
          <ModalBody>
            <GestionarTiposDeContexto
              isOpen={agregarContextoModalIsOpen && gestionarTiposDeContextoIsOpen}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleGestionarTiposDeContexto}>
              Cerrar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
      <Modal
        isOpen={editarContextoModalIsOpen}
        toggle={toggleEditarContextoModal}
        onClosed={limpiarSeleccion}
      >
        <Collapse isOpen={!gestionarTiposDeContextoIsOpen}>
          <ModalHeader toggle={toggleEditarContextoModal}>Editar contexto</ModalHeader>
          <ModalBody>
            <ContextoForm
              formId={EDITAR_CONTEXTO_FORM_ID}
              contexto={contextoSeleccionado}
              isOpen={editarContextoModalIsOpen && !gestionarTiposDeContextoIsOpen}
              toggleGestionarTiposDeContexto={toggleGestionarTiposDeContexto}
              onSubmit={handleEditarContextoSubmit}
            />
            <Collapse isOpen={!!mensajeDeError}>
              <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
                {mensajeDeError}
              </Alert>
            </Collapse>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleEditarContextoModal}>
              Cancelar
            </Button>
            <Button color="primary" form={EDITAR_CONTEXTO_FORM_ID} type="submit">
              Guardar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={gestionarTiposDeContextoIsOpen}>
          <ModalHeader toggle={toggleGestionarTiposDeContexto}>
            Gestionar tipos de contexto
          </ModalHeader>
          <ModalBody>
            <GestionarTiposDeContexto
              isOpen={editarContextoModalIsOpen && gestionarTiposDeContextoIsOpen}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleGestionarTiposDeContexto}>
              Cerrar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
      <Modal
        isOpen={confirmarEliminarContextoModalIsOpen}
        toggle={toggleConfirmarEliminarContextoModal}
        onClosed={limpiarSeleccion}
      >
        <ModalHeader toggle={toggleConfirmarEliminarContextoModal}>
          Confirmar eliminación
        </ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro que desea eliminar el contexto <b>{contextoSeleccionado?.descripcion}</b>?
          </p>
          <Collapse isOpen={!!mensajeDeError}>
            <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
              {mensajeDeError}
            </Alert>
          </Collapse>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleConfirmarEliminarContextoModal}>
            Cancelar
          </Button>
          <Button color="danger" onClick={handleConfirmarEliminarContextoClick}>
            Eliminar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TablaDePartesInteresadas;
