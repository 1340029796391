import { FC, useCallback, useContext, useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import {
  Button,
  ButtonGroup,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Context } from "../../Context";
import { PATHS } from "../../../utilities/Paths";
import {
  createDocumentoSugerido,
  deleteDocumentoSugerido,
  editDocumentoSugerido,
  listDocumentosSugeridos,
} from "../../../services/admin-api-client";
import { DocumentoSugerido, DocumentoSugeridoBase } from "../../../models/DocumentoSugerido";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import DocumentoSugeridoForm from "./DocumentoSugeridoForm";
import {
  FrecuenciaDeActualizacion,
  textoDeLaFrecuenciaDeActualizacion,
  textoDePeriodoDeActualizacion,
  textoDelTipoDeDocumento,
} from "../../../models/Documento";

const NUEVO_DOCUMENTO_SUGERIDO_FORM_ID = "nuevoDocumentoSugerido";
const EDITAR_DOCUMENTO_SUGERIDO_FORM_ID = "editarDocumentoSugerido";

const GestionarDocumentosSugeridos: FC = () => {
  const { adminAuthToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [documentosSugeridos, setDocumentosSugeridos] = useState<DocumentoSugerido[]>([]);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState<DocumentoSugerido | null>(
    null
  );
  const [nuevoDocumentoSugeridoModalIsOpen, setNuevoDocumentoSugeridoModalIsOpen] =
    useState<boolean>(false);
  const [editarDocumentoSugeridoModalIsOpen, setEditarDocumentoSugeridoModalIsOpen] =
    useState<boolean>(false);
  const [eliminarDocumentoModalIsOpen, setEliminarDocumentoModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cargarDocumentosSugeridos = useCallback(() => {
    if (!adminAuthToken) return;
    setIsLoading(true);
    listDocumentosSugeridos(adminAuthToken)
      .then(({ data }) => setDocumentosSugeridos(data))
      .finally(() => setIsLoading(false));
  }, [adminAuthToken]);

  const toggleNuevoDocumentoSugeridoModal = () =>
    setNuevoDocumentoSugeridoModalIsOpen(!nuevoDocumentoSugeridoModalIsOpen);

  const handleCrearSubmit = (body: DocumentoSugeridoBase) => {
    setIsLoading(true);
    createDocumentoSugerido(adminAuthToken, body)
      .then(() => {
        cargarDocumentosSugeridos();
        toggleNuevoDocumentoSugeridoModal();
      })
      .finally(() => setIsLoading(false));
  };

  const toggleEditarDocumentoSugeridoModal = () =>
    setEditarDocumentoSugeridoModalIsOpen(!editarDocumentoSugeridoModalIsOpen);

  const handleEditarDocumentoClick = (documentoId: string) => {
    const documentoSeleccionado = documentosSugeridos.find(({ _id }) => _id === documentoId);
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    toggleEditarDocumentoSugeridoModal();
  };

  const handleEditarSubmit = (body: DocumentoSugeridoBase) => {
    if (!adminAuthToken || !documentoSeleccionado) return;
    setIsLoading(true);
    editDocumentoSugerido(adminAuthToken, documentoSeleccionado._id, body)
      .then(() => {
        cargarDocumentosSugeridos();
        toggleEditarDocumentoSugeridoModal();
      })
      .finally(() => setIsLoading(false));
  };

  const toggleEliminarDocumentoModal = () =>
    setEliminarDocumentoModalIsOpen(!eliminarDocumentoModalIsOpen);

  const handleEliminarDocumentoClick = (documentoId: string) => {
    const documentoSeleccionado = documentosSugeridos.find(({ _id }) => _id === documentoId);
    if (!documentoSeleccionado) return;
    setDocumentoSeleccionado(documentoSeleccionado);
    toggleEliminarDocumentoModal();
  };

  const handleConfirmarEliminarDocumentoClick = () => {
    if (!adminAuthToken || !documentoSeleccionado) return;
    const { _id } = documentoSeleccionado;
    setIsLoading(true);
    deleteDocumentoSugerido(adminAuthToken, _id)
      .then(() => {
        cargarDocumentosSugeridos();
        toggleEliminarDocumentoModal();
      })
      .catch(() => setIsLoading(false));
  };

  const limpiarFormulario = () => {
    setDocumentoSeleccionado(null);
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.adminDocumentosSugeridos.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(cargarDocumentosSugeridos, [cargarDocumentosSugeridos]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="mb-3">
        <Col>
          <Breadcrumbs items={[PATHS.adminHome]} />
        </Col>
        <Col xs="auto">
          <Button
            type="button"
            color="primary"
            title="Añadir documento"
            onClick={toggleNuevoDocumentoSugeridoModal}
          >
            <FaPlus size={20} />
          </Button>
        </Col>
      </Row>
      <ListGroup>
        {documentosSugeridos.map(
          ({
            _id,
            nombreDelDocumento,
            tipoDeDocumento,
            frecuenciaDeActualizacion,
            valorDeLaFrecuenciaDeActualizacion,
            unidadDeLaFrecuenciaDeActualizacion,
          }) => (
            <ListGroupItem key={_id}>
              <Row>
                <Col>{nombreDelDocumento}</Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <Row>
                    <Col>
                      <small className="text-muted">
                        {textoDelTipoDeDocumento(tipoDeDocumento)}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <small className="text-muted">
                        {frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra &&
                        !!valorDeLaFrecuenciaDeActualizacion &&
                        !!unidadDeLaFrecuenciaDeActualizacion
                          ? textoDePeriodoDeActualizacion(
                              valorDeLaFrecuenciaDeActualizacion,
                              unidadDeLaFrecuenciaDeActualizacion
                            )
                          : textoDeLaFrecuenciaDeActualizacion(frecuenciaDeActualizacion)}
                      </small>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  <ButtonGroup>
                    <Button color="primary" onClick={() => handleEditarDocumentoClick(_id)}>
                      <FaEdit size={15} />
                    </Button>
                    <Button color="danger" onClick={() => handleEliminarDocumentoClick(_id)}>
                      <FaTrash size={15} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </ListGroupItem>
          )
        )}
      </ListGroup>
      <Modal
        isOpen={nuevoDocumentoSugeridoModalIsOpen}
        toggle={toggleNuevoDocumentoSugeridoModal}
        onClosed={limpiarFormulario}
      >
        <ModalHeader toggle={toggleNuevoDocumentoSugeridoModal}>
          Nuevo Documento Sugerido
        </ModalHeader>
        <ModalBody>
          <DocumentoSugeridoForm
            formId={NUEVO_DOCUMENTO_SUGERIDO_FORM_ID}
            onSubmit={handleCrearSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" form={NUEVO_DOCUMENTO_SUGERIDO_FORM_ID} color="primary">
            Crear
          </Button>
          <Button color="secondary" onClick={toggleNuevoDocumentoSugeridoModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={editarDocumentoSugeridoModalIsOpen}
        toggle={toggleEditarDocumentoSugeridoModal}
        onClosed={limpiarFormulario}
      >
        <ModalHeader toggle={toggleEditarDocumentoSugeridoModal}>
          Editar Documento Sugerido
        </ModalHeader>
        <ModalBody>
          <DocumentoSugeridoForm
            formId={EDITAR_DOCUMENTO_SUGERIDO_FORM_ID}
            onSubmit={handleEditarSubmit}
            documento={documentoSeleccionado as DocumentoSugerido}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" form={EDITAR_DOCUMENTO_SUGERIDO_FORM_ID} color="primary">
            Guardar
          </Button>
          <Button color="secondary" onClick={toggleEditarDocumentoSugeridoModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={eliminarDocumentoModalIsOpen}
        toggle={toggleEliminarDocumentoModal}
        onClosed={limpiarFormulario}
      >
        <ModalHeader toggle={toggleEliminarDocumentoModal}>Eliminar Documento Sugerido</ModalHeader>
        <ModalBody>
          <p>
            ¿Está seguro de que desea eliminar <b>{documentoSeleccionado?.nombreDelDocumento}?</b>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="danger" onClick={handleConfirmarEliminarDocumentoClick}>
            Eliminar
          </Button>
          <Button color="secondary" onClick={toggleEliminarDocumentoModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default GestionarDocumentosSugeridos;
