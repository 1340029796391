import { Incidente } from "./Incidente";

export interface ArchivoDeIncidenteBase {
  incidente: Incidente | string;
  nombre: string;
  filepath: string;
}

export interface ArchivoDeIncidente extends ArchivoDeIncidenteBase {
  _id: string;
}

export interface ArchivoDeIncidenteResponse extends ArchivoDeIncidenteBase {
  _id: string;
  incidente: string;
  nombre: string;
}

export class ArchivoDeIncidente implements ArchivoDeIncidente {
  constructor({ _id, incidente, nombre, filepath }: ArchivoDeIncidenteResponse) {
    this._id = _id;
    this.incidente = incidente;
    this.nombre = nombre;
    this.filepath = filepath;
  }
}
