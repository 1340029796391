import { PATHS, procesarPath } from "../utilities/Paths";
import { procesarLinkDeDocumento } from "../utilities/utils";
import { Accion, AccionResponse } from "./Accion";
import { ContextoConParteInteresada, ContextoConParteInteresadaResponse } from "./Contexto";
import { EvaluacionDeRiesgo, EvaluacionDeRiesgoResponse } from "./EvaluacionDeRiesgo";
import { Incidente, IncidenteResponse } from "./Incidente";
import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";

export enum OrigenDePlanDeAccion {
  RequisitoLegal = "RequisitoLegal",
  MIPER = "EvaluacionDeRiesgo",
  Contexto = "Contexto",
  InvestigacionDeIncidente = "Incidente",
  Auditoria = "Auditoria",
  Otra = "Otra",
}

export enum TiposDeAuditoria {
  Interna = "interna",
  Externa = "externa",
}

export type FuenteDePlanDeAccion =
  | string
  | RequisitoLegal
  | Incidente
  | ContextoConParteInteresada
  | EvaluacionDeRiesgo;

export type FuenteDePlanDeAccionResponse =
  | string
  | RequisitoLegalResponse
  | IncidenteResponse
  | ContextoConParteInteresadaResponse
  | EvaluacionDeRiesgoResponse;

export type FuenteDePlanDeAccionBase = FuenteDePlanDeAccion | FuenteDePlanDeAccionResponse;

export enum TiposDeHallazgo {
  NoConformidadMenor = "noConformidadMenor",
  NoConformidadMayor = "noConformidadMayor",
}

export interface PlanDeAccionBase {
  origen: OrigenDePlanDeAccion;
  fuente?: FuenteDePlanDeAccionBase;
  fuenteManual?: string | TiposDeAuditoria;
  tipoDeHallazgo?: TiposDeHallazgo;
  descripcion: string;
  acciones?: (AccionResponse | Accion)[];
}

export interface PlanDeAccion extends PlanDeAccionBase {
  _id: string;
  fuente?: FuenteDePlanDeAccion;
  acciones: Accion[];
}

export interface PlanDeAccionResponse extends PlanDeAccionBase {
  _id: string;
  fuente?: FuenteDePlanDeAccionResponse;
  acciones?: AccionResponse[];
}

export class PlanDeAccion implements PlanDeAccion {
  constructor({
    _id,
    origen,
    fuente,
    fuenteManual,
    tipoDeHallazgo,
    descripcion,
    acciones,
  }: PlanDeAccionResponse) {
    this._id = _id;
    this.origen = origen;
    if (typeof fuente === "string") {
      this.fuente = fuente;
    } else if (!!fuente) {
      switch (origen) {
        case OrigenDePlanDeAccion.RequisitoLegal:
          this.fuente = new RequisitoLegal(fuente as RequisitoLegalResponse);
          break;
        case OrigenDePlanDeAccion.MIPER:
          this.fuente = new EvaluacionDeRiesgo(fuente as EvaluacionDeRiesgoResponse);
          break;
        case OrigenDePlanDeAccion.InvestigacionDeIncidente:
          this.fuente = new Incidente(fuente as IncidenteResponse);
          break;
        case OrigenDePlanDeAccion.Contexto:
          this.fuente = new ContextoConParteInteresada(
            fuente as ContextoConParteInteresadaResponse
          );
          break;
      }
    }
    this.fuenteManual = fuenteManual;
    this.tipoDeHallazgo = tipoDeHallazgo;
    this.descripcion = descripcion;
    this.acciones = !!acciones ? acciones.map((accion) => new Accion(accion)) : [];
  }

  toString(): string {
    return this._id;
  }
}

export interface PlanDeAccionPayload {
  origen: OrigenDePlanDeAccion;
  fuente?: string;
  fuenteManual?: string;
  tipoDeHallazgo?: TiposDeHallazgo;
  descripcion: string;
}

export function origenEsDeFuenteManual(origen: OrigenDePlanDeAccion) {
  return origen === OrigenDePlanDeAccion.Otra || origen === OrigenDePlanDeAccion.Auditoria;
}

export function textoDeOrigenDePlanDeAccion(origen: OrigenDePlanDeAccion): string {
  switch (origen) {
    case OrigenDePlanDeAccion.RequisitoLegal:
      return "Requisito Legal";
    case OrigenDePlanDeAccion.MIPER:
      return "MIPER";
    case OrigenDePlanDeAccion.Contexto:
      return "Contexto";
    case OrigenDePlanDeAccion.InvestigacionDeIncidente:
      return "Investigación de Incidente";
    case OrigenDePlanDeAccion.Auditoria:
      return "Auditoría";
    case OrigenDePlanDeAccion.Otra:
      return "Otra";
  }
}

export function textoDeOrigenDePlanDeAccionEnPlural(origen: OrigenDePlanDeAccion): string {
  switch (origen) {
    case OrigenDePlanDeAccion.RequisitoLegal:
      return "Requisitos Legales";
    case OrigenDePlanDeAccion.MIPER:
      return "MIPER";
    case OrigenDePlanDeAccion.Contexto:
      return "Contexto";
    case OrigenDePlanDeAccion.InvestigacionDeIncidente:
      return "Investigación de Incidentes";
    case OrigenDePlanDeAccion.Auditoria:
      return "Auditorías";
    case OrigenDePlanDeAccion.Otra:
      return "Otra";
  }
}

export function textoDeTipoDeAuditoria(tipo: TiposDeAuditoria): string {
  switch (tipo) {
    case TiposDeAuditoria.Interna:
      return "Auditoría Interna";
    case TiposDeAuditoria.Externa:
      return "Auditoría Externa";
  }
}

export function procesarLinkDeLaFuente(origen: OrigenDePlanDeAccion, fuente: FuenteDePlanDeAccion) {
  switch (origen) {
    case OrigenDePlanDeAccion.RequisitoLegal:
      if (typeof fuente === "string") return procesarPath(PATHS.requisitoLegal, fuente);
      const requisitoLegal = fuente as RequisitoLegal;
      return procesarLinkDeDocumento(requisitoLegal.filepath);
    case OrigenDePlanDeAccion.InvestigacionDeIncidente:
      if (typeof fuente === "string") return procesarPath(PATHS.incidente, fuente);
      const incidente = fuente as Incidente;
      return procesarPath(PATHS.incidente, incidente._id);
    case OrigenDePlanDeAccion.MIPER:
      if (typeof fuente === "string") return procesarPath(PATHS.resultadosDeRiesgos, fuente);
      const miper = fuente as EvaluacionDeRiesgo;
      return procesarPath(PATHS.resultadosDeRiesgos, miper._id);
    case OrigenDePlanDeAccion.Contexto:
      return PATHS.contexto.to;
  }
  return "";
}

export function textoDeTipoDeHallazgo(tipo: TiposDeHallazgo): string {
  switch (tipo) {
    case TiposDeHallazgo.NoConformidadMenor:
      return "No conformidad menor";
    case TiposDeHallazgo.NoConformidadMayor:
      return "No conformidad mayor";
  }
}

export interface IndicadorDePlanesDeAccion {
  origen: OrigenDePlanDeAccion;
  cantidad: number;
}
