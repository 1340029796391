import { FC } from "react";
import Header from "../Header";
import UsuarioForm from "./UsuarioForm";

const Registro: FC = () => {
  return (
    <>
      <Header />
      <UsuarioForm />
    </>
  );
};

export default Registro;
