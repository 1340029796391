import { Empresa } from "./Empresa";

export interface EquipoHerramientaBase {
  identificador: string;
  nombre: string;
  marca?: string;
}

export interface EquipoHerramienta extends EquipoHerramientaBase {
  _id: string;
  empresa: Empresa;
}

export interface EquipoHerramientaResponse extends EquipoHerramientaBase {
  _id: string;
  empresa: Empresa;
}

export class EquipoHerramienta implements EquipoHerramienta {
  constructor({ _id, identificador, nombre, marca, empresa }: EquipoHerramientaResponse) {
    this._id = _id;
    this.identificador = identificador;
    this.nombre = nombre;
    this.marca = marca;
    this.empresa = empresa;
  }

  get nombreCompleto() {
    if (!this.marca) return `${this.identificador} - ${this.nombre}`;
    return `${this.identificador} - ${this.nombre} ${this.marca}`;
  }
}
