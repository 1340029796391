import { useState, FC, FormEvent } from "react";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  UncontrolledButtonDropdown,
} from "reactstrap";
import {
  FrecuenciaDeActualizacion,
  TiposDeDocumento,
  UnidadesDeTiempo,
  textoDeLaFrecuenciaDeActualizacion,
  textoDeLaUnidadDeTiempo,
  textoDelTipoDeDocumento,
} from "../../../models/Documento";
import { DocumentoSugerido, DocumentoSugeridoBase } from "../../../models/DocumentoSugerido";

interface DocumentoSugeridoFormProps {
  formId: string;
  documento?: DocumentoSugerido;
  onSubmit: (body: DocumentoSugeridoBase) => void;
}

const DocumentoSugeridoForm: FC<DocumentoSugeridoFormProps> = ({ formId, documento, onSubmit }) => {
  const [nombreDelDocumento, setNombreDelDocumento] = useState<string>(
    documento?.nombreDelDocumento || ""
  );
  const [tipoDeDocumento, setTipoDeDocumento] = useState<TiposDeDocumento | null>(
    documento?.tipoDeDocumento || null
  );
  const [frecuenciaDeActualizacion, setFrecuenciaDeActualizacion] =
    useState<FrecuenciaDeActualizacion | null>(documento?.frecuenciaDeActualizacion || null);
  const [valorDeLaFrecuenciaDeActualizacion, setValorDeLaFrecuenciaDeActualizacion] =
    useState<number>(documento?.valorDeLaFrecuenciaDeActualizacion || 1);
  const [unidadDeLaFrecuenciaDeActualizacion, setUnidadDeLaFrecuenciaDeActualizacion] =
    useState<UnidadesDeTiempo>(
      documento?.unidadDeLaFrecuenciaDeActualizacion || UnidadesDeTiempo.Horas
    );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (tipoDeDocumento === null || frecuenciaDeActualizacion === null) return;

    const body: DocumentoSugeridoBase = {
      nombreDelDocumento,
      tipoDeDocumento,
      frecuenciaDeActualizacion,
    };

    if (frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra) {
      body.valorDeLaFrecuenciaDeActualizacion = valorDeLaFrecuenciaDeActualizacion;
      body.unidadDeLaFrecuenciaDeActualizacion = unidadDeLaFrecuenciaDeActualizacion;
    }

    onSubmit(body);
  };

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="nombreDelDocumento"
            placeholder="Nombre del documento"
            value={nombreDelDocumento}
            onChange={({ target }) => setNombreDelDocumento(target.value)}
            required
          />
          <Label for="nombreDelDocumento">Nombre del documento</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="tipoDeDocumento"
            value={tipoDeDocumento || ""}
            onChange={({ target }) => setTipoDeDocumento(target.value as TiposDeDocumento)}
            required
          >
            <option value="" disabled>
              Selecciona un tipo de documento
            </option>
            {Object.values(TiposDeDocumento).map((value) => (
              <option key={value} value={value}>
                {textoDelTipoDeDocumento(value as TiposDeDocumento)}
              </option>
            ))}
          </Input>
          <Label for="tipoDeDocumento">Tipo de documento</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="frecuenciaDeActualizacion"
            placeholder="Frecuencia de actualización"
            value={frecuenciaDeActualizacion || ""}
            onChange={({ target }) =>
              setFrecuenciaDeActualizacion(target.value as FrecuenciaDeActualizacion)
            }
            required
          >
            <option value="" disabled>
              Selecciona una frecuencia de actualización
            </option>
            {Object.values(FrecuenciaDeActualizacion).map((value) => (
              <option key={value} value={value}>
                {textoDeLaFrecuenciaDeActualizacion(value as FrecuenciaDeActualizacion)}
              </option>
            ))}
          </Input>
          <Label for="frecuenciaDeActualizacion">Frecuencia de actualización</Label>
        </FormGroup>
        <Collapse isOpen={frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra}>
          <InputGroup className="mb-3">
            <Input
              type="number"
              id="valorDeLaFrecuenciaDeActualizacion"
              value={valorDeLaFrecuenciaDeActualizacion}
              onChange={({ target }) => setValorDeLaFrecuenciaDeActualizacion(Number(target.value))}
              required={frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra}
            />
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                {textoDeLaUnidadDeTiempo(unidadDeLaFrecuenciaDeActualizacion)}
              </DropdownToggle>
              <DropdownMenu>
                {Object.values(UnidadesDeTiempo).map((value) => (
                  <DropdownItem
                    key={value}
                    active={value === unidadDeLaFrecuenciaDeActualizacion}
                    onClick={() => setUnidadDeLaFrecuenciaDeActualizacion(value)}
                  >
                    {textoDeLaUnidadDeTiempo(value as UnidadesDeTiempo)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </InputGroup>
        </Collapse>
      </Form>
    </>
  );
};

export default DocumentoSugeridoForm;
