import { useState, FC, useEffect, useContext } from "react";
import { ArchivoDeDocumento } from "../../../../models/ArchivoDeDocumento";
import { Documento } from "../../../../models/Documento";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  archivosDeDocumento,
  archivosDeDocumentoPorContrato,
} from "../../../../services/usuario-api-client";
import { Contrato } from "../../../../models/Contrato";
import TablaDeArchivos from "./TablaDeArchivos";

interface HistoricoDeDocumentosProps {
  documento: Documento;
  contrato?: Contrato;
}

const HistoricoDeDocumentos: FC<HistoricoDeDocumentosProps> = ({ documento, contrato }) => {
  const { authToken } = useContext(Context);
  const [archivos, setArchivos] = useState<ArchivoDeDocumento[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!authToken) return;
    setIsLoading(true);
    if (contrato) {
      archivosDeDocumentoPorContrato(authToken, documento._id, contrato._id)
        .then(({ data }) => {
          setArchivos(data.map((archivo) => new ArchivoDeDocumento(archivo)));
        })
        .finally(() => setIsLoading(false));
    } else {
      archivosDeDocumento(authToken, documento._id)
        .then(({ data }) => {
          setArchivos(data.map((archivo) => new ArchivoDeDocumento(archivo)));
        })
        .finally(() => setIsLoading(false));
    }
  }, [authToken, documento, contrato]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <TablaDeArchivos
        archivos={archivos}
        tipoDeDocumento={documento.tipoDeDocumento}
        titulo="Histórico"
      />
    </>
  );
};

export default HistoricoDeDocumentos;
