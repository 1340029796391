import { useContext, FC, useState, FormEvent, useEffect, useCallback } from "react";
import { Form } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import {
  CustomTable,
  ImageButton,
  TableCaption,
  TableContainer,
  TableMessage,
} from "../../../../utilities/utils";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import { listDocumentosDeContratosParaImportar } from "../../../../services/usuario-api-client";

interface DocumentosParaImportar {
  _id: string;
  nombre: string;
}

interface ImportarDocumentosProps {
  formId: string;
  onSubmit: (documentosParaImportar: { nombre: string }[]) => void;
}

const ImportarDocumentos: FC<ImportarDocumentosProps> = ({ formId, onSubmit }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [documentosDelContrato, setDocumentosDelContrato] = useState<DocumentosParaImportar[]>([]);
  const [documentosSeleccionados, setDocumentosSeleccionados] = useState<DocumentosParaImportar[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(documentosSeleccionados);
  };

  const cargarDocumentosDelContrato = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listDocumentosDeContratosParaImportar(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        if (data.length > 0) {
          setDocumentosDelContrato(data);
        } else {
          setDocumentosDelContrato([
            { _id: uuid(), nombre: "Paz y Salvo CSS" },
            { _id: uuid(), nombre: "Aviso de Entrada a CSS" },
          ]);
        }
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(cargarDocumentosDelContrato, [cargarDocumentosDelContrato]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <TableContainer>
          <TableCaption>Selecciona los documentos que desea importar</TableCaption>
          <CustomTable>
            <thead>
              <tr>
                <th>
                  {documentosDelContrato.length > 0 &&
                  documentosDelContrato.every((ddc) =>
                    documentosSeleccionados.some((v) => v._id === ddc._id)
                  ) ? (
                    <ImageButton
                      type="button"
                      title="Deseleccionar"
                      className="d-inline-block bg-primary"
                      onClick={() => setDocumentosSeleccionados([])}
                    >
                      <FaCheckSquare size={15} color="#EAEAEA" />
                    </ImageButton>
                  ) : (
                    <ImageButton
                      type="button"
                      title="Seleccionar"
                      className="d-inline-block bg-primary"
                      onClick={() => setDocumentosSeleccionados(documentosDelContrato)}
                    >
                      <FaSquare size={15} color="#EAEAEA" />
                    </ImageButton>
                  )}
                </th>
                <th>Nombre del documento</th>
              </tr>
            </thead>
            <tbody>
              {documentosDelContrato.map((documentoDelContrato) => (
                <tr key={documentoDelContrato._id}>
                  <td className="text-center">
                    {documentosSeleccionados.includes(documentoDelContrato) ? (
                      <ImageButton
                        type="button"
                        title="Deseleccionar"
                        className="d-inline-block bg-primary"
                        onClick={() =>
                          setDocumentosSeleccionados(
                            documentosSeleccionados.filter(
                              ({ _id }) => _id !== documentoDelContrato._id
                            )
                          )
                        }
                      >
                        <FaCheckSquare size={15} color="#EAEAEA" />
                      </ImageButton>
                    ) : (
                      <ImageButton
                        type="button"
                        title="Seleccionar"
                        className="d-inline-block bg-primary"
                        onClick={() =>
                          setDocumentosSeleccionados([
                            ...documentosSeleccionados,
                            documentoDelContrato,
                          ])
                        }
                      >
                        <FaSquare size={15} color="#EAEAEA" />
                      </ImageButton>
                    )}
                  </td>
                  <td>{documentoDelContrato.nombre}</td>
                </tr>
              ))}
              {documentosDelContrato.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <TableMessage>No hay documentos</TableMessage>
                  </td>
                </tr>
              )}
            </tbody>
          </CustomTable>
        </TableContainer>
      </Form>
    </>
  );
};

export default ImportarDocumentos;
