import { FC, useState, FormEvent, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Vinculacion } from "../../../models/Vinculacion";
import { CustomTable, TableContainer } from "../../../utilities/utils";
import { Cargo } from "../../../models/Cargo";
import Checkbox from "../../../utilities/Checkbox";

interface BusquedaDeUsuariosProps {
  formId: string;
  vinculacionesPreseleccionadas: Vinculacion[];
  vinculaciones: Vinculacion[];
  cargos: Cargo[];
  onSubmit: (vinculaciones: Vinculacion[]) => void;
}

const BusquedaDeUsuarios: FC<BusquedaDeUsuariosProps> = ({
  formId,
  vinculacionesPreseleccionadas,
  vinculaciones,
  cargos,
  onSubmit,
}) => {
  const [busqueda, setBusqueda] = useState<string>("");
  const [cargoSeleccionado, setCargoSeleccionado] = useState<Cargo | null>(null);
  const [vinculacionesSeleccionadas, setVinculacionesSeleccionadas] = useState<Vinculacion[]>(
    vinculacionesPreseleccionadas
  );
  const [vinculacionesFiltradas, setVinculacionesFiltradas] = useState<Vinculacion[]>([]);

  const toggleUsuario = (vinculacion: Vinculacion) => {
    if (vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)) {
      setVinculacionesSeleccionadas(
        vinculacionesSeleccionadas.filter((v) => v._id !== vinculacion._id)
      );
    } else {
      setVinculacionesSeleccionadas([...vinculacionesSeleccionadas, vinculacion]);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(vinculacionesSeleccionadas);
  };

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setVinculacionesFiltradas(
      vinculaciones.filter(
        (vinculacion) =>
          (vinculacion.persona.nombre.toLowerCase().includes(busquedaLower) ||
            vinculacion.persona.apellidos.toLowerCase().includes(busquedaLower) ||
            vinculacion.persona.identificacion.toLowerCase().includes(busquedaLower) ||
            vinculacion.persona.correo.toLowerCase().includes(busquedaLower)) &&
          (!cargoSeleccionado || vinculacion.cargo?.toString() === cargoSeleccionado.toString())
      )
    );
  }, [vinculaciones, busqueda, cargoSeleccionado]);

  return (
    <>
      <Row className="align-items-center">
        <Col xs="auto">
          <Label>Filtrar</Label>
        </Col>
        <div className="w-100 d-sm-none" />
        <Col>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Buscar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Buscar</Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup floating>
            <Input
              type="select"
              name="cargo"
              id="cargo"
              placeholder="Cargo"
              autoComplete="off"
              value={cargoSeleccionado?._id || ""}
              onChange={(event) =>
                setCargoSeleccionado(
                  cargos.find((cargo) => cargo._id === event.target.value) || null
                )
              }
            >
              <option value="">Todos</option>
              {cargos.map((cargo) => (
                <option key={cargo._id} value={cargo._id}>
                  {cargo.cargo}
                </option>
              ))}
            </Input>
            <Label for="cargo">Cargo</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} id={formId}>
        <TableContainer>
          <CustomTable>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={
                      vinculacionesFiltradas.length > 0 &&
                      vinculacionesFiltradas.every((vinculacion) =>
                        vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)
                      )
                    }
                    disabled={vinculacionesFiltradas.length === 0}
                    toggle={() => {
                      if (vinculacionesFiltradas.length === vinculacionesSeleccionadas.length) {
                        setVinculacionesSeleccionadas([]);
                      } else {
                        setVinculacionesSeleccionadas(vinculacionesFiltradas);
                      }
                    }}
                  />
                </th>
                <th>Identificación</th>
                <th>Nombre</th>
                <th>Apellido(s)</th>
                <th>Correo</th>
                <th>Cargo</th>
              </tr>
            </thead>
            <tbody>
              {vinculacionesFiltradas.map((vinculacion) => (
                <tr key={vinculacion._id} onClick={() => toggleUsuario(vinculacion)}>
                  <td className="text-center">
                    <Checkbox
                      checked={vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)}
                      toggle={() => {
                        if (vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)) {
                          setVinculacionesSeleccionadas(
                            vinculacionesSeleccionadas.filter((v) => v._id !== vinculacion._id)
                          );
                        } else {
                          setVinculacionesSeleccionadas([
                            ...vinculacionesSeleccionadas,
                            vinculacion,
                          ]);
                        }
                      }}
                    />
                  </td>
                  <td>{vinculacion.persona.identificacion}</td>
                  <td>{vinculacion.persona.nombre}</td>
                  <td>{vinculacion.persona.apellidos}</td>
                  <td>{vinculacion.persona.correo}</td>
                  <td>{(vinculacion.cargo as Cargo)?.cargo}</td>
                </tr>
              ))}
              {vinculaciones.length === 0 ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    No hay usuarios registrados
                  </td>
                </tr>
              ) : (
                vinculacionesFiltradas.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No hay usuarios que coincidan con la búsqueda
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </CustomTable>
        </TableContainer>
      </Form>
    </>
  );
};

export default BusquedaDeUsuarios;
