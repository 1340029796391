import { Actividad, ActividadResponse } from "./Actividad";
import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";
import { TipoDeConsecuencia, TipoDeExposicion, TipoDeProbabilidad } from "./Riesgo";

export interface EvaluacionDeRiesgoBase {
  _id: string;
  actividad: string | ActividadResponse | Actividad;
  tipoDePeligroId: number;
  tipoDePeligro: string;
  peligroId: number;
  nombreDePeligro: string;
  riesgo: string;
  actualesMedidasDeControl: string;
  consecuencia: TipoDeConsecuencia;
  exposicion: TipoDeExposicion;
  probabilidad: TipoDeProbabilidad;
  calificacionDeRiesgo: number;
  requisitoLegal?: string | RequisitoLegalResponse | RequisitoLegal;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface EvaluacionDeRiesgo extends EvaluacionDeRiesgoBase {
  actividad: string | Actividad;
  requisitoLegal?: string | RequisitoLegal;
  createdAt: Date;
  updatedAt: Date;
}

export interface EvaluacionDeRiesgoResponse extends EvaluacionDeRiesgoBase {
  actividad: string | ActividadResponse;
  requisitoLegal?: string | RequisitoLegalResponse;
  createdAt: string;
  updatedAt: string;
}

export class EvaluacionDeRiesgo implements EvaluacionDeRiesgo {
  constructor({
    _id,
    actividad,
    tipoDePeligroId,
    tipoDePeligro,
    peligroId,
    nombreDePeligro,
    riesgo,
    actualesMedidasDeControl,
    consecuencia,
    exposicion,
    probabilidad,
    calificacionDeRiesgo,
    requisitoLegal,
    createdAt,
    updatedAt,
  }: EvaluacionDeRiesgoResponse) {
    this._id = _id;
    if (typeof actividad === "string") {
      this.actividad = actividad;
    } else {
      this.actividad = new Actividad(actividad);
    }
    this.tipoDePeligroId = tipoDePeligroId;
    this.tipoDePeligro = tipoDePeligro;
    this.peligroId = peligroId;
    this.nombreDePeligro = nombreDePeligro;
    this.riesgo = riesgo;
    this.actualesMedidasDeControl = actualesMedidasDeControl;
    this.consecuencia = consecuencia;
    this.exposicion = exposicion;
    this.probabilidad = probabilidad;
    this.calificacionDeRiesgo = calificacionDeRiesgo;
    if (typeof requisitoLegal === "string") {
      this.requisitoLegal = requisitoLegal;
    } else if (!!requisitoLegal) {
      this.requisitoLegal = new RequisitoLegal(requisitoLegal);
    }
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }

  get nombreCompleto() {
    if (this.actividad instanceof Actividad) {
      return `${this.actividad.departamento} - ${this.actividad.actividad} - ${
        this.nombreDePeligro
      } (${textoDeCalificacion(this.calificacionDeRiesgo)})`;
    }
    return `${this.tipoDePeligro} - ${this.nombreDePeligro} (${textoDeCalificacion(
      this.calificacionDeRiesgo
    )})`;
  }
}

export function textoDeCalificacion(calificacion: number) {
  if (calificacion > 0 && calificacion < 200) {
    return "Bajo";
  } else if (calificacion >= 200 && calificacion < 400) {
    return "Medio";
  } else if (calificacion >= 400) {
    return "Alto";
  }
  return "—";
}

export interface EvaluacionDeRiesgoPayload {
  tipoDePeligroId: number;
  tipoDePeligro: string;
  peligroId: number;
  nombreDePeligro: string;
  riesgo: string;
  actualesMedidasDeControl: string;
  consecuencia: TipoDeConsecuencia;
  exposicion: TipoDeExposicion;
  probabilidad: TipoDeProbabilidad;
  calificacionDeRiesgo: number;
  requisitoLegal?: string;
}
