import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../Context";
import { PATHS, procesarPath } from "../../../utilities/Paths";
import { Accion, textoDeClasificacionDeAccion } from "../../../models/Accion";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { obtenerAccion } from "../../../services/usuario-api-client";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { Card, CardHeader, ListGroup, ListGroupItem, Table } from "reactstrap";
import { shortDateToString, VerticalTableContainer } from "../../../utilities/utils";

const breadcrumbs = [PATHS.usuarioHome, PATHS.planesDeAccion, PATHS.planDeAccion];

const AccionView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [accion, setAccion] = useState<Accion>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    obtenerAccion(authToken, id)
      .then(({ data }) => {
        const accion = new Accion(data);
        setAccion(accion);
        breadcrumbs[2] = {
          ...PATHS.planDeAccion,
          to: procesarPath(PATHS.planDeAccion, accion.planDeAccion),
        };
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.accion.name), [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={breadcrumbs} />
      {!!accion &&
        (({
          _id,
          accion,
          clasificacion,
          responsable,
          verificador,
          fechaLimite,
          requisitosLegales,
        }: Accion) => (
          <Fragment key={_id}>
            <VerticalTableContainer>
              <Table borderless style={{ tableLayout: "auto" }}>
                <tbody>
                  <tr>
                    <th scope="row">Acción:</th>
                    <td>{accion}</td>
                  </tr>
                  <tr>
                    <th scope="row">Clasificación:</th>
                    <td>{textoDeClasificacionDeAccion(clasificacion)}</td>
                  </tr>
                  <tr>
                    <th scope="row">Responsable:</th>
                    <td>
                      {typeof responsable === "string" ? responsable : responsable.nombreCompleto}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Verificador:</th>
                    <td>
                      {typeof verificador === "string" ? verificador : verificador.nombreCompleto}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Fecha límite:</th>
                    <td>{shortDateToString(fechaLimite)}</td>
                  </tr>
                </tbody>
              </Table>
            </VerticalTableContainer>
            {!!requisitosLegales && requisitosLegales.length > 0 && (
              <Card className="mb-3">
                <CardHeader>
                  <b>Requisitos legales</b>
                </CardHeader>
                <ListGroup flush>
                  {requisitosLegales.map((requisitoLegal) => (
                    <ListGroupItem key={requisitoLegal.toString()}>
                      <Link to={procesarPath(PATHS.requisitoLegal, requisitoLegal.toString())}>
                        {typeof requisitoLegal === "string"
                          ? requisitoLegal
                          : requisitoLegal.nombre}
                      </Link>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Card>
            )}
          </Fragment>
        ))(accion)}
    </>
  );
};

export default AccionView;
