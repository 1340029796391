import { FC, useContext, useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { Col, Nav, NavItem, NavLink, Row, Button, Collapse } from "reactstrap";
import { FaBalanceScale, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import { Context } from "../../Context";
import { IconsContainer, TableContainer } from "../../../utilities/utils";
import TablaDeRequisitosLegales from "./TablaDeRequisitosLegales";
import TablaDeRequisitosLegalesNuevos from "./TablaDeRequisitosLegalesNuevos";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";

const CustomButton = styled(Button)`
  margin: 0 12px;
`;

enum Vistas {
  NUEVOS = "nuevos",
  INTERNOS = "internos",
  TODOS = "todos",
}

const tabs = [
  {
    id: Vistas.NUEVOS,
    texto: "Nuevos",
    hash: "#nuevos",
  },
  {
    id: Vistas.INTERNOS,
    texto: "Internos",
    hash: "#internos",
  },
  {
    id: Vistas.TODOS,
    texto: "Todos",
    hash: "#todos",
  },
];

const RequisitosLegales: FC = () => {
  const location = useLocation();
  const { setTituloDeLaPantallaActual } = useContext(Context);
  const [activeTab, setActiveTab] = useState<Vistas>(tabs[0].id);

  const handleTabChange = (id: Vistas) => {
    setActiveTab(id);
  };

  const handleVincularRequisitoLegalSuccess = () => {
    setActiveTab(Vistas.TODOS);
  };

  useEffect(
    () => setTituloDeLaPantallaActual(PATHS.requisitosLegales.name),
    [setTituloDeLaPantallaActual]
  );

  useEffect(() => {
    const { hash } = location;
    const tab = tabs.find((tab) => tab.hash === hash);
    if (tab) {
      setActiveTab(tab.id);
    }
  }, [location]);

  return (
    <>
      <Breadcrumbs items={[PATHS.usuarioHome]} />
      <Row>
        <Col>
          <TableContainer>
            <Nav tabs className="flex-nowrap">
              {tabs.map(({ id, texto, hash }) => (
                <NavItem key={id}>
                  <NavLink
                    href={hash}
                    onClick={() => handleTabChange(id)}
                    active={activeTab === id}
                    className="d-flex align-items-center text-center"
                  >
                    {texto}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </TableContainer>
        </Col>
        <Collapse horizontal isOpen={activeTab === Vistas.INTERNOS} className="col-auto px-0">
          <LinkContainer to={PATHS.nuevoRequisitoLegal.to}>
            <CustomButton type="button" color="primary" title="Añadir Requisito Legal">
              <IconsContainer>
                <FaBalanceScale size={20} textAnchor="middle" alignmentBaseline="middle" />
                <FaPlus
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  style={{
                    fontSize: ".5em",
                    position: "absolute",
                    left: "2.35em",
                    bottom: "1.5em",
                  }}
                />
              </IconsContainer>
            </CustomButton>
          </LinkContainer>
        </Collapse>
      </Row>
      <Row className="mt-3">
        <Col>
          {
            {
              [Vistas.NUEVOS]: (
                <TablaDeRequisitosLegalesNuevos
                  onVincularSuccess={handleVincularRequisitoLegalSuccess}
                />
              ),
              [Vistas.INTERNOS]: <TablaDeRequisitosLegales internos />,
              [Vistas.TODOS]: <TablaDeRequisitosLegales />,
            }[activeTab]
          }
        </Col>
      </Row>
    </>
  );
};

export default RequisitosLegales;
