import { useState, FC, useContext, FormEvent } from "react";
import { SustanciaQuimica, SustanciaQuimicaBase } from "../../../../models/SustanciaQuimica";
import { Context } from "../../../Context";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { createSustanciaQuimica } from "../../../../services/usuario-api-client";

interface SustanciaQuimicaFormProps {
  formId: string;
  onSuccess: (sustanciaQuimica: SustanciaQuimica) => void;
}

const SustanciaQuimicaForm: FC<SustanciaQuimicaFormProps> = ({ formId, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [nombre, setNombre] = useState<string>("");
  const [descripcion, setDescripcion] = useState<string>("");
  const [marca, setMarca] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const body: SustanciaQuimicaBase = {
      nombre,
      descripcion,
      marca,
    };
    createSustanciaQuimica(authToken, idDeLaEmpresaSeleccionada, body)
      .then(({ data }) => onSuccess(data))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre"
                value={nombre}
                onChange={(event) => setNombre(event.target.value)}
                required
              />
              <Label for="nombre">Nombre</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="descripcion"
                id="descripcion"
                placeholder="Descripcion"
                value={descripcion}
                onChange={(event) => setDescripcion(event.target.value)}
              />
              <Label for="descripcion">Descripcion</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="marca"
                id="marca"
                placeholder="Marca"
                value={marca}
                onChange={(event) => setMarca(event.target.value)}
              />
              <Label for="marca">Marca</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SustanciaQuimicaForm;
