import { FC, useContext, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { FaChevronUp } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { ImageButton, obtenerURLCompleta } from "../../../utilities/utils";
import {
  AnalisisDeRequisitoLegal,
  Modulos,
  Submodulos,
  textoDeModulo,
} from "../../../models/AnalisisDeRequisitoLegal";
import { PATHS, procesarPath } from "../../../utilities/Paths";
import {
  listarAccionesDeLaEmpresa,
  listCursosDeLaEmpresa,
  listDocumentos,
  vincularAnalisisConCurso,
  vincularAnalisisConDocumentoInterno,
  vincularAnalisisConModulo,
  vincularAnalisisConAccion,
  listarPlanesDeAccion,
  listDocumentosDeContratosParaImportar,
  vincularAnalisisConDocumentoDeContrato,
  vincularAnalisisConPlanDeAccion,
} from "../../../services/usuario-api-client";
import { Curso } from "../../../models/Curso";
import { Documento } from "../../../models/Documento";
import { Accion } from "../../../models/Accion";
import { OrigenDePlanDeAccion, PlanDeAccion } from "../../../models/PlanDeAccion";
import { DocumentoDelContrato } from "../../../models/DocumentoDelContrato";

const TileButton = styled(Button)`
  width: 100%;
  height: 100px;
  font-size: 12px;
  border-radius: 15px;
`;

enum Vistas {
  Modulos,
  Modulo,
  Cursos,
  DocumentosInternos,
  DocumentosDeContrato,
  PlanesDeAccion,
  Acciones,
  NuevaAccion,
}

interface Props {
  isOpen: boolean;
  requisitoLegal: RequisitoLegal;
  analisis: AnalisisDeRequisitoLegal;
  toggle: () => void;
  onSuccess: () => void;
  onClosed: () => void;
}

const VincularAnalisisModal: FC<Props> = ({
  isOpen,
  requisitoLegal,
  analisis: analisisSeleccionado,
  toggle,
  onSuccess,
  onClosed,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [vistaSeleccionada, setVistaSeleccionada] = useState<Vistas>(Vistas.Modulos);
  const [moduloSeleccionado, setModuloSeleccionado] = useState<Modulos | null>(null);
  const [cursos, setCursos] = useState<Curso[]>([]);
  const [documentosInternos, setDocumentosInternos] = useState<Documento[]>([]);
  const [documentosDeContrato, setDocumentosDeContrato] = useState<DocumentoDelContrato[]>([]);
  const [planesDeAccion, setPlanesDeAccion] = useState<PlanDeAccion[]>([]);
  const [acciones, setAcciones] = useState<Accion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleModalClosed = () => {
    setModuloSeleccionado(null);
    setVistaSeleccionada(Vistas.Modulos);
    onClosed();
  };

  const handleVincularModuloClick = (modulo: Modulos) => {
    if (analisisSeleccionado) {
      setModuloSeleccionado(modulo);
      setVistaSeleccionada(Vistas.Modulo);
    }
  };

  const handleVincularAnalisisConModulo = (modulo: Modulos | Submodulos) => {
    if (analisisSeleccionado) {
      setIsLoading(true);
      vincularAnalisisConModulo(authToken, analisisSeleccionado._id, modulo)
        .then(onSuccess)
        .finally(() => setIsLoading(false));
    }
  };

  const handleRegresarAModulosClick = () => setVistaSeleccionada(Vistas.Modulo);

  const handleVincularConCursoClick = () => {
    setIsLoading(true);
    const { empresa } = requisitoLegal;
    listCursosDeLaEmpresa(authToken, empresa)
      .then(({ data }) => {
        setCursos(data.map((curso) => new Curso(curso)));
        setVistaSeleccionada(Vistas.Cursos);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCursosClosed = () => setCursos([]);

  const handleVincularAnalisisConCurso = (cursoId: string) => {
    setIsLoading(true);
    vincularAnalisisConCurso(authToken, analisisSeleccionado._id, cursoId)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  const handleVincularConDocumentosInternosClick = () => {
    setIsLoading(true);
    listDocumentos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setDocumentosInternos(data.map((documento) => new Documento(documento)));
        setVistaSeleccionada(Vistas.DocumentosInternos);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDocumentosInternosClosed = () => setDocumentosInternos([]);

  const handleVincularAnalisisConDocumentoInterno = (documentoId: string) => {
    setIsLoading(true);
    vincularAnalisisConDocumentoInterno(authToken, analisisSeleccionado._id, documentoId)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  const handleVincularConDocumentoDeContratoClick = () => {
    setIsLoading(true);
    listDocumentosDeContratosParaImportar(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setDocumentosDeContrato(data.map((documento) => new DocumentoDelContrato(documento)));
        setVistaSeleccionada(Vistas.DocumentosDeContrato);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDocumentosDeContratoClosed = () => setDocumentosDeContrato([]);

  const handleVincularAnalisisConDocumentoDeContrato = (documentoId: string) => {
    setIsLoading(true);
    vincularAnalisisConDocumentoDeContrato(authToken, analisisSeleccionado._id, documentoId)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  const handleVincularConPlanDeAccionClick = () => {
    setIsLoading(true);
    listarPlanesDeAccion(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setPlanesDeAccion(data.map((planDeAccion) => new PlanDeAccion(planDeAccion)));
        setVistaSeleccionada(Vistas.PlanesDeAccion);
      })
      .finally(() => setIsLoading(false));
  };

  const handlePlanesDeAccionClosed = () => setPlanesDeAccion([]);

  const handleVincularAnalisisConPlanDeAccion = (planDeAccionId: string) => {
    setIsLoading(true);
    vincularAnalisisConPlanDeAccion(authToken, analisisSeleccionado._id, planDeAccionId)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  const handleVincularConAccionesClick = () => {
    setIsLoading(true);
    listarAccionesDeLaEmpresa(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setAcciones(data.map((accion) => new Accion(accion)));
        setVistaSeleccionada(Vistas.Acciones);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAccionesClosed = () => setAcciones([]);

  const handleVincularAnalisisConAccion = (accionId: string) => {
    setIsLoading(true);
    vincularAnalisisConAccion(authToken, analisisSeleccionado._id, accionId)
      .then(onSuccess)
      .finally(() => setIsLoading(false));
  };

  const handleVincularConNuevaAccionClick = () => {
    setIsLoading(true);
    listarPlanesDeAccion(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        setPlanesDeAccion(data.map((planDeAccion) => new PlanDeAccion(planDeAccion)));
        setVistaSeleccionada(Vistas.NuevaAccion);
      })
      .finally(() => setIsLoading(false));
  };

  const handleNuevaAccionClosed = () => setPlanesDeAccion([]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Modal isOpen={isOpen} toggle={toggle} onClosed={handleModalClosed} size="lg">
        <ModalHeader toggle={toggle}>Vincular análisis</ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col xs sm={11} md={10}>
              <p className="small">
                Requisito legal:
                <br />
                <a
                  href={obtenerURLCompleta(requisitoLegal?.filepath || "")}
                  target="_blank"
                  rel="noreferrer"
                >
                  {requisitoLegal?.nombre}
                </a>
                <br />
                <small>{analisisSeleccionado?.analisis}</small>
              </p>
            </Col>
          </Row>
          <Collapse isOpen={vistaSeleccionada === Vistas.Modulos}>
            <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
              {Object.values(Modulos).map((modulo) => (
                <Col key={modulo}>
                  <TileButton
                    type="button"
                    color="primary"
                    onClick={() => handleVincularModuloClick(modulo as Modulos)}
                  >
                    {textoDeModulo(modulo as Modulos)}
                  </TileButton>
                </Col>
              ))}
            </Row>
          </Collapse>
          <Collapse
            isOpen={vistaSeleccionada === Vistas.Modulo}
            onClosed={() => setModuloSeleccionado(null)}
          >
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={() => setVistaSeleccionada(Vistas.Modulos)}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">{textoDeModulo(moduloSeleccionado as Modulos)}:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            {
              {
                [Modulos.Formacion]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() => handleVincularAnalisisConModulo(Modulos.Formacion)}
                      >
                        Vincular al módulo de Formación
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={handleVincularConCursoClick}
                      >
                        Vincular a curso(s)
                      </TileButton>
                    </Col>
                    <Col>
                      <LinkContainer
                        to={PATHS.nuevoCurso.to}
                        state={{ analisis: analisisSeleccionado }}
                      >
                        <div>
                          <TileButton type="button" color="primary">
                            Crear nuevo curso
                          </TileButton>
                        </div>
                      </LinkContainer>
                    </Col>
                  </Row>
                ),
                [Modulos.RequisitosLegales]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() => handleVincularAnalisisConModulo(Modulos.RequisitosLegales)}
                      >
                        Vincular a Requisitos Legales
                      </TileButton>
                    </Col>
                  </Row>
                ),
                [Modulos.GestionDocumental]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() => handleVincularAnalisisConModulo(Modulos.GestionDocumental)}
                      >
                        Vincular al módulo de Gestión Documental
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={handleVincularConDocumentosInternosClick}
                      >
                        Vincular a Documentos Internos Específicos
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() =>
                          handleVincularAnalisisConModulo(Submodulos.GestionDocumentalInterna)
                        }
                      >
                        Vincular a Gestión Documental Interna
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={handleVincularConDocumentoDeContratoClick}
                      >
                        Vincular a Documento de Contrato
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() =>
                          handleVincularAnalisisConModulo(Submodulos.AdministracionDeContratos)
                        }
                      >
                        Vincular a Administración de Contratos
                      </TileButton>
                    </Col>
                  </Row>
                ),
                [Modulos.InvestigacionDeIncidentes]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() =>
                          handleVincularAnalisisConModulo(Modulos.InvestigacionDeIncidentes)
                        }
                      >
                        Vincular al módulo de Investigación de Incidentes
                      </TileButton>
                    </Col>
                  </Row>
                ),
                [Modulos.PlanesDeAccion]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() => handleVincularAnalisisConModulo(Modulos.PlanesDeAccion)}
                      >
                        Vincular al módulo de Planes de Acción
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={handleVincularConPlanDeAccionClick}
                      >
                        Vincular a Plan de Acción
                      </TileButton>
                    </Col>
                    <Col>
                      <LinkContainer
                        to={PATHS.planesDeAccion.to}
                        state={{
                          origen: OrigenDePlanDeAccion.RequisitoLegal,
                          fuente: analisisSeleccionado?.requisitoLegal,
                        }}
                      >
                        <div>
                          <TileButton type="button" color="primary">
                            Nuevo Plan de Acción
                          </TileButton>
                        </div>
                      </LinkContainer>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={handleVincularConAccionesClick}
                      >
                        Vincular a Acciones Específicas
                      </TileButton>
                    </Col>
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={handleVincularConNuevaAccionClick}
                      >
                        Nueva Acción
                      </TileButton>
                    </Col>
                  </Row>
                ),
                [Modulos.MIPER]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() => handleVincularAnalisisConModulo(Modulos.MIPER)}
                      >
                        Vincular al módulo de MIPER
                      </TileButton>
                    </Col>
                  </Row>
                ),
                [Modulos.Contexto]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() => handleVincularAnalisisConModulo(Modulos.Contexto)}
                      >
                        Vincular al módulo de Contexto
                      </TileButton>
                    </Col>
                  </Row>
                ),
                [Modulos.IndicadoresDeGestion]: (
                  <Row className="row-cols-2 row-cols-sm-3 row-cols-md-4 gy-3 justify-content-center">
                    <Col>
                      <TileButton
                        type="button"
                        color="primary"
                        onClick={() =>
                          handleVincularAnalisisConModulo(Modulos.IndicadoresDeGestion)
                        }
                      >
                        Vincular al módulo de Indicadores de Gestión
                      </TileButton>
                    </Col>
                  </Row>
                ),
              }[moduloSeleccionado as Modulos]
            }
          </Collapse>
          <Collapse isOpen={vistaSeleccionada === Vistas.Cursos} onClosed={handleCursosClosed}>
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={handleRegresarAModulosClick}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">Cursos:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            <ListGroup>
              {cursos.map(({ _id, tema }) => (
                <ListGroupItem
                  key={_id}
                  action
                  tag="button"
                  onClick={() => handleVincularAnalisisConCurso(_id)}
                >
                  {tema}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Collapse>
          <Collapse
            isOpen={vistaSeleccionada === Vistas.DocumentosInternos}
            onClosed={handleDocumentosInternosClosed}
          >
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={handleRegresarAModulosClick}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">Documentos Internos:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            <ListGroup>
              {documentosInternos.map(({ _id, nombreDelDocumento }) => (
                <ListGroupItem
                  key={_id}
                  action
                  tag="button"
                  onClick={() => handleVincularAnalisisConDocumentoInterno(_id)}
                >
                  {nombreDelDocumento}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Collapse>
          <Collapse
            isOpen={vistaSeleccionada === Vistas.DocumentosDeContrato}
            onClosed={handleDocumentosDeContratoClosed}
          >
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={handleRegresarAModulosClick}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">Documentos de Contrato:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            <ListGroup>
              {documentosDeContrato.map(({ _id, nombre }) => (
                <ListGroupItem
                  key={_id}
                  action
                  tag="button"
                  onClick={() => handleVincularAnalisisConDocumentoDeContrato(_id)}
                >
                  {nombre}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Collapse>
          <Collapse
            isOpen={vistaSeleccionada === Vistas.PlanesDeAccion}
            onClosed={handlePlanesDeAccionClosed}
          >
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={handleRegresarAModulosClick}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">Planes de Acción:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            <ListGroup>
              {planesDeAccion.map(({ _id, descripcion }) => (
                <ListGroupItem
                  action
                  tag="button"
                  onClick={() => handleVincularAnalisisConPlanDeAccion(_id)}
                >
                  {descripcion}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Collapse>
          <Collapse isOpen={vistaSeleccionada === Vistas.Acciones} onClosed={handleAccionesClosed}>
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={handleRegresarAModulosClick}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">Acciones:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            <ListGroup>
              {acciones.map(({ _id, accion }) => (
                <ListGroupItem
                  key={_id}
                  action
                  tag="button"
                  onClick={() => handleVincularAnalisisConAccion(_id)}
                >
                  {accion}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Collapse>
          <Collapse
            isOpen={vistaSeleccionada === Vistas.NuevaAccion}
            onClosed={handleNuevaAccionClosed}
          >
            <Row>
              <Col>
                <ImageButton
                  type="button"
                  color="link"
                  title="Volver"
                  className="w-100"
                  onClick={handleRegresarAModulosClick}
                >
                  <Row className="align-items-center gx-2 mb-3">
                    <Col xs="auto">
                      <h6 className="mb-1">Planes de Acción:</h6>
                    </Col>
                    <Col>
                      <hr />
                    </Col>
                    <Col xs="auto">
                      <FaChevronUp />
                    </Col>
                  </Row>
                </ImageButton>
              </Col>
            </Row>
            <ListGroup>
              {planesDeAccion.map(({ _id, descripcion }) => (
                <LinkContainer
                  key={_id}
                  to={procesarPath(PATHS.planDeAccion, _id)}
                  state={{ analisisId: analisisSeleccionado?._id }}
                >
                  <ListGroupItem action tag="button">
                    {descripcion}
                  </ListGroupItem>
                </LinkContainer>
              ))}
            </ListGroup>
          </Collapse>
        </ModalBody>
      </Modal>
    </>
  );
};

export default VincularAnalisisModal;
