import { Autoridad } from "./Autoridad";
import { CategoriasDeRequisitosLegales, TiposDeRequisitosLegales } from "./RequisitoLegal";

export interface DocumentoLegalBase {
  nombre: string;
  filepath: string;
  tipo: TiposDeRequisitosLegales;
  autoridad: Autoridad;
  numeroDeArticulos: number;
  tiposDeIndustria: string[];
  clasificacion: CategoriasDeRequisitosLegales;
  fechaDeEmision: Date | string;
  creadoPor: string;
  vinculado?: boolean;
  createdAt?: Date | string;
  updatedAt?: Date | string;
}

export interface DocumentoLegal extends DocumentoLegalBase {
  _id: string;
  fechaDeEmision: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface DocumentoLegalResponse extends DocumentoLegalBase {
  _id: string;
  fechaDeEmision: string;
  createdAt: string;
  updatedAt: string;
}

export class DocumentoLegal implements DocumentoLegal {
  constructor({
    _id,
    nombre,
    filepath,
    tipo,
    autoridad,
    numeroDeArticulos,
    tiposDeIndustria,
    clasificacion,
    fechaDeEmision,
    creadoPor,
    vinculado,
    createdAt,
    updatedAt,
  }: DocumentoLegalResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.filepath = filepath;
    this.tipo = tipo;
    this.autoridad = autoridad;
    this.numeroDeArticulos = numeroDeArticulos;
    this.tiposDeIndustria = tiposDeIndustria;
    this.clasificacion = clasificacion;
    this.fechaDeEmision = new Date(fechaDeEmision);
    this.creadoPor = creadoPor;
    this.vinculado = vinculado;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }
}

export interface DocumentoLegalBody {
  nombre: string;
  adjunto: File;
  tipo: TiposDeRequisitosLegales;
  autoridad: string;
  numeroDeArticulos: number;
  fechaDeEmision: Date | null;
}

export interface DocumentoLegalEditBody {
  nombre: string;
  tipo: TiposDeRequisitosLegales;
  autoridad: string;
  numeroDeArticulos: number;
  clasificacion: CategoriasDeRequisitosLegales;
  fechaDeEmision: Date | null;
}
