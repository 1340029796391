import { FC, FormEvent, useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { dateInputToDate, dateToISOString } from "../../../utilities/utils";
import { actualizarTiempoDePrueba } from "../../../services/admin-api-client";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";

interface Props {
  id: string;
  tiempoDePrueba?: Date;
}

const TiempoDePrueba: FC<Props> = ({ id, tiempoDePrueba }) => {
  const { adminAuthToken } = useContext(Context);
  const [dateString, setDateString] = useState<string>(dateToISOString(tiempoDePrueba));
  const [habilitado, setHabilitado] = useState<boolean>(!!tiempoDePrueba);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState<boolean>(false);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    setLoadingCounter((prev) => prev + 1);
    actualizarTiempoDePrueba(adminAuthToken, id, habilitado ? dateInputToDate(dateString) : null)
      .then(() => setConfirmationModalIsOpen(true))
      .catch(({ response }) => {
        setMensajeDeError(response?.data?.message || "Error al actualizar el tiempo de prueba");
        setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  };

  useEffect(() => {
    if (habilitado && !dateString) {
      const monthFromNow = new Date();
      monthFromNow.setMonth(monthFromNow.getMonth() + 1);
      setDateString(dateToISOString(monthFromNow));
    }
  }, [habilitado, dateString]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Form onSubmit={handleSubmit}>
        <Row className="row-cols-md-auto g-3 align-items-center">
          <Col xs="auto">
            <FormGroup check>
              <Input
                type="checkbox"
                name="tiempoDePrueba"
                id={`tiempo-de-prueba-check-${id}`}
                checked={habilitado}
                onChange={({ target }) => setHabilitado(target.checked)}
              />
            </FormGroup>
          </Col>
          <Col>
            <div className="form-floating">
              <Input
                type="date"
                name="tiempoDePrueba"
                id={`tiempo-de-prueba-${id}`}
                disabled={!habilitado}
                value={dateString}
                onChange={({ target }) => setDateString(target.value)}
                style={{ minWidth: 140 }}
              />
              <Label for={`tiempo-de-prueba-${id}`}>Tiempo de prueba</Label>
            </div>
          </Col>
          <Col xs="auto">
            <Button type="submit" color="primary">
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
      <Collapse isOpen={!!mensajeDeError}>
        <Alert color="danger" className="mt-3" onClick={() => alert(mensajeDeErrorInsider)}>
          {mensajeDeError}
        </Alert>
      </Collapse>
      <Modal isOpen={confirmationModalIsOpen} toggle={() => setConfirmationModalIsOpen(false)}>
        <ModalHeader toggle={() => setConfirmationModalIsOpen(false)}>
          Tiempo de prueba actualizado
        </ModalHeader>
        <ModalBody>El tiempo de prueba ha sido actualizado correctamente.</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setConfirmationModalIsOpen(false)}>
            Aceptar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TiempoDePrueba;
