import { useState, FC, KeyboardEvent, ChangeEvent, useRef, useEffect } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import { NumberFormatValues, PatternFormat } from "react-number-format";
import styled from "styled-components";
import { Contacto } from "../../models/Contacto";

const CustomListGroupItem = styled(ListGroupItem)`
  padding: 1rem 0.75rem;
  &.input-container:focus-within {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
  }
  .invalid-tooltip {
    width: calc(200% + 24px);
    max-width: max-content;
  }
`;

const CustomFormGroup = styled(FormGroup)`
  &.mb-3 {
    margin-bottom: 0px !important;
  }
`;

const BorderlessInput = styled(Input)`
  &,
  &:focus,
  &:active,
  &.is-invalid,
  &.is-invalid:focus,
  &.is-invalid:active {
    border: 0;
    box-shadow: none;
    outline: 0;
  }
  ~ label {
    margin-left: -2px;
  }
`;

interface ListaDeContactosProps {
  lista: Contacto[];
  label: string;
  nombrePlaceholder: string;
  telefonoPlaceholder: string;
  mensajeVacio: string;
  invalid: boolean;
  setLista: React.Dispatch<React.SetStateAction<Contacto[]>>;
  setInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListaDeContactos: FC<ListaDeContactosProps> = ({
  lista,
  label,
  nombrePlaceholder,
  telefonoPlaceholder,
  mensajeVacio,
  invalid,
  setLista,
  setInvalid,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [nuevoContacto, setNuevoContacto] = useState<Contacto>({ nombre: "", telefono: "" });

  const handleAgregarContacto = () => {
    const { current } = inputRef;
    if (current) {
      current.focus();
    }
    const { nombre, telefono } = nuevoContacto;
    if (nombre.trim() === "" || telefono.trim() === "") {
      return;
    }

    setLista([...lista, nuevoContacto]);
    setNuevoContacto({ nombre: "", telefono: "" });
  };

  const handleEliminarContacto = (index: number) => {
    const nuevaLista = [...lista];
    nuevaLista.splice(index, 1);
    setLista(nuevaLista);
  };

  const handleNombreChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: nombre } = e.target;
    setNuevoContacto({ ...nuevoContacto, nombre });
  };

  const handleTelefonoChange = ({ formattedValue: telefono }: NumberFormatValues) => {
    setNuevoContacto({ ...nuevoContacto, telefono });
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAgregarContacto();
    }
  };

  useEffect(() => {
    setInvalid(!!nuevoContacto.nombre || !!nuevoContacto.telefono);
  }, [nuevoContacto, setInvalid]);

  const mensajeDeError =
    !!nuevoContacto.nombre.length || !!nuevoContacto.telefono.length
      ? "No has agregado este contacto. Para continuar, agrégalo o deja el campo vacío."
      : "Agrega al menos un valor.";

  const { nombre, telefono } = nuevoContacto;

  return (
    <FormGroup>
      <Label>{label}:</Label>
      <ListGroup tag="div">
        {lista.map(({ nombre, telefono }, index) => (
          <CustomListGroupItem tag="div" key={index}>
            <Row className="align-items-center">
              <Col>{nombre}</Col>
              <Col>{telefono}</Col>
              <Col xs="auto">
                <Button
                  color="danger"
                  onClick={() => handleEliminarContacto(index)}
                  title="Eliminar"
                >
                  <FaTrash size={20} />
                </Button>
              </Col>
            </Row>
          </CustomListGroupItem>
        ))}
        {lista.length === 0 && (
          <CustomListGroupItem tag="div">
            <Row className="justify-content-center">
              <Col xs="auto">{mensajeVacio}</Col>
            </Row>
          </CustomListGroupItem>
        )}
        <CustomListGroupItem tag="div" className="input-container py-0">
          <Row className="align-items-center">
            <Col>
              <CustomFormGroup floating>
                <BorderlessInput
                  id="nombreNuevoContacto"
                  name="nombre"
                  placeholder={nombrePlaceholder}
                  type="text"
                  className="px-0"
                  invalid={invalid && (!!nombre || !!telefono || !lista.length)}
                  value={nombre}
                  onChange={handleNombreChange}
                  onKeyDown={handleKeyDown}
                  innerRef={inputRef}
                />
                <Label for="nombreNuevoContacto" className="px-0">
                  {nombrePlaceholder}
                </Label>
                <FormFeedback tooltip>{mensajeDeError}</FormFeedback>
              </CustomFormGroup>
            </Col>
            <Col>
              <CustomFormGroup floating>
                <PatternFormat
                  customInput={BorderlessInput}
                  id="telefonoNuevoContacto"
                  name="telefono"
                  placeholder={telefonoPlaceholder}
                  type="text"
                  className="px-0"
                  invalid={invalid && (!!nombre || !!telefono || !lista.length)}
                  format={telefono.startsWith("7") ? "###-####" : "####-####"}
                  mask="_"
                  value={telefono}
                  onValueChange={handleTelefonoChange}
                  onKeyDown={handleKeyDown}
                />
                <Label for="telefonoNuevoContacto" className="px-0">
                  {telefonoPlaceholder}
                </Label>
              </CustomFormGroup>
            </Col>
            <Col xs="auto">
              <Button color="primary" onClick={handleAgregarContacto} title="Agregar">
                <FaPlus size={20} />
              </Button>
            </Col>
          </Row>
        </CustomListGroupItem>
      </ListGroup>
    </FormGroup>
  );
};

export default ListaDeContactos;
