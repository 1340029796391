import { useContext, FC, useEffect, useState, Fragment, useCallback } from "react";
import { FaBolt, FaEdit, FaPlus, FaTasks } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  CustomTable,
  IconsContainer,
  TableContainer,
  TableMessage,
  shortDateToString,
} from "../../../utilities/utils";
import {
  OrigenDePlanDeAccion,
  PlanDeAccion,
  TiposDeAuditoria,
  origenEsDeFuenteManual,
  procesarLinkDeLaFuente,
  textoDeOrigenDePlanDeAccion,
  textoDeTipoDeAuditoria,
  textoDeTipoDeHallazgo,
} from "../../../models/PlanDeAccion";
import { listarPlanesDeAccion } from "../../../services/usuario-api-client";
import AccionForm from "./AccionForm";
import PlanDeAccionForm from "./PlanDeAccionForm";
import { textoDeClasificacionDeAccion } from "../../../models/Accion";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";

const AGREGAR_PLAN_DE_ACCION_FORM_ID = "agregarPlanDeAccionForm";
const AGREGAR_ACCIONES_FORM_ID = "agregarAccionesForm";
const EDITAR_PLAN_DE_ACCION_FORM_ID = "editarPlanDeAccionForm";

interface BotonesDeAccionProps {
  idPlan: string;
  onGestionarAccionesClick: (id: string) => void;
  onEditarPlanDeAccionClick: (id: string) => void;
}

const BotonesDeAccion: FC<BotonesDeAccionProps> = ({
  idPlan,
  onGestionarAccionesClick,
  onEditarPlanDeAccionClick,
}) => (
  <Row className="justify-content-center flex-nowrap gx-0">
    <Col xs="auto">
      <Button
        type="button"
        color="link"
        size="sm"
        title="Gestionar Acciones"
        onClick={() => onGestionarAccionesClick(idPlan)}
      >
        <FaBolt />
      </Button>
    </Col>
    <Col xs="auto">
      <Button
        type="button"
        color="link"
        size="sm"
        title="Editar"
        onClick={() => onEditarPlanDeAccionClick(idPlan)}
      >
        <FaEdit />
      </Button>
    </Col>
  </Row>
);

const PlanesDeAccion: FC = () => {
  const { state } = useLocation();
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [planesDeAccion, setPlanesDeAccion] = useState<PlanDeAccion[]>([]);
  const [planDeAccionSeleccionado, setPlanDeAccionSeleccionado] = useState<PlanDeAccion | null>(
    null
  );
  const [agregarPlanDeAccionModalIsOpen, setAgregarPlanDeAccionModalIsOpen] =
    useState<boolean>(false);
  const [accionesModalIsOpen, setAccionesModalIsOpen] = useState<boolean>(false);
  const [editarPlanDeAccionModalIsOpen, setEditarPlanDeAccionModalIsOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleAgregarPlanDeAccionModal = () =>
    setAgregarPlanDeAccionModalIsOpen(!agregarPlanDeAccionModalIsOpen);

  const toggleEditarPlanDeAccionModal = () =>
    setEditarPlanDeAccionModalIsOpen(!editarPlanDeAccionModalIsOpen);

  const handleEditarPlanDeAccionClick = (id: string) => {
    const planDeAccion = planesDeAccion.find(({ _id }) => _id === id);
    if (!planDeAccion) return;
    setPlanDeAccionSeleccionado(planDeAccion);
    toggleEditarPlanDeAccionModal();
  };

  const handlePlanDeAccionSuccess = () => {
    setAgregarPlanDeAccionModalIsOpen(false);
    setEditarPlanDeAccionModalIsOpen(false);
    cargarPlanesDeAccion();
  };

  const toggleAccionesModal = () => setAccionesModalIsOpen(!accionesModalIsOpen);

  const handleGestionarAccionesClick = (id: string) => {
    const planDeAccion = planesDeAccion.find(({ _id }) => _id === id);
    if (!planDeAccion) return;
    setPlanDeAccionSeleccionado(planDeAccion);
    toggleAccionesModal();
  };

  const handleAccionesSuccess = () => {
    toggleAccionesModal();
    cargarPlanesDeAccion();
  };

  const limpiarSeleccion = () => {
    setPlanDeAccionSeleccionado(null);
  };

  const cargarPlanesDeAccion = useCallback(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    listarPlanesDeAccion(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) =>
        setPlanesDeAccion(data.map((planDeAccion) => new PlanDeAccion(planDeAccion)))
      )
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(cargarPlanesDeAccion, [cargarPlanesDeAccion]);

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.planesDeAccion.name);
  }, [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (!state) return;
    const { origen, fuente } = state;
    if (origen && fuente) {
      setAgregarPlanDeAccionModalIsOpen(true);
    }
  }, [state]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="mb-3">
        <Col>
          <Breadcrumbs items={[PATHS.usuarioHome]} />
        </Col>
        <Col xs="auto" className="ms-auto">
          <Button
            color="primary"
            title="Agregar plan de acción"
            onClick={toggleAgregarPlanDeAccionModal}
          >
            <IconsContainer>
              <FaTasks size={20} textAnchor="middle" alignmentBaseline="middle" />
              <FaPlus
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{
                  fontSize: ".5em",
                  position: "absolute",
                  left: "2.35em",
                  bottom: "2em",
                }}
              />
            </IconsContainer>
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <TableContainer>
            <CustomTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Origen</th>
                  <th>Fuente / Norma o Artículo / Incidente</th>
                  <th>Descripción</th>
                  <th>Acción</th>
                  <th>Clasificación</th>
                  <th>Responsable</th>
                  <th>Fecha Límite</th>
                  <th>Estado</th>
                  <th>Comentarios</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {planesDeAccion.map(
                  (
                    {
                      _id: idPlan,
                      origen,
                      fuente,
                      fuenteManual,
                      tipoDeHallazgo,
                      descripcion,
                      acciones,
                    },
                    index
                  ) => (
                    <Fragment key={idPlan}>
                      {acciones.map(
                        (
                          {
                            _id: idAccion,
                            accion,
                            clasificacion,
                            responsable,
                            fechaLimite,
                            estado,
                          },
                          accionIndex
                        ) => (
                          <tr key={idAccion}>
                            {accionIndex === 0 && (
                              <>
                                <td rowSpan={acciones.length}>{index + 1}</td>
                                <td rowSpan={acciones.length}>
                                  {textoDeOrigenDePlanDeAccion(origen)}
                                </td>
                                <td rowSpan={acciones.length}>
                                  {origen === OrigenDePlanDeAccion.Otra && !!fuenteManual ? (
                                    fuenteManual
                                  ) : origen === OrigenDePlanDeAccion.Auditoria &&
                                    !!fuenteManual ? (
                                    textoDeTipoDeAuditoria(fuenteManual as TiposDeAuditoria)
                                  ) : !origenEsDeFuenteManual(origen) && !!fuente ? (
                                    <a
                                      href={procesarLinkDeLaFuente(origen, fuente)}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {typeof fuente === "string" ? fuente : fuente.nombreCompleto}
                                    </a>
                                  ) : (
                                    "—"
                                  )}
                                </td>
                                <td rowSpan={acciones.length}>
                                  {origen === OrigenDePlanDeAccion.Auditoria &&
                                    !!tipoDeHallazgo && (
                                      <>
                                        <b>{textoDeTipoDeHallazgo(tipoDeHallazgo)}</b>
                                        <br />
                                      </>
                                    )}
                                  {descripcion}
                                </td>
                              </>
                            )}
                            <td>{accion}</td>
                            <td>{textoDeClasificacionDeAccion(clasificacion)}</td>
                            <td>
                              {typeof responsable === "string"
                                ? responsable
                                : responsable.nombreCompleto}
                            </td>
                            <td>{shortDateToString(fechaLimite)}</td>
                            <td>{estado}</td>
                            <td className="text-center">—</td>
                            {accionIndex === 0 && (
                              <td rowSpan={acciones.length}>
                                <BotonesDeAccion
                                  idPlan={idPlan}
                                  onGestionarAccionesClick={handleGestionarAccionesClick}
                                  onEditarPlanDeAccionClick={handleEditarPlanDeAccionClick}
                                />
                              </td>
                            )}
                          </tr>
                        )
                      )}
                      {acciones.length === 0 && (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{textoDeOrigenDePlanDeAccion(origen)}</td>
                          <td>
                            {origen === OrigenDePlanDeAccion.Otra && !!fuenteManual ? (
                              fuenteManual
                            ) : origen === OrigenDePlanDeAccion.Auditoria && !!fuenteManual ? (
                              textoDeTipoDeAuditoria(fuenteManual as TiposDeAuditoria)
                            ) : !origenEsDeFuenteManual(origen) && !!fuente ? (
                              <a
                                href={procesarLinkDeLaFuente(origen, fuente)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {typeof fuente === "string" ? fuente : fuente.nombreCompleto}
                              </a>
                            ) : (
                              "—"
                            )}
                          </td>
                          <td>
                            {origen === OrigenDePlanDeAccion.Auditoria && !!tipoDeHallazgo && (
                              <>
                                <b>{textoDeTipoDeHallazgo(tipoDeHallazgo)}</b>
                                <br />
                              </>
                            )}
                            {descripcion}
                          </td>
                          <td className="text-center">—</td>
                          <td className="text-center">—</td>
                          <td className="text-center">—</td>
                          <td className="text-center">—</td>
                          <td className="text-center">—</td>
                          <td className="text-center">—</td>
                          <td>
                            <BotonesDeAccion
                              idPlan={idPlan}
                              onGestionarAccionesClick={handleGestionarAccionesClick}
                              onEditarPlanDeAccionClick={handleEditarPlanDeAccionClick}
                            />
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )
                )}
                {planesDeAccion.length === 0 && (
                  <tr>
                    <td colSpan={11}>
                      <TableMessage>
                        <Row className="gx-0">
                          <Col className="text-center">No hay planes de acción registrados.</Col>
                        </Row>
                        <Row className="justify-content-center gx-0 mt-3">
                          <Col xs="auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={toggleAgregarPlanDeAccionModal}
                            >
                              Agregar plan de acción
                            </Button>
                          </Col>
                        </Row>
                      </TableMessage>
                    </td>
                  </tr>
                )}
              </tbody>
            </CustomTable>
          </TableContainer>
        </Col>
      </Row>
      <Modal isOpen={agregarPlanDeAccionModalIsOpen} toggle={toggleAgregarPlanDeAccionModal}>
        <ModalHeader toggle={toggleAgregarPlanDeAccionModal}>Agregar Plan de Acción</ModalHeader>
        <ModalBody>
          <PlanDeAccionForm
            formId={AGREGAR_PLAN_DE_ACCION_FORM_ID}
            onSuccess={handlePlanDeAccionSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={AGREGAR_PLAN_DE_ACCION_FORM_ID}>
            Guardar
          </Button>
          <Button type="button" color="secondary" onClick={toggleAgregarPlanDeAccionModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={accionesModalIsOpen} toggle={toggleAccionesModal} onClosed={limpiarSeleccion}>
        <ModalHeader toggle={toggleAccionesModal}>Gestionar Acciones</ModalHeader>
        <ModalBody>
          <AccionForm
            formId={AGREGAR_ACCIONES_FORM_ID}
            planDeAccion={planDeAccionSeleccionado as PlanDeAccion}
            onSuccess={handleAccionesSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={AGREGAR_ACCIONES_FORM_ID}>
            Guardar
          </Button>
          <Button type="button" color="secondary" onClick={toggleAccionesModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={editarPlanDeAccionModalIsOpen} toggle={toggleEditarPlanDeAccionModal}>
        <ModalHeader toggle={toggleEditarPlanDeAccionModal}>Editar Plan de Acción</ModalHeader>
        <ModalBody>
          <PlanDeAccionForm
            formId={EDITAR_PLAN_DE_ACCION_FORM_ID}
            planDeAccion={planDeAccionSeleccionado as PlanDeAccion}
            onSuccess={handlePlanDeAccionSuccess}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" form={EDITAR_PLAN_DE_ACCION_FORM_ID}>
            Guardar
          </Button>
          <Button type="button" color="secondary" onClick={toggleEditarPlanDeAccionModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PlanesDeAccion;
