import { FormEvent, FC, useState, useContext, useEffect } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { FaCar, FaPlus } from "react-icons/fa";
import { CustomTable, IconsContainer, TableContainer } from "../../../../utilities/utils";
import { Vehiculo } from "../../../../models/Vehiculo";
import { Context } from "../../../Context";
import { listVehiculos } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";

interface BusquedaDeVehiculosProps {
  formId: string;
  vehiculosPreseleccionados: Vehiculo[];
  isCreating: boolean;
  onCreateToggle: () => void;
  onSubmit: (vehiculosSeleccionados: Vehiculo[]) => void;
}

const BusquedaDeVehiculos: FC<BusquedaDeVehiculosProps> = ({
  formId,
  vehiculosPreseleccionados,
  isCreating,
  onCreateToggle,
  onSubmit,
}) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [vehiculosSeleccionados, setVehiculosSeleccionados] =
    useState<Vehiculo[]>(vehiculosPreseleccionados);
  const [vehiculosFiltrados, setVehiculosFiltrados] = useState<Vehiculo[]>([]);
  const [vehiculos, setVehiculos] = useState<Vehiculo[]>([]);
  const [busqueda, setBusqueda] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(vehiculosSeleccionados);
  };

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setVehiculosFiltrados(
      vehiculos.filter(
        (vehiculo) =>
          vehiculo.placa.toLowerCase().includes(busquedaLower) ||
          vehiculo.marca.toLowerCase().includes(busquedaLower) ||
          vehiculo.modelo.toLowerCase().includes(busquedaLower)
      )
    );
  }, [vehiculos, busqueda]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada || isCreating) return;
    setIsLoading(true);
    listVehiculos(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const vehiculos = data.map((vehiculo) => new Vehiculo(vehiculo));
        setVehiculos(vehiculos);
        setVehiculosFiltrados(vehiculos);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada, isCreating]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Row className="align-items-center">
        <Col xs sm={6} md={4}>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Buscar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Buscar</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form id={formId} onSubmit={handleSubmit}>
        <TableContainer>
          <CustomTable>
            <thead>
              <tr>
                <th>
                  <Input
                    type="checkbox"
                    id="seleccionar-todos"
                    checked={
                      vehiculosFiltrados.length > 0 &&
                      vehiculosFiltrados.every((vinculacion) =>
                        vehiculosSeleccionados.some((v) => v._id === vinculacion._id)
                      )
                    }
                    onChange={({ target }) => {
                      if (target.checked) {
                        setVehiculosSeleccionados(vehiculosFiltrados);
                      } else {
                        setVehiculosSeleccionados([]);
                      }
                    }}
                    disabled={vehiculosFiltrados.length === 0}
                  />
                </th>
                <th>Placa</th>
                <th>Marca</th>
                <th>Modelo</th>
              </tr>
            </thead>
            <tbody>
              {vehiculosFiltrados.map((vehiculo) => (
                <tr key={vehiculo._id}>
                  <td className="text-center">
                    <Input
                      type="checkbox"
                      id={vehiculo._id}
                      checked={vehiculosSeleccionados.some((v) => v._id === vehiculo._id)}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setVehiculosSeleccionados([...vehiculosSeleccionados, vehiculo]);
                        } else {
                          setVehiculosSeleccionados(
                            vehiculosSeleccionados.filter((v) => v._id !== vehiculo._id)
                          );
                        }
                      }}
                    />
                  </td>
                  <td>{vehiculo.placa}</td>
                  <td>{vehiculo.marca}</td>
                  <td>{vehiculo.modelo}</td>
                </tr>
              ))}
              {vehiculos.length === 0 ? (
                <tr>
                  <td colSpan={4} className="text-center">
                    No hay vehículos registrados
                  </td>
                </tr>
              ) : (
                vehiculosFiltrados.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No hay vehículos que coincidan con la búsqueda
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </CustomTable>
        </TableContainer>
      </Form>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button type="button" color="primary" title="Agregar vehículo" onClick={onCreateToggle}>
            <IconsContainer>
              <FaCar size={20} textAnchor="middle" alignmentBaseline="middle" />
              <FaPlus
                textAnchor="middle"
                alignmentBaseline="middle"
                style={{
                  fontSize: ".5em",
                  position: "absolute",
                  left: "2.35em",
                  bottom: "1.5em",
                }}
              />
            </IconsContainer>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default BusquedaDeVehiculos;
