import styled from "styled-components";
import { Evaluacion } from "../models/DocumentoDelContrato";

const EvaluacionText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  padding: 6px 3px;
  &.aprobado {
    background-color: #599a3e;
  }
  &.pendiente {
    background-color: #ffd848;
    color: #000;
  }
  &.rechazado {
    background-color: #fb0006;
  }
`;

interface EvaluacionBadgeProps {
  evaluacion: Evaluacion;
}

export const EvaluacionBadge = ({ evaluacion }: EvaluacionBadgeProps) => {
  switch (evaluacion) {
    case Evaluacion.Aprobado:
      return <EvaluacionText className="aprobado">Aprobado</EvaluacionText>;
    case Evaluacion.Pendiente:
      return <EvaluacionText className="pendiente">Pendiente</EvaluacionText>;
    case Evaluacion.Rechazado:
      return <EvaluacionText className="rechazado">Rechazado</EvaluacionText>;
  }
};
