import { FC, useState, FormEvent, useEffect, useContext } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Vinculacion } from "../../../models/Vinculacion";
import { CustomTable, TableContainer } from "../../../utilities/utils";
import { Cargo } from "../../../models/Cargo";
import {
  fetchVinculaciones,
  listCargos,
  listUsuariosAsignados,
} from "../../../services/usuario-api-client";
import { Context } from "../../Context";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Curso } from "../../../models/Curso";

interface BusquedaDeUsuariosProps {
  formId: string;
  curso: Curso;
  onSubmit: (vinculaciones: Vinculacion[]) => void;
}

const BusquedaDeUsuarios: FC<BusquedaDeUsuariosProps> = ({ formId, curso, onSubmit }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [busqueda, setBusqueda] = useState<string>("");
  const [cargoSeleccionado, setCargoSeleccionado] = useState<Cargo | null>(null);
  const [vinculacionesPreseleccionadas, setVinculacionesPreseleccionadas] = useState<Vinculacion[]>(
    []
  );
  const [vinculacionesSeleccionadas, setVinculacionesSeleccionadas] = useState<Vinculacion[]>([]);
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [cargos, setCargos] = useState<Cargo[]>([]);
  const [vinculacionesFiltradas, setVinculacionesFiltradas] = useState<Vinculacion[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const toggleUsuario = (vinculacion: Vinculacion) => {
    if (vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)) {
      setVinculacionesSeleccionadas(
        vinculacionesSeleccionadas.filter((v) => v._id !== vinculacion._id)
      );
    } else {
      setVinculacionesSeleccionadas([...vinculacionesSeleccionadas, vinculacion]);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(vinculacionesSeleccionadas);
  };

  useEffect(() => {
    const busquedaLower = busqueda.toLowerCase();
    setVinculacionesFiltradas(
      vinculaciones.filter(
        (vinculacion) =>
          (vinculacion.persona.nombre.toLowerCase().includes(busquedaLower) ||
            vinculacion.persona.apellidos.toLowerCase().includes(busquedaLower) ||
            vinculacion.persona.identificacion.toLowerCase().includes(busquedaLower) ||
            vinculacion.persona.correo.toLowerCase().includes(busquedaLower)) &&
          (!cargoSeleccionado || vinculacion.cargo?.toString() === cargoSeleccionado.toString())
      )
    );
  }, [vinculaciones, busqueda, cargoSeleccionado]);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    Promise.all([
      fetchVinculaciones(authToken, idDeLaEmpresaSeleccionada),
      listCargos(authToken, idDeLaEmpresaSeleccionada),
      listUsuariosAsignados(authToken, curso._id),
    ])
      .then(([vinculacionesResponse, cargosResponse, asignadosResponse]) => {
        const vinculaciones = vinculacionesResponse.data.map(
          (vinculacion) => new Vinculacion(vinculacion)
        );
        setVinculaciones(vinculaciones);
        setCargos(cargosResponse.data.map((cargo) => new Cargo(cargo)));
        const preseleccionadas = asignadosResponse.data.map(
          (vinculacion) => new Vinculacion(vinculacion)
        );
        setVinculacionesPreseleccionadas(preseleccionadas);
        setVinculacionesSeleccionadas(preseleccionadas);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada, curso]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row className="align-items-center">
        <Col xs="auto">
          <Label>Filtrar</Label>
        </Col>
        <div className="w-100 d-sm-none" />
        <Col>
          <FormGroup floating>
            <Input
              type="search"
              name="busqueda"
              id="busqueda"
              placeholder="Buscar"
              autoComplete="off"
              value={busqueda}
              onChange={(event) => setBusqueda(event.target.value)}
            />
            <Label for="busqueda">Buscar</Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup floating>
            <Input
              type="select"
              name="cargo"
              id="cargo"
              placeholder="Cargo"
              autoComplete="off"
              value={cargoSeleccionado?._id || ""}
              onChange={(event) =>
                setCargoSeleccionado(
                  cargos.find((cargo) => cargo._id === event.target.value) || null
                )
              }
            >
              <option value="">Todos</option>
              {cargos.map((cargo) => (
                <option key={cargo._id} value={cargo._id}>
                  {cargo.cargo}
                </option>
              ))}
            </Input>
            <Label for="cargo">Cargo</Label>
          </FormGroup>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} id={formId}>
        <TableContainer>
          <CustomTable>
            <thead>
              <tr>
                <th>
                  <Input
                    type="checkbox"
                    id="seleccionar-todos"
                    checked={
                      vinculacionesFiltradas.length > 0 &&
                      vinculacionesFiltradas.every((vinculacion) =>
                        vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)
                      )
                    }
                    onChange={({ target }) => {
                      if (target.checked) {
                        setVinculacionesSeleccionadas(vinculacionesFiltradas);
                      } else {
                        setVinculacionesSeleccionadas([]);
                      }
                    }}
                    disabled={vinculacionesFiltradas.length === 0}
                  />
                </th>
                <th>Identificación</th>
                <th>Nombre</th>
                <th>Apellido(s)</th>
                <th>Correo</th>
                <th>Cargo</th>
              </tr>
            </thead>
            <tbody>
              {vinculacionesFiltradas.map((vinculacion) => (
                <tr key={vinculacion._id} onClick={() => toggleUsuario(vinculacion)}>
                  <td className="text-center">
                    <Input
                      type="checkbox"
                      id={`usuario-${vinculacion._id}`}
                      checked={vinculacionesSeleccionadas.some((v) => v._id === vinculacion._id)}
                      disabled={vinculacionesPreseleccionadas.some(
                        (v) => v._id === vinculacion._id
                      )}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setVinculacionesSeleccionadas([
                            ...vinculacionesSeleccionadas,
                            vinculacion,
                          ]);
                        } else {
                          setVinculacionesSeleccionadas(
                            vinculacionesSeleccionadas.filter((v) => v._id !== vinculacion._id)
                          );
                        }
                      }}
                    />
                  </td>
                  <td>{vinculacion.persona.identificacion}</td>
                  <td>{vinculacion.persona.nombre}</td>
                  <td>{vinculacion.persona.apellidos}</td>
                  <td>{vinculacion.persona.correo}</td>
                  <td>{(vinculacion.cargo as Cargo)?.cargo}</td>
                </tr>
              ))}
              {vinculaciones.length === 0 ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    No hay usuarios registrados
                  </td>
                </tr>
              ) : (
                vinculacionesFiltradas.length === 0 && (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No hay usuarios que coincidan con la búsqueda
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </CustomTable>
        </TableContainer>
      </Form>
    </>
  );
};

export default BusquedaDeUsuarios;
