import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Context } from "../../Context";
import { indicadoresDePlanesDeAccion } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { textoDeOrigenDePlanDeAccionEnPlural } from "../../../models/PlanDeAccion";
import { COLORS } from "../../../utilities/utils";

const PlanesDeAccion: FC = () => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [indicadores, setIndicadores] = useState<{ origen: string; cantidad: number }[]>([]);
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setLoadingCounter((prev) => prev + 1);
    indicadoresDePlanesDeAccion(authToken, idDeLaEmpresaSeleccionada)
      .then(({ data }) => {
        const indicadores = data.map(({ origen, cantidad }) => ({
          origen: textoDeOrigenDePlanDeAccionEnPlural(origen),
          cantidad,
        }));
        setIndicadores(indicadores);
      })
      .finally(() => setLoadingCounter((prev) => prev - 1));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Row className="mb-3">
        <Col className="text-center h5">Cantidad de Planes de Acción por origen</Col>
      </Row>
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth="100%"
        minHeight={40 * indicadores.length}
      >
        <BarChart width={500} height={500} data={indicadores} barSize={20} layout="vertical">
          <YAxis
            dataKey="origen"
            type="category"
            width={100}
            interval={0}
            tick={{ fontSize: 10 }}
          />
          <XAxis type="number" allowDecimals={false} />
          <Tooltip />
          <CartesianGrid />
          <Bar dataKey="cantidad" name="Cantidad" fill="#12416B">
            {indicadores.map((_, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default PlanesDeAccion;
