import { useState, FC, KeyboardEvent, useRef, useEffect, ChangeEvent } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { FaPlus, FaTrash } from "react-icons/fa";
import styled from "styled-components";
import { toCamelCase } from "../../utilities/utils";

const CustomListGroupItem = styled(ListGroupItem)`
  padding: 1rem 0.75rem;
  &.input-container:focus-within {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    outline: 0;
  }
`;

const CustomFormGroup = styled(FormGroup)`
  &.mb-3 {
    margin-bottom: 0px !important;
  }
`;

const BorderlessInput = styled(Input)`
  &,
  &:focus,
  &:active,
  &.is-invalid,
  &.is-invalid:focus,
  &.is-invalid:active {
    border: 0;
    box-shadow: none;
    outline: 0;
  }
  ~ label {
    margin-left: -2px;
  }
`;

interface ListaInputProps {
  lista: string[];
  placeholder: string;
  mensajeVacio: string;
  invalid: boolean;
  setLista: React.Dispatch<React.SetStateAction<string[]>>;
  setInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}

const ListaInput: FC<ListaInputProps> = ({
  lista,
  placeholder,
  mensajeVacio,
  invalid,
  setLista,
  setInvalid,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [nuevoItem, setNuevoItem] = useState<string>("");
  const idString = toCamelCase(placeholder);

  const handleAgregarItem = () => {
    const { current } = inputRef;
    if (current) {
      current.focus();
    }
    if (nuevoItem.trim() === "") {
      return;
    }

    setLista([...lista, nuevoItem]);
    setNuevoItem("");
  };

  const handleEliminarItem = (index: number) => {
    const nuevaLista = [...lista];
    nuevaLista.splice(index, 1);
    setLista(nuevaLista);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAgregarItem();
    }
  };

  useEffect(() => {
    setInvalid(nuevoItem !== "");
  }, [nuevoItem, setInvalid]);

  const mensajeDeError = !!nuevoItem.length
    ? "No has agregado este valor. Para continuar, agrégalo o deja el campo vacío."
    : "Agrega al menos un valor o selecciona 'No'.";

  return (
    <ListGroup tag="div">
      {lista.map((item, index) => (
        <CustomListGroupItem tag="div" key={item}>
          <Row className="align-items-center">
            <Col>{item}</Col>
            <Col xs="auto">
              <Button color="danger" onClick={() => handleEliminarItem(index)} title="Eliminar">
                <FaTrash size={20} />
              </Button>
            </Col>
          </Row>
        </CustomListGroupItem>
      ))}
      {lista.length === 0 && (
        <CustomListGroupItem tag="div">
          <Row className="justify-content-center">
            <Col xs="auto">{mensajeVacio}</Col>
          </Row>
        </CustomListGroupItem>
      )}
      <CustomListGroupItem tag="div" className="input-container py-0">
        <Row className="align-items-center">
          <Col>
            <CustomFormGroup floating>
              <BorderlessInput
                id={idString}
                name={idString}
                placeholder={placeholder}
                type="text"
                className="px-0"
                invalid={invalid}
                value={nuevoItem}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setNuevoItem(e.target.value)}
                onKeyDown={handleKeyDown}
                innerRef={inputRef}
              />
              <Label for={idString} className="px-0">
                {placeholder}
              </Label>
              <FormFeedback tooltip>{mensajeDeError}</FormFeedback>
            </CustomFormGroup>
          </Col>
          <Col xs="auto">
            <Button color="primary" onClick={handleAgregarItem} title="Agregar">
              <FaPlus size={20} />
            </Button>
          </Col>
        </Row>
      </CustomListGroupItem>
    </ListGroup>
  );
};

export default ListaInput;
