import { useState, FC, FormEvent, useEffect, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { FaMinusCircle, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import {
  Activo,
  Documento,
  DocumentoBase,
  FrecuenciaDeActualizacion,
  TiposDeDocumento,
  UnidadesDeTiempo,
  textoDeLaFrecuenciaDeActualizacion,
  textoDeLaUnidadDeTiempo,
  textoDelTipoDeDocumento,
} from "../../../../models/Documento";
import { Vinculacion } from "../../../../models/Vinculacion";
import BusquedaDeUsuarios from "./BusquedaDeUsuarios";
import { Cargo } from "../../../../models/Cargo";
import { Context } from "../../../Context";
import { listCargos, fetchVinculaciones } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import BusquedaDeVehiculos from "./BusquedaDeVehiculos";
import { Vehiculo } from "../../../../models/Vehiculo";
import { EquipoHerramienta } from "../../../../models/EquipoHerramienta";
import { SustanciaQuimica } from "../../../../models/SustanciaQuimica";
import BusquedaDeEquiposYHerramientas from "./BusquedaDeEquiposYHerramientas";
import BusquedaDeSustanciasQuimicas from "./BusquedaDeSustanciasQuimicas";
import SustanciaQuimicaForm from "./SustanciaQuimicaForm";
import VehiculoForm from "./VehiculoForm";
import EquipoHerramientaForm from "./EquipoHerramientaForm";
import { useLocation } from "react-router-dom";
import { DocumentoSugerido } from "../../../../models/DocumentoSugerido";

const PERSONAS_RESPONSABLES_FORM_ID = "personas-responsables-form";
const USUARIOS_FORM_ID = "usuarios-form";
const EQUIPOS_Y_HERRAMIENTAS_FORM_ID = "equipos-y-herramientas-form";
const CREAR_EQUIPO_O_HERRAMIENTA_FORM_ID = "crear-equipo-o-herramienta-form";
const VEHICULOS_FORM_ID = "vehiculos-form";
const CREAR_VEHICULO_FORM_ID = "crear-vehiculo-form";
const SUSTANCIAS_QUIMICAS_FORM_ID = "sustancias-quimicas-form";
const CREAR_SUSTANCIA_QUIMICA_FORM_ID = "crear-sustancia-quimica-form";

const ActivosContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

interface DocumentoFormProps {
  formId: string;
  documento?: Documento;
  onSubmit: (body: DocumentoBase) => void;
}

const DocumentoForm: FC<DocumentoFormProps> = ({ formId, documento, onSubmit }) => {
  const { state } = useLocation();
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [nombreDelDocumento, setNombreDelDocumento] = useState<string>(
    documento?.nombreDelDocumento || ""
  );
  const [tipoDeDocumento, setTipoDeDocumento] = useState<TiposDeDocumento | null>(
    documento?.tipoDeDocumento || null
  );
  const [personasResponsables, setPersonasResponsables] = useState<Vinculacion[]>(
    documento?.personasResponsables || []
  );
  const [frecuenciaDeActualizacion, setFrecuenciaDeActualizacion] =
    useState<FrecuenciaDeActualizacion | null>(documento?.frecuenciaDeActualizacion || null);
  const [valorDeLaFrecuenciaDeActualizacion, setValorDeLaFrecuenciaDeActualizacion] =
    useState<number>(documento?.valorDeLaFrecuenciaDeActualizacion || 1);
  const [unidadDeLaFrecuenciaDeActualizacion, setUnidadDeLaFrecuenciaDeActualizacion] =
    useState<UnidadesDeTiempo>(
      documento?.unidadDeLaFrecuenciaDeActualizacion || UnidadesDeTiempo.Horas
    );
  const [usarPersonasResponsablesComoActivos, setUsarPersonasResponsablesComoActivos] =
    useState<boolean>(false);
  const [activos, setActivos] = useState<Activo[]>(documento?.activos || []);
  const [vinculaciones, setVinculaciones] = useState<Vinculacion[]>([]);
  const [cargos, setCargos] = useState<Cargo[]>([]);
  const [busquedaDeUsuariosModalIsOpen, setBusquedaDeUsuariosModalIsOpen] =
    useState<boolean>(false);
  const [busquedaDeVinculacionesModalIsOpen, setBusquedaDeVinculacionesModalIsOpen] =
    useState<boolean>(false);
  const [busquedaDeEquiposYHerramientasModalIsOpen, setBusquedaDeEquiposYHerramientasModalIsOpen] =
    useState<boolean>(false);
  const [busquedaDeVehiculosModalIsOpen, setBusquedaDeVehiculosModalIsOpen] =
    useState<boolean>(false);
  const [busquedaDeSustanciasQuimicasModalIsOpen, setBusquedaDeSustanciasQuimicasModalIsOpen] =
    useState<boolean>(false);
  const [crearVehiculoIsOpen, setCrearVehiculoIsOpen] = useState<boolean>(false);
  const [crearEquipoOHerramientaIsOpen, setCrearEquipoOHerramientaIsOpen] =
    useState<boolean>(false);
  const [crearSustanciaQuimicaIsOpen, setCrearSustanciaQuimicaIsOpen] = useState<boolean>(false);
  const [importando, setImportando] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleBusquedaDeVinculacionesModal = () =>
    setBusquedaDeVinculacionesModalIsOpen(!busquedaDeVinculacionesModalIsOpen);

  const toggleBusquedaDeUsuariosModal = () =>
    setBusquedaDeUsuariosModalIsOpen(!busquedaDeUsuariosModalIsOpen);

  const toggleBusquedaDeEquiposYHerramientasModal = () =>
    setBusquedaDeEquiposYHerramientasModalIsOpen(!busquedaDeEquiposYHerramientasModalIsOpen);

  const toggleCrearEquipoOHerramienta = () =>
    setCrearEquipoOHerramientaIsOpen(!crearEquipoOHerramientaIsOpen);

  const toggleBusquedaDeVehiculosModal = () =>
    setBusquedaDeVehiculosModalIsOpen(!busquedaDeVehiculosModalIsOpen);

  const toggleCrearVehiculo = () => setCrearVehiculoIsOpen(!crearVehiculoIsOpen);

  const toggleBusquedaDeSustanciasQuimicasModal = () =>
    setBusquedaDeSustanciasQuimicasModalIsOpen(!busquedaDeSustanciasQuimicasModalIsOpen);

  const toggleCrearSustanciaQuimica = () =>
    setCrearSustanciaQuimicaIsOpen(!crearSustanciaQuimicaIsOpen);

  const handlePersonasResponsablesChange = (vinculaciones: Vinculacion[]) => {
    setPersonasResponsables(vinculaciones);
    setBusquedaDeVinculacionesModalIsOpen(false);
  };

  const handleActivosChange = (activos: Activo[]) => {
    setActivos(activos);
    setBusquedaDeUsuariosModalIsOpen(false);
    setBusquedaDeEquiposYHerramientasModalIsOpen(false);
    setBusquedaDeVehiculosModalIsOpen(false);
    setBusquedaDeSustanciasQuimicasModalIsOpen(false);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (tipoDeDocumento === null || frecuenciaDeActualizacion === null) return;

    const body: DocumentoBase = {
      nombreDelDocumento,
      tipoDeDocumento,
      personasResponsables,
      frecuenciaDeActualizacion,
    };

    if (frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra) {
      body.valorDeLaFrecuenciaDeActualizacion = valorDeLaFrecuenciaDeActualizacion;
      body.unidadDeLaFrecuenciaDeActualizacion = unidadDeLaFrecuenciaDeActualizacion;
    }

    if (tipoDeDocumento !== TiposDeDocumento.DocumentacionGeneralDeLaEmpresa) {
      if (tipoDeDocumento === TiposDeDocumento.DocumentacionDeUsuariosDirectos) {
        if (usarPersonasResponsablesComoActivos) {
          body.activos = personasResponsables.map(({ asResponse }) => asResponse);
        } else {
          body.activos = (activos as Vinculacion[]).map(({ asResponse }) => asResponse);
        }
      } else {
        body.activos = activos;
      }
    }

    onSubmit(body);
  };

  useEffect(() => {
    if (!authToken || !idDeLaEmpresaSeleccionada) return;
    setIsLoading(true);
    Promise.all([
      fetchVinculaciones(authToken, idDeLaEmpresaSeleccionada),
      listCargos(authToken, idDeLaEmpresaSeleccionada),
    ])
      .then(([vinculacionesResponse, cargosResponse]) => {
        setVinculaciones(
          vinculacionesResponse.data.map((vinculacion) => new Vinculacion(vinculacion))
        );
        setCargos(cargosResponse.data.map((cargo) => new Cargo(cargo)));
      })
      .finally(() => setIsLoading(false));
  }, [authToken, idDeLaEmpresaSeleccionada]);

  useEffect(() => {
    if (!state) return;
    const { documentoSugerido } = state;
    setImportando(!!documentoSugerido);
    if (!documentoSugerido) return;
    const {
      nombreDelDocumento,
      tipoDeDocumento,
      frecuenciaDeActualizacion,
      unidadDeLaFrecuenciaDeActualizacion,
      valorDeLaFrecuenciaDeActualizacion,
    } = documentoSugerido as DocumentoSugerido;
    setNombreDelDocumento(nombreDelDocumento);
    setTipoDeDocumento(tipoDeDocumento);
    setFrecuenciaDeActualizacion(frecuenciaDeActualizacion);
    setValorDeLaFrecuenciaDeActualizacion(valorDeLaFrecuenciaDeActualizacion || 1);
    setUnidadDeLaFrecuenciaDeActualizacion(
      unidadDeLaFrecuenciaDeActualizacion || UnidadesDeTiempo.Horas
    );
  }, [state]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="nombreDelDocumento"
            placeholder="Nombre del documento"
            value={nombreDelDocumento}
            onChange={({ target }) => setNombreDelDocumento(target.value)}
            required
            disabled={importando}
          />
          <Label for="nombreDelDocumento">Nombre del documento</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="select"
            id="tipoDeDocumento"
            value={tipoDeDocumento || ""}
            onChange={({ target }) => setTipoDeDocumento(target.value as TiposDeDocumento)}
            required
            disabled={importando}
          >
            <option value="" disabled>
              Selecciona un tipo de documento
            </option>
            {Object.values(TiposDeDocumento).map((value) => (
              <option key={value} value={value}>
                {textoDelTipoDeDocumento(value as TiposDeDocumento)}
              </option>
            ))}
          </Input>
          <Label for="tipoDeDocumento">Tipo de documento</Label>
        </FormGroup>
        <Collapse
          isOpen={
            !!tipoDeDocumento &&
            tipoDeDocumento !== TiposDeDocumento.DocumentacionGeneralDeLaEmpresa
          }
        >
          {tipoDeDocumento === TiposDeDocumento.DocumentacionDeUsuariosDirectos && (
            <FormGroup check className="mb-3">
              <Label check>
                <Input
                  type="checkbox"
                  id="usarPersonasResponsablesComoActivos"
                  checked={usarPersonasResponsablesComoActivos}
                  onChange={({ target }) => setUsarPersonasResponsablesComoActivos(target.checked)}
                />
                Usar las mismas personas responsables
              </Label>
            </FormGroup>
          )}
          <Collapse
            isOpen={
              !usarPersonasResponsablesComoActivos ||
              tipoDeDocumento !== TiposDeDocumento.DocumentacionDeUsuariosDirectos
            }
          >
            <Card className="mb-3">
              <CardBody className="pt-1">
                <Row className="justify-content-between">
                  <Col>
                    <Label className="small text-muted">
                      {
                        {
                          [TiposDeDocumento.DocumentacionDeUsuariosDirectos]: "Usuarios",
                          [TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa]:
                            "Equipos y herramientas",
                          [TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa]: "Vehículos",
                          [TiposDeDocumento.DocumentacionDeSustanciasQuimicas]:
                            "Sustancias químicas",
                          [TiposDeDocumento.DocumentacionGeneralDeLaEmpresa]: "Activos",
                        }[tipoDeDocumento as TiposDeDocumento]
                      }
                    </Label>
                  </Col>
                  {activos.length > 0 && (
                    <Col xs="auto">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        className="border-0"
                        title="Buscar"
                        onClick={() => {
                          if (
                            tipoDeDocumento === TiposDeDocumento.DocumentacionDeUsuariosDirectos
                          ) {
                            toggleBusquedaDeUsuariosModal();
                          } else if (
                            tipoDeDocumento === TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa
                          ) {
                            toggleBusquedaDeEquiposYHerramientasModal();
                          } else if (
                            tipoDeDocumento === TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa
                          ) {
                            toggleBusquedaDeVehiculosModal();
                          } else if (
                            tipoDeDocumento === TiposDeDocumento.DocumentacionDeSustanciasQuimicas
                          ) {
                            toggleBusquedaDeSustanciasQuimicasModal();
                          }
                        }}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <ActivosContainer>
                      {activos.map(({ _id, nombreCompleto }) => (
                        <Row key={_id} className="gx-0">
                          <Col>
                            <small>{nombreCompleto}</small>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() => setActivos(activos.filter((a) => a._id !== _id))}
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {activos.length === 0 && (
                        <>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Label className="small">
                                No hay{" "}
                                {
                                  {
                                    [TiposDeDocumento.DocumentacionDeUsuariosDirectos]: "usuarios",
                                    [TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa]:
                                      "equipos y herramientas",
                                    [TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa]:
                                      "vehículos",
                                    [TiposDeDocumento.DocumentacionDeSustanciasQuimicas]:
                                      "sustancias químicas",
                                    [TiposDeDocumento.DocumentacionGeneralDeLaEmpresa]: "activos",
                                  }[tipoDeDocumento as TiposDeDocumento]
                                }{" "}
                                seleccionados
                              </Label>
                            </Col>
                          </Row>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Button
                                color="primary"
                                size="sm"
                                className="mb-1"
                                onClick={() => {
                                  if (
                                    tipoDeDocumento ===
                                    TiposDeDocumento.DocumentacionDeUsuariosDirectos
                                  ) {
                                    toggleBusquedaDeUsuariosModal();
                                  } else if (
                                    tipoDeDocumento ===
                                    TiposDeDocumento.DocumentacionDeEquiposDeLaEmpresa
                                  ) {
                                    toggleBusquedaDeEquiposYHerramientasModal();
                                  } else if (
                                    tipoDeDocumento ===
                                    TiposDeDocumento.DocumentacionDeVehiculosDeLaEmpresa
                                  ) {
                                    toggleBusquedaDeVehiculosModal();
                                  } else if (
                                    tipoDeDocumento ===
                                    TiposDeDocumento.DocumentacionDeSustanciasQuimicas
                                  ) {
                                    toggleBusquedaDeSustanciasQuimicasModal();
                                  }
                                }}
                              >
                                Agregar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ActivosContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Collapse>
        </Collapse>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardBody className="pt-1">
                <Row className="justify-content-between">
                  <Col>
                    <Label className="small text-muted">Persona(s) responsable(s)</Label>
                  </Col>
                  {personasResponsables.length > 0 && (
                    <Col xs="auto">
                      <Button
                        color="primary"
                        outline
                        size="sm"
                        className="border-0"
                        title="Buscar"
                        onClick={toggleBusquedaDeVinculacionesModal}
                      >
                        <FaPlus />
                      </Button>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col>
                    <ActivosContainer>
                      {personasResponsables.map((vinculacion) => (
                        <Row key={vinculacion._id} className="gx-0">
                          <Col>
                            <small>{vinculacion.nombreCompleto}</small>
                          </Col>
                          <Col xs="auto">
                            <Button
                              color="link"
                              size="sm"
                              className="py-0 border-0 text-danger"
                              onClick={() =>
                                setPersonasResponsables(
                                  personasResponsables.filter((v) => v._id !== vinculacion._id)
                                )
                              }
                            >
                              <FaMinusCircle />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      {personasResponsables.length === 0 && (
                        <>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Label className="small">No hay personas seleccionados</Label>
                            </Col>
                          </Row>
                          <Row className="gx-0">
                            <Col className="text-center">
                              <Button
                                color="primary"
                                size="sm"
                                className="mb-1"
                                onClick={toggleBusquedaDeVinculacionesModal}
                              >
                                Agregar
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                    </ActivosContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="align-items-center row-cols-1 row-cols-md-2">
          <Col>
            <FormGroup floating>
              <Input
                type="select"
                id="frecuenciaDeActualizacion"
                placeholder="Frecuencia de actualización"
                value={frecuenciaDeActualizacion || ""}
                onChange={({ target }) =>
                  setFrecuenciaDeActualizacion(target.value as FrecuenciaDeActualizacion)
                }
                required
                disabled={importando}
              >
                <option value="" disabled>
                  Selecciona una frecuencia de actualización
                </option>
                {Object.values(FrecuenciaDeActualizacion).map((value) => (
                  <option key={value} value={value}>
                    {textoDeLaFrecuenciaDeActualizacion(value as FrecuenciaDeActualizacion)}
                  </option>
                ))}
              </Input>
              <Label for="frecuenciaDeActualizacion">Frecuencia de actualización</Label>
            </FormGroup>
          </Col>
          <Col>
            <Collapse isOpen={frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra}>
              <InputGroup className="mb-3">
                <Input
                  type="number"
                  id="valorDeLaFrecuenciaDeActualizacion"
                  value={valorDeLaFrecuenciaDeActualizacion}
                  onChange={({ target }) =>
                    setValorDeLaFrecuenciaDeActualizacion(Number(target.value))
                  }
                  required={frecuenciaDeActualizacion === FrecuenciaDeActualizacion.Otra}
                  disabled={importando}
                />
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret disabled={importando}>
                    {textoDeLaUnidadDeTiempo(unidadDeLaFrecuenciaDeActualizacion)}
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.values(UnidadesDeTiempo).map((value) => (
                      <DropdownItem
                        key={value}
                        active={value === unidadDeLaFrecuenciaDeActualizacion}
                        onClick={() => setUnidadDeLaFrecuenciaDeActualizacion(value)}
                      >
                        {textoDeLaUnidadDeTiempo(value as UnidadesDeTiempo)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </InputGroup>
            </Collapse>
          </Col>
        </Row>
      </Form>
      <Modal
        size="lg"
        isOpen={busquedaDeVinculacionesModalIsOpen}
        toggle={toggleBusquedaDeVinculacionesModal}
      >
        <ModalHeader toggle={toggleBusquedaDeVinculacionesModal}>Buscar usuarios</ModalHeader>
        <ModalBody>
          <BusquedaDeUsuarios
            formId={PERSONAS_RESPONSABLES_FORM_ID}
            vinculacionesPreseleccionadas={personasResponsables}
            vinculaciones={vinculaciones}
            cargos={cargos}
            onSubmit={handlePersonasResponsablesChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={PERSONAS_RESPONSABLES_FORM_ID}>
            Aceptar
          </Button>
          <Button color="secondary" onClick={toggleBusquedaDeVinculacionesModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={busquedaDeUsuariosModalIsOpen}
        toggle={toggleBusquedaDeUsuariosModal}
      >
        <ModalHeader toggle={toggleBusquedaDeUsuariosModal}>Buscar usuarios</ModalHeader>
        <ModalBody>
          <BusquedaDeUsuarios
            formId={USUARIOS_FORM_ID}
            vinculacionesPreseleccionadas={activos as Vinculacion[]}
            vinculaciones={vinculaciones}
            cargos={cargos}
            onSubmit={handleActivosChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" form={USUARIOS_FORM_ID}>
            Aceptar
          </Button>
          <Button color="secondary" onClick={toggleBusquedaDeUsuariosModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        isOpen={busquedaDeEquiposYHerramientasModalIsOpen}
        toggle={toggleBusquedaDeEquiposYHerramientasModal}
        onClosed={() => setCrearEquipoOHerramientaIsOpen(false)}
      >
        <Collapse isOpen={!crearEquipoOHerramientaIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeEquiposYHerramientasModal}>
            Buscar equipos y herramientas
          </ModalHeader>
          <ModalBody>
            <BusquedaDeEquiposYHerramientas
              formId={EQUIPOS_Y_HERRAMIENTAS_FORM_ID}
              equiposYHerramientasPreseleccionados={activos as EquipoHerramienta[]}
              isCreating={crearEquipoOHerramientaIsOpen}
              onCreateToggle={toggleCrearEquipoOHerramienta}
              onSubmit={handleActivosChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={EQUIPOS_Y_HERRAMIENTAS_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleBusquedaDeEquiposYHerramientasModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={crearEquipoOHerramientaIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeEquiposYHerramientasModal}>
            Crear equipo o herramienta
          </ModalHeader>
          <ModalBody>
            <EquipoHerramientaForm
              formId={CREAR_EQUIPO_O_HERRAMIENTA_FORM_ID}
              onSuccess={toggleCrearEquipoOHerramienta}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={CREAR_EQUIPO_O_HERRAMIENTA_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleCrearEquipoOHerramienta}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
      <Modal
        size="lg"
        isOpen={busquedaDeVehiculosModalIsOpen}
        toggle={toggleBusquedaDeVehiculosModal}
        onClosed={() => setCrearVehiculoIsOpen(false)}
      >
        <Collapse isOpen={!crearVehiculoIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeVehiculosModal}>Buscar vehiculos</ModalHeader>
          <ModalBody>
            <BusquedaDeVehiculos
              formId={VEHICULOS_FORM_ID}
              vehiculosPreseleccionados={activos as Vehiculo[]}
              isCreating={crearVehiculoIsOpen}
              onCreateToggle={toggleCrearVehiculo}
              onSubmit={handleActivosChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={VEHICULOS_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleBusquedaDeVehiculosModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={crearVehiculoIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeVehiculosModal}>Crear vehiculo</ModalHeader>
          <ModalBody>
            <VehiculoForm formId={CREAR_VEHICULO_FORM_ID} onSuccess={toggleCrearVehiculo} />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={CREAR_VEHICULO_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleCrearVehiculo}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
      <Modal
        size="lg"
        isOpen={busquedaDeSustanciasQuimicasModalIsOpen}
        toggle={toggleBusquedaDeSustanciasQuimicasModal}
        onClosed={() => setCrearSustanciaQuimicaIsOpen(false)}
      >
        <Collapse isOpen={!crearSustanciaQuimicaIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeSustanciasQuimicasModal}>
            Buscar sustancias químicas
          </ModalHeader>
          <ModalBody>
            <BusquedaDeSustanciasQuimicas
              formId={SUSTANCIAS_QUIMICAS_FORM_ID}
              sustanciasQuimicasPreseleccionadas={activos as SustanciaQuimica[]}
              isCreating={crearSustanciaQuimicaIsOpen}
              onCreateToggle={toggleCrearSustanciaQuimica}
              onSubmit={handleActivosChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={SUSTANCIAS_QUIMICAS_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleBusquedaDeSustanciasQuimicasModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
        <Collapse isOpen={crearSustanciaQuimicaIsOpen}>
          <ModalHeader toggle={toggleBusquedaDeSustanciasQuimicasModal}>
            Crear sustancia química
          </ModalHeader>
          <ModalBody>
            <SustanciaQuimicaForm
              formId={CREAR_SUSTANCIA_QUIMICA_FORM_ID}
              onSuccess={toggleCrearSustanciaQuimica}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit" form={CREAR_SUSTANCIA_QUIMICA_FORM_ID}>
              Aceptar
            </Button>
            <Button color="secondary" onClick={toggleCrearSustanciaQuimica}>
              Cancelar
            </Button>
          </ModalFooter>
        </Collapse>
      </Modal>
    </>
  );
};

export default DocumentoForm;
