import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardHeader, ListGroup, ListGroupItem, Table } from "reactstrap";
import styled from "styled-components";
import { Context } from "../../Context";
import {
  Incidente,
  textoDePartesAfectadas,
  textoDelEstadoACR,
  textoDelTipoDeIncidente,
} from "../../../models/Incidente";
import { getIncidente } from "../../../services/usuario-api-client";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import {
  procesarLinkDeDocumento,
  shortDateToString,
  shortDatetimeToString,
} from "../../../utilities/utils";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { PATHS } from "../../../utilities/Paths";

const TableContainer = styled.div`
  th {
    width: 0;
    min-width: fit-content;
    @media (min-width: 768px) {
      white-space: nowrap;
    }
  }
`;

const IncidenteView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [incidente, setIncidente] = useState<Incidente>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.incidente.name), [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    getIncidente(authToken, id)
      .then(({ data }) => setIncidente(new Incidente(data)))
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.investigacionDeIncidentes]} />
      <TableContainer>
        <Table borderless style={{ tableLayout: "auto" }}>
          <tbody>
            <tr>
              <th scope="row">Título:</th>
              <td>{incidente?.tituloDelIncidente}</td>
            </tr>
            <tr>
              <th scope="row">Descripción:</th>
              <td>{incidente?.descripcionDelIncidente}</td>
            </tr>
            <tr>
              <th scope="row">Acciones inmediatas:</th>
              <td>{incidente?.accionesInmediatas}</td>
            </tr>
            <tr>
              <th scope="row">Fecha de ocurrencia:</th>
              <td>{!!incidente ? shortDatetimeToString(incidente.fechaOcurrencia) : "N/A"}</td>
            </tr>
            <tr>
              <th scope="row">Fecha límite de ACR:</th>
              <td>{!!incidente?.fechaLimite ? shortDateToString(incidente.fechaLimite) : "N/A"}</td>
            </tr>
            <tr>
              <th scope="row">Lugar del incidente:</th>
              <td>{incidente?.lugarDelIncidente}</td>
            </tr>
            <tr>
              <th scope="row">Tipo de incidente:</th>
              <td>{!!incidente ? textoDelTipoDeIncidente(incidente.tipoDeIncidente) : ""}</td>
            </tr>
            <tr>
              <th scope="row">Estado de ACR:</th>
              <td>{!!incidente ? textoDelEstadoACR(incidente.estadoACR) : ""}</td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>
      <Card className="mb-3">
        <CardHeader>
          <b>Afectados</b>
        </CardHeader>
        <ListGroup flush>
          {incidente?.afectados && incidente?.afectados.length > 0 ? (
            incidente?.afectados.map((afectado) => (
              <ListGroupItem>
                {afectado.nombreCompleto}
                <br />
                Partes afectadas:{" "}
                {afectado.partesAfectadas.map((pa) => textoDePartesAfectadas(pa)).join(", ")}
                <br />
                Detalle: {afectado.detalle}
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>No hay afectados</ListGroupItem>
          )}
        </ListGroup>
      </Card>
      <Card className="mb-3">
        <CardHeader>
          <b>Testigos</b>
        </CardHeader>
        <ListGroup flush>
          {incidente?.testigos && incidente?.testigos.length > 0 ? (
            incidente?.testigos.map((testigo) => (
              <ListGroupItem>{testigo.nombreCompleto}</ListGroupItem>
            ))
          ) : (
            <ListGroupItem>No hay testigos</ListGroupItem>
          )}
        </ListGroup>
      </Card>
      <Card className="mb-3">
        <CardHeader>
          <b>Personas responsables de ACR</b>
        </CardHeader>
        <ListGroup flush>
          {incidente?.personasResponsables && incidente?.personasResponsables.length > 0 ? (
            incidente?.personasResponsables.map((responsable) => (
              <ListGroupItem>{responsable.nombreCompleto}</ListGroupItem>
            ))
          ) : (
            <ListGroupItem>No hay responsables de ACR</ListGroupItem>
          )}
        </ListGroup>
      </Card>
      <Card>
        <CardHeader>
          <b>Archivos</b>
        </CardHeader>
        <ListGroup flush>
          {!!incidente?.archivos && incidente?.archivos.length > 0 ? (
            incidente?.archivos.map(({ _id, filepath, nombre }) => (
              <ListGroupItem>
                <figure className="figure">
                  <a href={procesarLinkDeDocumento(filepath)} target="_blank" rel="noreferrer">
                    <img
                      src={procesarLinkDeDocumento(filepath)}
                      className="figure-img img-fluid rounded"
                      alt={nombre}
                    />
                  </a>
                  <figcaption className="figure-caption">{nombre}</figcaption>
                </figure>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>
              <h6>No hay archivos</h6>
            </ListGroupItem>
          )}
        </ListGroup>
      </Card>
    </>
  );
};

export default IncidenteView;
