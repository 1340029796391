import { Empresa } from "./Empresa";

export interface VehiculoBase {
  placa: string;
  marca: string;
  modelo: string;
}

export interface Vehiculo extends VehiculoBase {
  _id: string;
  empresa: Empresa;
}

export interface VehiculoResponse extends VehiculoBase {
  _id: string;
  empresa: Empresa;
}

export class Vehiculo implements Vehiculo {
  constructor({ _id, placa, marca, modelo, empresa }: VehiculoResponse) {
    this._id = _id;
    this.placa = placa;
    this.marca = marca;
    this.modelo = modelo;
    this.empresa = empresa;
  }

  get nombreCompleto() {
    return `${this.placa} - ${this.marca} ${this.modelo}`;
  }
}
