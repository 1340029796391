import { useState, FC, useContext, useMemo } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Contrato, ContratoBody } from "../../../../models/Contrato";
import { Empresa } from "../../../../models/Empresa";
import { Vinculacion } from "../../../../models/Vinculacion";
import { Context } from "../../../Context";
import { FaEdit, FaPlus } from "react-icons/fa";
import BusquedaDeEmpresa from "./BusquedaDeEmpresa";

interface ContratoFormProps {
  formId: string;
  contrato?: Contrato;
  onSubmit: (contrato: ContratoBody) => void;
}

const ContratoForm: FC<ContratoFormProps> = ({ formId, contrato, onSubmit }) => {
  const { idDeLaEmpresaSeleccionada: idDeLaEmpresaContratante } = useContext(Context);
  const [titulo, setTitulo] = useState<string>(contrato?.titulo || "");
  const [numeroDeDocumento, setNumeroDeDocumento] = useState<string>(
    contrato?.numeroDeDocumento || ""
  );
  const [empresaContratista, setEmpresaContratista] = useState<Empresa | null>(
    contrato?.empresaContratista || null
  );
  const [contactoContratista, setContactoContratista] = useState<Vinculacion | null>(
    contrato?.contactoContratista || null
  );
  const [isBusquedaDeEmpresaOpen, setIsBusquedaDeEmpresaOpen] = useState<boolean>(false);
  const [empresaBuscada, setEmpresaBuscada] = useState<Empresa | null>(null);
  const [contactoBuscado, setContactoBuscado] = useState<Vinculacion | null>(null);

  const handleEmpresaYContactoChange = ({
    empresa,
    usuario,
  }: {
    empresa: Empresa;
    usuario: Vinculacion;
  }) => {
    setEmpresaBuscada(empresa);
    setContactoBuscado(usuario);
  };

  const handleBusquedaSubmit = () => {
    if (!empresaBuscada || !contactoBuscado) return;
    setEmpresaContratista(empresaBuscada);
    setContactoContratista(contactoBuscado);
    setIsBusquedaDeEmpresaOpen(false);
  };

  const limpiarBusqueda = () => {
    setEmpresaBuscada(null);
    setContactoBuscado(null);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!empresaContratista || !contactoContratista) return;
    const contratoBody: ContratoBody = {
      _id: contrato?._id,
      tituloDelContrato: titulo,
      numeroDeDocumento,
      idDeLaEmpresaContratante,
      idDeLaEmpresaContratista: empresaContratista?._id,
      idDelContacto: contactoContratista?._id,
    };

    onSubmit(contratoBody);
  };

  const nombreYCargoDelContacto = useMemo(
    () => (!!contactoContratista ? `${contactoContratista?.nombreCompleto})` : ""),
    [contactoContratista]
  );

  return (
    <>
      <Form id={formId} onSubmit={handleSubmit}>
        <FormGroup floating>
          <Input
            type="text"
            id="titulo"
            name="titulo"
            value={titulo}
            onChange={({ target }) => setTitulo(target.value)}
            placeholder="Título del contrato"
          />
          <Label htmlFor="titulo">Título del contrato</Label>
        </FormGroup>
        <FormGroup floating>
          <Input
            type="text"
            id="numeroDeDocumento"
            name="numeroDeDocumento"
            value={numeroDeDocumento}
            onChange={({ target }) => setNumeroDeDocumento(target.value)}
            placeholder="Número de documento"
          />
          <Label htmlFor="numeroDeDocumento">Número de documento</Label>
        </FormGroup>
        <Card className="mb-3">
          <CardBody className="pt-1">
            <Row className="justify-content-between">
              <Col>
                <Label className="small text-muted">Contratista</Label>
              </Col>
              <Col xs="auto">
                <Button
                  color="primary"
                  outline
                  size="sm"
                  className="border-0"
                  title="Buscar"
                  onClick={() => setIsBusquedaDeEmpresaOpen(true)}
                >
                  {!empresaContratista || !contactoContratista ? <FaPlus /> : <FaEdit />}
                </Button>
              </Col>
            </Row>
            <FormGroup floating>
              <Input
                type="text"
                id="empresaContratista"
                name="empresaContratista"
                value={empresaContratista?.nombreDeLaEmpresa || ""}
                placeholder="Empresa contratista"
                disabled
              />
              <Label htmlFor="empresaContratista">Empresa contratista</Label>
            </FormGroup>
            <FormGroup floating>
              <Input
                type="text"
                id="contactoContratista"
                name="contactoContratista"
                value={nombreYCargoDelContacto}
                placeholder="Contacto contratista"
                disabled
              />
              <Label htmlFor="contactoContratista">Contacto contratista</Label>
            </FormGroup>
          </CardBody>
        </Card>
      </Form>
      <Modal
        isOpen={isBusquedaDeEmpresaOpen}
        toggle={() => setIsBusquedaDeEmpresaOpen(false)}
        onClosed={limpiarBusqueda}
      >
        <ModalHeader toggle={() => setIsBusquedaDeEmpresaOpen(false)}>Buscar empresa</ModalHeader>
        <ModalBody>
          <BusquedaDeEmpresa
            empresaPreseleccionada={empresaContratista}
            usuarioPreseleccionado={contactoContratista}
            onChange={handleEmpresaYContactoChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleBusquedaSubmit}>
            Aceptar
          </Button>
          <Button color="secondary" onClick={() => setIsBusquedaDeEmpresaOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ContratoForm;
