import { Contexto } from "./Contexto";
import { RequisitoLegal, RequisitoLegalResponse } from "./RequisitoLegal";

export enum TiposDeParteInteresada {
  Interna = "interna",
  Externa = "externa",
}

export enum ClasificacionDeParteInteresada {
  Necesidad = "necesidad",
  Expectativa = "expectativa",
}

export interface NecesidadExpectativa {
  tipo: ClasificacionDeParteInteresada;
  descripcion: string;
}

export interface ParteInteresadaBase {
  _id: string;
  nombre: string;
  tipo: TiposDeParteInteresada;
  necesidadesYExpectativas: NecesidadExpectativa[];
  requisitoLegal?: string | RequisitoLegalResponse | RequisitoLegal;
  contextos?: Contexto[];
  empresa: string;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface ParteInteresada extends ParteInteresadaBase {
  requisitoLegal?: string | RequisitoLegal;
  createdAt: Date;
  updatedAt: Date;
}

export interface ParteInteresadaResponse extends ParteInteresadaBase {
  requisitoLegal?: string | RequisitoLegalResponse;
  createdAt: string;
  updatedAt: string;
}

export class ParteInteresada implements ParteInteresada {
  constructor({
    _id,
    nombre,
    tipo,
    necesidadesYExpectativas,
    requisitoLegal,
    contextos,
    empresa,
    createdAt,
    updatedAt,
  }: ParteInteresadaResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.tipo = tipo;
    this.necesidadesYExpectativas = necesidadesYExpectativas;
    if (typeof requisitoLegal === "string") {
      this.requisitoLegal = requisitoLegal;
    } else if (!!requisitoLegal) {
      this.requisitoLegal = new RequisitoLegal(requisitoLegal);
    }
    this.contextos = contextos;
    this.empresa = empresa;
    this.createdAt = new Date(createdAt);
    this.updatedAt = new Date(updatedAt);
  }
}

export function textoTipoDeParteInteresada(tipo: TiposDeParteInteresada): string {
  switch (tipo) {
    case TiposDeParteInteresada.Interna:
      return "Interna";
    case TiposDeParteInteresada.Externa:
      return "Externa";
  }
}

export function textoTipoNecesidadExpectativa(tipo: ClasificacionDeParteInteresada): string {
  switch (tipo) {
    case ClasificacionDeParteInteresada.Necesidad:
      return "Necesidad";
    case ClasificacionDeParteInteresada.Expectativa:
      return "Expectativa";
  }
}

export interface ParteInteresadaPayload {
  nombre: string;
  tipo: TiposDeParteInteresada;
  necesidadesYExpectativas: NecesidadExpectativa[];
  requisitoLegal?: string;
}
