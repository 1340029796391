import axios from "axios";
import { UsuarioResponse } from "../models/Usuario";
import { Empresa } from "../models/Empresa";
import { VinculacionResponse } from "../models/Vinculacion";
import { CargoResponse } from "../models/Cargo";
import { UsuariosLMSResponse } from "../models/UsuarioLMS";
import {
  DocumentoLegalBody,
  DocumentoLegalEditBody,
  DocumentoLegalResponse,
} from "../models/DocumentoLegal";
import { Autoridad } from "../models/Autoridad";
import { DocumentoSugerido, DocumentoSugeridoBase } from "../models/DocumentoSugerido";

const authHeader = (authToken: string) => ({
  headers: {
    Authorization: `Bearer ${authToken}`,
  },
});

export const restablecerContrasena = (authToken: string, correo: string) => {
  return axios.post<{ nuevaContrasena: string }>(
    "/api/admin/usuarios/restablecer",
    { correo },
    authHeader(authToken)
  );
};

export const enviarCorreoConfirmacion = (authToken: string, correo: string) => {
  return axios.post("/api/admin/usuarios/correo/confirmacion", { correo }, authHeader(authToken));
};

export const actualizarTiempoDePrueba = (
  authToken: string,
  idDelUsuario: string,
  tiempoDePrueba: Date | null
) => {
  return axios.post(
    `/api/admin/usuarios/${idDelUsuario}/tiempo-de-prueba`,
    { tiempoDePrueba },
    authHeader(authToken)
  );
};

export const eliminarUsuario = (authToken: string, idDelUsuario: string) => {
  return axios.delete(`/api/admin/usuarios/${idDelUsuario}`, authHeader(authToken));
};

export const repararVinculaciones = (authToken: string, idDeLaEmpresa: string) => {
  return axios.get(
    `/api/admin/empresas/${idDeLaEmpresa}/vinculaciones/reparar`,
    authHeader(authToken)
  );
};

export const obtenerUsuarios = (authToken: string) => {
  return axios.get<UsuarioResponse[]>("/api/admin/usuarios", authHeader(authToken));
};

export const obtenerEmpresas = (authToken: string) => {
  return axios.get<Empresa[]>("/api/admin/empresas", authHeader(authToken));
};

export const obtenerVinculaciones = (authToken: string) => {
  return axios.get<VinculacionResponse[]>("/api/admin/vinculaciones", authHeader(authToken));
};

export const obtenerCargos = (authToken: string) => {
  return axios.get<CargoResponse[]>("/api/admin/cargos", authHeader(authToken));
};

export const fetchUsuariosLMS = (authToken: string) => {
  return axios.get<UsuariosLMSResponse>("/api/lms/usuarios", authHeader(authToken));
};

export const obtenerDocumentosLegales = (authToken: string) => {
  return axios.get<DocumentoLegalResponse[]>(
    "/api/admin/documentos-legales",
    authHeader(authToken)
  );
};

export const obtenerBlobDeUnDocumento = (url: string) => {
  return axios.get(url, { responseType: "blob" });
};

export const crearDocumentoLegal = (authToken: string, documentoLegal: DocumentoLegalBody) => {
  return axios.post<DocumentoLegalResponse>("/api/admin/documentos-legales", documentoLegal, {
    headers: { Authorization: `Bearer ${authToken}`, "Content-Type": "multipart/form-data" },
  });
};

export const editarDocumentoLegal = (
  authToken: string,
  id: string,
  documentoLegal: DocumentoLegalEditBody
) => {
  return axios.put<DocumentoLegalResponse>(
    `/api/admin/documentos-legales/${id}`,
    documentoLegal,
    authHeader(authToken)
  );
};

export const eliminarDocumentoLegal = (authToken: string, id: string) => {
  return axios.delete(`/api/admin/documentos-legales/${id}`, authHeader(authToken));
};

export const listarAutoridades = (authToken: string) => {
  return axios.get<Autoridad[]>("/api/admin/autoridades", authHeader(authToken));
};

export const crearAutoridad = (authToken: string, nombre: string) => {
  return axios.post<Autoridad>("/api/admin/autoridades", { nombre }, authHeader(authToken));
};

export interface AutoridadConCantidadDeVinculaciones extends Autoridad {
  cantidadDeVinculaciones: number;
}

export const listarAutoridadesConCantidadDeVinculaciones = (authToken: string) => {
  return axios.get<AutoridadConCantidadDeVinculaciones[]>(
    "/api/admin/autoridades/vinculaciones",
    authHeader(authToken)
  );
};

export const editAutoridad = (authToken: string, id: string, nombre: string) => {
  return axios.put<Autoridad>(`/api/admin/autoridades/${id}`, { nombre }, authHeader(authToken));
};

export const deleteAutoridad = (authToken: string, id: string) => {
  return axios.delete<Autoridad>(`/api/admin/autoridades/${id}`, authHeader(authToken));
};

export const listDocumentosSugeridos = (authToken: string) => {
  return axios.get<DocumentoSugerido[]>("/api/admin/documentos-sugeridos", authHeader(authToken));
};

export const createDocumentoSugerido = (authToken: string, body: DocumentoSugeridoBase) => {
  return axios.post<DocumentoSugerido>(
    "/api/admin/documentos-sugeridos",
    body,
    authHeader(authToken)
  );
};

export const editDocumentoSugerido = (
  authToken: string,
  id: string,
  body: DocumentoSugeridoBase
) => {
  return axios.put<DocumentoSugerido>(
    `/api/admin/documentos-sugeridos/${id}`,
    body,
    authHeader(authToken)
  );
};

export const deleteDocumentoSugerido = (authToken: string, id: string) => {
  return axios.delete(`/api/admin/documentos-sugeridos/${id}`, authHeader(authToken));
};
