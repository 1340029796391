import { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../../Context";
import { PATHS } from "../../../utilities/Paths";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { fetchHost } from "../../../services/lms-api-client";
import { getCurso } from "../../../services/usuario-api-client";
import { Curso, textoDeFrecuencia, textoDeModalidad } from "../../../models/Curso";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import {
  procesarLinkDeDocumento,
  shortDateToString,
  TableContainer,
} from "../../../utilities/utils";
import { Button, Table } from "reactstrap";
import { PlanDeAccion } from "../../../models/PlanDeAccion";
import { RequisitoLegal } from "../../../models/RequisitoLegal";
import { Cargo } from "../../../models/Cargo";

const CursoView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { authToken, setTituloDeLaPantallaActual } = useContext(Context);
  const [curso, setCurso] = useState<Curso | null>(null);
  const [lmsHost, setLmsHost] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => setTituloDeLaPantallaActual(PATHS.curso.name), [setTituloDeLaPantallaActual]);

  useEffect(() => {
    if (!authToken || !id) return;
    setIsLoading(true);
    Promise.all([getCurso(authToken, id), fetchHost(authToken)])
      .then(([cursoResponse, hostResponse]) => {
        setCurso(new Curso(cursoResponse.data));
        setLmsHost(hostResponse.data.host);
      })
      .finally(() => setIsLoading(false));
  }, [authToken, id]);

  // Requisito legal	Modalidad	Objetivo	Contenido	Duración (horas)	Frecuencia	Evaluación de efectividad	Cargo	Fecha de vencimiento	Avance

  /*
  {cursos.map((curso) => (
              <tr key={curso.id}>
                <td>{curso.id}</td>
                <td>
                  {curso.tema} ({curso.codigo})
                </td>
                <td>
                  {curso.planDeAccion ? (
                    (curso.planDeAccion as PlanDeAccion).descripcion
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
                <td>
                  {curso.requisitosLegales.length > 0 ? (
                    curso.requisitosLegales.map((requisito) => (
                      <a
                        href={procesarLinkDeDocumento((requisito as RequisitoLegal).filepath)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {(requisito as RequisitoLegal).nombre}
                      </a>
                    ))
                  ) : (
                    <span>N/A</span>
                  )}
                </td>
                <td>{textoDeModalidad(curso.modalidad)}</td>
                <td>{curso.objetivo}</td>
                {curso.contenidoEsHTML ? (
                  <td dangerouslySetInnerHTML={{ __html: curso.contenido }} />
                ) : (
                  <td>{curso.contenido}</td>
                )}
                <td>{curso.duracionEnHoras}</td>
                <td>{textoDeFrecuencia(curso.frecuencia)}</td>
                <td>{curso.evaluacionEfectividad ? "Sí" : "No"}</td>
                <td>{curso.cargos.map((cargo) => (cargo as Cargo).cargo).join(", ")}</td>
                <td>
                  {curso.fechaDeVencimiento ? shortDateToString(curso.fechaDeVencimiento) : ""}
                </td>
                <td>{curso.avance}</td>
  */

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.formacion]} />
      {!!curso &&
        (({
          tema,
          codigo,
          planDeAccion,
          requisitosLegales,
          modalidad,
          objetivo,
          contenidoEsHTML,
          contenido,
          duracionEnHoras,
          frecuencia,
          cargos,
          fechaDeVencimiento,
          avance,
        }: Curso) => (
          <TableContainer>
            <Table borderless style={{ tableLayout: "auto" }}>
              <tbody>
                <tr>
                  <th scope="row">Tema</th>
                  <td>{tema}</td>
                </tr>
                <tr>
                  <th scope="row">Código</th>
                  <td>{codigo}</td>
                </tr>
                <tr>
                  <th scope="row">Plan de acción</th>
                  <td>{planDeAccion ? (planDeAccion as PlanDeAccion).descripcion : "N/A"}</td>
                </tr>
                <tr>
                  <th scope="row">Requisitos legales</th>
                  <td>
                    {requisitosLegales.length > 0 ? (
                      requisitosLegales.map((requisito) => (
                        <a
                          href={procesarLinkDeDocumento((requisito as RequisitoLegal).filepath)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {(requisito as RequisitoLegal).nombre}
                        </a>
                      ))
                    ) : (
                      <span>N/A</span>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Modalidad</th>
                  <td>{textoDeModalidad(modalidad)}</td>
                </tr>
                <tr>
                  <th scope="row">Objetivo</th>
                  <td>{objetivo}</td>
                </tr>
                <tr>
                  <th scope="row">Contenido</th>
                  {contenidoEsHTML ? (
                    <td dangerouslySetInnerHTML={{ __html: contenido }} />
                  ) : (
                    <td>{contenido}</td>
                  )}
                </tr>
                <tr>
                  <th scope="row">Duración (horas)</th>
                  <td>{duracionEnHoras}</td>
                </tr>
                <tr>
                  <th scope="row">Frecuencia</th>
                  <td>{textoDeFrecuencia(frecuencia)}</td>
                </tr>
                <tr>
                  <th scope="row">Cargos</th>
                  <td>{cargos.map((cargo) => (cargo as Cargo).cargo).join(", ")}</td>
                </tr>
                <tr>
                  <th scope="row">Fecha de vencimiento</th>
                  <td>{fechaDeVencimiento ? shortDateToString(fechaDeVencimiento) : ""}</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <Button
                      tag="a"
                      color="primary"
                      size="sm"
                      href={`${lmsHost}/course/view.php?id=${curso.id}`}
                      target="_blank"
                    >
                      Ir al curso
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </TableContainer>
        ))(curso)}
    </>
  );
};

export default CursoView;
