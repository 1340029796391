import { useContext, FC, FormEvent, useState } from "react";
import { Vehiculo, VehiculoBase } from "../../../../models/Vehiculo";
import { Context } from "../../../Context";
import { createVehiculo } from "../../../../services/usuario-api-client";
import LoadingSpinner from "../../../../utilities/LoadingSpinner";
import { Form } from "react-router-dom";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

interface VehiculoFormProps {
  formId: string;
  onSuccess: (vehiculo: Vehiculo) => void;
}

const VehiculoForm: FC<VehiculoFormProps> = ({ formId, onSuccess }) => {
  const { authToken, idDeLaEmpresaSeleccionada } = useContext(Context);
  const [placa, setPlaca] = useState<string>("");
  const [marca, setMarca] = useState<string>("");
  const [modelo, setModelo] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const body: VehiculoBase = {
      placa,
      marca,
      modelo,
    };
    createVehiculo(authToken, idDeLaEmpresaSeleccionada, body)
      .then(({ data }) => onSuccess(data))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LoadingSpinner isLoading={isLoading} />
      <Form id={formId} onSubmit={handleSubmit}>
        <Row className="row-cols-1 row-cols-md-3">
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="placa"
                id="placa"
                placeholder="Placa"
                value={placa}
                onChange={(event) => setPlaca(event.target.value)}
                required
              />
              <Label for="placa">Placa</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="marca"
                id="marca"
                placeholder="Marca"
                value={marca}
                onChange={(event) => setMarca(event.target.value)}
                required
              />
              <Label for="marca">Marca</Label>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup floating>
              <Input
                type="text"
                name="modelo"
                id="modelo"
                placeholder="Modelo"
                value={modelo}
                onChange={(event) => setModelo(event.target.value)}
                required
              />
              <Label for="modelo">Modelo</Label>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default VehiculoForm;
