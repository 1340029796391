import { Empresa } from "./Empresa";

export interface SustanciaQuimicaBase {
  nombre: string;
  descripcion?: string;
  marca?: string;
}

export interface SustanciaQuimica extends SustanciaQuimicaBase {
  _id: string;
  empresa: Empresa;
}

export interface SustanciaQuimicaResponse extends SustanciaQuimicaBase {
  _id: string;
  empresa: Empresa;
}

export class SustanciaQuimica implements SustanciaQuimica {
  constructor({ _id, nombre, descripcion, marca, empresa }: SustanciaQuimicaResponse) {
    this._id = _id;
    this.nombre = nombre;
    this.descripcion = descripcion;
    this.marca = marca;
    this.empresa = empresa;
  }

  get nombreCompleto() {
    if (!this.marca) return this.nombre;
    return `${this.nombre} ${this.marca}`;
  }
}
