import { FC, useContext, useEffect, useState } from "react";
import { Alert, Button, Collapse, Navbar, Row } from "reactstrap";
import LoadingSpinner from "../../../utilities/LoadingSpinner";
import { Context } from "../../Context";
import ParteInteresadaForm from "./ParteInteresadaForm";
import { ParteInteresadaPayload } from "../../../models/ParteInteresada";
import { createParteInteresada } from "../../../services/usuario-api-client";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../utilities/Paths";
import Breadcrumbs from "../../../utilities/Breadcrumbs";
import { scrollToTheBottom } from "../../../utilities/utils";

const AGREGAR_PARTE_INTERESADA_FORM_ID = "nuevaParteInteresada";

const NuevaParteInteresada: FC = () => {
  const navigate = useNavigate();
  const { authToken, idDeLaEmpresaSeleccionada, setTituloDeLaPantallaActual } = useContext(Context);
  const [mensajeDeError, setMensajeDeError] = useState<string>("");
  const [mensajeDeErrorInsider, setMensajeDeErrorInsider] = useState<string>("");
  const [loadingCounter, setLoadingCounter] = useState<number>(0);

  const handleOnSubmit = (parteInteresada: ParteInteresadaPayload) => {
    setMensajeDeError("");
    setMensajeDeErrorInsider("");
    setLoadingCounter((prev) => prev + 1);
    createParteInteresada(authToken, idDeLaEmpresaSeleccionada, parteInteresada)
      .then(() => navigate(PATHS.contexto.to))
      .catch(({ response }) => {
        setMensajeDeError(response?.data?.message || "Error al crear la parte interesada");
        setMensajeDeErrorInsider(JSON.stringify(response, null, 2));
      })
      .finally(() => {
        setLoadingCounter((prev) => prev - 1);
      });
  };

  useEffect(() => {
    setTituloDeLaPantallaActual(PATHS.nuevaParteInteresada.name);
  }, [setTituloDeLaPantallaActual]);

  return (
    <>
      <LoadingSpinner isLoading={loadingCounter > 0} />
      <Breadcrumbs items={[PATHS.usuarioHome, PATHS.contexto]} />
      <Row className="mb-3" />
      <ParteInteresadaForm formId={AGREGAR_PARTE_INTERESADA_FORM_ID} onSubmit={handleOnSubmit} />
      <Collapse isOpen={!!mensajeDeError} onEntered={scrollToTheBottom}>
        <Alert color="danger" onClick={() => alert(mensajeDeErrorInsider)}>
          {mensajeDeError}
        </Alert>
      </Collapse>
      <Row className="mb-5" />
      <Navbar light container fixed="bottom" className="bg-white">
        <Button
          form={AGREGAR_PARTE_INTERESADA_FORM_ID}
          type="submit"
          color="primary"
          className="ms-auto"
        >
          Crear
        </Button>
      </Navbar>
    </>
  );
};

export default NuevaParteInteresada;
